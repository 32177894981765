<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="155" viewBox="0 0 96 155" fill="none">
        <path fill="#fff" d="M15.65 116.55c1.74 19.58 18.55 35.07 38.85 35.07 21.45 0 39-17.29 39-38.43V40.93C93.5 19.79 76 2.5 54.5 2.5c-21.45 0-39 17.29-39 38.43v61.88" />
        <path fill="#fff" stroke="#25282A" stroke-miterlimit="10" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M15.65 116.55c1.74 19.58 18.55 35.07 38.85 35.07 21.45 0 39-17.29 39-38.43V40.93C93.5 19.79 76 2.5 54.5 2.5c-21.45 0-39 17.29-39 38.43v61.88" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M24.35 40.81c-.003-2.26.262-4.513.79-6.71a29.79 29.79 0 0 1 29-22.62A29.81 29.81 0 0 1 83.07 34c.54 2.233.812 4.523.81 6.82l-59.53-.01Z" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M84.5 49.81v62.76c0 16.26-13.5 29.56-30 29.56a30.136 30.136 0 0 1-25-13.3l-1-1.77M24.5 93.81v-44" />
        <path fill="#fff" d="M38.92 117a16.139 16.139 0 0 0 27.358 3.919 16.138 16.138 0 0 0-9.285-26.031 16.14 16.14 0 0 0-18.073 9.522" />
        <path fill="#fff" stroke="#25282A" stroke-miterlimit="10" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M38.92 117a16.139 16.139 0 0 0 27.358 3.919 16.138 16.138 0 0 0-9.285-26.031 16.14 16.14 0 0 0-18.073 9.522" />
        <path fill="#fff" stroke="#25282A" stroke-miterlimit="10" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M2.5 110.62v.38a7.101 7.101 0 0 0 7.32 6.81h44.36A7.1 7.1 0 0 0 61.5 111v-.38a7.09 7.09 0 0 0-7.32-6.81H9.82a7.09 7.09 0 0 0-7.32 6.81Z" />
        <path stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M53.5 21.81h2" />
    </svg>
</template>