<template>
    <button
        :disabled="disabled"
        @click="toggleFilter"
        class="btn-app-icon-black-on-gray"
    >
        <BaseSvg name="icon-filter" />
    </button>
    <Transition name="search-pop">
        <dropdown-wrapper
            v-click-outside="closeFilter"
            v-if="show_filter"
            class="bg-gray-tier-3 rounded border border-keyline-primary shadow-md absolute"
            :offset="{ top: 4, left: -2.5}"
            :class="props.class"
        >
                <ul>
                    <template v-for="(option, key) in options">
                        <li class="px-3.5 py-2 border-b border-keyline-primary">
                            <seclock-input
                                type="checkbox"
                                :label="option.label"
                                @change="onChange(option.facet,option)"
                                v-model="state.options[option.facet]"
                                :disabled="!_.get(searchFacets, option.facet+'.0.data', []).length"
                            />
                        </li>
                        <li v-if="option.facet && state.options[option.facet]" class="border-b border-keyline-primary">
                            <div class="bg-gray-tier-4 p-4 pb-1">
                                <span class="text-center items-center justify-center">
                                    <SeclockMultiselect
                                        v-model="state.filters[option.facet]"
                                        :options="_.get(searchFacets, option.facet+'.0.data', [])"
                                        placeholder="Select"
                                        :can-clear="false"
                                        :hideSelected="false"
                                        mode="single"
                                        :option-is-object="true"
                                        label-by="value"
                                        :large="false"
                                        :close-on-select="true"
                                        :searchable="true"
                                        class="mb-4"
                                        @deselect="removeFilter(option.facet,$event.value)"
                                        @select="setFilter(option.facet,$event.value)"
                                    >
                                        <template v-slot:option="{ option }">
                                            <div class="flex items-center justify-between gap-4 w-full">
                                                <span>{{ option.value }}</span>
                                                <BaseSvg name="icon-check" class="shrink-0 hidden" />
                                            </div>
                                        </template>
                                    </SeclockMultiselect>

                                </span>
                            </div>
                        </li>
                    </template>
                    <li class="border-b border-keyline-primary">
                        <button class="px-3.5 py-2 w-full text-left text-xs type-secondary" @click="resetFilter">Reset</button>
                        <divider />
                    </li>
                </ul>
        </dropdown-wrapper>
    </Transition>
</template>

<script setup>
import { DropdownWrapper, Divider, SeclockInput, BaseSvg, SeclockMultiselect } from '@/Components';
import { reactive, ref } from 'vue';
import {usePinSearch} from "@/Composables";

const {
    searchTerm,
    searchIsLoading,
    searchResults,
    searchTotalResults,
    searchHasResults,
    searchFacets,
    searchWasSearched,
    searchClearFilters,
    searchState,
    searchReset,
    searchRefresh,
    searchSuggestions,
    searchTrackClick,
    searchCurrentPage,
    searchSortBy,
    searchResultsPerPage,
    searchAddFilter,
    searchSetFilter,
    searchRemoveFilter,
    searchAppliedFilters,
    searchFacetLabels,
} = usePinSearch(true);

const props = defineProps({
    options: {
        type: Array,
        required: true,
        default: [],
    },
    initial: {
        type: Array,
        required: false,
        default: [],
    },
    class: {
        type: String,
        required: false,
        default: '',
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const show_filter = ref(false);

const state = reactive({
    options: {},
    filters: {}
});

function toggleFilter() {
    if(!show_filter.value) {
        refresh_state();
    }
    show_filter.value = !show_filter.value;
}

function setFilter(facet, value) {
    searchSetFilter(facet,value)
    state.filters[facet] = {value: value}
}

function removeFilter(facet, value) {
    searchRemoveFilter(facet,value)
    delete state.filters[facet];
}

function initializeState() {
    state.options = {}
    state.filters = {}

    props.options.forEach((option) => {
        state.options[option.facet] = false
        state.filters[option.facet] = {value : ''};
    })
}

function refresh_state() {
    initializeState()

    Object.entries(searchAppliedFilters.value ?? [])
        .filter(([key,filter]) => {
            return key !== 'trays';
        })
        .forEach(([facet, filter]) => {
            state.options[facet] = true;
            state.filters[facet] = {value: filter[0].value};
        });
}

function onChange(key, option) {
    //If we clear the option (checkbox) and we had any filters applied (multiselect)
    // clear the filter and refresh the state
    if(!state.options[option.facet] && searchAppliedFilters?.value[option.facet]) {
        console.log("Remove this filter", option.facet);
        searchRemoveFilter(option.facet,searchAppliedFilters.value[option.facet][0].value);
        searchRefresh();
        refresh_state();
    }
}

function resetFilter() {
    searchClearFilters(['trays'])
    initializeState()
}

function closeFilter() {
    show_filter.value = false
}

</script>
