<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="155" viewBox="0 0 129 155" fill="none">
        <path fill="#fff" stroke="#25282A" stroke-miterlimit="10" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M94.56 2.5H12.35a9.85 9.85 0 0 0-9.85 9.85v130.3a9.85 9.85 0 0 0 9.85 9.85h82.21a9.942 9.942 0 0 0 9.94-9.94V12.44a9.94 9.94 0 0 0-9.94-9.94ZM106.05 54.5h6.9a1.55 1.55 0 0 0 1.55-1.55v-26.9a1.55 1.55 0 0 0-1.55-1.55h-6.9a1.55 1.55 0 0 0-1.55 1.55v26.9c0 .856.694 1.55 1.55 1.55Z" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M92.5 125.5v9.93a3.057 3.057 0 0 1-1.894 2.839 3.063 3.063 0 0 1-1.176.231H17.57a3.063 3.063 0 0 1-3.07-3.07V40.5" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M46.5 14.5h42.93a3.06 3.06 0 0 1 3.07 3.07v93.93" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M14.5 28.5V17.57a3.06 3.06 0 0 1 3.07-3.07H34.5" />
        <path fill="transparent" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-transparent dark:stroke-gray-475" d="M116.5 38.7a1.41 1.41 0 0 1 1.55-1.2H125a1.419 1.419 0 0 1 1.55 1.2v113.8" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M14.5 40.05a1.54 1.54 0 0 1 1.55-1.55H23a1.54 1.54 0 0 1 1.55 1.55V67.5" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M34.5 38.5V79a1.54 1.54 0 0 0 1.55 1.55H43A1.54 1.54 0 0 0 44.5 79V16.05a1.54 1.54 0 0 1 1.55-1.55" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M62.5 34.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM44.5 120.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M88.5 115.5h-4.45a1.541 1.541 0 0 1-1.55-1.5V28.05A1.54 1.54 0 0 0 81 26.5h-6.9a1.54 1.54 0 0 0-1.55 1.55V126a1.542 1.542 0 0 1-1.55 1.5h-6.9a1.546 1.546 0 0 1-1.114-.415A1.549 1.549 0 0 1 62.5 126V35.5" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M24.5 63.5V125a1.543 1.543 0 0 0 .955 1.435c.189.077.391.116.595.115H33a1.538 1.538 0 0 0 1.5-1.55v-21.9a1.543 1.543 0 0 1 .955-1.435c.189-.077.391-.116.595-.115H43a1.553 1.553 0 0 1 1.435.955c.077.189.116.391.115.595v8.45" />
        <path fill="#fff" stroke="#25282A" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" class="dark:fill-gray-785 dark:stroke-gray-475" d="M92.5 106.5v7.41a1.588 1.588 0 0 1-1.59 1.59H87.5" />
    </svg>
</template>