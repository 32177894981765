import _ from "lodash";

export default function useAssetTransforms() {
    function assetUrl(asset, transform = "product-card") {
        let external_id = asset?.external_id ?? asset;
        if (external_id) {
            return external_id
                ? `https://assets.seclock.com/image/private/t_${transform}/${external_id}`
                : false;
        }
        return false;
    }

    function marketingAssetUrl(asset, transform = "") {
        let external_id = asset?.external_id ?? asset;
        if (external_id) {
            let transformQueryParameter = transform ? "/t_" + transform : "";
            return `https://marketing-assets.seclock.com/image/upload${transformQueryParameter}/${external_id}`;
        }
        return false;
    }

    function marketingAssetPdfThumbnail(asset, transforms = "") {
        let external_id = asset?.external_id ?? asset;
        if (external_id) {
            return `https://marketing-assets.seclock.com/image/upload/${transforms}/${external_id}.jpg`;
        }
        return false;
    }

    function youTubeUrl(id) {
        return `https://www.youtube.com/watch?v=${id}`;
    }

    function youTubeEmbed(id, autoplay = false) {
        let autoplayParameter = autoplay ? "?autoplay=1" : 1;
        return `https://www.youtube.com/embed/${id}${autoplayParameter}`;
    }

    function youTubeThumbnail(id) {
        return `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
    }

    function productCardImage(product) {
        let thumb = _.get(product, "display_thumbnails.0", _.get(product, "display_thumbnails.0", false));
        if (thumb) {
            return assetUrl(thumb);
        }
        let logo = _.get(product, "display_logo", _.get(product, "display_logo", false));
        if (logo) {
            return marketingAssetUrl(logo, "logo-replace-dmode");
        }
        let pin = _.get(product, "thumbnail", _.get(product, "thumbnail", false));
        if (pin) {
            return assetUrl(pin);
        }

        return false;
    }

    function productImage(summary, selectedImage = 0, logoDefault = true, logoTransform = null, transform = "product-detail", ) {
        let image = _.get(summary, `assets.product-image.${selectedImage}`, false);
        if (image) {
            return assetUrl(image, transform);
        }

        if (logoDefault) {
            //default transform
            let logo_transform = logoTransform ? logoTransform : "";
            let logo = _.get(summary, "vendor.logo.external_id", false);
            if (logo) {
                return marketingAssetUrl(logo, logo_transform);
            }
        }

        return false;
    }

    return {
        assetUrl,
        marketingAssetUrl,
        marketingAssetPdfThumbnail,
        productCardImage,
        productImage,
        youTubeEmbed,
        youTubeUrl,
        youTubeThumbnail,
    };
}
