<template>
    <component
        @keyup.esc="useDialog().forceClose"
        v-if="wrap"
        :is="wrap"
        :visible="visible"
        :zindex="zindex"
        :under="under"
        @before-leave="beforeLeave($refs.content)"
        @after-enter="afterEnter($refs.content)"
    >
        <component v-if="dialog" :is="dialog" :data="data" ref="content" />
    </component>
    <component
        @keyup.esc="useDialog().forceClose"
        v-else-if="visible && dialog"
        :is="dialog"
        :data="data"
        :zindex="zindex"
        ref="content"
    />
</template>

<script setup>
import { useDialog } from "@/Composables";
import { nextTick } from "vue";

const { dialog, wrap, trigger, visible, zindex, data, clearTrigger, under } = useDialog();

const beforeLeave = (contentRef) => {
    try {
        document.querySelectorAll("[data-inert='true']").forEach((element) => {
            element.removeAttribute("inert", "");
        });
    } catch (error) {

    }
};

const afterEnter = (contentRef) => {
    try {
        nextTick().then(() => {
            document
                .querySelectorAll("[data-inert='true']")
                .forEach((element) => {
                    element.setAttribute("inert", "");
                });
            document
                .querySelector("[data-dialog-focus='true'], [autofocus]")
                ?.focus();
        });
    } catch (error) {

    }
};
</script>
