<template>
    <span
        v-if="type === 'dot'"
        class="font-medium whitespace-nowrap relative before:relative after:relative before:transition-colors after:transition-colors"
        :class="[
            classes,
            {
                'before:bg-gray-500 after:bg-gray-500': status === 'New',
                'before:bg-white after:bg-white':
                    status === 'Approved' && state.showParticles,
                'before:bg-apple after:bg-apple':
                    status === 'Approved' && !state.showParticles,
                'before:bg-amber after:bg-amber': status === 'In Progress',
                'before:bg-scarlet after:bg-scarlet': status === 'On Hold',
                'before:bg-transparent after:bg-transparent': status === 'All',
            },
            {
                'before:top-px after:top-px': !button,
                'before:opacity-0 after:opacity-0': loading,
            },
            {
                bullet: placement === 'before',
                'bullet-after': placement === 'after',
            },
        ]"
    >
        <transition
            enter-from-class="opacity-0 scale-50"
            enter-to-class="opacity-100 scale-100"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-50"
        >
            <loading-circle
                v-if="loading"
                class="absolute w-3 h-3 top-2.5 left-2.5 transition"
            />
        </transition>
        <status-animation v-if="state.showParticles" />
        <slot>{{ status }}</slot>
    </span>
    <span
        v-else-if="type === 'badge'"
        class="font-semibold leading-none px-2 py-1 rounded-3xl text-white text-3xs uppercase"
        :class="{
            'bg-gray-650': props.status === 'New',
            'bg-apple': props.status === 'Approved',
            'bg-amber': props.status === 'In Progress',
            'bg-scarlet': props.status === 'On Hold',
        }"
    >
        {{ status }}
    </span>
</template>

<script setup>
import { reactive, watch } from "vue";
import { LoadingCircle, StatusAnimation } from "@/Components";

const props = defineProps({
    type: {
        type: String,
        required: false,
        validator: (value) => ["dot", "badge"].includes(value),
        default: "dot",
    },
    status: {
        type: String,
        required: true,
    },
    placement: {
        type: String,
        required: false,
        default: "before",
    },
    classes: {
        type: String,
        required: false,
        default: "",
    },
    button: {
        type: Boolean,
        required: false,
        default: false,
    },
    animate: {
        type: Boolean,
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const state = reactive({
    showParticles: false,
});

watch(
    () => props.status,
    (newStatus) => {
        if (props.animate && newStatus === "Approved") {
            state.showParticles = true;
            setTimeout(() => {
                state.showParticles = false;
            }, 700);
        }
    }
);
</script>
