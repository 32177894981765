import config from "@/config";
import useDialog from "./useDialog";
import { ModalTransition, TrayAddToDialog } from "@/Components";

const { toggleDialog, forceClose } = useDialog();

export default function useTrayAddToDialog() {
    const toggleTrayAddToDialog = (event, data) => {
        toggleDialog(
            TrayAddToDialog,
            ModalTransition,
            data,
            event,
            config.dialogs.drawers.pinsDialog.zindex,
            true
        );
    };

    return {
        toggleTrayAddToDialog,
        forceClose,
    };
}
