<template>
    <div
        data-dialog="true"
        id="settings-panel"
        class="absolute panel-standard p-2 right-[-10px] rounded-md top-full translate-y-2 w-112"
    >
        <div class="px-4 pt-4 mb-3">
            <h5 class="font-semibold mb-3 text-lg">{{ $t("Quick settings") }}</h5>
            <!-- <Divider class="mb-3" />
            <h6 class="font-semibold mb-2 text-gray-475 text-xs tracking-wider uppercase">{{ $t("Density") }}</h6>
            <ul class="flex justify-between mb-3">
                <li v-for="(setting, i) in densitySettings" :key="i">
                    <label class="capitalize">
                        <span
                            aria-hidden="true"
                            :class="setting.settingClasses"
                            class="block h-20 mb-2 w-[123px]"
                        ></span>
                        <input
                            type="radio"
                            name="density"
                            v-model="selectedDensity"
                            @click="eventBus.emit('user:set-density', setting.name)"
                            :value="setting.name"
                            class="mr-1 -translate-y-px"
                        />
                        {{ setting.name }}
                    </label>
                </li>
            </ul>
            <Divider /> -->
        </div>
        <div class="m-4 mt-6 px-4 py-3 rounded-md bg-gray-130 dark:bg-gray-650">
            <label aria-live="polite" class="flex items-center text-sm">
                <div
                    aria-hidden="true"
                    class="bg-dark-mode-off h-12 w-12 dark:bg-dark-mode-on"
                ></div>
                <div class="ml-4">
                    <div class="leading-none">{{ $t("Dark mode") }}</div>
                    <div class="capitalize leading-none opacity-60 dark:hidden">
                        {{ $t("is off") }}
                    </div>
                    <div
                        class="hidden capitalize leading-none opacity-60 dark:block"
                    >
                        {{ $t("is on") }}
                    </div>
                </div>
                <span
                    aria-hidden="true"
                    class="bg-gray border-2 border-transparent cursor-pointer flex-shrink-0 h-6 inline-flex ml-auto relative rounded-full transition-colors w-11 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue dark:bg-black"
                >
                    <span
                        class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 dark:translate-x-5"
                    ></span>
                    <input
                        type="checkbox"
                        class="sr-only"
                        :disabled="inert"
                        :checked="settings.is_dark_mode"
                        @click="eventBus.emit('user:toggle-dark-mode')"
                    />
                </span>
            </label>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import { Divider } from "@/Components";
import { eventBus, useUser } from "@/Composables";

const { settings } = useUser();

let selectedDensity = ref(settings.value.density);
onMounted(() => console.log('setting:', settings.value.density));

const densitySettings = [
    {
        name: "default",
        settingClasses: "bg-density-default-light dark:bg-density-default-dark",
    },
    {
        name: "compact",
        settingClasses: "bg-density-compact-light dark:bg-density-compact-dark",
    },
    {
        name: "dense",
        settingClasses: "bg-density-dense-light dark:bg-density-dense-dark",
    },
];
</script>
