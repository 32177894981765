<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#000000"
    >
        <g>
            <rect fill="none" height="24" width="24" />
        </g>
        <g>
            <path
                d="m634-479 78 77v75H517.5v233L480-56l-37.5-38v-233H248v-75l78-77v-278h-40v-75h388v75h-40v278Zm-279 77h250l-46-45v-310H401v310l-46 45Zm125 0Z"
            />
        </g>
    </svg>
</template>
