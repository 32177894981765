<template>
    <MarketingModal :title="data?.card?.display_sku" v-if="!settings.is_sourcing_mode">
        <ul class="flex items-center flex-col w-full p-8 at480:flex-row at1024:p-16">
            <li class="w-full">
                <button
                    :disabled="data.pricing?.price === 0 || (!data.availability?.total && data.card?.filter_status?.toUpperCase() === 'D') || inert"
                    @click="eventBus.emit('cart:add-item',{sku:data.card.item_id})"
                    class="disabled:opacity-50 w-full p-4 at768:p-6 text-center block group hover:bg-gray-125 active:bg-gray-150"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-shopping-cart"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 fill-white bg-gray-800 p-4 rounded-full mb-4"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t("Add To Cart") }}
                    </span>
                </button>
            </li>
            <li class="w-full">
                <Pin type="item"
                     :id="data?.card?.item_reference_id"
                     :modal-button="true"
                />
            </li>
            <!--<li class="w-full">
                <button
                    :disabled="(!data.availability?.total && data.card?.filter_status?.toUpperCase() !== 'D') || inert"
                    @click="eventBus.emit('cart:add-item',data)"
                    class="disabled:opacity-50 w-full p-4 at768:p-6 text-center block group hover:bg-gray-125 active:bg-gray-150"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-bookmark-border"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 fill-gray bg-gray-125 p-4 rounded-full mb-4 group-hover:bg-white"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t('Add To Compare') }}
                    </span>
                </button>
            </li>-->
        </ul>
    </MarketingModal>
    <AppModal v-else :title="data?.card?.item_id" :close-on-success="true">
      <template #content>
        <div class="p-4 flex flex-col w-full at768:flex-row items-center at1024:w-[700px]">
            <template v-for="button in addButtons">
                <div v-if="button.name === 'pin'" class="group w-full at768:w-1/4 hover:bg-gray-tier-5 active:bg-gray-tier-6 h-full rounded-md">
                    <Pin
                        type="item"
                        :id="data?.card?.item_reference_id"
                        :modal-button="true"
                    />
                </div>
                <button
                    v-else
                    @click.prevent="button.action"
                    :class="{'opacity-50': inert}"
                    :disabled="inert"
                    v-show="!button.hidden"
                    class="w-full at768:w-1/4 shrink-0 group p-8 hover:bg-gray-tier-5 active:bg-gray-tier-6 rounded-md"
                >
                <span class="w-fit mb-2 mx-auto flex items-start justify-center bg-gray-tier-2 p-4 rounded-full">
                    <LoadingSpinner v-if="isLoading === button.name" />
                    <BaseSvg
                        v-else
                        :name="button.icon"
                        :class="{
                            'fill-red':button.isToggled,
                        }"
                        class="w-8 h-8"
                    />
                </span>
                    <span class="flex justify-center whitespace-nowrap">{{ button.name }}</span>
                </button>
            </template>
        </div>
      </template>
      <template #footer>
        <div class="hidden"></div>
      </template>
    </AppModal>
</template>

<script setup>
import {BaseSvg, LoadingSpinner, Pin, MarketingModal} from "@/Components";
import {eventBus, useAddToProjectDialog, useMetadata, usePinsDrawer, useUser} from "@/Composables";
import {computed, onMounted, ref} from "vue";
import AppModal from "@/Components/Modules/Dialogs/AppModal.vue";

const { fetchPin, togglePin } = usePinsDrawer();
const props = defineProps(['data']);
const {toggleAddToProjectDialog} = useAddToProjectDialog();
const {settings} = useUser();

let isPinned = ref(false);
let isLoading = ref('');

const { meta, loadMeta, isLoadingMeta } = useMetadata(
    { sku: props.data.card.slug },
    ["summary", "options", "availability", "pricing"],
);

function onPinFetched(pinData) {
    if(pinData) {
        isPinned.value = true;
    }
}

function onPinToggled() {
    isPinned.value = !isPinned.value;
}

onMounted(() => {
    fetchPin('item', props.data.card.item_reference_id);
    eventBus.on('pins:pin-fetched', onPinFetched)
    loadMeta();
});

const addButtons = computed(() => [
    {
        name: 'Add to cart',
        icon: 'icon-shopping-cart',
        action: () => {
            isLoading.value = 'Add to cart';
            eventBus.emit('cart:add-item', {sku: props.data.card.item_id});
            isLoading.value = '';
        },
        hidden: false,
    },
    {
        name: 'pin',

        // name: isPinned.value ? 'Remove from pins' :'Add to pins',
        // icon: isPinned.value ? 'icon-push-pin' : 'icon-push-pin-outlined',
        // isToggled: isPinned.value ?? false,
        // action: () => {
        //     onPinToggled();
        //     togglePin('item', props.data.card.item_reference_id);
        // },
        // hidden: false,

    },

    // {
    //     name: 'Add to compare',
    //     icon: 'icon-bookmark-border',
    //     action: event => {
    //         isLoading.value = 'Add to compare';
    //         console.log(event, 'add to compare')
    //         isLoading.value = '';
    //     },
    //     hidden: false,
    //},
    {
        name: 'Add to project',
        icon: 'icon-projects-outline',
        action: event => {
            toggleAddToProjectDialog(event, props.data);
        },
        hidden: false,
    },
]);
</script>
