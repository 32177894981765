<template>
    <transition appear name="search-pop" v-bind="$attrs">
        <div
            v-show="visible"
            class="fixed inset-0 flex justify-center items-center h-screen w-screen overflow-y-auto overflow-x-hidden"
            :class="'z-' + zindex"
        >
            <slot></slot>
        </div>
    </transition>
</template>
<style>
.search-pop-enter-active,
.search-pop-leave-active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.search-pop-enter-from,
.search-pop-leave-to {
    transform: scale(0.97);
    opacity: 0;
    transform-origin: top center;
}
</style>
<script>
export default {
    name: "modal-transition-component-component",
    inheritAttrs: false,
    props: {
        component: {
            type: Object,
            required: false,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        zindex: {
            type: Number,
            required: true,
        },
    },
};
</script>
