<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="48"
        fill="none"
        viewBox="0 0 30 48"
    >
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.523"
            d="M25.582.92H10.787A2.962 2.962 0 0 0 7.825 3.88v4.047H4.388a2.962 2.962 0 0 0-2.961 2.961v25.46a2.962 2.962 0 0 0 2.961 2.961h3.437v4.351a2.962 2.962 0 0 0 2.962 2.962h14.795a2.962 2.962 0 0 0 2.962-2.962V3.881A2.961 2.961 0 0 0 25.582.92Z"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.523"
            d="M18.183 9.146a1.523 1.523 0 1 0 0-3.047 1.523 1.523 0 0 0 0 3.047ZM18.183 41.443a1.523 1.523 0 1 0 0-3.047 1.523 1.523 0 0 0 0 3.047Z"
        />
        <path
            fill="none"
            d="M12.7 14.021v-2.438H5.54a.761.761 0 0 0-.761.762v6.508a.912.912 0 0 0 0 .131.868.868 0 0 0 0 .128v15.78a.762.762 0 0 0 .761.762h7.16v-2.438"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.523"
            d="M12.7 14.021v-2.438H5.54a.761.761 0 0 0-.761.762v6.508a.912.912 0 0 0 0 .131.868.868 0 0 0 0 .128v15.78a.762.762 0 0 0 .761.762h7.16v-2.438"
        />
        <path
            fill="none"
            d="M5.388 30.778h10.511a.771.771 0 0 0 .762-.761V18.294a.896.896 0 0 0 0-.13.936.936 0 0 0 0-.135v-.81a.77.77 0 0 0-.762-.762H5.388"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.523"
            d="M5.388 30.778h10.511a.771.771 0 0 0 .762-.761V18.294a.896.896 0 0 0 0-.13.936.936 0 0 0 0-.135v-.81a.77.77 0 0 0-.762-.762H5.388"
        />
    </svg>
</template>
