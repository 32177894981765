<template>
    <div class="block at768:flex print:flex justify-between items-center bg-gray-tier-5 uppercase type-primary rounded overflow-hidden border border-gray-200 dark:border-gray-850">
        <div class="flex items-center gap-8 p-4 at768:w-2/3 print:w-2/3">
            <div>
                <div class="text-2xs text-light tracking-wider type-secondary">
                    {{ $t("Sub Total") }}
                </div>
                <div class="text-xl font-medium">
                    {{ showMeTheMoney(record.subtotal) }}
                </div>
            </div>
            <div v-if="record.tax">
                <div class="text-2xs text-light tracking-wider type-secondary">
                    {{ $t("Tax") }}
                </div>
                <div class="text-xl font-medium">
                    {{ showMeTheMoney(record.tax) }}
                </div>
            </div>
            <div v-if="!hideFreight">
                <div class="text-2xs text-light tracking-wider type-secondary">
                    {{ $t("Freight") }}
                </div>
                <div class="text-xl font-medium">
                    {{ showMeTheMoney(record.freight) }}
                </div>
            </div>
            <div v-if="record.gross_profit">
                <div class="text-2xs text-light tracking-wider type-secondary">
                    {{ $t("GP") }}
                </div>
                <div class="text-xl font-medium">
                    {{ record.gross_profit }}%
                </div>
            </div>
        </div>
        <div class="at768:w-1/3 print:w-1/3 p-4 text-white bg-gray-tier-1 text-right">
            <div class="text-2xs text-light tracking-wider opacity-50">
                {{ $t("Order Total") }}
            </div>
            <div class="text-xl font-bold">
                {{ showMeTheMoney(record.total) }}
                <span class="opacity-50">USD</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useHelpers } from "@/Composables";

const props = defineProps({
    record: {
        type: Object,
        required: true,
    },
    hideFreight: {
        type: Boolean,
        default: false,
    },
});
const { showMeTheMoney } = useHelpers();
</script>
