<template>
    <!-- Drawer -->
    <aside
        data-dialog="true"
        aria-label="Product search panel"
        class="bg-white h-full w-screen at1024:w-[880px]"
    >
        <!--         Content -->
        <div id="wrapper" class="relative w-full at1024:px-8 at1024:pt-8 h-[calc(100vh_-_var(--header-height))] at1024:flex overflow-visible">
            <div id="container" ref="container" class="relative grow overflow-y-auto h-[calc(100vh_-_var(--header-height))] at1024:h-auto at1024:w-3/4">
                <form class="sticky top-0 z-10 flex items-center bg-white border-b at1024:border-gray-tier-7 px-6 at1024:px-2 py-0.5">
                        <input
                            ref="searchInput"
                            type="text"
                            autofill="off"
                            autocomplete="off"
                            data-dialog-focus="true"
                            v-model="searchTerm"
                            class="w-full text-lg border-none font-bold !px-0 focus:ring-0 placeholder:font-normal placeholder:text-base at1024:placeholder:text-lg"
                            :placeholder="$t('Search by keyword, product or manufacturer')"
                            @keydown.enter.prevent="() => {}"
                        />
                        <div v-if="searchIsLoading" class="flex items-center justify-start px-1.5">
                            <LoadingSpinner />
                        </div>
                        <button
                            v-else
                            type="button"
                            @click="() => resetForm()"
                            :disabled="!searchTerm.length"
                            class="flex group w-8 h-8 duration-150 items-center justify-center disabled:!cursor-default disabled:opacity-50 disabled:pointer-events-none"
                        >
                            <BaseSvg
                                name='icon-refresh'
                                class="duration-150 fill-gray p-1 rounded-full group-hover:fill-black group-hover:bg-gray-125 group-active:bg-gray-150"
                            />
                            <span class="sr-only">{{ $t("Reset search") }}</span>
                        </button>
                    </form>
                <div v-if="isLoadingMeta" class="px-6 at1024:px-0 py-8">
                    <LoadingSpinner />
                </div>
                <template v-else>
                    <div :class="searchIsLoading ? 'opacity-25' : ''" class="overflow-y-auto at1024:overflow-visible">
                        <!--Recent searches-->
                        <section
                            v-if="(!searchTerm.length || !searchResultsTerm.length)  && useSettingsLoader().settings('recently_queried')?.length"
                            id="recent"
                            class="mt-8 px-4 at1024:px-0"
                        >
                            <h2 class="text-gray font-medium mb-2 px-2">{{ $t("Recent searches") }}</h2>
                            <ul>
                                <li v-for="recentSearch in useSettingsLoader().settings('recently_queried')">
                                    <button
                                        @click.prevent="() => searchTermQuick = recentSearch"
                                        class="group flex items-center gap-2 w-full py-1.5 hover:bg-gray-125 active:bg-gray-150 rounded-sm"
                                    >
                                        <BaseSvg name="icon-search" class="fill-gray pl-1"/>
                                        <span class="text-base at1024:text-lg font-bold group-hover:underline underline-offset-4 decoration-1">
                                        {{ recentSearch }}
                                    </span>
                                    </button>
                                </li>
                            </ul>
                        </section>
                        <section
                            v-if="(!searchTerm.length || !searchResultsTerm.length ) && useSettingsLoader().settings('recently_viewed')?.length"
                            id="recent_products"
                            class="px-4 at1024:px-0 mt-8 pb-8"
                        >
                            <h2 class="text-gray font-medium mb-2 px-2">
                                {{ $t("Recent products") }}
                            </h2>
                            <ul>
                                <li v-for="product in useSettingsLoader().settings('recently_viewed')">
                                    <Link
                                        :href="route('web.catalog.products.show',product?.slug)"
                                        class="px-2 py-3 group flex items-center hover:bg-gray-125 active:bg-gray-150"
                                    >
                                        <div class="w-28 shrink-0" v-if="product.display_thumbnails.length">
                                            <img
                                                class="max-h-24 mx-auto"
                                                :src="productCardImage(product)"
                                                :alt="product.display_thumbnails[0]"
                                            />
                                        </div>
                                        <span v-else class="block h-24 py-2">
                                        <BaseSvg name="icon-product-empty" class="block p-6 w-28 h-auto bg-black fill-gray"/>
                                    </span>
                                        <span class="block text-left ml-6 w-80">
                                        <span class="block type-secondary font-medium text-sm">
                                            {{product.filter_manufacturer }}
                                        </span>
                                        <span class="block type-primary text-lg font-bold">
                                            {{ product.display_sku }}
                                        </span>
                                        <span class="block type-primary text-sm">
                                            {{ product.display_short_description }}
                                        </span>
                                    </span>
                                        <div
                                            v-if="user"
                                            class="hidden at1024:group-hover:flex items-center justify-end w-fit ml-auto mr-4 shrink-0"
                                        >
                                            <div class="relative h-fit flex items-center justify-start">
                                                <button
                                                    @mouseover="() => hintSeen = true"
                                                    @mouseleave="() => hintSeen = false"
                                                    @click.prevent="(event)=>{eventBus.emit('cart:add-item',{sku:product.display_sku})}"
                                                    :disabled="inert"
                                                    class="mx-auto relative btn-icon-black-outline-pill"
                                                >
                                                    <BaseSvg name="icon-shopping-cart" class="w-5 h-5"/>
                                                </button>
                                                <HintTransition name="fade-top" class="ml-0.5 capitalize" :hint-seen="hintSeen">
                                                    {{ $t("Add to cart") }}
                                                </HintTransition>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </section>
                        <!-- suggested searches -->
                        <section v-if="searchHasResults && searchResultsTerm.length  && searchTerm.length" class="pt-4 mb-3 border-b mx-4 at1024:mx-0">
                            <ul class="mb-4">
                                <template v-for="(suggestions, key) in searchSuggestions">
                                    <li v-for="(suggestion, key) in suggestions" class="w-full mb-1 group">
                                        <a
                                            v-if="suggestion?.label"
                                            :href="route('web.catalog.products.index', suggestion.query) "
                                            class="flex flex-col group"
                                            @click="() => {
                                                eventBus.emit('search:suggestion-clicked',searchTerm);
                                            }"
                                        >
                                        <span class="w-full flex items-center py-1 px-4 rounded group-hover:underline underline-offset-4 hover:bg-gray-tier-4 active:bg-gray-tier-5">
                                            {{ suggestion.label }}
                                        </span>
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </section>
                        <!-- results -->
                        <section v-if="searchHasResults && searchResultsTerm.length && searchTerm.length" class="px-4 at1024:px-0">
                            <ul class="mb-16">
                                <li v-for="product in searchResults">
                                    <Link
                                        :href="route('web.catalog.products.show',product?.slug )"
                                        class="px-2 py-3 group flex items-center hover:bg-gray-125 active:bg-gray-150"
                                        :data="{ query: searchTerm }"
                                        @click="() => searchTrackClick(product?.id)"
                                    >
                                        <div class="w-28 shrink-0" v-if="product.display_thumbnails?.length">
                                            <img
                                                class="max-h-24 mx-auto"
                                                :src="productCardImage(product)"
                                                :alt="product.display_thumbnails[0]"
                                            />
                                        </div>
                                        <span v-else class="block h-24 py-2">
                                        <BaseSvg name="icon-product-empty" class="block p-6 w-28 h-auto bg-black fill-gray"/>
                                    </span>
                                        <span class="block text-left ml-6 w-80">
                                        <span class="block font-medium type-secondary text-sm">
                                            {{ product.filter_manufacturer }}
                                        </span>
                                        <span class="block type-primary text-lg font-bold">
                                            {{ product.display_sku }}
                                        </span>
                                        <span class="block type-primary text-sm">
                                            {{product.display_short_description }}
                                        </span>
                                    </span>
                                        <div
                                            v-if="user"
                                            class="hidden at1024:group-hover:flex items-center justify-end w-fit ml-auto mr-4 shrink-0"
                                        >
                                            <div class="relative h-fit flex items-center justify-start">
                                                <button
                                                    @mouseover="() => hintSeen = true"
                                                    @mouseleave="() => hintSeen = false"
                                                    @click.prevent="(event)=>{eventBus.emit('cart:add-item',{sku:product.item_id})}"
                                                    :disabled="inert"
                                                    class="mx-auto relative btn-icon-black-outline-pill"
                                                >
                                                    <BaseSvg name="icon-shopping-cart" class="w-5 h-5"/>
                                                </button>
                                                <HintTransition name="fade-top" class="ml-0.5 capitalize" :hint-seen="hintSeen">
                                                    {{ $t("Add to cart") }}
                                                </HintTransition>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li class="pb-16">
                                    <div class="bg-gray-125 p-4 mt-4">
                                        <h5 class="font-bold type-primary">{{ $t("Can't find what you are looking for?") }}</h5>
                                        <span>
                                        {{ $t("With nearly 10 million SKU's in our database, chances are we have it") }}.
                                    </span>
                                        <div>
                                            <button
                                                v-if="!!user"
                                                @click.stop.prevent="() => eventBus.emit('chat:showNewMessage')"
                                                class="text-red underline-offset-4 hover:type-primary hover:underline"
                                            >
                                                {{ $t("Live Chat") }}
                                            </button>
                                            <Link
                                                v-else
                                                :href="route('web.contact')"
                                                class="text-red underline-offset-4 hover:type-primary hover:underline"
                                            >
                                                {{ $t("Contact Us") }}
                                            </Link>
                                            {{ $t("now") }}.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <!-- search performed, but no results -->
                        <section
                            v-if="
                            searchResultsTerm.length &&
                            !searchHasResults &&
                            !searchIsLoading &&
                            searchWasSearched
                        "
                            class="bg-white absolute inset-0 flex items-center justify-start flex-col text-center text-gray mt-12 px-4 at1024:px-0"
                        >
                            <RecordsNotFound
                                icon="icon-zoom-out"
                                :large="true"
                                header="Can't find what you are looking for?"
                                message="With nearly 10 million SKU's in our database, chances are we have it"
                            >
                                <div>
                                    <button
                                        v-if="!!user"
                                        @click.stop.prevent="() => eventBus.emit('chat:showNewMessage')"
                                        class="text-red underline-offset-4 hover:type-primary hover:underline"
                                    >
                                        {{ $t("Live Chat") }}
                                    </button>
                                    <Link v-else
                                        :href="route('web.contact')"
                                        class="text-red underline-offset-4 hover:type-primary hover:underline"
                                    >
                                        {{ $t("Contact Us") }}
                                    </Link>
                                    {{ $t("now") }}.
                                </div>
                            </RecordsNotFound>
                        </section>
                        <!-- no search performed, ever -->
                        <section
                            v-if="
                            (!searchResultsTerm.length || !searchTerm.length) &&
                            !useSettingsLoader().settings('recently_queried')?.length &&
                            !useSettingsLoader().settings('recently_viewed')?.length
                        "
                        >
                            <RecordsNotFound
                                :large="true"
                                icon="icon-zoom-in"
                                header="No recent items yet."
                                message="Start searching for products above."
                            />
                        </section>
                    </div>
                </template>
            </div>
            <div class="hidden at1024:block ml-8 at1024:w-1/4">
                <div class="flex justify-end items-center w-full mb-2">
                    <DialogCloseButton class="z-40 absolute top-4 right-4 ml-auto" :large="true"/>
                </div>
                <h2 class="font-medium text-gray pb-1">{{ $t("Quicklinks") }}</h2>
                <nav class="pb-2 mb-2">
                    <ul>
                        <li v-for="(option, i) in config.catalogOptions" :key="i" class="py-1">
                            <Link
                                :href="route(option.route)"
                                :title="option.title"
                                class="text-gray truncate whitespace-nowrap underline-offset-4 hover:type-primary hover:underline"
                            >
                                {{ option.title }}
                            </Link>
                        </li>
                    </ul>
                </nav>
<!--                <button class="flex items-center gap-2 font-medium text-sm text-blue hover:type-primary group mt-4">-->
<!--                    <BaseSvg name="icon-catalogs-book-outline" class="w-5 h-auto" />-->
<!--                    <span class="whitespace-nowrap group-hover:underline">-->
<!--                        {{ $t("Search like a pro") }}-->
<!--                    </span>-->
<!--                </button>-->
            </div>
        </div>
    </aside>
</template>

<script setup>
import config from "@/config";
import {inject, nextTick, onMounted, onUnmounted, ref, watch} from "vue";

import {
    eventBus,
    useBasicSearchApi,
    useAssetTransforms,
    useUser,
    useSettingsLoader
} from "@/Composables";

import {
    BaseSvg,
    DialogCloseButton,
    HintTransition,
    LoadingSpinner,
    RecordsNotFound,
} from "@/Components";

const { user } = useUser();
const route = inject("route");
const hintSeen = ref(false);
const searchInput = ref(null);


const { productCardImage } = useAssetTransforms();

const {
    searchTerm,
    searchIsLoading,
    searchResults,
    searchHasResults,
    searchWasSearched,
    searchResultsTerm,
    searchSuggestions,
    searchReset,
    searchTrackClick,
    searchTermQuick,
} = useBasicSearchApi(true);

const resetForm = () => {
    searchReset();
    searchInput.value.focus();
}

const container = ref(null);
watch(searchIsLoading,v => {
    if(container.value){
        container.value.scrollTop = 0;
    }
});

const isLoadingMeta = ref(true);

onMounted(() => {
    useSettingsLoader()
        .fetchSettings(['recently_queried','recently_viewed'])
        .finally(() => {
            isLoadingMeta.value = false;
            nextTick(() => {
                if(searchInput.value) {
                    searchInput.value.focus()
                }
            })
        });
});

onUnmounted(()=> searchReset());

</script>

<style scoped>
.mobile-nav-height {
    height: calc(100vh - var(--header-height) - 100px);
}
</style>
