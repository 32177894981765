import { reactive } from "vue";
import _ from "lodash";
import { useUser, eventBus } from "@/Composables";

const chartObjects = reactive({});

const common_chart_options = {
    colors: ['#2364ee', '#119b5c', '#fa6a0d', '#c610c8'],
    chart: {
        background: 'inherit',
        fontFamily: "Calibre, system-ui, sans-serif",
        animations: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
    },
};

const default_chart_options = {
    "area" :
        {
            chart: {...common_chart_options.chart,...{type: "area"} },
            stroke: {
                width: 3,
                curve: "smooth",
                // curve: "straight",
            },
            tooltip: {
                x: {
                    format: "ddd d MMM",
                },
                style: {
                    fontSize: "14px",
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'solid',
                opacity: 0,
                // type: 'gradient',
                // gradient: {
                //     shadeIntensity: 1,
                //     inverseColors: false,
                //     opacityFrom: 0.45,
                //     opacityTo: 0.05,
                //     stops: [20, 100, 100, 100]
                // },
            },
            grid: {
                show: true,
                strokeDashArray: 5,
                // padding: {
                //     top: 0,
                //     right: 30,
                //     bottom: 0,
                //     left: 40,
                // },
            },
            legend: {
                fontSize: '16px',
                itemMargin: {
                    horizontal: 10,
                    vertical: 10,
                }
            },
            // markers: {
            //     size: 5,
            //     // colors: ['transparent'],
            //     // strokeColors: '#fff',
            //     hover: {
            //         size: 7,
            //     },
            // },
            xaxis: {
                axisBorder: {
                    show: false,
                    // offsetY: -5,
                },
                axisTicks: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        fontSize: "11px",
                        fontWeight: 600,
                        cssClass: "tracking-wider uppercase",
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return new Intl.NumberFormat("en-US", {
                            currency: "USD",
                            style: "currency",
                            notation: "compact",
                        }).format(value);
                    },
                },
            },
        },
    "bar" :
        {
            chart: {...common_chart_options.chart,...{type: "bar"} },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: "top",
                    },
                },
            },
            grid: {
                show: true,
                strokeDashArray: 4,
            },
            tooltip: {
                style: {
                    fontSize: "14px",
                },
            },
            // dataLabels: {
            //     offsetY: -20,
            //     style: {
            //         fontSize: "10px",
            //         colors: ['inherit'],
            //     },
            //     formatter: function (value) {
            //         return new Intl.NumberFormat("en-US", {
            //             notation: "standard",
            //         }).format(value);
            //     },
            // },
            legend: {
                itemMargin: {
                    horizontal: 10,
                },
                markers: {
                    width: 10,
                    height: 10,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                axisBorder: {
                    show: true,
                    offsetY: -1,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        fontSize: "11px",
                        fontWeight: 600,
                        cssClass: "tracking-wider uppercase",
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return new Intl.NumberFormat("en-US", {
                            notation: "compact",
                        }).format(value);
                    },
                },
            },
        },
    "pie" :
        {
            chart: {...common_chart_options.chart,...{type: "pie"} },
            stroke: {
                width: 0, // Set this to 0 to remove the gaps between slices
            },
            dataLabels: {
                dropShadow: {
                    enabled: false,
                },
            },
            tooltip: {
                style: {
                    fontSize: "14px",
                },
            },
        },
    "donut" :
        {
            chart: {...common_chart_options.chart,...{type: "donut"} },
            stroke: {
                width: 0, // Set this to 0 to remove the gaps between slices
            },
            plotOptions: {
                pie: {
                    donut: {
                        size:'75%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '12px',
                                fontWeight: 600,
                            },
                            value: {
                                show: true,
                                fontWeight: 600,
                                formatter: function (w) {
                                    return '$'+w.globals.seriesTotals.reduce((a, b) => { //keeps centered title static as total value
                                        return a + b
                                    }, 0).toLocaleString('en-us', {minimumFractionDigits:2})
                                }
                            },
                            total: {
                                show: true,
                                label: 'Sales',
                                fontSize: '12px',
                                fontWeight: 600,
                                formatter: function (w) {
                                    return '$'+w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0).toLocaleString('en-us', {minimumFractionDigits:2})
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false // This will hide the percentage labels on each segment
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, w}) {
                    const totalSum = w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                    let label = w.config.labels[seriesIndex];
                    let value = series[seriesIndex];
                    let percentage = (value / totalSum) * 100;
                    return `<div style="padding: 2px 6px; background-color: black; color: white; font-size: 14px;">
                            <strong style="line-height: 0">${label}</strong>
                            <div style="line-height: 1.5">$${value.toLocaleString('en-us', {minimumFractionDigits: 2})} Sales / ${percentage.toPrecision(3)}%</div>
                        </div>`;
                }
            },
            legend: {
                position: 'right',
                offsetY: 0,
                horizontalAlign: 'center',
                width: '50%',
            },
            // Add responsive options if needed
            responsive: [{
                breakpoint: 1024,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom',
                        width: '100%',
                    }
                }
            }]
        }
}

export default function useApexCharts(chart_id, chart_type = "area", chart_options = {}) {

    const { settings } = useUser();

    chartObjects[chart_id] = {
        //default options
        ...common_chart_options,

        //chart type specific options
        ...default_chart_options[chart_type],

        //Overwrite default options with provided ones.
        ...chart_options
    };

    const darkModeText = 'dark';
    const lightModeText = 'light';
    const darkModeGridHex = '#474747';
    const lightModeGridHex = '#e7e7e7';

    //default dynamic options
    chartObjects[chart_id]['theme'] = {...chartObjects[chart_id]['theme'], ...{mode:settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeText : lightModeText}};
    //grid, border, line stroke colors won't change in dark mode, this forces it to
    chartObjects[chart_id]['grid'] = {...chartObjects[chart_id]['grid'], ...{borderColor:settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeGridHex : lightModeGridHex}};
    if(_.get(chartObjects[chart_id],'xaxis.axisBorder',false)){
        chartObjects[chart_id]['xaxis']['axisBorder'] = {...chartObjects[chart_id]['xaxis']['axisBorder'], ...{color:settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeGridHex : lightModeGridHex}};
    }
    chartObjects[chart_id]['chart'] = {...chartObjects[chart_id]['chart'], ...{id:chart_id}};

    //Update chart theme when toggling dark mode
    eventBus.on("user:toggle-dark-mode", () => {
        Object.keys(chartObjects)?.forEach(chart_id => {
            ApexCharts.exec(chart_id, 'updateOptions', {
                theme: {
                    mode: settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeText : lightModeText,
                },
                grid: {
                    borderColor: settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeGridHex : lightModeGridHex,
                },
                xaxis: {
                    axisBorder: {
                        color: settings.value.is_dark_mode && settings.value.is_sourcing_mode ? darkModeGridHex : lightModeGridHex,
                    } ,
                }
            });
        });
    });

    return {
        chart_options: chartObjects[chart_id],
        getOption(key){
            if (key){
                return _.get(chartObjects[chart_id],key,null);
            }
            return null;
        },
        setOption(key, option) {
            chartObjects[chart_id][key] = option
        }
    };

}
