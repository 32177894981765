<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="156" height="113" viewBox="0 0 156 113" fill="none">
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M152.53 103.75v-32.5a6.75 6.75 0 0 0-6.75-6.75H20.28a6.75 6.75 0 0 0-6.75 6.75v32.5a6.75 6.75 0 0 0 6.75 6.75h125.5a6.75 6.75 0 0 0 6.75-6.75Z" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M29.53 47.5h28a4 4 0 1 1 0 8h-51" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M29.53 47.5h28a4 4 0 1 1 0 8h-51" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M101.94 21.33 7.81 55.27a4 4 0 0 1-5.09-2.46 4 4 0 0 1 2.46-5.09L99.3 13.78a4 4 0 0 1 2.42-.06" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M101.94 21.33 7.81 55.27a4 4 0 0 1-5.09-2.46 4 4 0 0 1 2.46-5.09L99.3 13.78a4 4 0 0 1 2.42-.06" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M64.53 63.45v-6.9c0-.58-.47-1.05-1.05-1.05h-15.9c-.58 0-1.05.47-1.05 1.05v6.9c0 .58.47 1.05 1.05 1.05h15.9c.58 0 1.05-.47 1.05-1.05ZM152.53 24.33V5.67a3.17 3.17 0 0 0-3.17-3.17H105.7a3.17 3.17 0 0 0-3.17 3.17v18.66a3.17 3.17 0 0 0 3.17 3.17h43.66a3.17 3.17 0 0 0 3.17-3.17Z" />
        <path fill="none" stroke="#212121" stroke-miterlimit="10" stroke-width="5" d="M114.03 18.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM141.03 18.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM6.53 55.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
    </svg>
</template>