<template>
    <component
        :is="display"
        :is-loading="isLoading"
        :order="order"
        :data="data"
        :invoice-columns="invoiceColumns"
        :invoice-rows="invoiceRows"
        :line-columns="lineColumns"
        :line-rows="lineRows"
        :shipment-columns="shipmentColumns"
        :shipment-rows="shipmentRows"
        :errors="errors"
    />
</template>

<script setup>
import {computed, inject, markRaw, onMounted, ref} from "vue";
import {useUser} from "@/Composables";
import SourcingOrderDialog from "../../../Themes/Sourcing/Components/Modules/Dialogs/OrderDialog.vue";
import MarketingOrderDialog from "../../../Themes/Marketing/Components/Modules/Dialogs/OrderDialog.vue";
import axios from "axios";
import {
    DateFormat,
    MoneyFormat,
    OrderLineDetail,
    OrderTracking,
    TableDownloadLink,
    TableStatus,
    LinkFormat
} from "@/Components";

const route = inject("route");
const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode ? SourcingOrderDialog : MarketingOrderDialog
);

const props = defineProps(["data"]);
const isLoading = ref(true);
const order = ref();
const errors = ref(false);

onMounted(() => {
    axios
        .get(route("api.v1.public.account.orders.show", props.data), {
            params: {
                with: ["order"],
            },
        })
        .then((response) => {
           order.value = response.data["order"];
          isLoading.value = false;
        })
        .catch(() => errors.value = true);
});

const invoiceColumns = [
    {
        name: "Inv #",
        component: markRaw(TableDownloadLink),
        props: (row) => ({
            linkText: row.invoice_id,
            class: 'hover:underline underline-offset-4 decoration-dotted font-medium',
            svg: 'FileDownload',
            svgSide: 'right',
            href: route('web.account.sales.invoices.show', {
                invoice: row.invoice_id,
                sequence: row.sequence,
            }),
        }),
    },
    {
        name: "Due Date",
        tdClasses: "type-tertiary",
        component: markRaw(DateFormat),
        props: (row) => ({
            date: row.date,
        }),
    },
    {
        name: "Status",
        component: TableStatus,
        props: (row) => ({
            value: row.accounts_receivable?.opn_balance > 0 ? "Due" : "Paid",
        }),
    },
    {
        name: "Amount",
        component: markRaw(MoneyFormat),
        headClass: '!pr-6',
        props: (row) => ({
            amount: row.total,
            alignRight: true,
            cellClass: 'pr-4',
        }),
        alignRight: true,
    },
];
const lineColumns = [
    {
        name: "Item #",
        component: markRaw(OrderLineDetail),
        props: row => ({
            linkText: row.item_id,
            routeName: 'web.catalog.products.show',
            routeParams: row.slug,
            line: row,
        }),
        noWrap: false
    },
    {
        name: "Qty",
        path: "quantity_ordered",
    },
    {
        name: "Shp",
        path: "quantity_shipped",
    },
    {
        name: "BO",
        path: "quantity_backordered",
    },
    {
        name: "Price",
        component: markRaw(MoneyFormat),
        props: (row) => ({
            amount: row.price,
            stripFormatting: true,
            alignRight: true,
        }),
        alignRight: true,
    },
    {
        name: "Surcharge",
        component: markRaw(MoneyFormat),
        props: (row) => ({
            amount: row.surcharge,
            stripFormatting: true,
            alignRight: true,
        }),
        alignRight: true,
    },
    {
        name: "Ext",
        component: markRaw(MoneyFormat),
        headClass: '!pr-6',
        props: (row) => ({
            amount: row.subtotal,
            alignRight: true,
            stripFormatting: true,
            cellClass: 'pr-4',
        }),
        alignRight: true,
    },
];
const shipmentColumns = [
    {
        name: "Shipped",
        component: markRaw(DateFormat),
        props: row => ({
            date: row.date,
        }),
    },
    {
        name: "Tracking",
        component: markRaw(OrderTracking),
        props: row => ({
            url: row.tracking_link,
            text: row.tracking_number,
        }),
    },
    {
        name: "Ship Via Code",
        path: "ship_via_code",
    },
];

const lineRows = computed(() => order.value?.lines);
const shipmentRows = computed(() => order.value?.shipments);
const invoiceRows = computed(() => order.value?.invoices);
</script>
