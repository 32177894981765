<template>
    <div
        id="printQuoteDialog"
        class="relative bg-gray-tier-3 h-full w-screen at1024:shadow-xl at1024:w-[855px]"
    >
        <header class="sticky top-0 z-50 bg-gray-tier-4">
            <div class="w-full flex items-center justify-between py-2 px-6">
                <h2>
                    {{ $t("Quote:") }}
                </h2>
                <span class="print:hidden flex items-center justify-end gap-2">
                    <AppButton
                        v-if="isEmployee()"
                        @click.prevent="
                            toggleModal(AuditModal, $event, { quote_id: data })
                        "
                        :text="$t('Audit')"
                        icon="table-rows"
                        :disabled="inert"
                        :loading="inert"
                    />
                    <AppButton
                        @click="toggleEmailQuoteDialog($event, {id:data})"
                        icon="icon-email-envelope"
                        text="Email"
                    />
                    <DialogCloseButton />
                </span>
            </div>
            <Divider />
        </header>
        <div v-if="quote" class="relative z-40 h-full overflow-y-auto pb-12">
            <div class="flex items-center justify-between px-6 py-3">
                <RecordHeader
                    :record="quote"
                    :header="header"
                    :reference-number="quote?.reference_id"
                    class="mb-4"
                />
            </div>
            <Divider />
            <div class="pt-6 px-6">
                <InsightsQuoteCustomerInfo :quote="quote" />
            </div>
            <div class="px-6 mb-8">
                <QuoteRecord
                    :quote="quote"
                    :rows="rows"
                    :columns="columns"
                    :quotesSelectable="false"
                    :sort="{ id: 'asc' }"
                    :insights="true"
                    :notesColumns="notesColumns"
                />
            </div>
        </div>
        <div
            v-else
            class="print:hidden absolute inset-0 flex items-center justify-center"
        >
            <LoadingSpinner :large="true" />
        </div>
    </div>
</template>
<script setup>
import { computed, inject, markRaw, onMounted, ref } from "vue";
import { useEmailQuoteDialog, useModalDialog, useUser } from "@/Composables";
import axios from "axios";
import {
    AppButton,
    AuditModal,
    DialogCloseButton,
    Divider, InsightsQuoteCustomerInfo,
    LoadingSpinner,
    MoneyFormat,
    PreviewLinkFormat,
    QuoteRecord,
    RecordHeader,
} from "@/Components";

const route = inject("route");
const { settings } = useUser();

const props = defineProps(["data"]);
const isLoading = ref(true);
const quote = ref();
const { toggleEmailQuoteDialog } = useEmailQuoteDialog();
const { isEmployee } = useUser();
const { toggleModal } = useModalDialog();

onMounted(() => {
    axios
        .get(route("api.v1.private.insights.quotes.show", props.data), {
            params: {
                with: ["quote"],
            },
        })
        .then((response) => {
            quote.value = response.data["quote"];
            quote.value.notes.data = quote.value.notes;
            isLoading.value = false;
        });
});

const header = computed(() => {
    if (!quote.value) {
        return "";
    }

    let data = "Quote: <strong>" + quote.value?.id + "</strong>";

    if (quote.value?.reference_id) {
        data += " Ref# " + quote.value?.reference_id;
    }

    return data;
});

const columns = [
    {
        name: "Line",
        sortPath: "id",
        path: "id",
    },
    {
        name: "Item #",
        sortPath: "item_id",
        component: markRaw(PreviewLinkFormat),
        props: (row) => ({
            linkText: row.item_id,
            routeName: "web.catalog.products.show",
            routeParams: row.slug,
            linkDescription:
                props.cards?.[row.item_id]?.display_short_description ??
                row.description?.["descr_1"] ??
                "",
        }),
    },
    {
        name: "Qty",
        sortPath: "quantity_ordered",
        path: "quantity_ordered",
    },
    {
        name: "Cost",
        component: markRaw(MoneyFormat),
        sortPath: "cost",
        props: (row) => {
            return {
                amount: row.cost,
                stripFormatting: true,
            };
        },
    },
    {
        name: "GP%",
        path: "gross_profit",
    },
    {
        name: "Price",
        component: markRaw(MoneyFormat),
        sortPath: "price",
        props: (row) => {
            return {
                amount: row.price,
                stripFormatting: true,
            };
        },
    },
    {
        name: "Surcharge",
        component: markRaw(MoneyFormat),
        sortPath: "surcharge",
        props: (row) => {
            return {
                amount: row.surcharge,
                stripFormatting: true,
            };
        },
    },
    {
        name: "Ext",
        component: markRaw(MoneyFormat),
        sortPath: "subtotal",
        props: (row) => {
            return {
                amount: row.subtotal,
            };
        },
    },
];

const notesColumns = [
    {
        name: "Enter Date",
        path: "enter_date",
    },
    {
        name: "Entered By",
        path: "enter_by",
    },
    {
        name: "Note",
        path: "note",
        noWrap: true,
    },
];

const rows = computed(() => quote.value?.lines);
</script>
