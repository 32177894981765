<template>
    <DropdownWrapper
        data-dialog="true"
        class="duration-300 absolute w-80 rounded-md shadow-app-standard "
        :class="transitioning? 'overflow-hidden h-80' : 'overflow-visible'"
        :offset="{top: 1, right: 0}"
    >
        <transition :name="animation" @before-enter="transitioning=true" @after-enter="transitioning=false">
            <div v-if="!show_tray_create">
                <header class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md">
                    <h2 class="flex items-center gap-2 text-base font-medium">
                        <BaseSvg v-if="pin.state.pinned" name="icon-check" class="w-4 h-4 rounded-full bg-green-600 fill-white" />
                        {{ $t('Page Pinned') }}
                    </h2>
                    <DialogCloseButton />
                </header>
                <Divider />
                <div  class="p-4 bg-gray-tier-3">

                    <!-- ----- LOADING PIN ----- -->
                    <div v-if="!pin.data" class="flex w-full items-start gap-2 mb-4">
                        <span class="shrink-0 p-1 bg-gray rounded-md w-1/6 overflow-visible">
                            <BaseSvg name="icon-product-empty"/>
                        </span>
                        <div class="flex w-full justify-center items-center pt-3 ">
                            <loading-circle />
                        </div>
                    </div>

                    <!-- ----- PIN DETAILS ----- -->
                    <div v-else class="flex w-full items-start gap-2 mb-4">
                        <span class="shrink-0 p-1 bg-gray rounded-md w-1/6 overflow-visible">
                            <img
                                v-if="assetUrl(pin.data?.thumbnail)"
                                :src="assetUrl(pin.data?.thumbnail)"
                                loading="lazy"
                                :alt="pin.data?.name"
                            />
                            <BaseSvg v-else name="icon-product-empty"/>
                        </span>
                        <div class="w-5/6">
                            <h2 class="type-primary font-medium text-sm flex items-center gap-2">
                                {{ pin?.data?.name }}
                                <span class="px-1.5 py-0.5 text-2xs rounded type-primary bg-gray-tier-6">
                                    {{ pin.data?.vendor }}
                                </span>
                            </h2>
                            <p class="w-full type-secondary font-normal text-xs truncate whitespace-nowrap">
                                {{ pin.data?.description }}
                            </p>
                        </div>
                    </div>

                    <!-- ----- SELECT TRAY ----- -->
                    <div>
                        <h5 class="type-secondary font-normal text-base mb-2">{{ $t('Tray') }}</h5>
                        <SearchSelect
                            v-model="trays_dropdown"
                            :options="tray_options"
                            :search-hidden="true"
                            :hover-blue="true"
                            :is-loading="inert"
                            @select="selectTray"
                        >
                            <li>
                                <button
                                    @click.prevent="show_tray_create=true"
                                    class="overflow-hidden type-secondary hover:text-white flex gap-1 items-center text-left w-full text-base px-4 py-3 hover:bg-blue active:bg-blue-600">
                                    <BaseSvg name="icon-addition-sign" class="shrink-0" />
                                    {{ $t('Create a tray') }}
                                </button>
                            </li>
                        </SearchSelect>
                    </div>
                </div>
                <Divider />
                <div class="flex justify-end gap-2 p-4 bg-gray-tier-3 rounded-b-md">
                    <AppButton @click.prevent="remove()" text="Remove"/>
                    <AppButton @click.prevent="forceClose" text="Done" theme="primary"/>
                </div>
            </div>
            <div v-else>
                <header class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md">
                    <div class="flex items-center justify-start gap-2">
                        <button @click.prevent="show_tray_create=false" class="btn-app-sm-white-on-gray px-0 w-8 h-8 flex items-center justify-center">
                            <BaseSvg name="icon-chevron-left" class="shrink-0" />
                        </button>
                        <h2 class="flex items-center gap-2 text-base font-medium">
                            {{ $t('Create new tray') }}
                        </h2>
                    </div>
                    <DialogCloseButton />
                </header>
                <Divider />
                <form
                    @submit.prevent=""
                    class="text-base px-4 py-2 bg-gray-tier-3"
                >
                    <AppInput
                        v-model="new_tray_name"
                        class="mb-4"
                        type="text"
                        :required="true"
                        :label="$t('Name')"
                    />
                    <AppInput
                        v-model="new_tray_description"
                        class="mb-4 resize-none"
                        :required="false"
                        type="textarea"
                        :label="$t('Description')"
                        maxlength="100"
                    ></AppInput>
                    <label for="icon-select" class="block text-sm type-secondary mb-1">{{ $t("Tray icon") }}</label>
                    <div id="icon-select" class="bg-gray-tier-5 rounded-md p-2 mb-4">
                        <ul class="grid grid-cols-6 w-full gap-4">
                            <li v-for="icon in config.pins.icons">
                                <button
                                    @click="new_tray_icon_dropdown = config.pins.icons.indexOf(icon)"
                                    type="button"
                                    :id="icon.value"
                                    :name="icon.value"
                                    class="flex items-center justify-center  p-2 rounded"
                                    :class="new_tray_icon_dropdown === config.pins.icons.indexOf(icon) ? 'bg-blue text-white' :'opacity-50 hover:opacity-100 hover:bg-gray-tier-3'"
                                >
                                    <BaseSvg :name="icon.icon" class="w-6 h-6" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </form>
                <Divider />
                <div class="p-4 flex justify-end gap-2">
                    <button @click.prevent="hide_new_tray_inputs" class="btn-app-sm-white-on-gray">{{ $t('Cancel') }}</button>
                    <button
                        :disabled="disable_add_tray || inert"
                        @click.prevent="addTray"
                        class="btn-app-sm-white-on-red">
                        {{ $t('Create Tray') }}
                        <LoadingCircle v-if="inert" class="ml-2" />
                    </button>
                </div>
            </div>
        </transition>
    </DropdownWrapper>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {DropdownWrapper, Divider, BaseSvg, DialogCloseButton, SearchSelect, AppInput, LoadingCircle, AppButton} from "@/Components";
import {usePinsDrawer, useDialog, eventBus, useAssetTransforms} from "@/Composables";
import config from '@/config';

const {drawer, fetchPin, movePin, createTray, fetchTrays} = usePinsDrawer();
const {forceClose} =  useDialog();
const { assetUrl } = useAssetTransforms();

let transitioning = ref(false);
const trays_dropdown = ref(0);
const new_tray_name = ref('');
const new_tray_description = ref('');
const new_tray_icon_dropdown = ref(0);
const show_tray_create = ref(false);
const creating_tray = ref(false);
const moving_pin = ref(false);

const animation = computed(() =>  show_tray_create.value ? 'slide-right' : 'slide-left');

const tray_options = computed(()=>{
    return drawer.value.trays.map((tray) => {
        return {
            ...tray,
            label: null,
            value: tray.name, //Translate 'name' -> 'value' for the SearchSelect component
        };
    });
})

const emits = defineEmits(['toggle-pin']);
const disable_add_tray = computed(() => new_tray_name.value.trim().length === 0 );

const props = defineProps(['pin']);

async function selectTray(key,previous_key) {
    let current_tray = tray_options.value[previous_key];
    let new_tray = tray_options.value[key];
    let pin_id = props.pin.data.id;

    if (current_tray !== new_tray) {
        moving_pin.value = true

        //If we were creating a Tray, pass that info along so that we may know which is the new tray and
        // the badge can be assigned to the dropdown selection.
        movePin(pin_id,current_tray,new_tray, creating_tray.value ?? false)
        creating_tray.value = false;
    }
}

function remove() {
    emits('toggle-pin');
    forceClose();
}

function addTray() {
    if(!disable_add_tray.value) {
        creating_tray.value = true;
        let icon = config.pins.icons[new_tray_icon_dropdown.value].icon;
        createTray(new_tray_name.value,new_tray_description.value,icon)
    }
}

function hide_new_tray_inputs() {
    show_tray_create.value = false

    //reset form fields
    new_tray_name.value = '';
    new_tray_description.value = '';
    new_tray_icon_dropdown.value = 0;
}

function build_options(trays) {
    return trays.map((tray) => {
        return {
            ...tray,
            label: null,
            value: tray.name, //Translate 'name' -> 'value' for the SearchSelect component
        };
    });
}

function onTrayCreated(tray) {
    if(creating_tray.value){
        hide_new_tray_inputs()
        fetchTrays(tray.name);
    }
}

function onTraysFetched(trays) {
    if(!moving_pin.value) {
        tray_options.value = build_options(trays);
    } else {
       moving_pin.value = false;
    }
}

//onMounted
onMounted(()=>{
    //Register event listeners
    eventBus.on('pins:tray-created',onTrayCreated);
    eventBus.on("pins:trays-fetched", onTraysFetched);
    fetchTrays()
})

onUnmounted(() => {
    eventBus.off('pins:tray-created',onTrayCreated)
    eventBus.off("pins:trays-fetched", onTraysFetched);
    fetchPin(props.pin.data.type, props.pin.data.pinnable_id)
})
</script>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all .2s ease-out;
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.slide-right-leave-to,
.slide-left-enter-from {
    transform: translateX(100%);
    overflow: hidden;
}

.slide-right-enter-from,
.slide-left-leave-to {
    transform: translateX(-100%);
    overflow: hidden;
}
</style>
