<template>
    <div
        data-dialog="true"
        id="account-panel"
        class="absolute panel-standard right-[-10px] rounded-md top-full translate-y-2 w-64 text-sm py-1"
    >
        <ul class="py-2 w-full border-b border-b-keyline-tertiary">
            <li v-for="link in mainLinks" class="block w-full">
                <Link
                    @click="loadingRoute = link.name"
                    class="hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-4 block px-6 py-1 w-full"
                    :href="route(link.route)"
                    method="get"
                    :class="loadingRoute === link.name ? '!bg-gray-tier-5 animate-pulse type-primary' : ''"
                >
                    {{ $t(link.name) }}
                </Link>
            </li>
        </ul>

        <div class="py-2 border-b border-b-keyline-tertiary">
            <a href="https://support.seclock.com" target="_blank" class=" px-6 py-1 hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-3 w-full flex items-center group">
                <BaseSvg name="icon-question-mark-circle" class="w-[20px] min-w-[20px] mr-2 text-gray-600 dark:text-gray-400 group-hover:type-primary block" aria-hidden="true"/>
                {{ $t('Support') }}
            </a>
            <button
                class="relative px-6 py-1 hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-3 w-full flex items-center group gap-2"
                @click="toggleNotificationsDialog"
            >
                <span class="absolute left-2.5 top-1/2 -translate-y-1/2 size-2 rounded-full bg-scarlet" v-if="notificationCount"></span>
                <BaseSvg name="icon-bell-notification" class="w-[20px] min-w-[20px] text-gray-600 dark:text-gray-400 group-hover:type-primary block text-left"/>
                {{ $t("Notifications") }}
                <span v-if="notificationCount" class="type-tertiary ml-auto flex items-center gap-1">
                    {{ notificationCount }} {{ $t('unread') }}
                </span>
            </button>
        </div>

        <div class="py-2">
            <button
                class="flex items-center py-1 px-6 w-full text-red hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-4 group"
                :disabled="inert"
                @click="eventBus.emit('user:toggle-sourcing-mode')"
            >
                <BaseSvg
                    name="icon-seclock-icon"
                    class="w-[20px] min-w-[20px] mr-2 p-0.25 left"
                    aria-hidden="true"
                />
                {{ $t("Exit Source Mode") }}
            </button>
            <Link
                method="post"
                :href="route('logout')"
                class="hover:type-primary flex items-center py-1 px-6 w-full hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-4 group"
                :disabled="inert"
            >
                <BaseSvg
                    name="icon-exit-to-app"
                    class="w-[20px] min-w-[20px] mr-2 text-gray-600 dark:text-gray-400 group-hover:type-primary"
                    aria-hidden="true"
                />
                {{ $t("Logout") }}
            </Link>
        </div>
    </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import { BaseSvg } from "@/Components";
import { eventBus, useNotificationsDialog, useUser } from "@/Composables";
import { inject, ref } from "vue";

const route = inject("route");

const { notificationCount } = useUser();
const { toggleNotificationsDialog } = useNotificationsDialog();

const loadingRoute = ref('');

let mainLinks = [
    {
        name: 'Profile',
        route: 'web.account.profile.show',
    },
    {
        name: 'Saved Carts',
        route: 'web.account.saved-carts.index',
    },
    {
        name: 'Addresses',
        route: 'web.account.addresses.index',
    },
    {
        name: 'Credit Reference',
        route: 'web.account.credit-reference',
    },
    {
        name: 'Returns',
        route: 'web.account.returns.index',
    },
    {
        name: 'My Products',
        route: 'web.account.my-products.index',
    },
];
</script>
