<template>
    <button
        type="button"
        class="flex items-start gap-2.5 w-full text-left border border-keyline-primary rounded-md bg-gray-tier-5 p-2.5"
        :class="{ 'ring-2 ring-royal': selected }"
        :aria-label="`select ${'file name'}`"
        @click="$emit('select')"
    >
        <label class="seclock-input flex">
            <input
                type="checkbox"
                name="select-file"
                :checked="selected"
                @click.stop="$emit('select')"
            />
            <span class="sr-only">Select this file</span>
        </label>
        <span class="space-y-0.5 -mt-0.5">
            <p class="text-sm leading-none">{{ file.name }}</p>
            <p class="type-secondary text-xs leading-none" v-if="metadata.length">
                {{ metadata.join(" • ") }}
            </p>
        </span>
    </button>
</template>

<script setup>
import { useFormatBytes } from "@/Composables";
import { computed } from "vue";

const emit = defineEmits(["select"]);

const props = defineProps({
    file: {
        type: Object,
        required: true,
    },
    selected: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const metadata = computed(() =>
    [
        props.file.size ? useFormatBytes(props.file.size) : null,
        props.file.name.split(".").pop().toUpperCase(),
    ].filter(Boolean)
);
</script>
