<template>
    <tr
        class="border-b border-gray-225 dark:border-gray-850"
        :class="floatingTable ? '' : 'bg-gray-tier-4' "
    >
        <th v-if="selectable">
            <SeclockInput
                type="checkbox"
                @click="(event) => emit('toggle-all',event.target.checked)"
                :checked="selectedRows.length === rows.length"
                label=""
            />
        </th>
        <th
            v-for="column in columns"
            class="px-[10px] py-2"
            :class="[(typeof column.headClass === 'function'
                ? column.headClass()
                : column.headClass) ?? '']
            "
        >
            <component
                :is="column.headerComponent
                ? (typeof column.headerComponent === 'function' ? column.headerComponent() : column.headerComponent)
                : (column.sortPath ? 'button' : 'span')"
                class="group inline-flex items-center gap-2 whitespace-nowrap"
                :class="{ 'ml-auto justify-end w-full': column.alignRight, }"
                @click="
                    column.sortPath
                        ? sortColumn(column.sortPath)
                        : null
                "
            >
                {{ column.name }}
                <BaseSvg
                    v-if="column.sortPath"
                    :name="sort_icons?.[sort?.[column.sortPath] ?? 'default']"
                    class="shrink-0 w-5"
                    :class="
                       sort_icons?.[sort?.[column.sortPath] ?? 'default'] ===
                        'default'
                            ? 'w-4 opacity-0 type-primary group-hover:opacity-50 group-active:opacity-100'
                            : 'opacity-75 type-primary group-hover:opacity-75 group-active:opacity-100'
                    "
                    aria-hidden="true"
                />
            </component>
        </th>
    </tr>
</template>

<script setup>
import {BaseSvg, SeclockInput} from "@/Components";
import {onMounted, ref} from 'vue';
import _ from 'lodash';

const props = defineProps({
    "selectedRows": {
        type: Array,
        required: false,
        default: () => [],
    },
    "columns": {
        type: Array,
        required: true,
    },
    "rows": {
        type: Array,
        required: true,
    },
    "sort": {
        type: Object,
        required: true,
    },
    "sortSingleColumn": {
        type: Boolean,
        required: false,
        default: true,
    },
    "subTable": {
        type: Object,
        required: true,
    },
    "name": {
        type: String,
        required: true,
    },
    "footerComponent": {
        type: Object,
        required: true,
    },
    "bodyComponent": {
        type: Object,
        required: true,
    },
    "selectable": {
        type: Boolean,
        required: false,
        default: false,
    },
    "floatingTable": {
        type: Boolean,
        required:false,
        default: false,
    },
});

let sort_icons = {
    'default' : "icon-sort",
    'desc' : "icon-arrow-down",
    'asc' : "icon-arrow-up",
};

const emit = defineEmits(["update:sort","toggle-all"]);

// sort
function sortColumn(column) {
    let sort = props.sort;

    if (props.sortSingleColumn) {
        _.forEach(sort, (value, key) => {
            if (key !== column) {
                delete sort[key];
            }
        });

        if (_.has(props.sort, column)) {
            sort[column] = props.sort[column] === 'asc' ? 'desc' : 'asc';
        } else {
            sort[column] = 'asc';
        }
    } else {
        sort[column] = props.sort[column] ?? 'default';
        switch (sort[column]) {
            case 'default':
                sort[column] = 'asc';
                break;
            case 'asc':
                sort[column] = 'desc';
                break;
            case 'desc':
                delete sort[column];
                break;
        }
    }

    emit("update:sort", sort);
}

</script>
