import config from "@/config";
import useDialog from "./useDialog";
import { PinsDialog, RightFloatingDrawerTransition } from "@/Components";

export default function usePinsDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const togglePinsDialog = (event) => {
        toggleDialog(
            PinsDialog,
            RightFloatingDrawerTransition,
            "pins",
            event,
            config.dialogs.drawers.pinsDialog.zindex,
            false,
            config.dialogs.drawers.pinsDialog.underHeader,
        );
    };

    return {
        togglePinsDialog,
        forceClose,
    };
}
