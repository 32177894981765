<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="16"
        viewBox="0 0 16 16"
    >
        <rect
            width="14"
            height="14"
            x="1"
            y="1"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            opacity=".3"
            rx="7"
        />
        <path
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            d="M7.5 1H8a7 7 0 0 1 7 7"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                dur="1s"
                repeatCount="indefinite"
                from="0 8 8"
                to="360 8 8"
            />
        </path>
    </svg>
</template>
