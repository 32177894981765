<template>
    <div class="dark h-screen w-screen bg-gradient-to-b from-black to-gray-800">
        <div
            class="h-screen w-screen bg-cover bg-no-repeat bg-shape-paths-black bg-top text-white"
        >
            <main class="flex h-screen items-center justify-center">
                <slot></slot>
            </main>
        </div>
    </div>
</template>
