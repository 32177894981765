<template>
    <div
        data-dialog="true"
        id="panel"
        class="mx-auto border border-gray-225 dark:border-gray-850 absolute -left-8 -right-8 top-1 h-auto w-[935px] rounded-md bg-white dark:bg-gray-785 panel-shadow"
    >
        <div class="px-5 py-2 pr-4 flex justify-between border-b border-b-keyline-secondary">
            <div class="flex items-center w-full gap-1">
                <BaseSvg name="icon-search-thick" class="" />
                <input
                    type="text"
                    autocomplete="off"
                    aria-autocomplete="off"
                    data-dialog-focus="true"
                    v-model="searchTerm"
                    :placeholder="$t('Search by keyword, product or manufacturer')"
                    class="text-base font-bold w-full bg-transparent border-none focus:ring-0 placeholder:font-normal pl-[.25em]"
                />
            </div>
            <div class="flex items-center gap-0.5 shrink-0">
                <LoadingSpinner v-if="searchIsLoading && searchTerm.length" class="flex items-center justify-start mr-1" />
                <button v-else @click="searchTerm === '' ? forceClose() : searchTerm = ''" class="group">
                    <BaseSvg :name="searchTerm === '' ? 'icon-close-thick' : 'icon-refresh-thick'"
                        class="w-8 h-8 rounded-full p-1 group-hover:bg-gray-tier-5 fill-tertiary group-hover:fill-default group-active:bg-gray-tier-6 scale-95"
                        aria-hidden="true"
                    />
                    <span class="sr-only" v-if="searchTerm === ''">Cancel</span>
                    <span class="sr-only" v-else>Refresh</span>
                </button>
            </div>
        </div>

        <!--        <template v-if="searchHasResults && searchTerm!==''">-->
        <!--            <div class="flex justify-start px-6 py-3 relative">-->
        <!--                <p class="text-xs leading-6">-->
        <!--                    Press <kbd class="font-medium font-sans">enter</kbd> to select,-->
        <!--                    <kbd>-->
        <!--                        <span class="sr-only">down arrow</span>-->
        <!--                        <BaseSvg name="icon-arrow-down-mini" aria-hidden="true"/>-->
        <!--                    </kbd>-->
        <!--                    <kbd>-->
        <!--                        <span class="sr-only">up arrow</span>-->
        <!--                        <BaseSvg name="icon-arrow-up-mini" aria-hidden="true"/>-->
        <!--                    </kbd>-->
        <!--                    or <kbd class="font-medium font-sans">tab</kbd> to navigate, <kbd class="font-medium font-sans">esc</kbd> to dismiss-->
        <!--                </p>-->
        <!--            </div>-->
        <!--            <Divider :strict="true" />-->
        <!--        </template>-->

        <div v-if="isLoadingMeta">
            <div class="h-24 flex w-full items-center justify-center">
                <LoadingSpinner />
            </div>
        </div>

        <div v-else :class="searchIsLoading && searchTerm.length ? 'opacity-25' : ''">

            <!-- Recently Searched -->
            <template v-if="!searchTerm.length && useSettingsLoader().settings('recently_queried')?.length">
                <div class="px-4 py-4 border-b border-b-keyline-secondary">
                    <h5 class="mb-2 font-medium type-secondary px-2 text-sm">{{ $t("Recent searches") }}</h5>
                    <ul>
                        <li v-for="query in useSettingsLoader().settings('recently_queried')">
                            <button
                                @click="searchTermQuick = query"
                                class="text-base hover:underline underline-offset-4 decoration-0 w-full flex items-center py-1.5 px-2 hover:bg-gray-tier-5 active:bg-gray-tier-6 font-medium"
                            >
                                <BaseSvg name="icon-history-clock" class="h-5 w-5 mr-2 shrink-0 type-tertiary" aria-hidden="true"/>
                                {{ query }}
                            </button>
                        </li>
                    </ul>
                </div>
            </template>

            <!-- Recently Viewed -->
            <template v-if="!searchTerm.length && useSettingsLoader().settings('recently_viewed')?.length">
                <h5 class="mt-4 px-6 mb-4 font-medium type-secondary text-sm">{{ $t("Recently viewed") }}</h5>
                <ul class="pb-6 px-6 w-full overflow-x-auto flex first-of-type:-pl-2">
                    <li class="shrink-0 w-1/5 pr-2" v-for="product in useSettingsLoader().settings('recently_viewed')">
                        <Link class="bg-gray-tier-5 hover:bg-gray-tier-6 active:bg-gray-tier-4 flex flex-col p-4 rounded h-full group font-medium" :href="route('web.catalog.products.show', product.slug)">
                            <div v-if="_.get(product,'display_thumbnails.0',false)">
                                <img
                                    class="h-20 mx-auto"
                                    :src="'https://assets.seclock.com/image/private/t_thumb/' +product.display_thumbnails[0]"
                                    alt=""
                                    loading="lazy"
                                />
                            </div>
                            <span v-else class="flex justify-center flex-col h-20">
                                <BaseSvg name="icon-product-empty" class="block p-4 h-24 w-24 mx-auto fill-gray-tier-7" aria-hidden="true"/>
                            </span>

                            <h5 class="grow whitespace-nowrap truncate text-sm text-center pt-1">
                                {{ product.display_sku }}
                            </h5>
                        </Link>
                    </li>
                </ul>
            </template>

            <!-- SEARCH RESULTS -->
            <div v-if="searchTerm.length && searchHasResults" class="relative">

                <!-- Shadow -->
                <div
                    :style="{ opacity: 1 - shadowOpacity }"
                    v-if="searchHasResults && searchTerm !== '' && !searchIsLoading"
                    class="absolute top-0 w-full h-12 bg-gradient-to-t from-transparent to-black/15 dark:to-black/20"
                ></div>

                <div @scroll="handleScroll()" ref="scrollContainer" class="relative z-10 px-4 py-3 max-h-[75vh] overflow-y-auto">

                    <!-- Suggested -->
                    <ul class="pb-3 w-full text-base border-b border-b-keyline-secondary">
                        <template v-for="(suggestions, key) in searchSuggestions">
                            <li v-for="(suggestion, key) in suggestions" class="w-full group">
                                <a :href="route('web.catalog.products.index',suggestion.query)"
                                    class="flex flex-col group"
                                    @click="() =>eventBus.emit('search:suggestion-clicked',searchTerm )">
                                    <span class="w-full flex items-center py-1.5 px-4 rounded group-hover:underline underline-offset-4 decoration-1 hover:bg-gray-tier-5 active:bg-gray-tier-6">
                                        {{ suggestion.label }}
                                    </span>
                                </a>
                            </li>
                        </template>
                    </ul>

                    <!-- Results -->
                    <ul id="resultList" class="inline-block w-full mt-4">
                        <li v-for="(result, index) in searchResults">
                            <Link
                                :href="route('web.catalog.products.show', result.slug)"
                                class="group flex w-full gap-6 p-4 rounded-sm hover:bg-gray-tier-5 active:bg-gray-tier-6"
                                :data="{ query: searchTerm }"
                                @click="searchTrackClick(result?.id)"
                            >
                                <div class="flex-none w-24">
                                    <template v-if="_.get(result,'display_thumbnails.0',false)">
                                        <div class="w-24 shrink-0" v-if="result.display_thumbnails.length">
                                            <img
                                                class="max-h-20 mx-auto"
                                                :src="'https://assets.seclock.com/image/private/t_thumb/' +result.display_thumbnails[0]"
                                                alt=""
                                                loading="lazy"
                                            />
                                        </div>
                                    </template>
                                    <span v-else class="h-24 w-24 flex bg-gray-tier-5 rounded">
                                        <BaseSvg name="icon-product-empty" class="block p-7 fill-gray-tier-7" aria-hidden="true"/>
                                    </span>
                                </div>
                                <div class="grow">
                                    <div class="text-sm font-medium type-tertiary">{{ result.filter_manufacturer }}</div>
                                    <span class="text-lg font-bold type-primary">
                                        {{ result.display_sku }}
                                    </span>
                                    <div class="type-secondary text-sm" aria-hidden="true">{{ result.display_short_description }}</div>
                                </div>
                            </Link>
                        </li>
                    </ul>

                </div>

                <!-- Shadow -->
                <div
                    v-if="searchHasResults && searchTerm !== '' && !searchIsLoading"
                    :style="{opacity: shadowOpacity}"
                    class="z-31 absolute bottom-[calc(0.125rem-2px)] w-full h-12 bg-gradient-to-b from-transparent to-black/10 dark:to-black/20"
                ></div>
 
            </div>

            <template
                v-if="
                    !searchHasResults &&
                    searchTerm.length &&
                    !searchIsLoading &&
                    (searchTerm === searchResultsTerm)
                "
            >
                <div class="p-24 text-center flex flex-col items-center justify-center">
                    <BaseSvg name="icon-search" class="fill-gray-tier-7 w-10 h-10"/>
                    <span class="font-medium text-xl">{{ $t("No results for") }} "{{ searchTerm }}"</span>
                    <p class="text-sm type-secondary">
                        {{$t("Pro tip: Searches don't require an exact match. Try typing less, or use a different word or phrase") }}
                    </p>
                </div>

            </template>

        </div>

        <div class="px-6 py-4 flex gap-2 items-center justify-between text-sm type-tertiary leading-none border-t border-t-keyline-secondary">
            <div class="hidden at1200:block">
                {{ $t("Press the") }}
                <span class="text-center p-1 mx-0.5 shadow-2xl rounded border border-gray-tier-7 border-b-2 inline-block">S</span>
                {{ $t("key to access search faster") }}
            </div>
            <div class="flex items-center justify-between gap-2">
                <span class="inline-block">{{ $t("Not the results you expected?") }}</span>
                <button @click="showNewMessage" class="text-left type-primary underline decoration-dotted decoration-1 hover:decoration-solid underline-offset-4 inline-block font-medium">
                    {{ $t("Chat with us") }}
                </button>
            </div>
        </div>

    </div>
</template>

<script setup>
import {inject, onMounted, ref, watch} from "vue";
import {
    eventBus,
    useBasicSearchApi, useChat,
    useDialog,
    useSearchDialog,
    useSettingsLoader,
} from "@/Composables";
import {BaseSvg, Divider, LoadingSpinner} from "@/Components";
import _ from "lodash";

const route = inject("route");
const { forceClose } = useDialog();
const {toggleSearchDialog} = useSearchDialog();
const {
    searchTerm,
    searchIsLoading,
    searchResults,
    searchHasResults,
    searchWasSearched,
    searchSuggestions,
    searchTrackClick,
    searchResultsTerm,
    searchTermQuick,
} = useBasicSearchApi();

const { showNewMessage } = useChat();

const scrollContainer = ref(null);
let shadowOpacity = ref(1);

const handleScroll = () => {
    const maxScroll = scrollContainer.value.scrollHeight - scrollContainer.value.clientHeight;
    shadowOpacity.value = 1 - Math.min(1, scrollContainer.value.scrollTop / maxScroll);
};

const isLoadingMeta = ref(true);

watch(searchIsLoading,v => {
    if(scrollContainer.value){
        scrollContainer.value.scrollTop = 0;
    }
});

onMounted(()=>{
    useSettingsLoader().fetchSettings(['recently_queried','recently_viewed']).finally(()=> isLoadingMeta.value = false);
});
</script>
