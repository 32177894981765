<template>
    <component :is="display" :header="header" :address="address" />
</template>

<script setup>
import { computed } from "vue";
import { useUser } from "@/Composables";
import MarketingAddressCard from "../../../Themes/Marketing/Components/Modules/Cards/AddressCard.vue";
import SourcingAddressCard from "../../../Themes/Sourcing/Components/Modules/Cards/AddressCard.vue";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode ? SourcingAddressCard : MarketingAddressCard
);

defineProps(["header", "address"]);
</script>
