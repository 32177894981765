<template>
    <header
        class="z-50 block bg-white border-b border-gray-150 fixed top-0 left-0 w-full print:hidden"
        ref="mainHeader"
    >
        <!-- Navbar -->
        <div class="wrap relative z-100 flex h-12 at1024:h-[70px]">
            <div class="flex items-center flex-grow basis-1/4">
                <div class="hidden at1024:flex items-center">
                    <Link class="shrink-0" :href="route('web.home')">
                        <span class="sr-only">{{ $t("SECLOCK") }}</span>
                        <BaseSvg name="seclock-logo-horiz-gray" class="w-44" aria-hidden="true"/>
                    </Link>
                    <span class="w-44 text-center text-gray text-base hidden at1350:block">800.847.5625</span>
                </div>
                <MenuToggleButton />
            </div>
            <!-- SEC-3000 -->
            <StopImpersonationButton />
            <MainNav />
            <SecondaryNav />
        </div>
    </header>
</template>
<script setup>
import { useDialog } from "@/Composables";
import { onMounted, ref } from "vue";
import { MenuToggleButton, MainNav, SecondaryNav, BaseSvg, StopImpersonationButton } from "@/Components";

const mainHeader = ref(null);

onMounted(() => {
    // toggleAppModeData.value.is_dark_mode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const prevWidth = mainHeader.value.width;

    const observer = new ResizeObserver((entries) => {
        document.documentElement.style.setProperty(
            "--header-height",
            entries[0].target.offsetHeight + "px"
        );
        document.getElementById("dialogs").classList.add("marketing-layout");
        document.getElementById("dialogs").classList.remove("sourcing-layout");
        const width = entries[0].borderBoxSize?.[0].inlineSize;
        if (typeof width === "number" && Math.abs(prevWidth - width) > 75) {
            useDialog().forceClose();
        }
    });

    observer.observe(mainHeader.value);

    document.body.classList.add("marketing-layout");
    document.body.classList.remove(
        "bg-white",
        "text-gray-800",
        "dark:bg-gray-785",
        "dark:text-white",
        "sourcing-layout"
    );
});
</script>
