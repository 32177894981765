<template>
    <li class="w-8 at1024:w-[58px]">
        <Link
            :href="route('web.cart.show')"
            class="flex group h-full items-center justify-center relative"
        >
            <span class="sr-only">{{ $t("Cart") }}</span>
            <BaseSvg
                name="icon-shopping-cart-thick"
                class="text-gray w-6 at1024:w-8 group-hover:text-gray-800"
                aria-hidden="true"
            />
            <span
                v-if="cartItemCount"
                class="block bg-red w-2.5 outline outline-white outline-2 h-2.5 rounded-full absolute right-0 at1024:right-2 top-2 at1024:top-[17px]"
            >
                <span class="sr-only">{{ $t("You have items in your cart") }}</span>
            </span>
        </Link>
    </li>
</template>

<script setup>
import {useCart} from "@/Composables";

const { cartItemCount } = useCart();
</script>
