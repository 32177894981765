import axios from "axios";
import Vapor from "laravel-vapor";
import {eventBus, useFormatBytes} from "@/Composables";
import {onMounted, onUnmounted, reactive} from "vue";
import _ from "lodash";

let state = reactive({
        form_file_names: [],// File names for all files currently attached to the form
        uploaded_files: [], // All uploaded files, some may have been removed after being uploaded
        attached_files: [], // All uploaded files that are still attached to the form
    }
);

const broadcast_attached_files_Debounce = _.debounce(() => broadcast_attached_files(), 200);

//Compute which files have been uploaded and attached to the form and REMAIN attached to the form.
const broadcast_attached_files = () => {
    state.attached_files = Object.values(state.uploaded_files).filter((file) => state.form_file_names.includes(file.name));
    eventBus.emit("uploadFile:doc", state.attached_files);
}

export async function uploadFile(file, url = null) {

    if (file.uploadProgress === 0) {
        Vapor.store(file.file, {
            progress: (progress) => {
                file.uploadProgress = Math.round(progress * 100);
            },
        })
        .then((response) => {
            let doc = {
                    uuid: response.uuid,
                    key: response.key,
                    bucket: response.bucket,
                    name: file.name,
                    content_type: file.type,
                    file_size: useFormatBytes(file.file.size),
                    file_size_bytes: file.file.size,
                };

            state.uploaded_files[file.name] = doc;

            if (!url) {
                broadcast_attached_files_Debounce();
                return
            }

            axios.patch(url, {
                docs: [doc],
            })
            .then((response) => {
                file.saved = true
                eventBus.emit("uploadFile:saved", response)
                broadcast_attached_files_Debounce();
            })
            .catch((axios_error) => {
                eventBus.emit("uploadFile:error", file);
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: axios_error.response.data.message,
                    level: "danger",
                });
            });
        })
        .catch((error) => {
            eventBus.emit("alert:show", {
                title: "Error",
                message: "Error storing upload",
                level: "danger",
            });
        });
    }
}

export function uploadFiles(files, url) {
    return Promise.all(files.map((file) => uploadFile(file, url)));
}

export default function createUploader(url = null) {

    //These are the files CURRENTLY attached to the file-upload component
    const onFileList = (files) => {
        state.form_file_names = files.map((file) => file.name)
        broadcast_attached_files_Debounce();
    };
    onMounted(() => eventBus.on("file-list:files", onFileList))
    onUnmounted(() => eventBus.off("file-list:files", onFileList))

    return {
        uploadFile: function (file) {
            return uploadFile(file, url);
        },
        uploadFiles: function (files) {
            return uploadFiles(files, url);
        },
    };
}
