<template>
    <section class="flex flex-col gap-4 text-gray-400 mb-4 at1024:mb-8 at600:flex-row at600:items-center at600:justify-between">
        <div class="flex items-center justify-start gap-4">
            <button
                @click.stop.prevent="$emit('csvExport')"
                :disabled="isDownloading || inert || isCsvDisabled"
                class="type-primary btn-black-on-gray-pill"
            >
                <TransitionGroup name="switch-vertical" tag="div" class="flex items-center">
                    <LoadingCircle key="spinner" v-if="isDownloading" class="w-4 h-4 px-0.5 mr-px"/>
                    <BaseSvg
                        key="icon"
                        v-else
                        name="icon-file-download"
                        class="mr-px w-4"
                        aria-hidden="true"
                    />
                </TransitionGroup>
                <span class="sr-only">{{ $t("Download") }}</span>
                {{ $t("CSV") }}
            </button>
            <div class="flex gap-2 items-center" v-if="computedModel?.size">
                <div class="w-fit">
                    <SeclockMultiselect
                        v-model="computedModel.size"
                        :options="[10, 25, 50]"
                        label="Show"
                        label-position="side"
                        :contrast="true"
                        @change="(event) => {computedModel.size = event;  $emit('change')}"
                    />
                </div>
            </div>
        </div>
        <div>
            <SearchInput
                v-if="!searchHidden"
                v-model="computedModel.query"
                @keydown.enter="() => $emit('change')"
            />
        </div>
    </section>
</template>

<script setup>
import {BaseSvg, SearchInput, SeclockMultiselect, LoadingSpinner, LoadingCircle} from "@/Components";
import { computed } from "vue";

const props = defineProps(["modelValue", "searchHidden", "isDownloading", "isCsvDisabled"]);
const emit = defineEmits(["update:modelValue", "csvExport", "change"]);

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});


function oops(event){
console.log(event)
}
</script>
