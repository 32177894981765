<template>
    <!-- TRAYS -->
    <div class="px-4 relative">
        <button
            @click="openTray(tray)"
            class="group w-full flex items-center bg-gray-tier-4 hover:bg-gray-tier-5 rounded-md border border-keyline-primary"
            :class="{ 'bg-gray-tier-5': menu.active }"
        >
            <span
                class="flex items-center justify-center w-2/12 p-4 border-r border-keyline-primary"
            >
                <BaseSvg
                    :name="tray?.icon ?? 'icon-pin-tray'"
                    class="shrink-0 w-6"
                />
            </span>
            <span class="text-left w-full grid grid-cols-2 items-center p-4">
                <span class="type-primary font-medium">{{ tray.name }}</span>
                <span class="type-secondary text-xs ml-1 text-right"
                    >{{ tray.pin_count ?? 0 }}&nbsp;&nbsp;{{
                        $t("Pinned Items")
                    }}</span
                >
            </span>
            <span class="w-20 flex items-center justify-end">
                <button
                    @click.stop="toggleMenu"
                    class="relative hidden group-hover:block mr-4"
                    :class="{ '!block': menu.active }"
                >
                    <BaseSvg
                        name="icon-more-horiz"
                        class="p-0.5 rounded-full hover:!bg-gray-tier-4 group-active:bg-gray-tier-6"
                        :class="{
                            '!block bg-gray-tier-6 group-hover:bg-gray-tier-6':
                                menu.active,
                        }"
                    />
                </button>
            </span>
        </button>
    </div>
    <TrayActionMenu
        class="mx-8 -mt-4"
        :tray="tray"
        :open="menu.active"
        @close="closeMenu"
        :hasEditDetails="isEditable"
        :hasAddTo="true"
        :hasExport="true"
        :hasDelete="isEditable"
    />
</template>

<script setup>
import { TrayActionMenu, BaseSvg } from "@/Components";
import { eventBus, usePinsDrawer } from "@/Composables";
import { computed, onMounted, onUnmounted, reactive } from "vue";
import _ from "lodash";
import config from "@/config";

onMounted(() => {
    eventBus.on("pins:open-tray-action-menu", closeMenu);
});

onUnmounted(() => {
    eventBus.off("pins:open-tray-action-menu", closeMenu);
});

//COMPOSABLES
const { openTray, closeTray } = usePinsDrawer();

//REFS
const menu = reactive({
    active: false,
    opened: false, //user interacted with this menu and "opened" it
});

//PROPS
const { tray, isEditable } = defineProps({
    tray: {
        type: Object,
        required: true,
        default: {},
    },
    isEditable: {
        type: Boolean,
        required: false,
        default: true,
    },
});

//COMPUTED
const menu_key = computed(() => {
    return tray?.id ?? tray?.name ?? _.uniqueId();
});

//FUNCTIONS
function toggleMenu() {
    menu.active ? closeMenu() : openMenu();
}

function closeMenu() {
    !menu.opened && (menu.active = false);
    menu.opened = false;
}

function openMenu() {
    menu.active = true;
    menu.opened = true;
    eventBus.emit("pins:open-tray-action-menu");
}
</script>
