import { computed, ref } from "vue";
import { eventBus } from "@/Composables";
import axios from "axios";

let initialized = false;

const load = [
    "invoice_card",
    "project_card",
    "news_card",
    "shipment_card",
    "order_card",
    "recently_purchased_card",
    "frequently_purchased_card",
    "total_spending_card",
    "team_card",
];

const cards = ref({});
const shipments = computed(() => cards.value?.shipment_card?.orders ?? []);

export default function useDashboard() {
    function initialize() {
        initialized = true;
        reload();
    }

    function registerEventListeners() {
        eventBus.on("dashboard:reload", (event) => reload());
        eventBus.on("dashboard:reload-card", (event) => reload([event], true));
    }

    function reload(reload = load, refresh = false) {
        reload.forEach((card) =>
            axios
                .post(route("api.v1.public.account.dashboard"), {
                    with: [card],
                    refresh: refresh
                })
                .then((response) => {
                    cards.value[card] = response.data[card];
                })
        );
    }

    return {
        cards,
        shipments,
        reload,
        initialize,
        registerEventListeners,
    };
}
