<template>
    <RecordSection :title="$t('Items')" :record="quote" >
        <template #buttons>
            <button v-if="quote.status.toLowerCase() !== 'processing'" @click="exportQuoteLines(quote)" :disabled="isDownloading" class="btn-black-on-gray-pill">
                <BaseSvg
                    v-if="!isDownloading"
                    name="icon-file-download"
                    class="mr-px w-4"
                    aria-hidden="true"
                />
                <LoadingCircle v-else class="w-4 h-4" />
                {{ $t("CSV") }}
            </button>
        </template>
        <div class="mb-3">
            <TableNew
                ref="items"
                :columns="columns"
                :rows="computedRows"
                :selectable="((props.quotesSelectable && !quote?.source_code?.startsWith('QOC')) ? selectable : false)"
                :sort="sort"
                :row-component="markRaw(TableRowTwo)"
                :floating-table="true"
            />
        </div>
    </RecordSection>
    <footer class="w-full flex items-center justify-between pt-3" v-if="!insights">
        <template v-if="quotesSelectable && !quote?.source_code?.startsWith('QOC')">
            <AppButton
                v-if="settings.is_sourcing_mode"
                :disabled="!addToCartEnabled || inert"
                @click="addToCart"
                text="Add to cart"
                theme="primary"
                class="print:hidden"
            />

            <button v-else @click="addToCart" :disabled="!addToCartEnabled || inert || (quote.status.toLowerCase() === 'processing')" class="btn-white-on-black capitalize rounded-full print:hidden">
                {{ $t("Add to cart") }}
            </button>
        </template>

        <template v-else-if="quotesSelectable && quote?.source_code?.startsWith('QOC')">
            <button @click="() => eventBus.emit('chat:showNewMessage', 'Convert quote #'+quote.id)" class="btn-white-on-black capitalize rounded-full print:hidden">
                {{ $t("Live chat to convert this quote") }}
            </button>
        </template>
        <div class="grow flex items-end gap-2 justify-end">
            {{ $t("Quote Total") }}
            <span class="text-2xl font-bold"><span class="type-secondary">$</span>{{ showMeTheMoney(String(quote.total), false, false) }}</span>
        </div>

    </footer>

    <div class="pb-6" v-else>
        <TotalCostBar :record="quote" :hideFreight="true" />
    </div>

    <RecordSection
        v-if="insights && quote.notes?.data?.length"
        :title="$t('Header Notes')"
        :record="quote"
        class="mb-6"
    >
        <TableNew
            :header-component="null"
            :columns="notesColumns"
            :rows="quote.notes?.data"
            :row-component="TableRowTwo"
            :floating-table="true"
        />
    </RecordSection>
</template>

<script setup>
import {computed, inject, markRaw, ref} from "vue";
import {
    RecordSection,
    TableNew,
    TableRowTwo,
    AppButton,
    BaseSvg,
    TotalCostBar,
    LoadingCircle, MoneyFormat,
} from "@/Components";
import {eventBus, useDownload, useHelpers, useUser} from "@/Composables";
import {useForm} from "@inertiajs/vue3";
import _ from 'lodash';
import QuoteLineDetail from "../Formatters/QuoteLineDetail.vue";

const props = defineProps({
    quote: {
        type: Object,
        required: true,
    },
    rows: {
        type: Array,
        required: true,
    },
    data: {
        type: Object,
        required: false,
        default: {}
    },
    cards: {
        type: Array,
        required: false,
        default: []
    },
    quotesSelectable: {
        type: Boolean,
        required: false,
        default: true
    },
    sort: {
        type: Object,
        required: false,
        default: {}
    },
    insights: {
        type: Boolean,
        default: false
    },
    notesColumns: {
        type: Array,
        required: false,
        default: []
    }

});

const sort = ref(props.sort);
const { settings } = useUser();
const { showMeTheMoney } = useHelpers();
const { exportQuoteLines, isDownloading } = useDownload();
const items = ref(null);
const route = inject('route');

const addItemsForm = useForm({
    lines: [],
});

const addToCartEnabled = computed(() => {
    return props.quote?.source_code === 'QOC' || items.value?.selectedRows.length > 0;
});

const addToCart = () => {
    addItemsForm.lines = _.map(items.value.selectedRows, 'id');
    addItemsForm.submit('post', route('web.account.sales.quotes.add_to_cart', props.quote.id));
};

const computedRows = computed(() => {
    if (!sort.value) {
        return props.rows
    }
    let orderBy = Object.keys(sort.value)[0];
    let direction = sort.value[orderBy] === 'asc' ? 'asc' : 'desc';
    return _.orderBy(props.rows, [orderBy], [direction]);
})

function selectable(row){
    return row.price > 0 && !row.item_id.startsWith('@');
}

const columns = [
    {
        name: "Item #",
        sortPath: "item_id",
        component: markRaw(QuoteLineDetail),
        props: row => ({
            linkText: row.item_id,
            routeName: 'web.catalog.products.show',
            routeParams: row.slug,
            linkDescription: props.cards?.[row.item_id]?.display_short_description ?? (row.concat_description ?? ''),
            line: row,
            quote: props.quote,
        }),
    },
    {
        name: "Qty",
        sortPath: "quantity_ordered",
        path: "quantity_ordered",
    },
    {
        name: "Price",
        component: markRaw(MoneyFormat),
        sortPath: 'price',
        props: (row) => {
            return {
                amount: row.price,
            };
        },
    },
    {
        name: "List",
        path: "list_price",
        sortPath: 'list_price',
        component: markRaw(MoneyFormat),
        props: (row) => {
            return {
                amount: row.list_price,
            };
        },
    },
    {
        name: "Surcharge",
        sortPath: 'surcharge',
        component: markRaw(MoneyFormat),
        props: (row) => {
            return {
                amount: row.surcharge,
            };
        },
    },
    {
        name: "Ext",
        component: markRaw(MoneyFormat),
        props: (row) => {
            return {
                amount: row.subtotal,
            };
        },
    },
];

</script>
