import config from "@/config";
import useDialog from "./useDialog";
import {ModalTransition, EmailQuoteDialog} from "@/Components";

export default function useEmailQuoteDialog(){

    const { forceClose, toggleDialog } = useDialog();

    const toggleEmailQuoteDialog = (event, data) => {
        toggleDialog(EmailQuoteDialog, ModalTransition, data, event, config.dialogs.drawers.productSearchDialog.zindex, true);
    };

    return {
        forceClose,
        toggleEmailQuoteDialog,
    };

};