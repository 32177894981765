import config from "@/config";
import useDialog from "./Dialogs/useDialog";
import { NavigationDialog } from "@/Components";
import { computed } from "vue";

const { forceClose, toggleDialog, data } = useDialog();

export default function useNavigation() {
    const toggleMenu = (menu, event, overlay = true) => {
        toggleDialog(
            NavigationDialog,
            false,
            menu,
            event,
            config.dialogs.navigation.zindex,
            overlay
        );
    };

    return {
        forceClose,
        menu: computed(() => data.value),
        toggleMenu,
    };
}
