<template>
    <div class="w-full at768:w-fit relative z-10">
        <button
            @click.stop.prevent="open($event)"
            :class="isOpen ? 'border-blue ring-blue ring-1' : 'border-gray-tier-7'"
            class="w-full rounded-sm duration-200 flex items-center justify-start gap-2 px-2 py-1 border text-sm"
        >
            <span class="flex gap-2 items-center justify-start">
                <BaseSvg name="icon-calendar-icon" class="fill-gray" />
                <span>{{ startDateFormatted }} - {{ endDateFormatted }}</span>
            </span>
        </button>
        <transition name="search-pop">
            <div
                v-click-outside="close"
                :class="positionLeft ? 'at1024:left-0 at1024:flex-row-reverse' : 'at1024:right-0 at1024:flex-row'"
                class="w-full at1024:w-fit mt-2 flex gap-4 flex-col at1024:flex-row absolute bg-white p-2 shadow-xl-heavy rounded-md"
                v-if="isOpen"
            >
                <Datepicker
                    v-show="isCustomOpen || isCustom"
                    inline
                    range
                    multi-calendars
                    multi-calendars-solo
                    v-model="dateRange"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    ref="datepickerComponent"
                    @closed="close"
                    :max-date="DateTime.now().toISODate()"
                    :min-date="minDate"
                />
                <ul class="w-full at1024:w-40 space-y-2 shrink-0">
                    <li v-for="preset in presets">
                        <button
                            @click.stop.prevent="setPresetDateRange(preset)"
                            :class="preset.selected && !isCustom? 'bg-gray-750 text-white': null"
                            class="text-left w-full rounded-sm bg-gray-125 py-1 px-2 text-sm hover:bg-gray-750 hover:text-white"
                        >
                            {{ $t(preset.label) }}
                        </button>
                    </li>
                    <li>
                        <button
                            @click.stop.prevent="setIsCustom"
                            :class="isCustom || isCustomOpen ? 'bg-gray-750 text-white' : null"
                            class="text-left w-full rounded-sm bg-gray-125 py-1 px-2 text-sm hover:bg-gray-750 hover:text-white"
                        >
                            {{ $t("datepicker.custom-range") }}
                        </button>
                    </li>
                    <li class="grid grid-cols-2 items-center gap-2" v-if="isCustomOpen">
                        <button @click="setCustomDateRange()" class="uppercase font-medium tracking-widest rounded-sm border bg-red hover:bg-red-600 active:bg-red-700 border-red px-auto py-2 text-3xs text-white">
                            {{ $t('Apply') }}
                        </button>
                        <button class="uppercase font-medium tracking-widest rounded-sm border-2 bg-white border-gray-225 hover:border-gray px-auto py-2 text-3xs" @click.prevent="isOpen = false">
                            {{ $t('Cancel') }}
                        </button>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<style>
/*@import '@vuepic/vue-datepicker/dist/main.css';*/

.dp__menu:focus {
    border: none;
}

.dp__theme_light {
    border: none;
    background-color: transparent !important;
}

.dp__flex_display {
    flex-direction: column;
    gap: 8px;
}

@media screen and (min-width: 768px) {
    .dp__flex_display {
        flex-direction: row;
    }
}

.dp__month_year_row {
    color: #63666a;
    font-weight: bold;
}

.dp__action_row {
    display: none;
}

.dp__calendar_header_item {
    color: #63666a;
}

.dp__range_start {
    background-color: #3c434c;
}

.dp__range_end {
    background-color: #3c434c;
}

.dp__cell_inner {
    height: 42px;
}

.dp__calendar_header_separator {
    @apply bg-gray-tier-6;
}
</style>

<script setup>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import { BaseSvg } from "@/Components";

const props = defineProps({
    presets : {
        required: false,
        default: null,
    },
    modelValue: {
        required: false,
        default: [DateTime.now().minus({ months: 1 }).toJSDate(), DateTime.now().toJSDate()],
    },
    minDate: {
        required: false,
        default: DateTime.now().minus({ years: 1 }).toJSDate(),
    }
});
const emit = defineEmits(["datesSelected", "update:modelValue"]);

const isOpen = ref(false);
const isCustom = ref(false);
const isCustomOpen = ref(false);
const positionLeft = ref(true);
const datepickerComponent = ref(null);

let dateRange = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});
const ISORegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
const startDateFormatted = computed(() => {
    if (typeof dateRange.value[0] === "string" && ISORegex.test(dateRange.value[0])) {
        return DateTime.fromISO(dateRange.value[0]).toFormat('MM/dd/yyyy');
    } else if (dateRange.value[0] instanceof Date) {
        return DateTime.fromJSDate(dateRange.value[0]).toFormat('MM/dd/yyyy');
    } else {
        return "mm/dd/yyyy";
    }
});

const endDateFormatted = computed(() => {
    if (typeof dateRange.value[1] === "string" && ISORegex.test(dateRange.value[1])) {
        return DateTime.fromISO(dateRange.value[1]).toFormat('MM/dd/yyyy');
    } else if (dateRange.value[1] instanceof Date) {
        return DateTime.fromJSDate(dateRange.value[1]).toFormat('MM/dd/yyyy');
    } else {
        return "mm/dd/yyyy";
    }
});

function deselectAllPresets() {
    props.presets.forEach((preset) => preset.selected = false);
}

function setPresetDateRange(preset) {
    dateRange.value = [preset.startDate, preset.endDate];
    isCustom.value = false;
    isCustomOpen.value = false;
    close();
    deselectAllPresets();
    preset.selected = true;
    emit("datesSelected", dateRange.value);
}

function setCustomDateRange() {
    datepickerComponent.value.selectDate();
    isCustomOpen.value = false;
    close();
}

function setIsCustom() {
    isCustom.value = true;
    isCustomOpen.value = true;
}

const open = (element) => {
    let elementPosition = element.target.getBoundingClientRect();
    const threshold = window.innerWidth / 2;
    positionLeft.value = elementPosition.left < threshold;
    isOpen.value = !isOpen.value;
}

const close = () => isOpen.value = false;
</script>
