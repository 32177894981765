<template>
    <span class="relative w-fit flex items-center gap-2" :class="isDownloading ? 'opacity-50 pointer-events-none' : ''">
        <LinkFormat @click="download" :href="href" :link-text="linkText" v-bind="props" />
        <span class="absolute -right-6 flex items-center">
            <Transition name="fade-right">
                <LoadingCircle v-if="isDownloading" />
            </Transition>
        </span>
    </span>
</template>

<script setup>
import {LinkFormat, LoadingCircle} from "@/Components";
import {ref} from "vue";

const isDownloading = ref(false);

const download = () => {
    isDownloading.value = true;
    setTimeout(() => {
        isDownloading.value = false;
    }, 2000);
};

const props = defineProps({
    "linkText": {
        type: [String],
        required: true,
    },
    "href": {
        type: [String],
        required: true,
    },
    "class": {
        type: [String],
        required: false,
        default: "font-medium text-red hover:underline underline-offset-4"
    },
    "svgClass": {
        type: [String],
        required: false,
        default: ""
    },
    "svgSide": {
        type: [String],
        required: false,
        default: "left"
    },
    "svg": {
        type: [String],
        required: false,
        default: ""
    }
});
</script>
