<template>
    <div v-if="state.isOpen" class="app-modal pointer-events-none">
        <component
            :is="readOnly ? 'div' : 'form'"
            class="shadow-app-standard pointer-events-auto flex flex-col border border-keyline-primary rounded bg-gray-tier-3 max-h-[calc(100vh-10rem)] w-[95vw] at1024:min-w-[500px] at1024:w-auto at1024:max-w-[700px]"
            role="dialog"
            aria-modal="true"
            data-dialog="true"
            @submit.prevent="onSubmit"
            v-click-outside="closeDialog"
        >
            <div class="relative px-6 pr-2 bg-gray-tier-4 rounded-[3px]">
                <slot name="header">
                    <div class="flex justify-between items-center h-[52px]">
                        <h2 class="font-medium">{{ title }}</h2>
                        <div class="flex items-center gap-2">
                          <slot name="headerButtons" />
                          <CloseButton @click="onClose" />
                        </div>
                    </div>
                </slot>
            </div>

            <drawer-notification
                v-if="notification"
                :notification="notification"
            />

            <divider />

            <div :class="{ 'app-scrollbar': scroll }">
                <slot name="description"></slot>
                <slot name="content"></slot>
            </div>

            <slot name="footer">
                <divider />
                <div class="px-6 py-4">
                    <div class="flex justify-end space-x-2">
                        <template v-if="readOnly">
                            <app-button
                                size="large"
                                text="Close"
                                @click="onClose"
                            />
                        </template>
                        <template v-else>
                            <app-button
                                size="large"
                                text="Cancel"
                                @click="onClose"
                            />
                            <app-button
                                type="submit"
                                size="large"
                                :text="submitText ?? 'Confirm'"
                                theme="primary"
                                :disabled="!valid || state.submitting"
                                :loading="state.submitting"
                            />
                        </template>
                    </div>
                </div>
            </slot>
        </component>
    </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import {
    AppButton,
    CloseButton,
    Divider,
    DrawerNotification,
} from "@/Components";
import DialogCloseButton from "@/Components/Modules/Buttons/DialogCloseButton.vue";
import {eventBus, useDialog} from "@/Composables";
const { forceClose } = useDialog();
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    scroll: {
        type: Boolean,
        required: false,
        default: true,
    },
    submitText: {
        type: String,
        required: false,
        default: "Confirm",
    },
    valid: {
        type: Boolean,
        required: false,
        default: true,
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false,
    },
    isOpen: {
        type: Boolean,
        required: false,
        default: true,
    },
    notification: {
        type: Object,
        required: false,
        default: null,
    },
    closeOnSuccess: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["close", "submit", "click-outside"]);

const state = reactive({
    isOpen: true,
    submitting: false,
});

state.isOpen = props.isOpen;

watch(
    () => ({ ...props.isOpen }),
    () => {
        state.isOpen = props.isOpen;
    }
);

function onClose(e) {
    emit("close", e);
    state.submitting = false;
    eventBus.emit("form:reset");
    forceClose();
}

function onSubmit(e) {
    if (!props.valid) {
        return;
    }

    state.submitting = true;

    return new Promise((resolve, reject) => {
        emit("submit", resolve, reject);
    })
        .then(() => {
            console.log("closing");
            emit("close", e);
        })
        .catch(() => {})
        .finally(() => {
            state.submitting = false;
        });
}

const closeDialog = () => {
    emit("click-outside");
    state.isOpen = false;
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.app-modal {
    @apply fixed top-0 w-screen h-screen flex items-start justify-center pt-20;
}
</style>
