<template>
    <div class="hidden at768:flex justify-start items-center inset-0 w-full">
        <transition :name="name">
            <span
                v-if="hintSeen"
                :class="{
                    'fade-top-position': name === 'fade-top',
                    'fade-right-position': name === 'fade-right',
                    'fade-left-position': name === 'fade-left',
                    'bg-gray-900 rounded': settings.is_sourcing_mode,
                    'bg-gray-775': !settings.is_sourcing_mode,
                }"
                class="cursor-default text-white shadow-md text-xs p-2 w-fit my-0 absolute z-10 whitespace-nowrap"
            >
                <slot />
                <span
                    :class="{
                        'bg-gray-900': settings.is_sourcing_mode,
                        'bg-gray-775': !settings.is_sourcing_mode,
                        '-left-1 top-2.5': name === 'fade-right',
                        '-right-1 top-2.5': name === 'fade-left',
                        'right-3 top-6': name === 'fade-top',
                    }"
                    class="rotate-45 p-2 absolute -z-10"
                >
                </span>
            </span>
        </transition>
    </div>
</template>

<script setup>
import { useUser } from "@/Composables";

const { settings } = useUser();
const props = defineProps(["name", "hintSeen"]);
</script>

<style>
.fade-top-position {
    bottom: calc(100% + 8px);
    right: 0;
}

.fade-left-position {
    right: calc(100% + 8px);
}

.fade-right-position {
    left: calc(100% + 8px);
}

/*fade right*/
.fade-right-enter-active,
.fade-right-leave-active {
    transition: all 0.25s ease-out;
}

.fade-right-enter-from,
.fade-right-leave-to {
    transform: translateX(-1em);
    opacity: 0;
}

.fade-right-enter-from,
.fade-right-left-leave-to {
    opacity: 0;
}

/*fade left*/
.fade-left-enter-active,
.fade-left-leave-active {
    transition: all 0.25s ease-out;
}

.fade-left-enter-from,
.fade-left-leave-to {
    transform: translateX(1em);
    opacity: 0;
}

.fade-left-enter-from,
.fade-left-leave-to {
    opacity: 0;
}

/*fade up*/
.fade-top-enter-active,
.fade-top-leave-active {
    transition: all 0.1s ease-out;
}

.fade-top-enter-from,
.fade-top-leave-to {
    transform: translateY(0.25em);
    opacity: 0;
}

.fade-top-enter-from,
.fade-top-leave-to {
    opacity: 0;
}
</style>
