<template>
    <Transition name="search-pop">
        <ActionDropdown
            v-if="open"
            data-dialog="true"
            :options="options"
            :offset="{ right: 0 }"
            class="ml-auto !w-fit"
            v-click-outside="close"
        />
    </Transition>
</template>

<script setup>
import { ActionDropdown } from "@/Components";
import {
    usePinsDrawer,
    useDialog,
    usePinsDialog,
    useTrayDeleteDialog,
    useTrayAddToDialog,
    useTrayCreateEditDialog,
    eventBus,
} from "@/Composables";
import {onMounted, ref} from "vue";
import axios from "axios";

//COMPOSABLES
const { drawer } = usePinsDrawer();
const { forceClose } = useDialog();
const { togglePinsDialog } = usePinsDialog();
const { toggleTrayDeleteDialog } = useTrayDeleteDialog();
const { toggleTrayAddToDialog } = useTrayAddToDialog();
const { toggleTrayCreateEditDialog } = useTrayCreateEditDialog();

//EMITS
const emit = defineEmits(["close"]);

//PROPS
const {
    tray,
    open,
    hasPinPage,
    hasCreate,
    hasExportAll,
    hasEditDetails,
    hasAddTo,
    hasExport,
    hasDelete,
} = defineProps({
    tray: {
        type: Object,
        required: true,
        default: {},
    },
    open: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasPinPage: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasCreate: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasExportAll: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasEditDetails: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasAddTo: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasExport: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasDelete: {
        type: Boolean,
        required: false,
        default: false,
    },
});

//REFS
const options = ref([]);
const is_visible = ref(false);

//ONMOUNTED
onMounted(() => {
    let actions = [];

    //Actions
    //TODO: Removing this action until the logic (and/or) backend is implemented.
    //hasPinPage && actions.push(action_pin_page);
    hasCreate && actions.push(action_create);
    hasExportAll && actions.push(action_export_all);
    hasEditDetails && actions.push(action_edit_details);

    /*
        TODO: Should we still add it and just disable this option if there is no 'product_count'?
         If so we need to update the ActionDropdown component to account for disabling on option.
     */
    //Only add this menu item is we have PRODUCTS in this tray
    hasAddTo && tray.product_count && actions.push(action_add_to);

    hasExport && actions.push(action_export);

    //Add actions to the options
    actions.length && options.value.push(actions);

    //Include delete action in the options
    hasDelete && options.value.push([action_delete]);
});

//ACTIONS - Tray option actions
const action_pin_page = {
    value: "pinThisPage",
    label: "Pin this page",
    icon: "push-pin",
    action: () => {
        console.log("Pin page");
        close();
    },
};

const action_create = {
    value: "createNewTray",
    label: "Create new tray",
    icon: "addition-sign",
    action: (event) =>
        toggleTrayCreateEditDialog(event, { callback: togglePinsDialog }),
};

const action_export_all = {
    value: "exportAllTrays",
    label: "Export all trays",
    icon: "export-file",
    action: (event) => {
        export_tray();
    },
    link: false,
};

const action_edit_details = {
    value: "editDetails",
    label: "Edit details...",
    icon: "edit",
    action: (event) =>
        toggleTrayCreateEditDialog(event, {
            callback: togglePinsDialog,
            tray: tray,
            edit: true,
        }),
};

const action_add_to = {
    value: "addProductsTo",
    label: "Add " + tray?.product_count + " products to...",
    icon: "addition-sign",
    action: (event) => {
        toggleTrayAddToDialog(event, {
            tray: tray,
            callback: togglePinsDialog, //Used to toggle the Pin Dialog back to the previously opened tray/pin
        });
    },
};

const action_export = {
    value: "exportTray",
    label: "Export tray",
    icon: "export-file",
    action: (event) => {
        export_tray(tray?.name);
    },
    link: false,
};

const action_delete = {
    value: "deleteTray",
    label: "Delete tray",
    icon: "trash-can",
    action: (event) => {
        toggleTrayDeleteDialog(event, { tray: tray });
    },
};


//FUNCTIONS
function close() {
    emit("close");
}

function exporting(status = true) {
    eventBus.emit('pins:tray-exporting',status)
}

function export_tray(tray = '') {
    exporting()
    axios.get(route("web.account.pins.export_trays", tray))
        .then(response => {
            window.location.href = response.data.url
            exporting(false)
            close()
        })
        .catch(error => {
            let label = tray.length ? tray : 'trays';
            eventBus.emit("alert:show", {
                title: "Error",
                message: "There was an error exporting the "+label,
                level: "danger"
            });
            exporting(false)
            close()
        });
}
</script>
