import config from "@/config";
import useDialog from "./useDialog";
import { NotificationsDialog, RightFloatingDrawerTransition } from "@/Components";

export default function useNotificationsDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleNotificationsDialog = (event) => {
        toggleDialog(
            NotificationsDialog,
            RightFloatingDrawerTransition,
            {},
            event,
            config.dialogs.drawers.notificationsDialog.zindex,
            false,
            config.dialogs.drawers.notificationsDialog.underHeader,
        );
    };

    return {
        toggleNotificationsDialog,
        forceClose,
    };
}
