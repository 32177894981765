import {computed, inject, onBeforeMount, reactive} from 'vue';
import {eventBus,usePinsDialog, usePinSearch, useUser} from '@/Composables';
import axios from 'axios';
import {router} from '@inertiajs/vue3';

const { togglePinsDialog } = usePinsDialog();

const drawer = reactive({
    trays: [],
    selected_tray: null,
})

let route = false; //Hello from the other si-iiide!!!....

const {
    searchTerm,
    searchResults,
    searchClearFilters,
    searchSetFilter,
    searchRefresh,
    mountSearchDriver,
} = usePinSearch(true);


export default function usePinsDrawer(){

    route = route ? route : inject('route'); //....I tried to route a thousand ti-iiimes!!!

    const { settings } = useUser();
    const is_source_mode = settings.value.is_sourcing_mode;

    //PUBLIC Function
    const fetchTrays = (new_tray_name = null) => {
        axios
            .get(route('web.account.pins.trays'))
            .then(response => {
                drawer.trays = response.data.map((tray) => {
                    return {
                        ...tray,
                        isNew: new_tray_name === tray.name,
                        icon: tray.icon ?? 'icon-pin-tray',
                    }
                })
                eventBus.emit('pins:trays-fetched',drawer.trays)
            })
            .catch(error => console.log("ERROR", error))
    }

    const createTray = (tray_name, tray_description, tray_icon) => {
        //Prep parameters and clean up the optional route parameters
        let tray = tray_name.trim();
        let description = tray_description?.trim() === '' || tray_description == null ? '__null' : tray_description;
        let icon = tray_icon?.trim() === '' || !tray_icon ? '__null' : tray_icon;

        router.post(
            route('web.account.pins.create_tray', [
                tray,
                description,
                icon
            ]),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    let new_tray = response.props.flash.status.tray;
                    eventBus.emit('pins:tray-created',new_tray)
                    eventBus.emit('alert:show',{title:'Tray created', message:tray});
                },
                onError: (response) => {
                    eventBus.emit('alert:show',{title:'Error', message:response.error, level:'danger'})
                },
            }
        );
    }

    const updateTray = (tray_id, new_name, new_description, new_icon) => {

        //Clean up the optional route parameters
        let description = new_description?.trim() === '' || new_description == null ? '__null' : new_description;
        let icon = new_icon?.trim() === '' || !new_icon ? '__null' : new_icon;

        router.put(
            route('web.account.pins.update_tray', {
                'user_tray': tray_id,
                'name': new_name,
                'description': description,
                'icon': icon
            }),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    let updated_tray = response.props.flash.status.tray;

                    //If we currently have a selected tray... update it.
                    if (drawer.selected_tray) {
                        drawer.selected_tray = updated_tray;
                    }

                    eventBus.emit('pins:tray-updated',updated_tray)
                    eventBus.emit('alert:show',{title:'Tray updated', message:new_name});
                },
                onError: (response) => {
                    eventBus.emit('alert:show',{title:'Error', message:response.error, level:'danger'})
                },
            }
        );
    }

    const deleteTray = (tray) => {
        router.delete(
            route('web.account.pins.delete_tray', [tray]),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    drawer.selected_tray = null;
                    eventBus.emit('pins:tray-deleted',tray)
                    eventBus.emit('alert:show', {title: 'Status', message: 'Tray \'' + tray + '\' deleted'});
                },
                onError: (response) => {
                    console.log(response.error)
                },
            }
        );
    }

    const openDrawerTray = (data) => {
        let tray = data.pin?.trays[0] ?? null;
        openTray(tray);
        togglePinsDialog(data.event);
    }

    const openTray = (tray = null) => {
        searchTerm.value = '';
        searchSetFilter('trays',tray?.name);
        drawer.selected_tray = tray
    }

    const closeTray = () => {
        searchTerm.value = '';
        searchClearFilters([]);
        drawer.selected_tray = null;
    }

    const fetchPin = (pin_type, pinnable_id) => {
        axios
            .get(route('web.account.pins.'+pin_type, pinnable_id))
            .then(response => {
                eventBus.emit('pins:pin-fetched',response?.data ?? null)
            })
            .catch(error=>console.log(error));
    }

    const togglePin = (pin_type, pinnable_id) => {
        axios
            .post(route('web.account.pins.' + pin_type, pinnable_id))
            .then((response) => {
                let pin = response.data.pin;
                if (pin?.id) {
                    eventBus.emit('pins:pin-toggled-on',pin);
                    eventBus.emit('alert:show', {title:'Success', message: pin.type.charAt(0).toUpperCase() + pin.type.slice(1) + (is_source_mode ? ' pinned': ' added to Favorites'), level:'success'})
                } else {
                    eventBus.emit('pins:pin-toggled-off',{type:pin_type, pinnable_id:pinnable_id});
                    eventBus.emit('alert:show',{title:'Status', message:(is_source_mode ? 'Unpinned from trays' : 'Removed from Favorites')})
                }
            })
            .finally(() => {
                refresh_drawer()
            })
            .catch((response) => {
                console.log("ERROR", response);
                eventBus.emit('pins:pin-toggled-error',{type:pin_type, pinnable_id:pinnable_id});
                eventBus.emit('alert:show',{title:'Error', message:response?.error ?? 'Error while toggling state', level:'danger'})
            });
    }

    const movePin = (pin_id,current_tray_obj,new_tray_obj, new_tray = false) => {
        router.post(
            route('web.account.pins.move_pin', {
                pin:pin_id,
                current_tray: current_tray_obj?.value,
                new_tray: new_tray_obj?.value,
            }),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    eventBus.emit('alert:show', {title: 'Success', message: 'Pin moved to ' + new_tray_obj?.value, level: 'success'});
                    refresh_drawer(new_tray && new_tray_obj?.value)
                    eventBus.emit('pins:pin-moved')
                },
                onError: (response) => {
                    eventBus.emit('pins:pin-move-error', response.error)
                    console.log(response.error)
                },
            }
        );
    }

    const addPinToTray = (pin_id,tray) => {

        router.post(
            route('web.account.pins.pin_in_tray', [pin_id,tray]),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    eventBus.emit('alert:show',{title: 'Success', message: 'Pin added to '+tray, level: 'success'});
                    eventBus.emit('pins:pin-added-to-tray')
                },
                onError: (response) => {
                    console.log(response.error)
                },
            }
        );
    }

    const removePinFromTray = (pin_id,tray) => {
        router.delete(
            route('web.account.pins.pin_out_tray', [pin_id,tray]),
            {},
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: (response) => {
                    eventBus.emit('pins:pin-removed-from-tray');
                },
                onError: (response) => {
                    console.log(response.error)
                },
            }
        );
    }

    const removePin = (pin_type, pinnable_id, pin_name) => {
        return axios
            .delete(route('web.account.pins.'+pin_type, pinnable_id))
            .then(response => {
                eventBus.emit('alert:show',{title: 'Success', message: 'Pin '+ (pin_name ? pin_name+' ' : '') +'removed', level: 'success'});
                refresh_drawer();
                eventBus.emit('pins:pin-removed',{pin_type:pin_type, pinnable_id:pinnable_id});
            })
            .catch(error => {
                console.log("ERROR", error);
            });
    }

    //PRIVATE Functions
    function refresh_drawer(new_tray_name= null) {
        fetchTrays(new_tray_name);
        searchRefresh();
    }

    onBeforeMount(()=>{
        mountSearchDriver();
    })


    return {
        drawer: computed(() => {
            return {
                selected_tray: drawer.selected_tray,
                trays: drawer.trays,
                pins: searchResults.value,
                state: {
                    fetching: searchTerm.value.length === 0,
                    searching: searchTerm.value.length > 0,
                }
            }
        }),
        fetchPin,
        fetchTrays,
        openDrawerTray,
        createTray,
        updateTray,
        deleteTray,
        openTray,
        closeTray,
        movePin,
        addPinToTray,
        removePinFromTray,
        togglePin,
        removePin,
    };

};
