<template>
    <div
        data-dialog="true"
        class="border border-keyline-primary rounded-md bg-gray-tier-4 w-96 shadow-app-standard"
    >
        <header
            class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md"
        >
            <h2 class="flex items-center gap-2 text-base font-medium">
                {{ $t("Delete tray") }}
            </h2>
            <DialogCloseButton @closed="togglePinsDialog" />
        </header>
        <Divider />
        <div class="py-4 px-6 type-secondary text-xs typography">
            <p>
                Only the tray will be deleted. The pinned items in this tray
                will not be deleted and will be accessible in your
                `Favorites` tray.
            </p>
            <p class="text-white mt-2">
                Delete
                <span class="font-medium"
                    >'{{ data.tray.name ?? data.tray }}'</span
                >
                permanently?
            </p>
        </div>
        <Divider />
        <div class="p-4 flex justify-end gap-2">
            <button
                @click.prevent="togglePinsDialog"
                class="btn-app-sm-white-on-gray"
            >
                {{ $t("Cancel") }}
            </button>
            <button
                :disabled="inert"
                @click.prevent="delete_tray"
                class="btn-app-sm-white-on-red"
            >
                {{ $t("Delete Tray") }} <loading-circle v-if="inert" class="ml-2" />
            </button>
        </div>
    </div>
</template>

<script setup>
import {
    Divider,
    DialogCloseButton,
    LoadingCircle
} from "@/Components";
import { useDialog, usePinsDialog, usePinsDrawer } from "@/Composables";
import eventBus from "@/Composables/Modules/eventBus";
import {onMounted, onUnmounted} from "vue";
import config from "@/config";

const { forceClose } = useDialog();
const { togglePinsDialog } = usePinsDialog();
const { fetchTrays, deleteTray } = usePinsDrawer();

//PROPS
const { data } = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

function delete_tray(event) {
    let tray_name = data.tray.name ?? data.tray;
    deleteTray(tray_name);
}

function onTrayDeleted() {
    fetchTrays()
    togglePinsDialog()
}


//onMounted
onMounted(() => {
    //Register event listeners
    eventBus.on("pins:tray-deleted", onTrayDeleted);
});

//onUnmounted
onUnmounted(() => {
    //Register event listeners
    eventBus.off("pins:tray-deleted", onTrayDeleted);
    fetchTrays()
    togglePinsDialog()
});

</script>
