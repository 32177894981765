<template>
    <li data-dialog="true" class="at1024:flex at1024:items-center at1024:relative">
        <button
            class="flex font-bold items-center text-gray tracking-wider uppercase w-full hover:text-gray-800"
            aria-controls="nav-menu-catalog"
            :aria-expanded="menu === 'catalog'"
            @click="toggleMenu('catalog', $event)"
        >
            {{ $t("Catalog") }}
            <BaseSvg
                name="icon-expand-more-thick"
                class="shrink-0 ml-auto w-6 at1024:ml-0"
                aria-hidden="true"
                data-name="icon-expand-more-thick"
            />
        </button>
        <div class="absolute -left-8 top-full w-104 overflow-hidden">
            <transition
                name="slide-menu"
                @before-enter="menuTransitioning = true"
                @before-leave="menuTransitioning = true"
                @after-enter="menuTransitioning = false"
            >
                <div v-if="menu === 'catalog'" id="nav-menu-catalog" class="bg-white pt-6 w-104 shadow-menu">
                    <div class="nav-menu" :class="{'opacity-0': menuTransitioning,}">
                        <ul class="space-y-3 at1024:p-8 at1024:pt-0 at1024:space-y-4">
                            <li v-for="(catalogOption, i) in config.catalogOptions" :key="i">
                                <Link
                                    :href="route(catalogOption.route)"
                                    class="block group leading-4 text-gray-400 text-sm at1024:leading-normal"
                                >
                                    <b class="block group-hover:underline text-gray text-base at1024:text-xl">
                                        {{ catalogOption.title }}
                                    </b>
                                    <span class="sr-only">:</span>
                                    {{ catalogOption.description }}
                                </Link>
                            </li>
                        </ul>
                        <!--                                                <div-->
                        <!--                                                    class="mt-4 at1024:bg-gray-125 at1024:mt-0 at1024:p-8 at1024:pt-6"-->
                        <!--                                                >-->
                        <!--                                                    <span-->
                        <!--                                                        class="block mb-1 text-gray-400"-->
                        <!--                                                        >Browse by Segment</span-->
                        <!--                                                    >-->
                        <!--                                                    <ul class="space-y-2">-->
                        <!--                                                        <li-->
                        <!--                                                            v-for="catalogSegment in config.catalogSegments"-->
                        <!--                                                        >-->
                        <!--                                                            <Link-->
                        <!--                                                                :href="-->
                        <!--                                                                    route(-->
                        <!--                                                                        catalogSegment.route-->
                        <!--                                                                    )-->
                        <!--                                                                "-->
                        <!--                                                                class="font-medium text-gray hover:underline"-->
                        <!--                                                            >-->
                        <!--                                                                {{-->
                        <!--                                                                    catalogSegment.title-->
                        <!--                                                                }}-->
                        <!--                                                            </Link>-->
                        <!--                                                        </li>-->
                        <!--                                                    </ul>-->
                        <!--                                                </div>-->
                    </div>
                </div>
            </transition>
        </div>
    </li>
</template>
<script setup>
import {useNavigation} from "@/Composables";
import {ref} from "vue";
import config from "@/config";

const { menu, toggleMenu } = useNavigation();

const menuTransitioning = ref(false);
</script>
