<template>
    <article
        :id="article.id"
        class="relative bg-[#101012] text-white"
        itemtype="https://schema.org/Article"
    >
        <meta
            itemprop="datePublished"
            class="hidden"
            :content="article.publish_date"
        />
        <div
            class="absolute inset-0 after:absolute after:inset-0 after:bg-gradient-to-r after:from-[#111111_19%] after:to-transparent"
        >
            <img
                v-if="marketingAssetUrl(article.image)"
                :src="marketingAssetUrl(article.image)"
                :alt="article.image.alt_text || ''"
                class="absolute inset-0 w-full h-full object-cover"
                itemprop="image"
            />
        </div>
        <div class="wrap wrap-huge relative z-10 py-12 at1024:py-28">
            <div class="w-4/5 at1024:w-1/2">
                <p class="flex flex-wrap items-baseline gap-6">
                    <span class="sr-only">Article categories:</span>
                    <Link
                        v-for="category in article.categories"
                        :href="
                            route(
                                'web.education.category.show',
                                article.categories[0]?.slug
                            )
                        "
                        class="text-white break-words text-decoration-none bg-transparent hover:underline"
                        v-text="category.name"
                    />
                </p>
                <h3
                    class="font-bold leading-none mb-[10px] text-[40px] at1024:text-[80px]"
                    itemprop="headline"
                >
                    <Link
                        :href="article.url"
                        class="hover:text-red"
                        v-text="article.title"
                    />
                </h3>
                <div itemprop="description">
                    <p
                        class="mb-[30px] text-[20px] leading-tight at1024:text-[23px] at1024:mb-[40px]"
                    >
                        {{ article.headline }}
                    </p>
                    <p>
                        <Link
                            :href="article.url"
                            class="text-red border-b border-current capitalize hover:text-white"
                            tabindex="-1"
                            >Read more<span class="sr-only">
                                about {{ article.title }}</span
                            ></Link
                        >
                    </p>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useAssetTransforms } from "@/Composables";
import { inject } from "vue";

const { marketingAssetUrl } = useAssetTransforms();
const route = inject("route");
const props = defineProps(["article"]);
</script>
