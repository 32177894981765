<template>
    <select
        v-if="type === 'select'"
        v-model="computedModel"
        ref="input"
        v-bind="$attrs"
        @invalid.prevent="$emit('invalid', $event)"
        :class="fontSize ?? 'text-sm'"
        :autocomplete="autocomplete"
        @input.prevent="
            validateInputOnKeyUp($event.target);
            $event.target.setAttribute('data-placeholder', 'false');
        "
        :data-placeholder="
            $attrs.placeholder
                ? computedModel
                    ? false
                    : animated
                    ? 'true'
                    : false
                : 'false'
        "
    >
        <option v-if="$attrs.placeholder" selected disabled value="">
            {{ $attrs.placeholder }}
        </option>
        <slot></slot>
    </select>

    <textarea
        v-else-if="type === 'textarea'"
        v-model="computedModel"
        ref="input"
        v-bind="$attrs"
        @invalid.prevent="$emit('invalid', $event)"
        @input.prevent="validateInputOnKeyUp($event.target)"
        @blur.prevent="validateInputOnBlur($event.target)"
    />

    <input
        v-else-if="type === 'file'"
        :type="type"
        ref="input"
        v-bind="$attrs"
        @invalid.prevent="$emit('invalid', $event)"
        @change.prevent="uploadFile($event.target.files)"
        :multiple="multiple ?? true"
    />

    <input
        v-else-if="['radio', 'checkbox'].includes(type)"
        :type="type"
        ref="input"
        v-model="computedModel"
        v-bind="$attrs"
        :checked="type === 'checkbox' && modelValue"
        @invalid.prevent="$emit('invalid', $event)"
        :class="{
            '!cursor-not-allowed': disabled ?? false,
            }"
        :disabled="disabled ?? false"
    />

    <input
        v-else-if="type === 'date'"
        ref="input"
        :type="type"
        :disabled="disabled"
        v-model="computedModel"
        @change="validateDate"
        @invalid.prevent="$emit('invalid', $event)"
        v-bind="$attrs"
        :class="{
            [fontSize ?? ''] : fontSize,
            'text-sm': !fontSize,
            'cursor-not-allowed': disabled ?? false,
        }"
        @keyup.prevent="validateInputOnKeyUp($event.target)"
        @blur.prevent="validateInputOnBlur($event.target)"
    />

    <input
        v-else
        ref="input"
        data-type="text"
        :pattern="pattern"
        :type="type"
        :autocomplete="autocomplete"
        :disabled="disabled"
        v-model="computedModel"
        @invalid.prevent="$emit('invalid', $event)"
        v-bind="$attrs"
        :class="{
            [fontSize ?? ''] : fontSize,
            'text-sm': !fontSize,
            'cursor-not-allowed': disabled ?? false,
        }"
        @keyup.prevent="validateInputOnKeyUp($event.target)"
        @blur.prevent="validateInputOnBlur($event.target)"
    />
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import { useFormValidator } from "@/Composables";
import _ from 'lodash';

const $upload = inject("$upload");
const { validateInputOnBlur, validateInputOnKeyUp } = useFormValidator();

const emit = defineEmits(["update:modelValue", "invalid"]);
const props = defineProps([
    "modelValue",
    "animated",
    "type",
    "fontSize",
    "disabled",
    "multiple",
    "pattern",
    "autocomplete",
]);

const input = ref();
const uploading = ref(false);
const uploadProgress = ref(0);

function validate() {
    return validateInputOnKeyUp(input.value);
}

const validateDate = _.debounce((event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
        computedModel.value = currentDate.toISOString().slice(0, 10);
    }
}, 1000);

function uploadFile(files) {
    uploading.value = true;
    let file = files[0];
    $upload(files[0], {
        progress: (progress) => {
            uploadProgress.value = Math.round(progress * 100);
        },
    })
        .then((response) => {
            let value = {
                uuid: response.uuid,
                key: response.key,
                bucket: response.bucket,
                name: file.name,
                content_type: file.type,
            };
            props.modelValue.push(value);
            emit("update:modelValue", props.modelValue);
        })
        .catch((error) => {
        })
        .then(() => {
            uploading.value = false;
        });
}

defineExpose({
    uploading,
    uploadProgress,
    isValid: computed(() => {
        return input.value.validity.valid;
    }),
    validate,
});

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});
</script>
