<template>
    <div
        class="flex h-72 flex-col items-center justify-center"
        :class="{
            'gap-0': large,
            'gap-1': !large,
            [backgroundColor]: backgroundColor,
            'rounded-md': settings.is_sourcing_mode
        }"
    >
        <BaseSvg
            :name="icon"
            class="fill-gray-tier-7"
            :class="large ? 'w-28 h-28' : 'w-12 h-12'"
        />
        <h5
            class="font-normal"
            :class="large ? 'text-lg' : 'text-base'"
        >
            {{ $t(header) }}
        </h5>
        <p
            v-if="message"
            class="type-secondary text-center"
            :class="large ? 'text-base px-8' : 'text-sm w-96'"
        >
            {{ $t(message) }}
        </p>
        <slot />
    </div>
</template>

<script setup>
import {BaseSvg} from "@/Components";
import {useUser} from "@/Composables";

const { settings } = useUser();

const props = defineProps({
    header: {
        type: String,
        required: false,
        default: "No results found",
    },
    icon: {
        type: String,
        required: false,
        default: "icon-list",
    },
    message: {
        type: String,
        required: false,
    },
    large: {
        type: Boolean,
        required: false,
        default: false,
    },
    backgroundColor: {
        type: String,
        required: false,
    },
});
</script>
