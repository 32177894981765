import { computed, ref } from "vue";

const visibility = ref(false);
const coversNavbar = ref(false);
const visible = computed(() => !(visibility.value === false));
const zindex = computed(() => visibility.value);

export default function useOverlay() {
    const setVisibility = (value) => {
        visibility.value = value;
    };

    const setCoversNavbar = (value) => {
        coversNavbar.value = value;
    }

    return {
        coversNavbar,
        visible,
        zindex,
        setVisibility,
        setCoversNavbar,
    };
}
