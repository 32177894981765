<template>
    <svg
        width="512"
        height="512"
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M512 511.068V323.85C512 231.909 492.149 161.224 384.723 161.224C333.105 161.224 298.486 189.528 284.34 216.364H282.887V169.696H181.185V511.068H287.154V342.226C287.154 297.692 295.578 254.545 350.784 254.545C405.233 254.545 405.913 305.473 405.913 345.06V511.068H512ZM114.626 169.696H8.42131V511.068H114.626V169.696ZM61.5514 0C27.4765 0 0 27.5659 0 61.5249C0 95.4923 27.4765 123.057 61.5514 123.057C95.4923 123.057 123.047 95.4923 123.047 61.5249C123.047 27.5659 95.4923 0 61.5514 0Z"
        />
    </svg>
</template>
