<template>
    <div
        data-dialog="true"
        class="border border-keyline-primary rounded-md bg-gray-tier-4 w-96 shadow-app-standard"
    >
        <header
            class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md"
        >
            <h2 class="flex items-center gap-2 text-base font-medium">
                {{ data?.edit ? $t("Edit tray") : $t("Create new tray") }}
            </h2>
            <DialogCloseButton @closed="togglePinsDialog" />
        </header>
        <Divider />
        <form @submit.prevent="" class="text-base px-4 py-2 bg-gray-tier-3">
            <AppInput
                v-model="new_tray_name"
                data-dialog-focus="true"
                class="mb-4"
                type="text"
                :required="true"
                :label="$t('Name')"
            />
            <AppInput
                v-model="new_tray_description"
                class="mb-4 resize-none"
                :required="false"
                type="textarea"
                :label="$t('Description')"
                maxlength="100"
            ></AppInput>
            <label for="icon-select" class="block text-sm type-secondary mb-1">{{ $t("Tray icon") }}</label>
            <div id="icon-select" class="bg-gray-tier-5 rounded-md p-2 mb-4">
                <ul class="grid grid-cols-6 w-full gap-4">
                    <li v-for="icon in config.pins.icons">
                        <button
                            @click="new_tray_icon_dropdown = config.pins.icons.indexOf(icon)"
                            type="button"
                            :id="icon.value"
                            :name="icon.value"
                            class="flex items-center justify-center  p-2 rounded"
                            :class="new_tray_icon_dropdown === config.pins.icons.indexOf(icon) ? 'bg-blue text-white' :'opacity-50 hover:opacity-100 hover:bg-gray-tier-3'"
                        >
                            <BaseSvg :name="icon.icon" class="w-6 h-6" />
                        </button>
                    </li>
                </ul>
            </div>
        </form>
        <Divider />
        <div class="p-4 flex justify-end gap-2">
            <button @click.prevent="cancel" class="btn-app-sm-white-on-gray">
                {{ $t("Cancel") }}
            </button>
            <button
                v-if="props?.data?.edit"
                :disabled="disable_add_tray || inert"
                @click.prevent="editTray"
                class="btn-app-sm-white-on-red"
            >
                {{ $t("Edit Tray") }} <loading-circle v-if="inert" class="ml-2" />
            </button>
            <button
                v-else
                :disabled="disable_add_tray || inert"
                @click.prevent="addTray"
                class="btn-app-sm-white-on-red"
            >
                {{ $t("Create Tray") }} <loading-circle v-if="inert" class="ml-2" />
            </button>
        </div>
    </div>
</template>

<script setup>
import {
    Divider,
    DialogCloseButton,
    AppInput,
    LoadingCircle,
    BaseSvg,
} from "@/Components";
import { usePinsDrawer, useDialog, usePinsDialog, eventBus } from "@/Composables";
import { computed, ref, onMounted, onUnmounted } from "vue";
import config from "@/config";

const { forceClose } = useDialog();
const { togglePinsDialog } = usePinsDialog();
const { createTray, updateTray } = usePinsDrawer();

// PROPS
const props = defineProps({
    data: {
        type: Object,
        required: false,
        default: null,
    },
});

//COMPUTED
const disable_add_tray = computed(
    () => new_tray_name.value.trim().length === 0
);

//REFS
const new_tray_name = ref(initialize_name());
const new_tray_description = ref(initialize_description());
const new_tray_icon_dropdown = ref(initialize_icon());
const creating_tray = ref(false);

//FUNCTIONS
async function addTray(event) {
    if (!disable_add_tray.value) {
        creating_tray.value = true;
        let icon = config.pins.icons[new_tray_icon_dropdown?.value]?.icon;
        await createTray(new_tray_name.value, new_tray_description.value, icon);
    }
}
function editTray(event) {
    if (!disable_add_tray.value && props.data?.tray) {
        let tray = props.data.tray;
        let new_name = new_tray_name.value;
        let new_description = new_tray_description.value;
        let new_icon = config.pins.icons[new_tray_icon_dropdown.value].icon;
        updateTray(tray, new_name, new_description, new_icon);
    }
}

function cancel() {
    onCloseDialog();
}

function initialize_name() {
    return props?.data?.edit ? props?.data?.tray?.name ?? "" : "";
}

function initialize_description() {
    return props?.data?.edit ? props?.data?.tray?.description ?? "" : "";
}

function initialize_icon() {
    let initial_icon = 0;
    if (props?.data?.edit) {
        config.pins.icons.filter((tray, index) => {
            if (tray.icon === props?.data?.tray?.icon) {
                initial_icon = index;
                return true;
            }
        });
    }
    return initial_icon;
}

function onTrayCreated(tray) {
    if (creating_tray.value) {
        creating_tray.value = false;
        onCloseDialog(tray);
    }
}

function onTrayUpdated(tray) {
    onCloseDialog(tray);
}

function onCloseDialog(tray = null) {
    forceClose();
    if (props.data?.callback) {
        props.data?.callback(
            {},
            { ...props.data?.callback_params, tray_created: tray }
        );
    }
}

//onMounted
onMounted(() => {
    //Register event listeners
    eventBus.on("pins:tray-created", onTrayCreated);
    eventBus.on("pins:tray-updated", onTrayUpdated);
});

//onUnmounted
onUnmounted(() => {
    //Register event listeners
    eventBus.off("pins:tray-created", onTrayCreated);
    eventBus.off("pins:tray-updated", onTrayUpdated);
});
</script>
