<template>
    <Transition name="search-pop">
        <ActionDropdown
            v-if="open"
            data-dialog="true"
            :options="options"
            :offset="{ right: 0 }"
            class="ml-auto !w-48"
            v-click-outside="close"
        />
    </Transition>
</template>

<script setup>
import { ActionDropdown } from "@/Components";
import {
    usePinsDrawer,
    useDialog,
    usePinsDialog,
    useTrayMoveDialog,
    eventBus,
    useTrayAddToDialog,
} from "@/Composables";
import {onMounted, onUnmounted, ref} from "vue";

//COMPOSABLES
const { drawer, removePin } = usePinsDrawer();
const { forceClose } = useDialog();
const { togglePinsDialog } = usePinsDialog();
const { toggleTrayMoveDialog } = useTrayMoveDialog();
const { toggleTrayAddToDialog } = useTrayAddToDialog();

//EMITS
const emit = defineEmits(["close"]);

//PROPS
const { pin, open } = defineProps({
    pin: {
        type: Object,
        required: true,
        default: {},
    },
    open: {
        type: Boolean,
        required: false,
        default: false,
    },
});

//FUNCTIONS
function close() {
    emit("close");
}

function action_callback(event) {
    togglePinsDialog(event);
    emit_inert(false)
}

function emit_inert(state) {
    eventBus.emit('tray-pin-action-menu:inert', {state, pin})
}

//ACTIONS - Pin option actions
const action_move_to = {
    value: "moveTo",
    label: "Move to...",
    icon: "arrow-forward",
    action: (event) => {
        emit_inert(true)
        toggleTrayMoveDialog(event, {
            pinnable_id: pin.pinnable_id,
            pin_type: pin.type,
            callback: action_callback,
        });
    },
};

const action_add_to = {
    value: "addTo",
    label: "Add to...",
    icon: "addition-sign",
    action: (event) => {
        emit_inert(true)
        toggleTrayAddToDialog(event, {
            pin: pin,
            callback: action_callback, //Used to toggle the Pin Dialog back to the previously opened tray/pin
        });
    },
};

const action_copy_share_link = {
    value: "copyShareLink",
    label: "Copy share link",
    icon: "link",
    action: () => {
        navigator.clipboard.writeText(pin.route);

        eventBus.emit("alert:show", {
            title: "Copied to clipboard",
            message: pin.route,
            level: "info",
        });

        close();
    },
};

const action_remove_pin = {
    value: "removePin",
    label: "Remove Pin",
    icon: "trash-can",
    action: () => {
        emit_inert(true)
        removePin(pin.type, pin.pinnable_id, pin.name)
        close();
    },
};

//REFS
const options = ref([
    [
        action_move_to,
        pin.type === 'item' && action_add_to,
        action_copy_share_link,
    ],
    [action_remove_pin],
]);
const is_visible = ref(false);

//MOUNTED
onMounted(() => {
    eventBus.on('pins:pin-removed', () => emit_inert(false));
});

onUnmounted(() => {
    eventBus.off('pins:pin-removed');
});
</script>
