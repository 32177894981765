<template>
    <div
        data-dialog="true"
        id="printOrderDialog"
        class="flex flex-col relative w-[885px] print:w-full h-full bg-gray-tier-3"
    >
        <header class="sticky top-0 bg-gray-tier-2 border-b border-keyline-primary">
            <div
                class="w-full flex items-center justify-between py-2 px-6 print:px-0"
            >
                <h2>
                    {{ $t("Order #") }}
                    <span class="font-medium">{{ data }}</span>
                </h2>
                <span class="print:hidden flex items-center justify-end gap-2">
                    <AppButton
                        v-if="isEmployee()"
                        @click.prevent="
                            toggleModal(AuditModal, $event, {
                                order_id: order.id,
                            })
                        "
                        :text="$t('Audit')"
                        icon="table-rows"
                        :disabled="inert"
                        :loading="inert"
                    />
                    <AppButton
                        @click="
                            router.post(
                                route(
                                    'web.account.sales.orders.download',
                                    order.id
                                )
                            )
                        "
                        text="Download Full Details"
                        icon="export-file"
                        :disabled="inert"
                        :loading="inert"
                    />
                    <DialogCloseButton />
                </span>
            </div>
        </header>
        <div v-if="order" class="overflow-y-auto">
            <RecordHeader :record="order" class="px-6 print:px-0 py-3" />
            <Divider />
            <OrderRecord
                :order="order"
                :invoiceColumns="invoiceColumns"
                :invoiceRows="invoiceRows"
                :lineColumns="lineColumns"
                :lineRows="lineRows"
                :shipmentColumns="shipmentColumns"
                :shipmentRows="shipmentRows"
                :notesColumns="notesColumns"
                class="p-6 print:px-0 mb-8"
            />
        </div>
        <div
            v-else-if="errors"
            class="grow p-8 print:px-0 flex items-center justify-center"
        >
            <RecordsNotFound
                icon="icon-error-outline"
                header="Something went wrong"
                message="Please try again, and feel free to contact support if the problem persists."
            />
        </div>
        <div v-else class="absolute inset-0 flex items-center justify-center">
            <LoadingSpinner :large="true" />
        </div>
    </div>
</template>
<script setup>
import { computed, inject, markRaw, onMounted, ref } from "vue";
import { useHelpers, useModalDialog, useUser } from "@/Composables";
import axios from "axios";
import {
    AppButton,
    AuditModal,
    DateFormat,
    DialogCloseButton,
    Divider,
    LinkFormat,
    LoadingSpinner,
    MoneyFormat, OrderLineDetail,
    OrderRecord,
    OrderTracking,
    RecordHeader,
    RecordsNotFound,
    TableDownloadLink,
    TableStatus,
} from "@/Components";
import { router } from "@inertiajs/vue3";

const route = inject("route");
const props = defineProps(["data"]);

const { isEmployee } = useUser();
const { toggleModal } = useModalDialog();

const isLoading = ref(true);
const order = ref();
const errors = ref(false);

onMounted(() => {
    axios
        .get(route("api.v1.private.insights.orders.show", props.data), {
            params: {
                with: ["order"],
            },
        })
        .then((response) => {
            order.value = response.data["order"];
            order.value.notes.data = order.value.notes;
            isLoading.value = false;
        })
        .catch(() => (errors.value = true));
});

const invoiceColumns = [
    {
        name: "Inv #",
        component: markRaw(TableDownloadLink),
        props: (row) => ({
            linkText: row.invoice_id,
            class: 'hover:underline underline-offset-4 decoration-dotted font-medium',
            svg: 'FileDownload',
            svgSide: 'right',
            href: route('web.account.sales.invoices.show', {
                invoice: row.invoice_id,
                sequence: row.sequence,
            }),
        }),
    },
    {
        name: "Due Date",
        tdClasses: "type-tertiary",
        component: markRaw(DateFormat),
        props: (row) => ({
            date: row.date,
        }),
    },
    {
        name: "Status",
        component: TableStatus,
        props: (row) => ({
            value: row.accounts_receivable?.opn_balance > 0 ? "Due" : "Paid",
        }),
    },
    {
        name: "Amount",
        component: markRaw(MoneyFormat),
        headClass: '!pr-6',
        props: (row) => ({
            amount: row.total,
            alignRight: true,
            cellClass: 'pr-4',
        }),
        alignRight: true,
    },
];
const lineColumns = [
    {
        name: "Item #",
        component: markRaw(OrderLineDetail),
        props: row => ({
            linkText: row.item_id,
            routeName: 'web.catalog.products.show',
            routeParams: row.slug,
            line: row,
        }),
        noWrap: false
    },
    {
        name: "Qty",
        path: "quantity_ordered",
    },
    {
        name: "Shp",
        path: "quantity_shipped",
    },
    {
        name: "BO",
        path: "quantity_backordered",
    },
    {
        name: "GP%",
        path: "gross_profit",
        cellClass: 'type-tertiary',
    },
    {
        name: "Cost",
        path: "cost",
        component: markRaw(MoneyFormat),
        props: (row) => {
            return {
                amount: row.cost,
                stripFormatting: true,
                alignRight: true,
            };
        },
        alignRight: true,
    },
    {
        name: "Price",
        component: markRaw(MoneyFormat),
        props: (row) => ({
            amount: row.price,
            stripFormatting: true,
            alignRight: true,
        }),
        alignRight: true,
    },
    {
        name: "Surcharge",
        component: markRaw(MoneyFormat),
        props: (row) => ({
            amount: row.surcharge,
            stripFormatting: true,
            alignRight: true,
        }),
        alignRight: true,
    },
    {
        name: "Ext",
        component: markRaw(MoneyFormat),
        headClass: '!pr-6',
        props: (row) => ({
            amount: row.subtotal,
            alignRight: true,
            stripFormatting: true,
            cellClass: 'pr-4',
        }),
        alignRight: true,
    },
];
const shipmentColumns = [
    {
        name: "Shipped",
        component: markRaw(DateFormat),
        props: (row) => ({
            date: row.date,
        }),
    },
    {
        name: "Tracking",
        component: markRaw(OrderTracking),
        props: (row) => ({
            url: row.tracking_link,
            text: row.tracking_number,
        }),
    },
    {
        name: "Ship Via Code",
        path: "ship_via_code",
    },
];

const notesColumns = [
    {
        name: "Enter Date",
        path: "enter_date",
    },
    {
        name: "Entered By",
        path: "enter_by",
    },
    {
        name: "Note",
        path: "note",
        noWrap: true,
        cellClass: 'leading-tight',
    },
];

const lineRows = computed(() => order.value?.lines);
const shipmentRows = computed(() => order.value?.shipments);
const invoiceRows = computed(() => order.value?.invoices);
</script>
