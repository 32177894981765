<template>
    <AppModal
        :title="data?.card?.item_id"
        submit-text="Add To Project"
        @submit="select_project(selected_project)"
        @close="forceClose"
        :close-on-success="true"
        :valid="selected_project !== null"
        :scroll="false"
    >
        <template #content>
            <div class="p-6 items-center relative w-full space-y-4">

                <div v-if="loading" class="relative flex items-center justify-center h-full w-full">
                    <LoadingSpinner :large="true" />
                </div>

                <div v-else>
                  <div class="grow">
                    <SeclockMultiselect
                        size="large"
                        :large="true"
                        class="!h-full"
                        label-by="value"
                        value-by="value"
                        v-model="selected_project"
                        placeholder="Projects"
                        :disabled="!projects.length || loading"
                        :filter-results="false"
                        :min-chars="1"
                        :resolve-on-load="false"
                        :delay="0"
                        :no-options-text="$t('No projects found')"
                        :object="true"
                        :allow-absent="true"
                        :options="project_selections"
                    />
                  </div>

                    <div v-if="!projects.length" >

                        <p class="text-red underline-offset-4">No available projects to choose from.</p>

                        <div class="p-8">
                            <Divider />
                            <div class="flex flex-col at480:flex-row flex-wrap justify-center gap-4 text-center">
                                <div class="text-gray-400 w-full pt-8 pb-0">Visit Projects to create your first project.</div>
                                <Link
                                    :href="route('web.account.projects.index')"
                                    method="get"
                                    as="button"
                                    :disabled="inert"
                                    class="btn-white-on-black text-sm w-full flex gap-2 justify-center at480:w-fit whitespace-nowrap"
                                >
                                    Projects
                                </Link>
<!--                                <app-button-->
<!--                                    text="Create a project"-->
<!--                                    icon="icon-addition-sign"-->
<!--                                    theme="primary"-->
<!--                                    @click="toggleModal(ProjectCreateEditModal, $event, {})"-->
<!--                                />-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AppModal>
</template>


<script setup>
import {
    Divider,
    DialogCloseButton,
    SearchSelect,
    LoadingSpinner,
    AppButton,
} from "@/Components";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {eventBus, useDialog, useModalDialog, useUser} from "@/Composables";
import {router} from "@inertiajs/vue3";
import AppModal from "@/Components/Modules/Dialogs/AppModal.vue";
import SeclockMultiselect from "@/Components/Modules/FormElements/SeclockMultiselect.vue";
import Multiselect from "@vueform/multiselect";
import AppInput from "@/Components/Modules/FormElements/AppInput.vue";

const { forceClose } = useDialog();
const { toggleModal } = useModalDialog();

const props = defineProps(['data']);

const projects = ref({});
const selected_project = ref(null);
const loading = ref('true');

const project_selections = computed(() => {
    return projects.value.length ? projects.value.map((project) => {return {value: project.label}}) : {};
})

function select_project(key) {
  router.post(
        route("web.account.projects.hardware.store", {project: [...projects?.value]?.find(project => project.label === key)?.id}),
        {sku: props.data?.card?.item_id},
        {
            onSuccess: () => {
                forceClose();
            },
            onError: () => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was a problem adding this product",
                    level: "danger",
                });
            },
        }
    );
}

onMounted(() => {
    axios.get(route("web.account.projects.list"))
        .then(response => {
            projects.value = response.data ?? {};
        })
        .catch(error => {
            eventBus.emit("alert:show", {
                title: "Error",
                message: "There was an error fetching the project list ",
                level: "danger"
            });
        })
        .finally(() => {
            setTimeout(() => {loading.value = false}, 500);
        });
});
</script>
