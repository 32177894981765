<template>
    <component :is="display" :header="header" :record="record">
        <slot />
    </component>
</template>

<script setup>
import { computed } from "vue";
import { useUser } from "@/Composables";
import MarketingRecordHeader from "../../../Themes/Marketing/Components/Modules/Record/RecordHeader.vue";
import SourcingRecordHeader from "../../../Themes/Sourcing/Components/Modules/Record/RecordHeader.vue";

defineProps(["header", "record"]);
const { settings } = useUser();

const display = computed(() =>
    settings.value.is_sourcing_mode
        ? SourcingRecordHeader
        : MarketingRecordHeader
);
</script>
