<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        height="48"
        width="48"
    >
        <path
            d="M284-90q-31.76 0-54.38-22.62T207-167q0-31.76 22.62-54.38T284-244q31.76 0 54.38 22.62T361-167q0 31.76-22.62 54.38T284-90Zm391 0q-31.76 0-54.38-22.62T598-167q0-31.76 22.62-54.38T675-244q31.76 0 54.38 22.62T752-167q0 31.76-22.62 54.38T675-90ZM248-719l96 200h275l110-200H248Zm-35.88-75H788.5q23 0 34.5 19.84t.5 40.16l-138 249q-10 19.5-28.25 30.25T618-444H326l-45 82h471v75H284.19q-43.86 0-66.27-37.75Q195.5-362.5 216-400l54-96.78L128.12-797H50v-75h125l37.12 78ZM344-519h275-275Z"
        ></path>
    </svg>
</template>
