<template :key="id">
  <tr :id="id" class="border-none relative !bg-gray-tier-3 hover:!bg-gray-tier-5">
    <td v-if="isSubTable" class="w-10">
      <div
        class="mb-4 border-l border-b border-black dark:border-gray-350 rounded-bl"
        :class="{
          'pt-4 ml-11 h-5 w-10': settings.density === 'default',
          'pt-2 ml-8 h-5 w-10': settings.density === 'compact',
          'pt-1 ml-4 h-3 w-5': settings.density === 'dense',
        }" />
    </td>
    <td
      v-for="column in columns"
      :class="{'last-of-type:!pr-8': isSubTable}"
      class="relative group px-[10px] py-2 whitespace-nowrap at1024:py-[14px]">
      <span
        v-if="!column?.component"
        v-html="
          typeof column.formatter === 'undefined'
            ? _.get(row, column.path, '')
            : column.formatter(_.get(row, column.path, ''),row)
        " />
      <component
        :is="column?.component"
        v-else
        v-bind="column?.props ?? {}"
        :column="column"
        :value="
          typeof column.path === 'function'
            ? column.path(row)
            : _.get(row, column.path, row)
        " />
    </td>
  </tr>
  <template v-if="subTable && _.get(row,subTable?.rowPath,false)" class="w-full">
    <tr v-if="_.get(row, subTable?.rowPath, false)?.length" class="w-full" :class="{'hide':!showSubTable}">
      <td class="!p-0 !m-0 " :colspan="columns.length">
        <TableNew
          v-bind="subTable.props ?? {}"
          outer-div-classes="w-full overflow-x-scroll pb-0"
          :rows="_.get(row,subTable.rowPath)"
          :columns="subTable.columns ?? columns"
          :is-sub-table="true" />
      </td>
    </tr>
  </template>
</template>

<script setup>
import _ from "lodash";
import TableNew from "@/Components/Modules/jTable/TableNew.vue";
import {ref} from "vue";
import {useUser} from "@/Composables";

const { settings } = useUser();
const props = defineProps(["id","columns", "row", "subTable", "isSubTable"]);

const showSubTable = ref(props.subTable?.show ?? false);

</script>
