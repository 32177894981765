<template>
    <component
        ref="input"
        :is="display"
        :id="id"
        :placeholder="placeholder"
        v-model="computedModel"
        :search-is-loading="searchIsLoading"
        :large="large"
        :disabled="disabled"
        :medium="medium"
        @update:modelValue="$emit('update:modelValue', $event)"
        @submit="$emit('submit', $event)"
    />
</template>

<script setup>
import { useUser } from "@/Composables";
import {computed, ref} from "vue";
import MarketingSearch from "../../../Themes/Marketing/Components/Modules/FormElements/SearchInput.vue";
import SourcingSearch from "../../../Themes/Sourcing/Components/Modules/FormElements/SearchInput.vue";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode ? SourcingSearch : MarketingSearch
);

const emit = defineEmits(["update:modelValue", "submit"]);

const props = defineProps({
    placeholder: {
        type: String,
        required: false,
        default: "Search",
    },
    modelValue: {
        type: String,
        required: false,
        default: "",
    },
    searchIsLoading: {
        type: Boolean,
        required: false,
        default: false,
    },
    id: {
        type: String,
        required: false,
        default: "search",
    },
    large: {
        type: Boolean,
        required: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    medium: {
        type: Boolean,
        required: false,
    },
});

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});

const input = ref();

defineExpose(() => {
    input
});
</script>
