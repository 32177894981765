<template>
    <transition appear name="slide-right" v-bind="$attrs">
        <div
            v-if="visible"
            data-dialog="true"
            class="fixed h-screen right-0 bottom-0 shadow-search"
            :class="[
                'z-' + zindex,
                {
                    'top-[var(--header-height)] overflow-y-hidden': !settings.is_sourcing_mode
                },
            ]"
        >
            <slot></slot>
        </div>
    </transition>
</template>

<script setup>
import { useUser } from "@/Composables";
const { settings } = useUser();

const props = defineProps({
    component: {
        type: Object,
        required: false,
    },
    visible: {
        type: Boolean,
        required: true,
    },
    zindex: {
        type: Number,
        required: true,
    },
});
</script>

<style scoped>
.slide-right-enter-active {
    transition: all 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-right-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-right-enter-from,
.slide-right-leave-to {
    transform: translateX(100%);
}
</style>
