<template>
    <div class="relative z-20 w-full">
        <button
            @click.stop.prevent="open($event)"
            class="rounded w-full bg-gray-tier-2 duration-200 flex items-center justify-evenly gap-2 p-2 border text-xs focus:border-blue focus:ring-blue focus:ring-1 border-keyline-primary"
        >
            <BaseSvg name="icon-calendar" class="w-5" />
            {{ startDateFormatted }}
            <BaseSvg name="icon-arrow-forward" class="w-5" />
            {{ endDateFormatted }}
        </button>
        <Transition name="search-pop">
            <div
                v-click-outside="close"
                :class="positionLeft ? 'left-0 flex-row-reverse' : 'right-0 flex-row'"
                class="mt-2 flex absolute bg-gray-tier-4 border border-keyline-primary p-2 shadow-xl rounded-md"
                v-if="isOpen"
            >
                <Datepicker
                    :dark="settings.is_dark_mode"
                    v-show="isCustomOpen || isCustom"
                    inline
                    range
                    multi-calendars
                    multi-calendars-solo
                    v-model="dateRange"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    ref="datepickerComponent"
                    @closed="close"
                    :max-date="DateTime.now().toISODate()"
                    :min-date="minDate"
                    class="ml-4"
                />
                <ul v-if="presets" class="shrink-0 w-40">
                    <li v-for="preset in presets">
                        <button
                            @click.stop.prevent="setPresetDateRange(preset)"
                            :class="
                            preset.selected && !isCustom
                                ? 'dark:bg-gray-250 bg-gray-750 dark:text-black text-white'
                                : 'type-secondary hover:bg-gray-tier-5 hover:type-primary'
                        "
                            class="border-none text-left w-full rounded mb-2 py-1 px-2 text-sm"
                        >
                            {{ $t(preset.label) }}
                        </button>
                    </li>
                    <li>
                        <button
                            @click.stop.prevent="setIsCustom"
                            :class="isCustom || isCustomOpen? 'dark:bg-gray-250 bg-gray-750 dark:text-black text-white': 'hover:bg-gray-tier-5 hover:type-primary type-secondary'"
                            class="border-none text-left w-full rounded mb-2 py-1 px-2 text-sm"
                        >
                            {{ $t("datepicker.custom-range") }}
                        </button>
                    </li>
                    <li class="h-8 grid grid-cols-2 items-center gap-2" v-if="isCustomOpen">
                        <button @click="setCustomDateRange" class="h-full btn-app-sm-white-on-red flex justify-center text-tiny">
                            {{ $t("Apply") }}
                        </button>
                        <button
                            class="h-full btn-app-sm-white-on-gray flex justify-center text-tiny"
                            @click.prevent="isOpen = false"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>

<style>
/*@import '@vuepic/vue-datepicker/dist/main.css';*/

.dp__menu {
    border: none !important;
}

.dp__menu:focus {
    border: none;
}

.dp__theme_light {
    border: none;
    @apply bg-gray-tier-4;
}

.dp__month_year_row {
    color: #63666a;
    font-weight: bold;
}

.dp__action_row {
    display: none;
}

.dp__calendar_header_item {
    color: #63666a;
}

.dp__range_start, .dp__range_end {
    background-color: #3c434c;
}

.dp__cell_inner {
    height: 42px;
}

.--dp-border-color {
    border: none !important;
}
.--dp-menu-border-color{
    border: none !important;
}
.dp__theme_dark {
    border: none !important;
    background-color: transparent !important;
}

.dp__theme_dark .dp__range_start, .dp__theme_dark .dp__range_end {
    @apply bg-gray-250 text-black;
}
</style>

<script setup>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import { BaseSvg } from "@/Components";
import { useUser } from "@/Composables";

const {settings} = useUser();
const props = defineProps({
    presets : {
        required: false,
        default: null,
    },
    modelValue: {
        required: false,
        default: [DateTime.now().minus({ months: 1 }).toJSDate(), DateTime.now().toJSDate()],
    },
    minDate: {
        required: false,
        default: DateTime.now().minus({ years: 1 }).toJSDate(),
    }
});
const emit = defineEmits(["datesSelected", "update:modelValue"]);

const isOpen = ref(false);
const isCustom = ref(false);
const isCustomOpen = ref(false);
const positionLeft = ref(true);
const datepickerComponent = ref(null);

let dateRange = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});


const ISORegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
const startDateFormatted = computed(() => {
    if (typeof dateRange.value[0] === "string" && ISORegex.test(dateRange.value[0])) {
        return DateTime.fromISO(dateRange.value[0]).toFormat('MM/dd/yyyy');
    } else if (dateRange.value[0] instanceof Date) {
        return DateTime.fromJSDate(dateRange.value[0]).toFormat('MM/dd/yyyy');
    } else {
        return "mm/dd/yyyy";
    }
});

const endDateFormatted = computed(() => {
    if (typeof dateRange.value[1] === "string" && ISORegex.test(dateRange.value[1])) {
        return DateTime.fromISO(dateRange.value[1]).toFormat('MM/dd/yyyy');
    } else if (dateRange.value[1] instanceof Date) {
        return DateTime.fromJSDate(dateRange.value[1]).toFormat('MM/dd/yyyy');
    } else {
        return "mm/dd/yyyy";
    }
});

function deselectAllPresets() {
    props.presets.forEach((preset) => preset.selected = false);
}

function setPresetDateRange(preset) {
    dateRange.value = [preset.startDate, preset.endDate];
    isCustom.value = false;
    isCustomOpen.value = false;
    close();
    deselectAllPresets();
    preset.selected = true;
    emit("datesSelected", dateRange.value);
}

function setCustomDateRange() {
    datepickerComponent.value.selectDate();
    isCustomOpen.value = false;
    close();
}

function setIsCustom() {
    isCustom.value = true;
    isCustomOpen.value = true;
}

const open = (element) => {
    let elementPosition = element.target.getBoundingClientRect();
    const threshold = window.innerWidth / 2;
    positionLeft.value = elementPosition.left < threshold;
    isOpen.value = !isOpen.value;
}

const close = () => isOpen.value = false;
</script>
