<template>
    <component :is="display" v-model="computedModel" :presets="presets" />
</template>

<script setup>
import {computed, ref} from "vue";
import { useUser } from "@/Composables";
import MarketingDatePicker from "../../Themes/Marketing/Components/Modules/DatePicker.vue";
import SourcingDatePicker from "../../Themes/Sourcing/Components/Modules/DatePicker.vue";
import {DateTime} from "luxon";
import config from "@/config";

const emit = defineEmits(['update:modelValue', 'change']);
const { settings } = useUser();
const display = computed(() => settings.value.is_sourcing_mode ? SourcingDatePicker : MarketingDatePicker);

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
        emit("change");
    },
});

const presets = computed(() => {
    const [startDate, endDate] = props.modelValue;
    return props.presets.map(preset => {
        preset.selected = DateTime.fromJSDate(preset.startDate).hasSame(startDate, 'day')
            && DateTime.fromJSDate(preset.endDate).hasSame(endDate, 'day');

        return preset;
    })
})

const props = defineProps({
    presets : {
        required: false,
        default: config.datepicker.presets,
    },
    modelValue: {
        required: false,
        default: [DateTime.now().minus({ months: 1 }).toJSDate(), DateTime.now().toJSDate()],
    },
    minDate: {
        required: false,
        default: DateTime.now().minus({ years: 1 }).toJSDate(),
    }
});
</script>
