<template>
    <component :is="display" :title="title" :record="record">
        <template #buttons>
            <slot name="buttons" />
        </template>
        <slot />
    </component>
</template>

<script setup>
import { computed } from "vue";
import { useUser } from "@/Composables";
import MarketingRecordSection from "../../../Themes/Marketing/Components/Modules/Record/RecordSection.vue";
import SourcingRecordSection from "../../../Themes/Sourcing/Components/Modules/Record/RecordSection.vue";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode
        ? SourcingRecordSection
        : MarketingRecordSection
);

defineProps(["title", "record"]);
</script>
