<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        class="text-white"
    >
        <path
            fill-rule="evenodd"
            d="M184.261 169.077h-64.454c0 2.901-.341 5.689-.341 8.818 0 68.265 44.202 91.59 122.536 110.476 66.787 15.417 82.26 29.354 83.114 54.954h64.624v-4.438c0-61.552-40.504-89.427-118.327-108.087-70.484-17.066-87.323-29.468-87.323-58.367.057-1.308.114-2.332.171-3.356ZM254.631.002c103.663-.549 197.427 61.47 237.48 157.085 40.055 95.614 18.491 205.945-54.615 279.443-73.105 73.497-183.32 95.65-279.147 56.109C62.523 453.097 0 359.666 0 256.002-.314 115.022 113.652.445 254.631.002Z"
        />
    </svg>
</template>
