<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 613 90" style="height:26.91px;">
        <g fill="none" fill-rule="evenodd">
            <path
                fill="#63666A"
                fill-rule="nonzero"
                d="M144.63 80.15c13.59 0 23.11-7.21 23.11-19.42 0-10.82-7.11-15.72-20.8-19-12.38-3-15.34-5.18-15.34-10.27 0-4.34 3.88-7.76 10.54-7.76 5.91 0 11.74 2.31 17.56 6.65l6.1-8.6a35.85 35.85 0 0 0-23.48-8.13c-12.85 0-22.09 7.58-22.09 18.86 0 12 7.76 16.09 21.54 19.42 12 2.78 14.6 5.28 14.6 10 0 5-4.43 8.23-11.46 8.23-8 0-14.24-3-20.43-8.41l-6.84 8.14a39.86 39.86 0 0 0 26.99 10.29ZM239.23 79.16V69h-37.32V51.66h32.66V41.51h-32.66v-16.8h36.85V14.56h-48.3v64.6zM290.14 80.15c12.17 0 19.61-4.41 26.39-11.4l-7.34-7.36c-5.57 5.15-10.68 8.37-18.67 8.37-12.45 0-21.46-10.3-21.46-23S278.07 24 290.52 24c7.43 0 13 3.22 18.2 8l7.34-8.37c-6.22-5.89-13.47-10-25.45-10-19.7 0-33.54 15-33.54 33.38 0 18.52 14.12 33.14 33.07 33.14ZM384 78.82V68.49h-34.05V14.23h-11.38v64.59zM428.66 69.76c-12.94 0-22.15-10.39-22.15-23s9-22.81 22-22.81 22.15 10.39 22.15 23-9.06 22.81-22 22.81Zm-.18 10.39c20 0 34.16-15.17 34.16-33.38s-14-33.2-34-33.2S394.5 28.74 394.5 47c0 18.26 13.96 33.15 33.98 33.15ZM510.41 80.15c12.17 0 19.6-4.41 26.39-11.4l-7.34-7.36c-5.58 5.15-10.69 8.37-18.68 8.37-12.44 0-21.46-10.3-21.46-23S498.34 24 510.78 24c7.44 0 13 3.22 18.21 8l7.34-8.37c-6.22-5.89-13.47-10-25.45-10-19.7 0-33.54 15-33.54 33.38 0 18.52 14.12 33.14 33.07 33.14ZM566.9 78.82V60.09L577 49.76l22.15 29.06h13.81l-28.18-36.81 26.97-27.78h-13.99L566.9 46.71V14.23h-11.4v64.59z"
            />
            <path
                fill="#FE1940"
                d="M32.39 29.72H21.06c0 .51-.06 1-.06 1.55 0 12 7.77 16.1 21.54 19.42C54.28 53.4 57 55.85 57.15 60.35h11.36v-.78c0-10.82-7.12-15.72-20.8-19-12.39-3-15.35-5.18-15.35-10.26.01-.23.02-.41.03-.59ZM44.76 0a45.002 45.002 0 0 1 32.145 76.734A45 45 0 0 1 0 45C-.055 20.218 19.978.077 44.76 0Z"
            />
        </g>
    </svg>
</template>
