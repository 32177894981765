<template>
    <svg
        width="512"
        height="512"
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M200.465 287.999V512H300.729V287.999H375.489L389.714 195.339H300.729V135.207C300.729 109.857 313.16 85.1476 353.016 85.1476H393.474V6.26085C393.474 6.26085 356.757 0 321.653 0C248.365 0 200.465 44.3769 200.465 124.716V195.339H119V287.999H200.465Z"
        />
    </svg>
</template>
