import config from "@/config";
import useDialog from "./useDialog";
import { ImageDialog, ModalTransition } from "@/Components";

const { toggleDialog, forceClose } = useDialog();

export default function useImageDialog() {
    const toggleImageDialog = (event, data) => {
        toggleDialog(
            ImageDialog,
            ModalTransition,
            data,
            event,
            config.dialogs.modals.zindex,
            true
        );
    };

    return {
        toggleImageDialog,
        forceClose,
    };
}
