<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd">
            <path d="M24 0v24H0V0z" />
            <path
                d="M15.38 8.5H8.62L12 5.12l3.38 3.38M19 10l-7-7-7 7h14Zm0 4H5l7 7 7-7Z"
                fill="#000"
                fill-rule="nonzero"
            />
        </g>
    </svg>
</template>
