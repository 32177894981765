<template>
    <div
        data-dialog-focus="true"
        class="relative bg-white h-[calc(100vh_-_var(--header-height))] w-screen at1024:shadow-xl at1024:w-[880px]"
    >
        <div
            v-if="!isLoading && quote"
            class="relative overflow-y-auto p-8 at1024:p-16 h-full"
        >
            <DialogCloseButton class="absolute top-2 right-2" />
            <header class="mb-4">
                <RecordHeader
                    :header="$t('Quote: ') + '<strong>' + data + '</strong>'"
                    :reference-number="quote?.reference_id"
                    :record="quote"
                >
                    <button v-if="hasRole('Employee', 'sourcing')" class="btn-black-outline-pill">
                        <BaseSvg name="icon-table-rows" class="h-4" />
                        {{ $t("Audit") }}
                    </button>
                </RecordHeader>
            </header>
            <Divider class="mb-4" />
            <QuoteRecord v-bind="props" :data="data" />
        </div>
        <div
            v-else
            class="relative h-full w-full flex items-center justify-center"
        >
            <LoadingSpinner :large="true" />
        </div>
    </div>
</template>

<script setup>
import {
    BaseSvg,
    DialogCloseButton,
    Divider,
    LoadingSpinner,
    QuoteRecord,
    RecordHeader,
} from "@/Components";
import {useUser} from "@/Composables";

const { hasRole } = useUser();
const props = defineProps(["data", "quote", "isLoading", "columns", "rows"]);
</script>
