import { computed, reactive } from "vue";

export default function useDrawerNotification() {
    const state = reactive({
        notification: {
            message: "",
            type: "success",
            icon: "check-outlined",
            time: 3000,
        },
    });

    function notify(message, type = "success", icon = null, time = 3000) {
        const statusIcons = {
            success: "check-outlined",
            error: "error-outline",
            info: "info-circle",
        };

        icon = icon ?? statusIcons[type] ?? null;

        state.notification = {
            message,
            type,
            icon,
            time,
        };
    }

    function notifyFormErrors(
        form,
        fallback = "There was a problem with your submission",
        icon = null,
        time = 3000
    ) {
        const aggregatedErrors = Object.values(form.errors).join(". ");

        notify(
            aggregatedErrors ? aggregatedErrors : fallback,
            "error",
            icon,
            time
        );
    }

    return {
        notification: computed(() => state.notification),
        notify,
        notifyFormErrors,
    };
}
