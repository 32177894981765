<template>
    <li data-dialog="true" class="at1024:flex at1024:items-center at1024:relative">
        <button
            class="flex font-bold items-center text-gray tracking-wider uppercase w-full hover:text-gray-800"
            aria-controls="nav-menu-support"
            :aria-expanded="menu === 'support'"
            @click="toggleMenu('support', $event)"
        >
            {{ $t("Support") }}
            <BaseSvg
                name="icon-expand-more-thick"
                class="shrink-0 ml-auto w-6 at1024:ml-0"
                aria-hidden="true"
                data-name="icon-expand-more-thick"
            />
        </button>
        <div class="absolute -left-8 top-full w-104 overflow-hidden">
            <transition
                name="slide-menu"
                @before-enter="menuTransitioning = true"
                @before-leave="menuTransitioning = true"
                @after-enter="menuTransitioning = false"
            >
                <div v-if="menu === 'support'" id="nav-menu-support" class="bg-white pt-6 w-104 shadow-menu">
                    <div class="nav-menu" :class="{'opacity-0': menuTransitioning}">
                        <ul class="space-y-3 at1024:p-8 at1024:pt-0 at1024:space-y-4">
                            <li>
                                <a
                                    href="https://support.seclock.com"
                                    target="_blank"
                                    class="block group leading-4 text-gray-400 text-sm at1024:leading-normal"
                                >
                                    <b class="flex items-center gap-1 group-hover:underline text-gray text-base at1024:text-xl">
                                        {{$t("Support Articles") }}
                                        <BaseSvg name="OpenInNew" class="h-5 w-5"/>
                                    </b>
                                    <span class="sr-only">:</span>
                                    {{ $t('Reference library for questions and training') }}
                                </a>
                            </li>
                            <li>
                                <Link
                                    :href="route('web.locations')"
                                    class="block group leading-4 text-gray-400 text-sm at1024:leading-normal"
                                >
                                    <b class="block group-hover:underline text-gray text-base at1024:text-xl">
                                        {{$t("Locations") }}
                                    </b><span class="sr-only">:</span>
                                    {{ $t('Addresses, pick-up availability, and hours of operation') }}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('web.contact')"
                                    class="block group leading-4 text-gray-400 text-sm at1024:leading-normal"
                                >
                                    <b class="block group-hover:underline text-gray text-base at1024:text-xl">
                                        {{$t("Contact") }} </b><span class="sr-only">:</span>
                                    {{ $t('Contact form, directions, maps and contact emails') }}
                                </Link>
                            </li>
                        </ul>
                        <div class="mt-4 at1024:bg-gray-125 at1024:mt-0 at1024:p-8 at1024:pt-6">
                            <ul class="space-y-2">
                                <li>
                                    <Link :href="route('web.webinars')" class="font-medium text-gray hover:underline">
                                        {{ $t("Webinars") }}
                                    </Link>
                                </li>
                                <li>
                                    <Link :href="route('web.careers.index')" class="font-medium text-gray hover:underline">
                                        {{ $t("Careers") }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </li>
</template>

<script setup>
import {useNavigation} from "@/Composables";
import {ref} from "vue";

const {toggleMenu, menu} = useNavigation();
const menuTransitioning = ref(false);
</script>

