<template>
    <div
        role="status"
        aria-live="polite"
        aria-relevant="additions"
        class="fixed bottom-4 left-[82px] right-4 space-y-2 z-100 max-w-sm"
        v-if="alertCount"
        data-dialog="true"
    >
        <template v-for="(alert, uuid) in alerts" :key="uuid">
            <component
                :is="AlertTransition"
                :visible="alert.visible"
                :zindex="config.alerts.zindex"
                @click="deleteAlert(uuid)"
                @after-leave="afterLeave($el, alert, uuid)"
                @after-enter="afterEnter($el, alert, uuid)"
            >
                <div
                    v-if="alert.visible"
                    class="flex items-center max-w-md p-[14px] text-sm shadow-toast"
                    :class="config.alerts.levels[alert.level]?.bgColorClass"
                >
                    <base-svg
                        :name="config.alerts.levels[alert.level]?.icon"
                        class="h-8 mr-3 shrink-0 w-8"
                        :class="config.alerts.levels[alert.level]?.iconColorClass"
                    />
                    <div
                        :class="
                            config.alerts.levels[alert.level]?.textColorClass
                        "
                    >
                        <!-- <h4
                            v-if="alert.title"
                            class="font-semibold leading-none mb-1"
                        >
                            {{ alert.title }}
                        </h4> -->
                        <p v-if="alert.message" class="leading-none">
                            {{ alert.message }}
                        </p>
                    </div>
                </div>
            </component>
        </template>
    </div>
</template>

<script setup>
import config from "@/config";
import { AlertTransition, BaseSvg } from "@/Components";
import { useAlerts } from "@/Composables";

const { alerts, alertCount, deleteAlert, destroyAlert } = useAlerts();

const fadeOut = (alert, uuid) => {
    if (!alert.persist) {
        setTimeout(() => {
            deleteAlert(uuid);
        }, 5000);
    }
};

const afterEnter = (el, alert, uuid) => {
    fadeOut(alert, uuid);
};

const afterLeave = (el, alert, uuid) => {
    destroyAlert(uuid);
};
</script>
