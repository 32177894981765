import config from "@/config";
import useDialog from "./useDialog";
import {ModalTransition, AddToProjectDialog} from "@/Components";

export default function useAddToProjectDialog(){

    const { forceClose, toggleDialog } = useDialog();

    const toggleAddToProjectDialog = (event, data) => {
        toggleDialog(AddToProjectDialog, ModalTransition, data, event, config.dialogs.modals.zindex, true);
    };

    return {
        forceClose,
        toggleAddToProjectDialog,
    };

};
