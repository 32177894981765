export default function useFormValidator() {
    function validateInput(input) {
        return input.validity.valid;
    }

    function validateInputOnBlur(input) {
        let isValid = validateInput(input);

        if (!isValid) {
            input.classList.add("invalid");
        } else {
            input.classList.remove("invalid");
        }

        return isValid;
    }

    function validateInputOnKeyUp(input) {
        if (input.classList.contains("invalid")) {
            return validateInputOnBlur(input);
        }
        return true;
    }

    function validateForm(form) {
        if (form.reportValidity()) {
            return true;
        } else {
            elements(form)
                .reverse()
                .forEach((input) => {
                    validateInputOnBlur(input);
                });
            return false;
        }
    }

    function elements(form) {
        let elements = [];
        for (let i = 0; i < form.elements.length; i++) {
            if (
                ["INPUT", "SELECT", "TEXTAREA"].includes(
                    form.elements[i].nodeName
                )
            ) {
                elements.push(form.elements[i]);
            }
        }
        return elements;
    }

    return {
        validateInput,
        validateInputOnBlur,
        validateInputOnKeyUp,
    };
}
