<template>
    <section>
        <div
            class="project-page z-20 fixed flex flex-nowrap justify-start h-full w-full"
        >
            <div class="flex w-72 z-10 relative h-full border-r border-keyline-primary">
                <slot name="left"></slot>
            </div>
            <div
                class="transition-all w-[calc(100%-18rem)] flex bg-gray-tier-3 duration-200"
                :class="{
                    'translate-x-0': showDrawer,
                    'translate-x-60': showDrawer && showFiltersPanel,
                    '-translate-x-[353px]': !showDrawer && !showFiltersPanel,
                    '-translate-x-28': !showDrawer && showFiltersPanel,
                }"
            >
                <div
                    class="shrink-0 w-88 h-full transition-all duration-200"
                    :class="{ invisible: !showDrawer }"
                >
                    <slot name="drawer"></slot>
                </div>
                <div class="flex flex-1">
                    <div
                        class="border-l border-r border-keyline-primary flex-1 h-full app-scrollbar"
                    >
                        <slot name="center"></slot>
                    </div>
                    <div
                        class="transition-all overflow-visible"
                        :class="{
                            'w-88': props.showDetailPane,
                            'w-0': !props.showDetailPane,
                        }"
                    >
                        <div class="w-88 h-full app-scrollbar">
                            <slot name="right"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    showDrawer: {
        type: Boolean,
        required: false,
        default: false,
    },
    showFiltersPanel: {
        type: Boolean,
        required: false,
        default: false,
    },
    showDetailPane: {
        type: Boolean,
        required: false,
        default: true,
    },
});
</script>
