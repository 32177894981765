<template>
    <div
        class="seclock-multiselect"
        :class="{
            contrast: contrast,
            'not-large': !large,
            large: large,
            'sorting-button': sortingButton,
        }"
    >
        <div
            v-if="label && labelPosition === 'top'"
            class="mb-2 text-base type-primary"
        >
            <label
                :class="{ 'text-sm type-secondary leading-tight': isSourcing }"
                :html-for="id"
                >{{ label }}</label
            >
        </div>
        <Multiselect
            v-model="computedModel"
            :id="id"
            @select="$emit('select', $event)"
            @deselect="$emit('deselect', $event)"
            @paste="$emit('paste', $event)"
            @open="$emit('open', $event)"
            @close="$emit('close', $event)"
            @input="$emit('input', $event)"
            @search-change="$emit('input', $event)"
            @tag="$emit('tag', $event)"
            @option="$emit('option', $event)"
            @change="$emit('change', $event)"
            @clear="$emit('clear', $event)"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            @max="$emit('max', $event)"
            @create="$emit('create', $event)"
            :object="optionIsObject"
            :options="options"
            :value-prop="valueBy"
            :track-by="trackBy"
            :label="labelBy"
            :placeholder="placeholder"
            :disabled="disabled"
            :hide-selected="hideSelected"
            :searchable="searchable"
            :required="required"
            :close-on-select="closeOnSelect"
            :can-clear="canClear"
            :mode="mode"
            :multiple-label="multipleLabel"
            :allow-absent="allowAbsent"
            :max="max"
            :label-position="labelPosition"
            :can-deselect="canDeselect"
            :resolve-on-load="false"
        >
            <template v-slot:noresults="{ value }">
                <div class="p-1.5 text-center type-secondary bg-gray-tier-6">
                    {{ $t("No results found") }}
                </div>
            </template>
            <template v-slot:nooptions="{ value }">
                <div class="p-1.5 text-center type-secondary bg-gray-tier-6">
                    {{ $t("No options available") }}
                </div>
            </template>
            <template
                v-if="labelPosition === 'side' && label"
                v-slot:singlelabel="{ value }"
            >
                <div class="whitespace-nowrap flex items-center gap-1 justify-between px-4 !py-0">
                    <span class="float-left type-secondary">{{ label }}:</span>
                    {{ value.label }}
                </div>
            </template>
            <template v-for="(_, name) in $slots" #[name]="slotData">
                <slot :name="name" v-bind="slotData || {}" />
            </template>
        </Multiselect>
        <ValidationError v-if="error" :text="error" class="mt-1" />
    </div>

</template>

<script setup>
import Multiselect from "@/Components/Modules/Multiselect/Multiselect.vue";
import { useUser } from "@/Composables";
import { computed } from "vue";
import {ValidationError} from "@/Components";

const emit = defineEmits([
    "paste",
    "open",
    "close",
    "select",
    "deselect",
    "input",
    "search-change",
    "tag",
    "option",
    "update:modelValue",
    "change",
    "clear",
    "keydown",
    "keyup",
    "max",
    "create",
    "resolveOnLoad"
]);

const props = defineProps({
    id: {
        type: String,
        required: false,
    },
    modelValue: {
        type: [Array, Object, String, null, Number],
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: "",
    },
    optionIsObject: {
        type: Boolean,
        required: false,
        default: false,
    },
    options: {
        type: Array,
        required: false,
        default: [],
    },
    valueBy: {
        type: String,
        required: false,
        default: "value",
    },
    trackBy: {
        type: String,
        required: false,
        default: "value",
    },
    labelBy: {
        type: String,
        required: false,
        default: "label",
    },
    max: {
        type: Number,
        required: false,
        default: -1,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    searchable: {
        type: Boolean,
        required: false,
        default: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
    canClear: {
        type: Boolean,
        required: false,
        default: false,
    },
    canDeselect: {
        type: Boolean,
        required: false,
        default: true,
    },
    mode: {
        type: String,
        required: false,
        default: "single", // single|multiple|tags
    },
    multipleLabel: {
        type: Function,
        required: false,
    },
    closeOnSelect: {
        type: Boolean,
        required: false,
        default: true,
    },
    hideSelected: {
        type: Boolean,
        required: false,
        default: false,
    },
    allowAbsent: {
        type: Boolean,
        required: false,
        default: true,
    },
    contrast: {
        type: Boolean,
        required: false,
        default: false,
    },
    large: {
        type: Boolean,
        required: false,
        default: false,
    },
    label: {
        type: String,
        required: false,
    },
    labelPosition: {
        type: String,
        required: false,
        default: "top",
    },
    error:{
        type: String,
        required: false,
    },
    sortingButton: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const isSourcing = computed(() => {
    const { settings } = useUser();

    return settings.value.is_sourcing_mode;
});

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});
</script>

<style>
[multiple] {
    border: none;
    padding: 0;
}

.multiselect {
    @apply relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded-sm bg-white text-base leading-snug outline-none;
}

.multiselect:hover .multiselect-caret,
.multiselect.is-open .multiselect-caret {
    @apply opacity-70;
}

.multiselect-wrapper {
    @apply relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none;
}

.multiselect-single-label {
    @apply flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 pr-12 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5;
}

.multiselect-single-label-text {
    @apply overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full font-medium;
}

.multiselect-caret {
    @apply bg-multiselect-caret shrink-0 bg-center bg-no-repeat w-3.5 h-2.5 box-content mr-2.5 relative opacity-20 flex-grow-0 pointer-events-none rtl:mr-0 rtl:ml-3.5 py-2;
    /*
        z-10 removed in the rule above:
            The rule is causing a bug where the caret is persisting through other elements even with z index set to a higher value in other elements.
            Example Pin Drawer -> open tray -> open filter component -> caret will be present on first filter entry
        Original rule commented below.
    */
    /* @apply bg-multiselect-caret bg-center bg-no-repeat w-3 h-3 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5 py-2; */
}

/* .sm-filtered-search .multiselect-caret {
    @apply mr-4;
} */

.multiselect-tags {
    @apply flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2;
}

.multiselect-tag-placeholder-wrapper {
    @apply basis-full flex justify-between pt-1 font-medium text-gray-600;
}

/* Apply 50% opacity to the caret when focused */
/* .multiselect.is-open .multiselect-caret {
    opacity: 0.5 !important;
} */

.sourcing-layout .multiselect-tag-placeholder-wrapper {
    @apply font-normal !type-primary;
}

.sourcing-layout .contrast .multiselect-tag-placeholder-wrapper {
    @apply font-normal !type-primary;
}

.sourcing-layout .errors .multiselect {
    @apply outline-offset-0 outline outline-scarlet;
}

.multiselect-tag-placeholder {
    @apply pointer-events-none bg-transparent leading-snug pl-2 text-gray-600 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5;
}

.multiselect-tags-search {
    @apply cursor-pointer focus-within:cursor-text;
}

.sourcing-layout .multiselect-tag-placeholder {
    @apply type-primary;
}

.multiselect.is-disabled {
    @apply cursor-default pointer-events-none;
}

.multiselect.is-disabled .multiselect-wrapper {
    @apply opacity-50;
}

.multiselect.is-open {
    @apply rounded-sm;
}

.multiselect.is-open-top {
    @apply rounded-sm;
}

.multiselect.is-active {
    @apply ring-1 ring-blue !border-blue;
}

.multiselect-multiple-label {
    @apply flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2;
}

.multiselect-search {
    @apply flex items-center w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2;
}

.large .multiselect-search {
    @apply text-base;
}

.multiselect-tag {
    @apply bg-gray-600 text-white dark:text-gray-900 text-sm font-medium py-0.5 pl-2 rounded mr-1 mb-2 flex items-center rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1 max-w-[80%] overflow-ellipsis;
}

.sourcing-layout .sm-filtered-search .contrast .multiselect-tag {
    @apply py-1 rounded font-normal text-base;
}

.multiselect-tag.is-disabled {
    @apply pr-2 opacity-50 rtl:pl-2 pointer-events-none;
}

.multiselect-tag-remove {
    @apply flex items-center justify-center p-1 mx-1 rounded-sm;
}

.multiselect-tag-remove-icon {
    @apply bg-multiselect-remove invert bg-center bg-no-repeat opacity-50 inline-block w-2.5 h-2.5;
}

.multiselect-tag-remove:hover .multiselect-tag-remove-icon {
    @apply opacity-100;
}

.dark .multiselect-tag-remove-icon {
    @apply bg-multiselect-remove-dark;
}

.multiselect-tags-search-wrapper {
    @apply inline-block relative text-sm mb-1 flex-grow flex-shrink h-full;
}

.multiselect-tags-search {
    @apply absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full;
}

/* .multiselect-wrapper .multiselect-tags-search {
    @apply focus-within:opacity-30;
} */

.multiselect.is-open .multiselect-placeholder {
    @apply opacity-30;
}

.multiselect.is-open .multiselect-caret {
    @apply opacity-30 !important;
}

.large .multiselect-tags-search {
    @apply text-base;
}

.multiselect-tags-search-copy {
    @apply invisible whitespace-pre-wrap inline-block h-px;
}

.multiselect-placeholder {
    @apply flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2;
}

.contrast .multiselect-placeholder {
    @apply flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 text-gray-800 dark:text-gray-300 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2;
}

.multiselect-tag-placeholder-wrapper {
    @apply text-black dark:text-gray-300
}

.multiselect-tag-placeholder {
    @apply text-black;
}

.sourcing-layout .multiselect-placeholder {
    @apply type-tertiary;
}

/* .sourcing-layout .sm-filtered-search .multiselect:hover .multiselect-placeholder {
    @apply type-primary;
} */

.sourcing-layout .sm-filtered-search .multiselect .multiselect-placeholder {
    @apply type-primary;
}

.multiselect-caret.is-open {
    @apply pointer-events-auto shrink-0 opacity-10;
}

.multiselect-clear {
    @apply pr-2.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5;
}

.multiselect-clear-icon {
    @apply bg-multiselect-remove bg-center bg-no-repeat w-2 h-2 py-px box-content inline-block;
}

.dark .multiselect-clear-icon {
    @apply bg-multiselect-remove-dark;
}

.multiselect-spinner {
    @apply bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-2;
}

.multiselect-inifite {
    @apply flex items-center justify-center w-full;
}

.multiselect-inifite-spinner {
    @apply bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5;
}

.multiselect-dropdown {
    @apply max-h-60 absolute -left-px -right-px -bottom-1 transform translate-y-full border border-gray-150 -mt-px overflow-y-auto z-30 bg-white flex flex-col rounded-md pb-4 shadow-black/30 shadow-xl;
}

.multiselect-dropdown.is-top {
    @apply -translate-y-full top-px bottom-auto;
}

.multiselect-options {
    @apply flex flex-col p-0 m-0 list-none;
}

.multiselect-group {
    @apply p-0 m-0;
}

.multiselect-group-label {
    @apply flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal;
}

.multiselect-group-label.is-pointable {
    @apply cursor-pointer;
}

.multiselect-group-label.is-pointed {
    @apply bg-gray-300 text-gray-700;
}

.multiselect-group-label.is-selected {
    @apply bg-green-600 text-white;
}

.multiselect-group-label.is-disabled {
    @apply opacity-50 cursor-not-allowed pointer-events-none;
}

.multiselect-group-label.is-selected.is-pointed {
    @apply bg-green-600 text-white opacity-90;
}

.multiselect-group-label.is-selected.is-disabled {
    @apply text-blue bg-gray-tier-7 opacity-50 cursor-not-allowed pointer-events-none;
}

.multiselect-group-options {
    @apply p-0 m-0;
}

.multiselect-option {
    @apply type-secondary flex items-center justify-start box-border text-left cursor-pointer leading-snug py-1.5 px-2;
}

.large .multiselect-option {
    @apply text-base py-1.5 px-3;
}

.multiselect-option.is-pointed {
    @apply type-primary bg-gray-200;
}

.sourcing-layout .multiselect-option.is-pointed {
    @apply bg-gray-tier-6;
}

.multiselect-option.is-selected {
    @apply type-primary bg-gray-tier-6;
}

.multiselect-option.is-disabled {
    @apply text-gray-300 cursor-not-allowed pointer-events-none;
}

.multiselect-option.is-selected.is-pointed {
    @apply type-primary;
}

.multiselect-option.is-selected.is-disabled {
    @apply type-primary bg-gray-tier-4 opacity-50 cursor-not-allowed pointer-events-none;
}

.multiselect-no-options {
    @apply py-2 px-3 text-default bg-gray-tier-3 text-left rtl:text-right;
}

.multiselect-no-results {
    @apply py-2 px-3 text-gray-600 bg-white text-left rtl:text-right;
}

.multiselect-fake-input {
    @apply bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent;
}

.multiselect-assistive-text {
    @apply absolute -m-px w-px h-px overflow-hidden;
    clip: rect(0 0 0 0);
}

.multiselect-spacer {
    @apply h-7 py-px box-content;
}

.contrast .multiselect {
    @apply bg-gray-120 border-transparent py-0.5 rounded hover:bg-gray-150 focus-within:!bg-white;
}

.sourcing-layout .contrast .multiselect {
    @apply dark:bg-gray-795 bg-gray-130 hover:bg-gray-150 focus-within:!bg-gray-tier-3;
}

/* .sourcing-layout .sm-filtered-search .contrast {
    @apply mb-[0];
} */

.sourcing-layout .main-categories .seclock-multiselect {
    @apply mb-3;
}

.sourcing-layout .sm-filtered-search .contrast .multiselect {
    @apply  bg-gray-tier-5
            focus-within:!bg-gray-tier-3
            border-transparent
            hover:bg-gray-tier-6;
}

/* .sourcing-layout .sm-filtered-search .multiselect.is-active {
    @apply ring-0 border-blue border-b-2 mb-[-1px] duration-0;
} */

.sourcing-layout .contrast .multiselect.is-disabled {
    @apply focus-within:!bg-gray-tier-5 pointer-events-none;
}

.dark .sourcing-layout .multiselect-caret {
    @apply bg-multiselect-caret-dark py-2;
}

.contrast .multiselect-tags-search {
    @apply !bg-transparent;
}

.sourcing-layout .contrast .multiselect-tag {
    @apply dark:bg-gray-225;
}

.contrast .multiselect-tag {
    @apply bg-gray rounded-sm;
}

/* .dark .contrast .multiselect-tag {
    @apply !bg-gray-250 rounded;
} */

.sourcing-layout .multiselect {
    @apply bg-gray-tier-2 rounded border-keyline-primary text-sm;
}

.sourcing-layout .multiselect.is-active {
    @apply bg-gray-tier-4;
}

.sourcing-layout .multiselect-dropdown {
    @apply bg-gray-tier-5 !rounded-md;
}

.sourcing-layout .large .multiselect {
    @apply text-base h-auto p-0 py-1;
}

.large .multiselect {
    @apply text-base h-0 py-6;
}

.sourcing-layout .not-large .multiselect {
    @apply h-0 py-4;
}

.sourcing-layout .multiselect-search {
    @apply rounded;
    background-color: inherit;
}

.sourcing-layout .multiselect-dropdown {
    @apply bg-white dark:bg-gray-700 border-keyline-primary shadow-xl pb-4;
}

.sourcing-layout .multiselect-tags-search-wrapper {
    @apply border-keyline-primary;
}

.sourcing-layout .multiselect-tags-search {
    @apply bg-gray-tier-5;
}

.dark .sourcing-layout .multiselect-dropdown {
    @apply shadow-app-standard;
}

.sourcing-layout .multiselect-dropdown {
    @apply shadow-xl shadow-black/20;
}

.sorting-button .multiselect {
    @apply relative mx-auto h-8 bg-gray-125 w-full flex items-center justify-end box-border cursor-pointer text-base rounded-sm leading-snug outline-none;
}

.sorting-button .multiselect-dropdown {
    @apply max-h-60 absolute -left-px -right-px -bottom-1 transform translate-y-full border border-gray-150 -mt-px overflow-y-auto z-30 bg-white flex flex-col rounded pb-4 shadow-black/30 shadow-xl;
}

.multiselect-dropdown.is-hidden {
    @apply hidden;
}
</style>
