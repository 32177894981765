<template>
    <svg
        width="1792"
        height="129"
        viewBox="0 0 1792 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4611 3759.5C4611 5835.27 2947.5 7518 895.5 7518C-1156.5 7518 -2820 5835.27 -2820 3759.5C-2820 1683.73 -1156.5 1 895.5 1C2947.5 1 4611 1683.73 4611 3759.5Z"
            fill="white"
            stroke="#C8C9C7"
            stroke-width="2"
        />
    </svg>
</template>
