<template>
    <span v-if="!settings.is_sourcing_mode" class="font-medium text-secondary flex items-center ">
        <span class="p-1 rounded-full" :class="backgroundColor"></span>
        <span class="text-xs uppercase px-1.5 font-medium rounded-full">{{ value }}</span>
    </span>
    <span v-else class="font-medium text-secondary flex items-center ">
        <span class="text-[.8rem] uppercase py-0.5 px-3 rounded-full inline-block" :class="backgroundColorSourcing">{{ value }}</span>
    </span>
</template>

<script setup>
import { computed } from "vue";
import { useUser } from "@/Composables";

const { settings } = useUser();

const props = defineProps(['value']);

let backgroundColor = computed(() => {
    switch(props.value.toLowerCase()) {
        case "complete":
            return "text-green bg-green";
        case "paid":
            return "text-green bg-green";
        case "approved":
            return "text-green bg-green";
        case "pending":
            return "text-blue bg-blue";
        case "in progress":
            return "text-project-avocado bg-project-avocado";
        case "due":
            return "text-orange bg-orange";
        case "on hold":
            return "text-orange bg-orange";
        default:
            return "bg-gray-600 dark:bg-white";
    }
});

let backgroundColorSourcing = computed(() => {
    switch(props.value.toLowerCase()) {
        case "complete":
            return "text-green bg-green/10";
        case "paid":
            return "text-green bg-green/10";
        case "approved":
            return "text-green bg-green/10";
        case "pending":
            return "text-blue bg-blue/10";
        case "in progress":
            return "text-project-avocado bg-project-avocado/10";
        case "due":
            return "text-orange bg-orange/10";
        case "on hold":
            return "text-orange bg-orange/10";
        case "active":
            return "text-green bg-green/10";
        default:
            return "bg-gray-600/10 dark:bg-gray-600/20";
    }
})

</script>
