<template>
    <div class="relative w-full">
        <top-drawer-transition
            :visible="state.open"
            position="absolute"
            :zindex="10"
        >
            <p
                class="px-6 py-1 flex items-center"
                :class="{
                    'bg-apple text-white': notification.type === 'success',
                    'bg-scarlet text-white': notification.type === 'error',
                    'bg-gray-tier-7 text-gray-tier-1':
                        notification.type === 'info',
                }"
                @click="notification.show = false"
            >
                <base-svg
                    v-if="notification.icon"
                    :name="`icon-${notification.icon}`"
                    class="w-4"
                />
                <span class="ml-2 text-xs leading-4">{{
                    notification.message
                }}</span>
            </p>
        </top-drawer-transition>
    </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import { BaseSvg, TopDrawerTransition } from "@/Components";

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
});

const state = reactive({
    open: false,
    previous: {
        message: "",
        type: "success",
        icon: "check-outlined",
    },
});

watch(
    () => ({ ...props.notification }),
    () => {
        notify();
    }
);

function showNotification(notification) {
    state.open = true;

    window.setTimeout(() => {
        if (notification.message === props.notification.message) {
            state.open = false;
        }
    }, notification.time);
}

function notify() {
    if (state.open) {
        state.open = false;

        window.setTimeout(() => {
            showNotification({ ...props.notification });
        }, 500);
    } else {
        showNotification({ ...props.notification });
    }
}
</script>
