<template>
    <!-- WRAP -->
    <div id="marketing-layout" class="marketing-layout">
        <!-- HEADER -->
        <MarketingHeader />
        <!-- CONTENT -->
        <main
            id="main"
            class="ph"
        >
            <slot />
        </main>
        <!-- FOOTER -->
        <MarketingFooter v-if="!footerHidden.includes($page.component)" />
        <!-- MANUAL INTERCOM -->
        <IntercomButton />
    </div>
</template>

<script setup>
import { MarketingHeader, MarketingFooter, IntercomButton } from "@/Components";

// Footer will be hidden on all components within this footerHidden array
const footerHidden = ["Catalog/Products/Index"];
</script>
