<template>
    <div
        class="inline-flex relative"
        :class="{ 
            'w-full': (width === 'full'),
            'w-full h-full': (size === 'full'),
        }"
    >
        <indicator :active="showIndicator" />
        <button
            :type="type"
            :disabled="disabled"
            @mouseenter="onHover()"
            @mouseleave="offHover()"
            class="group relative border flex items-center justify-center gap-1 rounded"
            :class="[
                props.class,
                {
                    'font-medium': size === 'large' || ['primary', 'green'].includes(theme),
                    'p-1': size === 'small' || size === 'tight',
                    'py-2 px-2': size === 'large',
                    'p-2': size === 'large' && iconOnly,
                    'w-full h-[34px]': size === 'full',
                    'w-full': width === 'full',
                    'px-4': size === 'tight',
                    'border-keyline-primary bg-white dark:bg-gray-700': theme === 'default',
                    'dark:hover:bg-gray-650 dark:hover:border-gray-875 dark:active:bg-gray-700 hover:border-gray-700 active:bg-gray-150 ': !destructive && theme === 'default',
                    'hover:border-crimson hover:bg-crimson': destructive && theme === 'default',
                    'bg-scarlet !border-scarlet text-white enabled:hover:bg-crimson enabled:hover:!border-crimson': theme === 'primary',
                    'disabled:bg-gray-tier-7 disabled:!border-gray-tier-7 disabled:type-primary disabled:text-gray': theme === 'primary',
                    'border-apple bg-apple hover:bg-opacity-80 text-white': theme === 'green',
                    'bg-gray-tier-2': dark && theme === 'default',
                    'opacity-50 cursor-not-allowed': disabled,
                    'opacity-50': loading,
                    'btn-app-sm-white-on-black': theme === 'contrast',
                    'btn-app-lg-white-on-black': theme === 'contrast' && size === 'large',
                    'disabled:bg-gray-tier-5': theme !== 'green' && theme !== 'contrast',
                },
            ]"
            :aria-label="ariaLabel"
        >
            <span
                class="flex items-center gap-1.5"
                :class="{
                    'px-3': size === 'small' && !iconOnly,
                    'px-6': size === 'large' && !iconOnly,
                    'flex-row-reverse': reverse,
                }"
            >
<!--                <TransitionGroup-->
<!--                    v-if="icon"-->
<!--                    :name="{-->
<!--                        'switch-vertical': iconOnly,-->
<!--                        'switch': reverse,-->
<!--                        '': !iconOnly,-->
<!--                    }"-->
<!--                    tag="div"-->
<!--                    class="flex items-center"-->
<!--                >-->
                <TransitionGroup
                    v-if="icon"
                    name="switch-vertical"
                    tag="div"
                    class="flex items-center"
                >
                    <LoadingCircle key="loading" v-if="loading" :class="{
                        'w-4 h-4': !iconSize || iconSize === 'small',
                        'w-6 h-6 p-0.5':(iconOnly && !iconSize) || iconSize === 'large',
                    }"/>
                    <BaseSvg
                        key="button"
                        v-if="!loading"
                        :name="icon"
                        aria-hidden="true"
                        :class="[
                            {
                                [iconClass]: iconClass,
                                'type-secondary':
                                    !iconOnly &&
                                    theme === 'default' &&
                                    icon !== 'icon-trash-can',
                                'group-hover:text-white': destructive,
                                'w-4 h-4': !iconSize || iconSize === 'small',
                                'w-6 h-6':(iconOnly && !iconSize) || iconSize === 'large',
                            },
                        ]"
                    />
                </TransitionGroup>
                <span v-else-if="loading">
<!--                <Transition :name="reverse ? 'fade-right' : 'fade-left'">-->
                    <LoadingCircle class="w-4 h-4"/>
<!--                </Transition>-->
                </span>
                <span
                    class="whitespace-nowrap text-sm"
                    :class="{ 'sr-only': iconOnly }"
                >
                    <span v-if="prefix" class="text-gray-tier-7 font-bold inline-block">{{ prefix }}</span>
                    {{ typeof text === "string" ? $t(text) : text }}
                </span>
            </span>
        </button>
        <Transition v-if="tooltip" :name="'fade-' + tooltip?.side">
            <ButtonTooltip
                v-if="
                    tooltip.text?.length ||
                    (iconOnly &&
                        text?.toLowerCase() !== 'close' &&
                        text?.toLowerCase() !== 'back')
                "
                v-show="state.showTooltip"
                :text="tooltip?.text || text"
                :side="tooltip?.side"
            />
        </Transition>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { BaseSvg, ButtonTooltip, Indicator, LoadingCircle } from '@/Components'

const props = defineProps({
    type: {
        type: String,
        required: false,
        default: "button",
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    icon: {
        type: String,
        required: false,
        default: "",
    },
    iconOnly: {
        type: Boolean,
        required: false,
        default: false,
    },
    iconClass: {
        type: String,
        required: false,
        default: "",
    },
    iconSize: {
        type: String,
        required: false,
        default: "",
    },
    reverse: {
        type: Boolean,
        required: false,
        default: false,
    },
    theme: {
        type: String,
        required: false,
        default: "default",
    },
    destructive: {
        type: Boolean,
        required: false,
        default: false,
    },
    size: {
        type: String,
        required: false,
        default: "small",
    },
    text: {
        type: String,
        required: false,
    },
    prefix: {
        type: String,
        required: false,
    },
    class: {
        type: String,
        required: false,
        default: "",
    },
    dark: {
        type: Boolean,
        required: false,
        default: false,
    },
    tooltip: {
        type: Object,
        required: false,
        default: null,
    },
    ariaLabel: {
        type: String,
        required: false,
    },
    showIndicator: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: false,
    },
});

const state = reactive({
    hover: false,
    showTooltip: false,
});

function onHover() {
    if (!state.hover) {
        state.hover = true;
        showTooltip();
    }
}

function offHover() {
    state.hover = false;
    state.showTooltip = false;
}

function showTooltip() {
    window.setTimeout(() => {
        if (state.hover) {
            state.showTooltip = true;
        }
    }, 1000);
}
</script>

<style>
.switch-vertical-move, /* apply transition to moving elements */
.switch-vertical-enter-active,
.switch-vertical-leave-active {
    transition: all .15s ease-in-out;
}

.switch-vertical-enter-from,
.switch-vertical-leave-to {
    opacity: 0;
    transform: translateY(50%);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.switch-vertical-leave-active {
    position: absolute;
    overflow:hidden;
    display: flex;
    align-items: center;
}
</style>
