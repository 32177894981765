import { computed, markRaw, ref } from "vue";
import useOverlay from "../useOverlay";
import _ from "lodash";
import {eventBus} from "@/Composables";

const dialog = ref(false);
const wrap = ref(false);
const trigger = ref(null);
const visibility = ref(false);
const data = ref(false);
const underNav = ref(false);

const { setVisibility: setOverlayVisibility, setCoversNavbar: setCoversNavbar } = useOverlay();
const delta = 6;
let startX;
let startY;
let target;

export default function useDialog() {
    const clearTrigger = () => {
        trigger.value = null;
    };

    const forceClose = (clear = false) => {
        visibility.value = false;
        data.value = false;
        setOverlayVisibility(false);
        dialog.value = false;
        if (clear) {
            if (trigger.value) {
                trigger.value?.focus();
            }
            clearTrigger();
        }
        eventBus.emit("form:reset");
        eventBus.emit('force-close');
    };

    const forceOpen = (
        component,
        transition,
        componentData,
        event,
        zindex,
        overlay,
        under = false
    ) => {
        visibility.value = zindex;
        data.value = componentData;
        dialog.value = component ? markRaw(component) : false;
        wrap.value = transition ? markRaw(transition) : false;
        trigger.value = event?.target ?? null;
        setOverlayVisibility(overlay ? zindex - 40 : false);
        setCoversNavbar(wrap.value.name === "modal-transition-component-component");
        underNav.value = under;
    };

    const toggleDialog = (
        component,
        transition,
        componentData,
        event,
        zindex,
        overlay,
        under
    ) => {
        //if not visible, automatically open
        if (!visibility.value) {
            forceOpen(
                component,
                transition,
                componentData,
                event,
                zindex,
                overlay,
                under,
            );
            return;
        }

        //if the trigger matches previous trigger, close
        if (trigger.value === event.target) {
            forceClose();
            return;
        }

        if (component.__file === dialog.value.__file) {
            if (_.isEqual(componentData, data.value)) {
                forceClose();
                return;
            }
        }

        forceOpen(component, transition, componentData, event, zindex, overlay, under);
    };

    function registerEventListeners() {
        document.addEventListener(
            "keyup",
            (event) => {
                if (event.key === "Escape" || event.keyCode === 27) {
                    console.log('esc: clearing form');
                    eventBus.emit('form:reset');
                }
            }
        )

        document.addEventListener(
            "mousedown",
            function (event) {
                startX = event.pageX;
                startY = event.pageY;
                target = event.target;
            },
            { passive: true }
        );

        document.addEventListener(
            "mouseup",
            function (event) {
                const diffX = Math.abs(event.pageX - startX);
                const diffY = Math.abs(event.pageY - startY);

                if (diffX < delta && diffY < delta) {
                    if (!event.target.closest('[data-dialog="true"]') && !target.closest('[data-dialog="true"]')) {
                        try {
                            forceClose(true);
                        } catch (error) {}
                    }
                }
            },
            { passive: true }
        );

        document.addEventListener(
            "keyup",
            (event) => {
                if (event.key === "Escape") {
                    try {
                        forceClose(1);
                    } catch (error) {}
                }
            },
            { passive: true }
        );
    }

    return {
        registerEventListeners,
        clearTrigger,
        toggleDialog,
        forceClose,
        dialog,
        wrap,
        trigger: computed(() => trigger.value),
        visible: computed(() => !(visibility.value === false)),
        zindex: computed(() => (visibility.value ? visibility.value : 40)),
        data: computed(() => data.value),
        under: computed(() => underNav.value),
    };
}
