<template>
    <template v-if="amount > 0 || !zeroAmount">
        <span
            :class="[
                alignRight ? 'flex justify-end text-right' : '',
                stripFormatting ? '' : 'type-primary w-full font-medium',
                settings.is_sourcing_mode ? 'tabular-nums' : '',
                cellClass ? cellClass : '',
            ]"
        >
          {{ value }}
        </span>
    </template>
    <template v-else-if="typeof zeroAmount === 'object'">
        <component :is="zeroAmount" v-bind="props" />
    </template>
    <template v-else>
        <span v-html="typeof zeroAmount === 'function' ? zeroAmount(value) : zeroAmount" />
    </template>
</template>

<script setup>
import {useHelpers} from "@/Composables";
import {computed} from "vue";
import { useUser } from "@/Composables";

const { settings } = useUser();

const {showMeTheMoney} = useHelpers();

const props = defineProps({
    amount: {
        type: [Number, Boolean],
        required: true,
        default: 0,
    },
    zeroAmount: {
        type: [Function, Number, String, Object],
        required: false,
        default: false,
    },
    alignRight: {
        type: Boolean,
        required: false,
        default: false,
    },
    stripFormatting: {
        type: Boolean,
        required: false,
        default: false,
    },
    cellClass: {
        type: String,
        required: false,
    },
});

const value = computed(() => showMeTheMoney(props.amount));
</script>
