<template>
    <div class="flex flex-grow justify-end basis-1/4">
        <nav class="h-full" :aria-label="$t('Secondary navigation')">
            <ul class="flex gap-2 h-full at1024:justify-end">
                <Login v-if="!user" />
                <Account v-if="user" />
                <Register v-if="!user" />
<!--                <Compare v-if="user" />-->
                <Cart v-if="user" />
                <NavbarSearch />
            </ul>
        </nav>
    </div>
</template>

<script setup>
import {useCart, useUser} from "@/Composables";
import {Account, Cart, Compare, Login, NavbarSearch, Register} from "@/Components";

const { cartItemCount } = useCart();
const { user } = useUser();
</script>
