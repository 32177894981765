<template>
    <svg
        width="63"
        height="89.2834"
        viewBox="9 7 63 89.2834"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_1443_72772)">
            <rect x="9" y="7" width="63" height="89.2834" fill="white" />
        </g>
        <path
            d="M18.3083 14.2871C18.7312 14.2871 19.0266 14.0652 19.0266 13.6892C19.0266 13.3563 18.8058 13.2053 18.3798 13.1005C17.9942 13.008 17.9041 12.9402 17.9041 12.783C17.9041 12.6505 18.0253 12.5426 18.2305 12.5426C18.414 12.5426 18.5943 12.6135 18.7778 12.7491L18.9675 12.484C18.7623 12.3207 18.5291 12.2344 18.2368 12.2344C17.8388 12.2344 17.5496 12.4686 17.5496 12.8169C17.5496 13.1868 17.7921 13.3131 18.2181 13.4148C18.5912 13.5011 18.6721 13.5782 18.6721 13.7231C18.6721 13.8772 18.5353 13.9758 18.3145 13.9758C18.0658 13.9758 17.873 13.8803 17.6802 13.7169L17.4688 13.9666C17.7113 14.1792 18.0005 14.2871 18.3083 14.2871Z"
            fill="#1F1F1F"
        />
        <path
            d="M21.2486 14.2562V13.9418H20.0888V13.4086H21.1056V13.0942H20.0888V12.5764H21.2362V12.2651H19.7344V14.2562H21.2486Z"
            fill="#1F1F1F"
        />
        <path
            d="M22.8339 14.2871C23.2132 14.2871 23.4433 14.1515 23.6548 13.9357L23.4278 13.7076C23.2537 13.8679 23.0951 13.9666 22.8463 13.9666C22.4608 13.9666 22.1778 13.6491 22.1778 13.2577C22.1778 12.8662 22.4577 12.5549 22.8463 12.5549C23.0764 12.5549 23.2506 12.6535 23.4122 12.8015L23.6392 12.5426C23.4464 12.3607 23.2195 12.2344 22.8463 12.2344C22.2338 12.2344 21.8047 12.6967 21.8047 13.2638C21.8078 13.834 22.2462 14.2871 22.8339 14.2871Z"
            fill="#1F1F1F"
        />
        <path
            d="M25.7515 14.244V13.9265H24.6943V12.2529H24.3398V14.244H25.7515Z"
            fill="#1F1F1F"
        />
        <path
            d="M27.1415 13.9666C26.7404 13.9666 26.4513 13.646 26.4513 13.2577C26.4513 12.8693 26.7311 12.5549 27.1353 12.5549C27.5395 12.5549 27.8256 12.8755 27.8256 13.2638C27.8256 13.6522 27.5426 13.9666 27.1415 13.9666ZM27.1353 14.2871C27.7572 14.2871 28.1987 13.8186 28.1987 13.2577C28.1987 12.6967 27.7634 12.2344 27.1415 12.2344C26.5197 12.2344 26.0781 12.7029 26.0781 13.2638C26.0781 13.8248 26.5134 14.2871 27.1353 14.2871Z"
            fill="#1F1F1F"
        />
        <path
            d="M29.6816 14.2871C30.0609 14.2871 30.291 14.1515 30.5024 13.9357L30.2754 13.7076C30.1013 13.8679 29.9427 13.9666 29.694 13.9666C29.3084 13.9666 29.0255 13.6491 29.0255 13.2577C29.0255 12.8662 29.3053 12.5549 29.694 12.5549C29.9241 12.5549 30.0982 12.6535 30.2599 12.8015L30.4869 12.5426C30.2941 12.3607 30.0671 12.2344 29.694 12.2344C29.0814 12.2344 28.6523 12.6967 28.6523 13.2638C28.6523 13.834 29.0939 14.2871 29.6816 14.2871Z"
            fill="#1F1F1F"
        />
        <path
            d="M31.4404 14.244V13.6676L31.7545 13.3502L32.4416 14.244H32.8738L31.997 13.1098L32.8365 12.2529H32.4012L31.4404 13.2546V12.2529H31.0859V14.244H31.4404Z"
            fill="#1F1F1F"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.8199 12.7306H14.4686C14.4686 12.746 14.4655 12.7614 14.4655 12.7799C14.4655 13.1498 14.708 13.2761 15.134 13.3778C15.4978 13.4611 15.5848 13.5381 15.588 13.6768H15.9424C15.9424 13.6676 15.9424 13.6614 15.9424 13.6522C15.9424 13.3193 15.7217 13.1683 15.2957 13.0635C14.9101 12.971 14.8199 12.9032 14.8199 12.746C14.8199 12.7429 14.8199 12.7368 14.8199 12.7306ZM15.2055 11.8152C15.9735 11.8152 16.5985 12.4378 16.5985 13.2022C16.5985 13.9665 15.9766 14.5891 15.2055 14.5891C14.4344 14.5891 13.8125 13.9696 13.8125 13.2022C13.8125 12.4347 14.4344 11.8152 15.2055 11.8152Z"
            fill="#FE1940"
        />
        <g opacity="0.6">
            <rect
                opacity="0.2"
                x="13.8125"
                y="18.637"
                width="53.3694"
                height="12.6401"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="32.6816"
                width="36.7166"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="87.8569"
                width="53.3694"
                height="1.40446"
                rx="0.702229"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="27.8555"
                y="90.2646"
                width="25.2803"
                height="1.40446"
                rx="0.702229"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="42.7134"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="47.1274"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="51.5415"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="55.9553"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="60.3694"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="64.7834"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="69.1975"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="73.6116"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="42.7134"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="47.1274"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="51.5415"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="55.9553"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="60.3694"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="64.7834"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="69.1975"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="73.6116"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="13.8125"
                y="78.0254"
                width="14.0446"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
            <rect
                opacity="0.2"
                x="29.8633"
                y="78.0254"
                width="31.0987"
                height="3.21019"
                rx="1.00318"
                fill="#808080"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1443_72772"
                x="0.372612"
                y="0.780255"
                width="80.2548"
                height="106.538"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="2.40764" />
                <feGaussianBlur stdDeviation="4.31369" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.39 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1443_72772"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1443_72772"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>
