<template>
    <header>
        <h2 v-if="header" data-dialog-focus="true" class="text-2xl at1024:text-3xl font-medium mb-1" v-html="header"></h2>
        <div v-if="record" class="flex items-center gap-4 text-sm type-secondary">
            <span class="pl-0 border-0 border-keyline-primary" v-if="record.date">
                {{ record.record_type === 'O' ? $t("Date") : $t('Updated') }}
                <span class="font-medium">{{DateTime.fromISO(record.date).toFormat("D")}}</span>
            </span>
            <span class="border-l border-keyline-primary pl-6" v-if="record.entered_by && record.record_type === 'Q'">
                {{ $t("Salesperson") }}
                <span class="font-medium">{{ record.entered_by }}</span>
            </span>
            <span class="border-l border-keyline-primary pl-6" v-if="record.purchase_order">
                {{ $t('PO') }}
                <span class="font-medium">{{ record.purchase_order }}</span>
            </span>
            <span class="border-l border-keyline-primary pl-6" v-if="record.job_rel">
                {{ $t('Job Ref #') }}
                <span class="font-medium">{{ record.job_rel }}</span>
            </span>
            <span class="border-l border-keyline-primary pl-6" v-if="record.ordered_by && record.record_type === 'O'">
                {{ $t("By") }}
                <span class="font-medium">{{ record.ordered_by }}</span>
            </span>
            <span v-if="record.record_type !== 'Q'" class="flex items-center gap-1 px-3 border-keyline-primary">
                <span class="font-medium text-secondary flex items-center">
                    <span class="text-[.8rem] uppercase py-0.5 px-3 rounded-full inline-block" :class="backgroundColorSourcing">{{ record.status }}</span>
                </span>
            </span>
        </div>
    </header>
</template>

<script setup>
import { DateTime } from "luxon";
import { computed } from "vue";

const props = defineProps(["header", "record"]);

const statusClasses = computed(() => {
    if (props.record.status === 'Open') {
        return 'border-l pl-4 type-secondary';
    } else if (props.record.status === 'Pending' || props.record.status === 'Processing') {
        return 'text-blue bg-blue-600/10 rounded-full';
    } else {
        return 'text-green bg-green-600/10 rounded-full';
    }
});

const dotClasses = computed(() => {
    if (props.record.status === 'Open') {
        return 'bg-keyline-secondary';
    } else if (props.record.status === 'Pending' || props.record.status === 'Processing') {
        return 'bg-blue';
    } else {
        return 'bg-green';
    }
});

let backgroundColorSourcing = computed(() => {
    switch(props.record.status.toLowerCase()) {
        case "complete":
            return "text-green bg-green/10";
        case "paid":
            return "text-green bg-green/10";
        case "approved":
            return "text-green bg-green/10";
        case "pending":
            return "text-blue bg-blue/10";
        case "in progress":
            return "text-project-avocado bg-project-avocado/10";
        case "due":
            return "text-orange bg-orange/10";
        case "on hold":
            return "text-orange bg-orange/10";
        case "active":
            return "text-green bg-green/10";
        default:
            return "bg-gray-600/10 dark:bg-gray-600/20";
    }
});
</script>
