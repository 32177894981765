<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1792"
        height="1008"
        viewBox="0 0 1792 1008"
    >
        <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M1792 0v1008H0V0h1792ZM547.663 72H228.887a569.04 569.04 0 0 0-1.679 43.79c0 338.974 218.464 453.703 605.978 547.573 330.068 76.367 407.314 145.278 410.794 272.238h319.65c.25-7.241.37-14.547.37-21.918 0-305.077-200.26-443.274-585.172-537.144-348.502-83.44-431.726-146.02-431.726-289.432 0-5.082.188-10.12.561-15.107Z"
        />
    </svg>
</template>
