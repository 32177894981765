<template>
    <span
        class="flex items-center justify-center font-medium text-center text-white"
        :class="[
            colorClasses[color],
            {
                'w-6 h-6 rounded text-2xs': size === 'small',
                'w-23 h-23 rounded-2xl text-4xl': size === 'large',
            },
        ]"
        v-text="initials"
        aria-hidden="true"
    />
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    project: {
        type: Object,
        required: true,
    },
    size: {
        type: String,
        required: false,
        default: "small",
    },
});

const colorClasses = {
    gray: "bg-project-gray",
    blue: "bg-project-blue",
    lavender: "bg-project-lavender",
    orange: "bg-project-orange",
    avocado: "bg-project-avocado",
};

const color = computed(() => props.project.options.color || "gray");
const initials = computed(() => props.project.initials || "?");
</script>

<style scoped></style>
