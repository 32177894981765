<template>
    <transition appear name="slide-fade-bottom" v-bind="$attrs">
        <div
            v-if="visible"
            class="w-full left-0 right-0"
            :class="'z-' + zindex"
        >
            <slot></slot>
        </div>
    </transition>
</template>
<style>
.slide-fade-bottom-enter-active {
    transition: all 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-bottom-leave-active {
    transition: all 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-bottom-enter-from,
.slide-fade-bottom-leave-to {
    transform: translateY(100%);
    opacity: 0;
}
</style>
<script>
export default {
    name: "alert-transition-component",
    inheritAttrs: false,
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        zindex: {
            type: Number,
            default: 50,
            required: false,
        },
    },
};
</script>
