<template>
    <article
        :id="article.id"
        class="pb-[30px] mb-[30px] at1024:flex at1024:pb-[60px] at1024:mb-[60px] border-b border-gray-150 w-full dark:border-gray-850"
        itemtype="https://schema.org/Article"
    >
        <Link
            :href="route('web.education.show', article)"
            v-if="marketingAssetUrl(article.image)"
            class="break-words text-decoration-none bg-transparent text-inherit at1024:shrink at1024:grow at1024:basis-0"
            tabindex="-1"
        >
            <img
                :src="marketingAssetUrl(article.image)"
                :alt="article.image?.data?.alt_text || article.title"
                class="border border-gray-150 mb-[1em] mx-auto mt-0 h-auto w-auto at1024:grow at1024:shrink at1024:basis-0 hover:border-red"
                itemprop="image"
            />
        </Link>
        <div class="text-gray-800 at1024:ml-[60px] at1024:grow-[2] at1024:shrink at1024:basis-0">
            <div class="text-gray-400">
                <meta
                    itemprop="datePublished"
                    class="hidden"
                    :content="article.published_at"
                />
            </div>
            <p class="flex flex-wrap items-baseline gap-6">
                <span class="sr-only">Article categories:</span>
                <Link
                    v-for="category in article.categories"
                    :href="route('web.education.category.show', typeof category === 'string' ? kebabCase(category) : category.slug)"
                    class="text-gray font-medium break-words text-decoration-none bg-transparent hover:underline hover:text-black dark:text-gray-350"
                    v-text="typeof category === 'string' ? category : category.name"
                    tabindex="-1"
                />
            </p>
            <h3
                class="mb-[5px] text-[28px] at1024:text-[48px] at1024:leading-4.5 font-bold block m-0"
                itemprop="headline"
            >
                <Link
                    :href="route('web.education.show', article)"
                    class="hover:text-red dark:text-white"
                    v-text="article.title"
                />
            </h3>
            <div class="text-gray dark:text-white" itemprop="description">
                <p class="mb-[10px]">{{ article.headline }}</p>
                <Link
                    :href="route('web.education.show', article)"
                    class=" border-b border-gray capitalize hover:text-black dark:text-red dark:border-red dark:hover:text-white dark:hover:border-white"
                    tabindex="-1"
                >Read more<span class="sr-only"> about {{ article.title }}</span></Link>
            </div>
        </div>
    </article>
</template>

<script setup>
import { inject } from "vue";
import { useAssetTransforms } from "@/Composables";
import { kebabCase } from "lodash";

const { marketingAssetUrl } = useAssetTransforms();

const route = inject("route");
const props = defineProps(["article"]);
</script>
