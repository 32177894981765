<template>
    <div class="relative">
        <button
            @click.prevent="openDialog"
            class="duration-75 hover:border-blue hover:ring-1 ring-blue bg-white text-left w-full flex items-center justify-between border px-4 py-3 rounded-sm border-gray-300"
            :class="{
                '!border-blue ring-1 ring-blue': isOpen,
                'flash-yellow': shouldFlash,
             }"
            :disabled="computed_disabled"
        >
            <span>
                <BaseSvg v-if="icon" :name="icon" class="type-secondary" />
                <span
                    v-if="!icon"
                    :class="computedModel || defaultValue? 'text-xs type-tertiary absolute top-1': 'text-base'"
                >
                    {{ label }}
                </span>
                <span
                    v-else
                    :class="{
                        'text-xs type-tertiary absolute top-1': computedModel || defaultValue,
                        'text-base': !computedModel && !defaultValue,
                        'text-gray-300': disabled
                    }"
                >
                    {{ label }}
                </span>
                <span class="relative top-2 type-primary">
                    {{
                        computedModel
                            ? options[computedModel]
                            : defaultValue
                                ? options[defaultValue]
                                : ""
                    }}
                </span>
            </span>
            <BaseSvg :name="isOpen ? 'icon-expand-less' : 'icon-expand-more'" :class="{'text-gray-300': disabled}"/>
        </button>
        <teleport to="#dialogs">
            <Transition name="fade-fast" class="z-50 relative">
                <div data-dialog="true" v-if="isOpen">
                    <div :class="'z-'+config.dialogs.modals.zindex" class="flex items-start py-32 overflow-y-auto justify-center fixed inset-0 z-50 bg-overlay-gradient">
                        <div v-click-outside="close" class="relative w-full h-fit max-w-[700px] bg-white p-16 shadow-app-standard">
                            <div class="absolute top-4 right-4">
                                <DialogCloseButton @click="close" />
                            </div>
                            <div class="text-center flex flex-col gap-6">
                                <h6 class="text-3xl font-bold">{{ $t(label) }}</h6>
                                <div class="flex items-center gap-1 flex-col at1024:flex-row justify-center">
                                    <p class="type-tertiary">{{ $t("We offer all available options. Need something you don't see?") }}</p>
                                    <button @click.stop.prevent="openChat" class="underline underline-offset-4 type-primary">{{ $t('Live chat') }}</button>
                                </div>
                                <SearchInput id="searchSpecs" data-dialog-focus="true" v-model="search" />
                                <ul v-if="Object.entries(filteredOptions)?.length !== 0" class="overflow-y-auto h-full w-full flex flex-col gap-4">
                                    <li v-for="(option, key) in filteredOptions" :key="key">
                                        <button
                                            @click="selected(key)"
                                            class="relative duration-75 at600:active:scale-95 w-full p-4 bg-gray-125 at600:hover:bg-gray-150 at600:active:bg-gray-225"
                                        >
                                            {{ option?.value ?? option }}
                                            <span v-if="computedModel === key" class="absolute left-4">
                                                <BaseSvg name="icon-check" class="scale-150 text-green" />
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                                <RecordsNotFound v-else />
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </teleport>
    </div>
</template>

<script setup>
import {BaseSvg, DialogCloseButton, RecordsNotFound, SearchInput} from "@/Components";
import {computed, onMounted, onUnmounted, ref} from "vue";
import _ from "lodash";
import config from "@/config";
import {useChat} from "@/Composables";

const props = defineProps({
    options: {
        type: Object,
        required: true,
        default: {},
    },
    label: {
        type: String,
        required: true,
        default: null,
    },
    defaultValue: {
        type: Object,
        required: false,
        default: null,
    },
    modelValue: {
        type: Object,
        required: false,
        default: {},
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    card: {
        type: Object,
        required: false,
        default: {},
    },
});
const emits = defineEmits(["update:modelValue"]);

let isOpen = ref(false);
let search = ref("");
let previous_key = ref(0);

const openDialog = () => {
    isOpen.value = !isOpen.value;
    document.getElementById('searchSpecs')?.focus();
}

let filteredOptions = computed(() => {
    if (search.value) {
        return _.pickBy(props.options, (value) =>
            value.toLowerCase().includes(search.value.toLowerCase())
        );
    }
    return props.options;
});

const computed_disabled = computed(() => {
    return props.disabled ?? props.options.length;
});

const shouldFlash = ref(false);

let selected = (key) => {
    emits("update:modelValue", key);
    emits("select", key, previous_key.value);
    // added this setTimeout so the user can see the check mark move to the selected value. Remove this if you think it doesn't feel right.
    setTimeout(() => {
        isOpen.value = false;
        search.value = null;
        previous_key.value = key;

        shouldFlash.value = true;
        setTimeout(() => {
            shouldFlash.value = false;
        }, 2000);
    }, 100)
};

onMounted(() => {
    emits("update:modelValue", props.defaultValue);
    const escapeKeyListener = (event) => {
        if(event.key === 'Escape') {
            isOpen.value = false;
        }
    };
    document.addEventListener('keyup', escapeKeyListener);

    onUnmounted(() => document.removeEventListener('keyup', escapeKeyListener));
});

const close = () => (isOpen.value = false);


const { showNewMessage } = useChat();
const openChat = () => {
    let message = "I have a question about " + props.label + " options" + (props.card ? " for "+props.card.item_id : "") +" I need: "
    showNewMessage(message)
};

const computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emits("update:modelValue", newValue);
    },
});
</script>

<style>
@keyframes flashYellow {
    0% { background-color: rgba(255, 255, 0, 0.1); }
    50% { background-color: rgba(255, 255, 0, 0.1); }
    100% { background-color: transparent; }
}

.flash-yellow {
    animation: flashYellow 2s;
}
</style>
