<template>
    <button
        v-if="!settings.is_sourcing_mode"
        :class="{
            'w-9 h-9': large,
            'w-8 h-8': !large,
            'btn-app-icon-black-on-gray': settings.is_sourcing_mode,
            'group flex items-center justify-center rounded-full hover:bg-black/20 active:bg-black/30' : dark,
            'p-1 group flex items-center justify-center rounded-full hover:bg-black/5 active:bg-black/10':!settings.is_sourcing_mode,
        }"
        aria-label="Close product options panel"
        data-dialog-focus="true"
        @click="close_dialog"
    >
        <BaseSvg
            name="icon-close"
            aria-hidden="true"
            :class="{
                'w-7 h-7': large,
                'w-8 h-8': !large,
                'fill-gray group-hover:fill-black': !settings.is_sourcing_mode,
                '!fill-white/50 group-hover:!fill-white': dark,
            }"
        />
    </button>
    <AppButton
        v-else
        icon="close"
        :icon-only="true"
        @click="close_dialog"
    />
</template>

<script setup>
import {BaseSvg, AppButton} from '@/Components';
import {useDialog, useUser} from "@/Composables";

const { settings } = useUser();
const { forceClose } = useDialog();
const props = defineProps(["large", "dark"]);
const emit = defineEmits(["closed"]);
const close_dialog = () => {
    forceClose();
    emit("closed");
};
</script>
