<template>
    <div
        :disabled="disabled"
        @click="disabled ? null : searchInput?.focus()"
        :class="disabled ? '' : 'cursor-text'"
        class="dark:bg-gray-795 bg-gray-tier-5 dark:focus-within:bg-transparent focus-within:bg-transparent focus-within:ring-1 focus-within:ring-blue transition-colors border border-keyline-primary focus-within:border-blue dark:focus-within:border-blue duration-150 flex items-center gap-1 px-2 rounded w-full"
    >
        <BaseSvg name="icon-magnifying-glass" class="type-primary h-5 shrink-0"/>
        <input
            :disabled="disabled"
            ref="searchInput"
            autocomplete="off"
            v-model="computedModel"
            type="text"
            class="truncate appearance-none bg-transparent border-none focus:ring-0 px-0 focus-within:type-primary h-full w-full text-sm"
            :class="{
                'py-2': large,
                'py-1': !large,
                '!py-1.5': medium,
            }"
            :id="id"
            :placeholder="placeholder"
            @keypress.enter="$emit('submit')"
        />
        <div v-if="searchIsLoading" class="flex items-center">
            <LoadingSpinner />
        </div>
        <span v-else style="width: 28px;">&nbsp;</span>

        <div class="w-5">
            <button
                :disabled="disabled"
                v-if="computedModel?.length"
                class="flex items-center"
                aria-label="clear hardware search"
                @click="clear"
            >
                <BaseSvg
                    name="icon-close"
                    class="type-tertiary hover:type-primary h-5 w-5"
                />
            </button>
        </div>
    </div>
</template>

<script setup>
import { BaseSvg, LoadingSpinner } from "@/Components";
import { computed, ref } from "vue";

const searchInput = ref();
const emit = defineEmits(["update:modelValue", "submit", "clear"]);

const props = defineProps({
    placeholder: {
        type: String,
        required: false,
        default: "Search",
    },
    modelValue: {
        type: String,
        required: false,
        default: "",
    },
    searchIsLoading: {
        type: Boolean,
        required: false,
        default: false,
    },
    id: {
        type: String,
        required: false,
        default: "search",
    },
    large: {
        type: Boolean,
        required: true,
        default: false,
    },
    medium: {
        type: Boolean,
        required: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});

function clear() {
    searchInput.value.value = "";
    emit('clear');
    emit("update:modelValue", "");
    emit("submit", "");
}
</script>
