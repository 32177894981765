<template>
    <app-modal
        :title="data?.title ?? 'Delete Project'"
        :submitText="`Delete ${data?.project?.label}`"
        :valid="form.label === data.project.label"
        :notification="notification"
        @submit="onSubmit"
        @close="() => forceClose()"
    >
        <template #description>
            <div class="py-4 px-6 type-secondary text-xs typography">
                Once a project is deleted, it is <strong>unable to be recovered</strong>. By proceeding, you confirm that you would like to <strong>delete this project permanently</strong>.
            </div>
        </template>
        <template #content>
            <div class="type-secondary px-6 pb-4">
                <app-input
                    label="Type project name"
                    :placeholder="data.project.label"
                    v-model="form.label"
                    :error="form.errors?.label"
                    :required="true"
                />
            </div>
        </template>
    </app-modal>
</template>

<script setup>
import {inject, onMounted} from "vue";
import AppModal from "../AppModal.vue";
import { AppInput } from "@/Components";
import { eventBus, useDialog, useDrawerNotification } from "@/Composables";
import { useForm } from "@inertiajs/vue3";

const { forceClose } = useDialog();
const { notifyFormErrors, notification } = useDrawerNotification();
const route = inject("route");

const { data } = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

onMounted(() => {
    eventBus.on("form:reset", () => form.reset());
});

const form = useForm({
    label: "",
});

function onSubmit(resolve, reject) {
    form.delete(route("web.account.projects.destroy", [data.project]), {
        onSuccess: resolve,
        onError: (response) => {
            notifyFormErrors(form, "Error deleting project");
            reject(response);
        },
        onFinish: () => eventBus.emit("dashboard:reload-card", 'project_card')
    });
}
</script>
