<template>
    <!-- Drawer -->
    <aside
        data-dialog="true"
        aria-label="Product search panel"
        class="bg-white px-4 w-screen relative h-[calc(100vh_-_var(--header-height))] at1024:shadow-xl at1024:w-[880px] ph overflow-y-auto duration-200"
    >
        <!-- Content -->
        <nav class="w-auto at600:w-[600px] mx-auto duration-200">
            <ul class="text-gray duration-200">
                <TransitionGroup name="list">
<!--                    <li-->
<!--                        key="a"-->
<!--                        v-if="user"-->
<!--                        data-dialog-focus="true"-->
<!--                        class="mt-4 bg-white mx-auto py-2.5 px-8"-->
<!--                    >-->
<!--                        <Link-->
<!--                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"-->
<!--                            :href="route('web.daily-check-in')"-->
<!--                        >-->
<!--                            {{ $t("Daily Check-In") }}-->
<!--                        </Link>-->
<!--                    </li>-->
                    <li
                        key="b"
                        data-dialog-focus="true"
                        :class="{ 'mt-4': !user }"
                        class="bg-white mx-auto py-2.5 px-8"
                    >
                        <button
                            class="w-full flex items-center justify-between font-bold text-2xl max-w-[600px] my-0 mx-auto"
                            @click="
                                isOpen === 'catalog'
                                    ? (isOpen = false)
                                    : (isOpen = 'catalog')
                            "
                            :class="isOpen === 'catalog' ? 'text-black' : null"
                        >
                            {{ $t("Catalog") }}
                            <BaseSvg
                                name="icon-expand-more-thick"
                                aria-hidden="true"
                                :class="
                                    isOpen === 'catalog' ? 'rotate-180' : null
                                "
                            />
                        </button>

                        <ul
                            v-if="isOpen === 'catalog'"
                            class="mt-4 relative z-0 overflow-hidden"
                        >
                            <li
                                v-for="(
                                    catalogOption, i
                                ) in config.catalogOptions"
                                class="text-xl font-medium my-2"
                            >
                                <Link
                                    :href="route(catalogOption.route)"
                                    :key="i"
                                    >{{ catalogOption.title }}</Link
                                >
                            </li>
                        </ul>
                    </li>
                    <li
                        key="c"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <button
                            class="w-full flex items-center justify-between font-bold text-2xl max-w-[600px] my-0 mx-auto"
                            :class="isOpen === 'support' ? 'text-black' : null"
                            @click="
                                isOpen === 'support'
                                    ? (isOpen = false)
                                    : (isOpen = 'support')
                            "
                        >
                            {{ $t("Support") }}
                            <BaseSvg
                                name="icon-expand-more-thick"
                                aria-hidden="true"
                                :class="
                                    isOpen === 'support' ? 'rotate-180' : null
                                "
                            />
                        </button>

                        <ul
                            v-if="isOpen === 'support'"
                            class="mt-4 relative z-0 overflow-hidden"
                        >
                            <li class="text-xl font-medium my-2">
                                <a href="https://intercom.help/seclock/en/">{{ $t("Get Help") }}</a>
                            </li>
                            <li class="text-xl font-medium my-2">
                                <Link :href="route('web.locations')">{{
                                    $t("Locations")
                                }}</Link>
                            </li>
                            <li class="text-xl font-medium my-2">
                                <Link :href="route('web.contact')">{{
                                    $t("Contact")
                                }}</Link>
                            </li>
                        </ul>
                    </li>
                    <li
                        key="d"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <Link
                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :href="route('web.about')"
                        >
                            {{ $t("About") }}
                        </Link>
                    </li>
                    <li
                        key="e"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <Link
                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :href="route('web.education.index')"
                        >
                            {{ $t("Blog") }}
                        </Link>
                    </li>
                    <li
                        key="f"
                        v-if="user"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <button
                            class="w-full flex items-center justify-between font-bold text-2xl max-w-[600px] my-0 mx-auto"
                            @click="
                                isOpen === 'account'
                                    ? (isOpen = false)
                                    : (isOpen = 'account')
                            "
                            :class="isOpen === 'account' ? 'text-black' : null"
                        >
                            {{ $t("Account") }}
                            <BaseSvg
                                name="icon-expand-more-thick"
                                aria-hidden="true"
                                :class="
                                    isOpen === 'account' ? 'rotate-180' : null
                                "
                            />
                        </button>

                        <ul
                            v-if="isOpen === 'account'"
                            class="mt-4 relative z-0 overflow-hidden"
                        >
                            <li
                                v-for="(
                                    accountOption, i
                                ) in config.accountOptions"
                                class="text-xl font-medium my-2"
                            >
                                <Link
                                    :href="route(accountOption.route)"
                                    :key="i"
                                    >{{ accountOption.title }}</Link
                                >
                            </li>
                        </ul>
                    </li>
                    <!-- <li
                        key="g"
                        v-if="user"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <Link
                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :href="route('web.compare')"
                        >
                            {{ $t("Compare") }}
                        </Link>
                    </li> -->
                    <li
                        key="h2"
                        v-if="user && !$page.props.isMobile && !$page.props.isTablet"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <button
                            @click="eventBus.emit('user:toggle-sourcing-mode')"
                            class="w-full flex justify-between items-center font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :disabled="inert"
                        >
                            <span class="flex items-center gap-3">
                                <span>{{ $t("Source Mode") }}</span>
                                <span class="text-2xs px-2 py-0.5 rounded-lg bg-red text-white uppercase ">{{ $t('NEW') }}</span>
                            </span>
                            <BaseSvg name="icon-arrow-right-alt" />
                        </button>
                    </li>
                    <li
                        key="h"
                        v-if="user"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <Link
                            method="post"
                            as="button"
                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :href="route('logout')"
                        >
                            {{ $t("Logout") }}
                        </Link>
                    </li>
                    <li
                        key="i"
                        v-if="!user"
                        data-dialog-focus="true"
                        class="bg-white py-2.5 mx-auto px-8"
                    >
                        <Link
                            class="font-bold text-2xl py-0 max-w-[600px] my-0 mx-auto"
                            :href="route('login')"
                        >
                            {{ $t("Login") }}
                        </Link>
                    </li>
                    <li
                        key="j"
                        v-if="!user"
                        data-dialog-focus="true"
                        class="mt-12 mx-auto w-full px-8"
                    >
                        <Link
                            class="flex justify-center items-center btn-white-on-black w-full"
                            :href="route('register')"
                        >
                            {{ $t("Register") }}
                        </Link>
                    </li>
                    <li
                        key="k"
                        class="flex flex-col gap-8 justify-center mt-4 mx-auto w-full px-8"
                    >
                        <div
                            class="bg-gray-125 text-gray px-4 py-8 text-center"
                        >
                            <div class="font-medium">
                                {{ $t("Need help") }}? <span class="sr-only">{{ $t("Call us:") }}</span>
                            </div>
                            <a
                                href="tel:18008475625"
                                class="block"
                                ><span class="font-bold text-xl"
                                    >800-847-5625</span
                                ></a
                            >
                            <div class="text-sm">
                                {{ $t("Monday through Friday") }}<br />8:00am –
                                5:30pm EST
                            </div>
                        </div>
                    </li>
                    <li
                        key="l"
                        class="px-8 py-0.5 my-8 text-base text-gray-400 font-medium"
                    >
                        <ul class="gap-2 grid grid-cols-2">
                            <li
                                v-for="(
                                    miscellaneousLink, i
                                ) in config.miscellaneousLinks"
                            >
                                <Link
                                    :href="miscellaneousLink.route"
                                    :key="i"
                                    class="text-lg"
                                    >{{ miscellaneousLink.name }}</Link
                                >
                            </li>
                        </ul>
                    </li>
                    <li
                        key="m"
                        class="px-8 py-0.5 text-base text-gray-400 font-medium"
                    >
                        <ul class="flex gap-2 justify-start text-gray mb-4">
                            <li v-for="(socialLink, i) in config.socialLinks">
                                <a
                                    :href="socialLink.link"
                                    :key="i"
                                    class="inline-flex items-center hover:underline hover:text-gray-800"
                                >
                                    <base-svg
                                        :name="socialLink.svg"
                                        class="mr-3 w-4"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li key="n" class="px-8 py-0.5 text-sm text-gray-400 mb-32">
                        <div>©️ {{ new Date().getFullYear() }} {{ $t("SECLOCK") }}. All rights reserved.</div>
                        <div class="flex">
                            <Link
                                :href="route('web.privacy-policy')"
                                class="hover:text-gray hover:underline underline-offset-4"
                            >
                                {{ $t("Privacy Policy") }}
                            </Link>
                            <div class="mx-3">•</div>
                            <a
                                href="http://status.seclock.com/"
                                target="_blank"
                                class="hover:text-gray hover:underline underline-offset-4"
                            >
                                {{ $t("Status Page") }}
                            </a>
                        </div>
                    </li>
                </TransitionGroup>
            </ul>
        </nav>
    </aside>
</template>

<script setup>
import config from "../../../config";

import { BaseSvg } from "@/Components";
import { inject, ref } from "vue";
import {eventBus, useUser} from "@/Composables";

const route = inject("route");
const { user } = useUser();
const isOpen = ref(false);
</script>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.2s ease-out;
}

.list-enter-from,
.list-leave-to {
    transform: translateY(100%);
}

.list-leave-active {
    position: absolute;
    overflow: hidden;
}
</style>
