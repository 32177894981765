import config from "@/config";
import useDialog from "./useDialog";
import { LeftDrawerTransition, MobileNavigationDialog } from "@/Components";

export default function useMobileNavigationDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleMobileNavigationDialog = (event) => {
        toggleDialog(
            MobileNavigationDialog,
            LeftDrawerTransition,
            "mobile-nav",
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    return {
        forceClose,
        toggleMobileNavigationDialog,
    };
}
