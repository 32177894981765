<template>
    <!-- WRAP -->
    <div id="sourcing-layout" class="sourcing-layout inline-flex w-full">

        <!-- HEADER -->
        <header class="w-full z-30 print:hidden">
            <div class="print:hidden flex justify-between pr-2 w-1/3 at1200:w-1/4">
                <a
                    href="#main"
                    class="bg-yellow font-bold fixed -left-full px-4 py-1.5 text-black -top-full z-10 focus:left-0 focus:top-0"
                    ref="skip"
                >
                    {{ $t("Skip to main content") }}
                </a>

                <!-- LOGO -->
                <Link
                    :href="route('web.home')"
                    class="flex items-center w-[120px]"
                >
                    <BaseSvg name="seclock-logo-horiz-white" class="w-full" />
                </Link>
            </div>

            <!-- SEC-3000 -->
            <div v-if="usePage().props.impersonating" class="text-red flex items-center mr-8">
                <button
                    class="btn-app-sm-white-on-red p-1 w-6 h-6"
                    @click="useStopImpersonation().stop_impersonation"
                >
                    <BaseSvg
                        name="icon-accounts-outline-thick"
                        aria-hidden="true"
                    />
                </button>
            </div>

            <!-- SEARCH -->
            <div class="print:hidden my-1 relative w-1/3 at1200:w-1/2 ">
                <button
                    @click="toggleSearchDialog()"
                    :class="{
                        'justify-start': searchTerm !== '',
                        'justify-center': searchTerm === '',
                    }"
                    class="!cursor-text focus-visible:ring-0 z-10 flex items-center absolute bg-gray-700 hover:bg-gray-625 text-gray-350 left-0 rounded w-full h-full gap-1 text-sm"
                >
                    <BaseSvg
                        name="icon-search-thick"
                        class="fill-gray-225 scale-75"
                        :class="{ 'ml-2': searchTerm !== '' }"
                        aria-hidden="true"
                    />
                    {{
                        searchTerm === ""
                            ? $t("Search by keyword, product or manufacturer")
                            : searchTerm
                    }}
                </button>
            </div>

            <!-- UTILITY NAV -->
            <div
                class="print:hidden flex justify-end relative space-x-4 w-1/3 at1200:w-1/4"
                aria-live="polite"
            >
                <!-- SETTINGS -->
                <button
                    class="group flex items-center shrink-0"
                    data-dialog="true"
                    aria-controls="settings-panel"
                    aria-expanded="false"
                    @click="toggleMenu('settings', $event, false)"
                >
                    <span class="sr-only">Toggle settings</span>
                    <BaseSvg
                        :name="
                            menu === 'settings'
                                ? 'icon-settings-filled-thick'
                                : 'icon-settings-thick'
                        "
                        :class="{
                            '!fill-white shrink-0':
                                menu === 'settings',
                        }"
                        class="group-hover:fill-white fill-gray-225 w-5 h-5"
                        aria-hidden="true"
                    />
                </button>
                <Transition name="search-pop">
                    <SourcingSettingsDropdown v-if="menu === 'settings'" />
                </Transition>

                <!-- PINS -->
                <button
                    class="group flex items-center shrink-0"
                    data-dialog="true"
                    aria-controls="pins-drawer"
                    aria-expanded="false"
                    @click="togglePinsDialog($event)"
                >
                    <span class="sr-only">Toggle pins</span>
                    <BaseSvg
                        :name="
                            menu === 'pins'
                                ? 'icon-push-pin-filled-thick'
                                : 'icon-push-pin-outlined-thick'
                        "
                        :class="{
                            '!fill-white shrink-0':
                                menu === 'pins',
                        }"
                        class="group-hover:fill-white fill-gray-225 w-5 h-5"
                        aria-hidden="true"
                    />
                </button>

                <!-- CART -->
                <Link
                    :href="route('web.cart.show')"
                    class="group flex items-center shrink-0 relative"
                >
                    <span class="sr-only">Cart</span>
                    <BaseSvg
                        :name="
                            route().current('web.cart')
                                ? 'icon-shopping-cart-filled-thick'
                                : 'icon-shopping-cart-thick'
                        "
                        class="group-hover:fill-white fill-gray-225 w-5 h-5"
                        aria-hidden="true"
                    />
                    <span
                        v-if="cartItemCount"
                        class="block bg-red w-[5px] h-[5px] rounded-full absolute right-[calc(11px-3px)] top-2"
                    ></span>
                </Link>

                <!-- ACCOUNT -->
                <button
                    data-dialog="true"
                    aria-controls="account-panel"
                    aria-expanded="false"
                    @click="toggleMenu('account', $event, false)"
                    class="relative flex items-center max-w-[170px] at1200:max-w-[160px] group border-l border-gray-700 pl-4"
                >
                    <span
                        v-if="notificationCount"
                        class="bg-red flex-none size-2 rounded-full mr-1.5"
                    >
                        <span class="sr-only">{{ $t("copy.unread-notifications") }}</span>
                    </span>
                    <span
                        :class="{
                            '!fill-white !text-white': menu === 'account',
                        }"
                        class="capitalize font-medium mr-1 text-ellipsis text-sm truncate text-gray-225 group-hover:text-white"
                    >
                        {{ user?.name }}
                    </span>
                    <BaseSvg
                        :name="
                            menu === 'account'
                                ? 'icon-expand-less-thick'
                                : 'icon-expand-more-thick'
                        "
                        class="text-gray-225 w-5 group-hover:text-white"
                        :class="{
                            'text-white': menu === 'account',
                        }"
                        aria-hidden="true"
                    />
                </button>
                <Transition name="search-pop">
                    <SourcingAccountDropdown v-if="menu === 'account'" />
                </Transition>

            </div>
        </header>

        <!-- MAIN NAVIGATION -->
        <nav
            class="main-nav print:hidden"
            :class="{
                'overflow-x-hidden overflow-y-auto overscroll-contain': navIsExpanded,
            }"
        >
            <ul>

                <!-- DASH -->
                <li
                    class="group relative border-b border-gray-225 hover:bg-gray-tier-5 dark:border-gray-850"
                >
                    <Link
                        :href="route('web.account.dashboard')"
                        class="main-nav__link flex items-center h-12 pl-5"
                        :class="{
                            'bg-gray-tier-5 border-l-[3px] border-blue-600 pl-[calc(1.25rem-3px)]':
                                route().current('web.account.dashboard'),
                            'active:!bg-gray-tier-2':
                                !route().current('web.account.dashboard'),
                        }"
                    >
                        <base-svg
                            :name="
                                route().current('web.account.dashboard')
                                    ? 'icon-dash-filled-thick'
                                    : 'icon-dash-outline-thick'
                            "
                            aria-hidden="true"
                            class="shrink-0"
                            :class="{
                                'w-5': navIsExpanded,
                                'w-6': !navIsExpanded,
                                'text-gray-825 dark:text-white':
                                    route().current('web.account.dashboard'),
                                'text-gray-600 dark:text-gray-350 group-hover:type-primary':
                                    !route().current('web.account.dashboard'),
                            }"
                        ></base-svg>
                        <span
                            class="main-nav__link-name font-medium text-sm"
                        >Dashboard</span>
                    </Link>
                    <div
                        v-if="!navIsExpanded"
                        class="absolute bg-white border border-gray-225 hidden flex-col left-[3.25em] p-2 rounded shadow-xl top-0 w-56 z-50 group-hover:flex dark:bg-gray-700 dark:border-gray-850"
                    >
                        <Link
                            :href="route('web.account.dashboard')"
                            class="flex font-medium h-[31px] items-center px-2 text-gray-825 dark:text-white active:!bg-gray-tier-4 rounded"
                        >
                            Dashboard
                        </Link>
                    </div>
                </li>

                <!-- LOOP -->
                <template v-for="link in config.sourcingLinks">

                    <!-- Nav Links -->
                    <li
                        v-if="!link.role || hasRole(link.role) || isEmployee()"
                        class="group relative border-b border-gray-225 hover:bg-gray-tier-5 dark:border-gray-850"
                    >
                        <!-- Link Wrapper -->
                        <div
                            class="flex items-stretch justify-between"
                            :class="{
                                'bg-gray-tier-5':
                                    route().current(link.route_check),
                            }"
                        >
                            <Link
                                :href="route(link.route)"
                                :target="link.target"
                                class="main-nav__link flex grow items-center h-12 pl-5 relative"
                                :class="{
                                    'bg-gray-tier-5 border-l-[3px] border-blue-600 pl-[calc(1.25rem-3px)]':
                                        route().current(link.route_check),
                                    'active:!bg-gray-tier-2':
                                        !route().current(link.route_check),
                                }"
                            >
                                <BaseSvg
                                    :name="
                                        route().current(
                                            link.route_check
                                                ? link.route_check
                                                : link.route
                                        )
                                            ? link.icon_active
                                            : link.icon
                                    "
                                    class="shrink-0"
                                    :class="{
                                        'w-5': navIsExpanded,
                                        'w-6': !navIsExpanded,
                                        'text-gray-825 dark:text-white':
                                            route().current(link.route_check),
                                        'text-gray-600 dark:text-gray-350 group-hover:type-primary':
                                            !route().current(link.route_check),
                                    }"
                                    aria-hidden="true"
                                />
                                <span
                                    class="main-nav__link-name text-sm font-medium text-gray-825 dark:text-white"
                                >{{link.title}}</span>
                            </Link>

                            <!-- Expanded Toggle -->
                            <button
                                v-if="navIsExpanded && link.sublinks?.length"
                                @click.stop.prevent="
                                    toggleExpandedSubMenu(link.name)
                                "
                                class="main-nav__subnav-trigger px-1 m-2 rounded hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-2"
                                :class="{
                                    'bg-gray-130 dark:bg-gray-700':
                                        route().current(link.route_check),
                                    'active:!bg-gray-tier-2': !route().current(
                                        link.route_check
                                    ),
                                }"
                            >
                                <span class="sr-only"
                                    >Toggle sub-navigation</span
                                >
                                <BaseSvg
                                    :name="
                                        !expandedSubMenus.includes(link.name)
                                            ? 'icon-expand-more'
                                            : 'icon-expand-less'
                                    "
                                    aria-hidden="true"
                                />
                            </button>

                        </div><!-- /Link Wrapper -->

                        <!-- Sub Nav -->
                        <div
                            class="flex-col p-2"
                            :class="{
                                'bg-gray-tier-5':
                                    route().current(link.route_check),
                                'pt-0':
                                    navIsExpanded,
                                'absolute border border-gray-225 dark:border-gray-850 bg-white dark:bg-gray-700 left-[3.25em] shadow-xl rounded top-[-1px] w-56 z-50 group-hover:flex':
                                    !navIsExpanded,
                                hidden: !expandedSubMenus.includes(link.name),
                            }"
                        >
                            <!-- Sub Nav Link -->
                            <div v-if="!navIsExpanded">
                                <Link
                                    v-if="link.route"
                                    :href="route(link.route)"
                                    :target="link.target"
                                    class="flex font-medium h-8 items-center px-2 text-gray-825 dark:text-white active:!bg-gray-tier-4 rounded"
                                >
                                    {{ link.title }}
                                </Link>
                            </div>

                            <ul
                                v-if="link.sublinks?.length"
                                class="text-sm"
                                :class="{
                                    'mt-1.5': !navIsExpanded,
                                }"
                            >
                                <!-- Sub Nav Links -->
                                <template v-for="sublink in link.sublinks">
                                    <li
                                        v-if="
                                            !sublink.role ||
                                            hasRole(sublink.role)
                                        "
                                    >
                                        <Link
                                            v-if="!sublink.target"
                                            :href="route(sublink.route)"
                                            class="block leading-tight hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-4"
                                            :class="{
                                                'ml-9 px-3 py-2': navIsExpanded,
                                                'p-2': !navIsExpanded,
                                                'bg-gray-225 dark:bg-gray-650': route().current(sublink.route)
                                            }"
                                        >
                                            {{ sublink.title }}
                                        </Link>
                                        <!-- external link -->
                                        <a
                                            v-else
                                            :href="sublink.route"
                                            target="_blank"
                                            class="flex items-center block leading-tight hover:bg-gray-225 dark:hover:bg-gray-650 active:!bg-gray-tier-4"
                                            :class="{
                                                'ml-9 px-3 py-2': navIsExpanded,
                                                'p-2': !navIsExpanded,
                                            }"
                                        >
                                            {{ sublink.title }}
                                            <BaseSvg
                                                name="OpenInNew"
                                                aria-hidden="true"
                                                class="h-[18px] ml-2"
                                            />
                                        </a>

                                    </li>
                                </template>
                            </ul>

                        </div><!-- /Sub Nav -->
                    </li><!-- /Nav Link -->
                </template><!-- /Loop -->
            </ul>

            <!-- Expansion Toggle -->
            <div
                class="p-3"
                :class="{
                    'text-right': navIsExpanded,
                    'text-center': !navIsExpanded
                }"
            >
                <input
                    type="checkbox"
                    id="expand-nav"
                    v-model="navIsExpanded"
                    class="sr-only"
                    @click="toggleSideNavigation"
                />
                <label for="expand-nav" class="bg-gray-tier-5 inline-flex items-center justify-center p-2 rounded text-gray-475 hover:text-default">
                    <span class="sr-only">Toggle navigation size</span>
                    <BaseSvg
                        name="StartThick"
                        aria-hidden="true"
                        class="h-5 w-5"
                        :class="{
                            'rotate-180': navIsExpanded,
                        }"
                    />
                </label>
            </div>
        </nav>

        <!-- CONTENT -->
        <main id="main" class="w-full print:!m-0">
            <slot></slot>
        </main>

        <!-- MANUAL INTERCOM -->
        <IntercomButton />

    </div>
</template>

<script setup>
import config from "@/config.js";
import { Link, usePage } from "@inertiajs/vue3";
import {ref, inject, onMounted, nextTick} from "vue";
import {
    useBasicSearchApi,
    useDialog,
    useNavigation,
    usePinsDialog,
    useSearchDialog,
    useUser,
    useCart,
    useStopImpersonation,
} from "@/Composables";
import {
    BaseSvg,
    SourcingAccountDropdown,
    SourcingSearchDialog,
    SourcingSettingsDropdown,
    IntercomButton,
} from "@/Components";

const route = inject("route");
const {
    user,
    settings,
    toggleSideNavigationPanel,
    hasRole,
    isEmployee,
    notificationCount,
    loadNotifications,
} = useUser();
const { toggleMenu } = useNavigation();
const { data: menu } = useDialog();
const { togglePinsDialog, forceClose } = usePinsDialog();
const { toggleSearchDialog } = useSearchDialog();
const { searchTerm } =useBasicSearchApi(true);
const { cartItemCount } = useCart();

// ============================================================================
// BEGIN: Handle the navigation width toggle.
// ============================================================================
const navWidthCollapsed = 64;
const navWidthExpanded  = 220;
const navIsExpanded     = ref(settings.value.is_side_navigation_panel_open ?? false);
const expandedSubMenus  = ref([]);

function initializeSideNavigationPanel() {
    //Side navigation state
    settings.value.is_side_navigation_panel_open ? openSideNavigation() : closeSideNavigation();
}

const toggleSideNavigation = () => {
    //composable call. This call will persist the panel state
    toggleSideNavigationPanel();
    expandedSubMenus.value = [];
    settings.value.is_side_navigation_panel_open ? openSideNavigation() : closeSideNavigation();
};

function openSideNavigation() {
    document.documentElement.style.setProperty("--app-main-nav-width", navWidthExpanded + "px");
    if (!document.documentElement.classList.contains("nav-is-expanded")) {
        document.documentElement.classList.add("nav-is-expanded");
    }
    navIsExpanded.value = true;
    showSubMenu();
}

function closeSideNavigation() {
    document.documentElement.style.setProperty("--app-main-nav-width", navWidthCollapsed + "px");
    if (document.documentElement.classList.contains("nav-is-expanded")) {
        document.documentElement.classList.remove("nav-is-expanded");
    }
    navIsExpanded.value = false;
}

const toggleExpandedSubMenu = (link) => {
    if (expandedSubMenus.value.includes(link)) {
        expandedSubMenus.value = expandedSubMenus.value.filter(
            (expandedSubMenu) => expandedSubMenu !== link
        );
    } else {
        expandedSubMenus.value.push(link);
    }
};

function showSubMenu() {
    //Sub menu state
    config.sourcingLinks.forEach((link) => {
        link?.sublinks?.forEach((sublink) => {
            if(route().current(sublink.route)) {
                toggleExpandedSubMenu(sublink.name)
            }
        })
    })
}
// ============================================================================
// END: Handle the navigation width toggle.
// ============================================================================

// const afterEnter = () =>
//     document.querySelector("[data-dialog-focus='true']").focus();
// const handleNavPanelPosition = () => {
//     const mainNavLinks = [...document.querySelectorAll(".main-nav__link")];
//     const viewportHeight = window.innerHeight;
//     // This is the number of pixels we'll allow between the bottom of a
//     // .main-nav__link element and the viewport's bottom edge before we
//     // flip that element's panel upward:
//     const distanceThreshold = 300;
//     // Process all except the first one because it is positioned in a
//     // unique way, on account of the 3d borders.
//     mainNavLinks.slice(1).forEach((mainNavLink) => {
//         const mainNavLinkBottomEdge =
//             mainNavLink.getBoundingClientRect().bottom;
//         const mainNavLinkPanel = mainNavLink.nextSibling;
//
//         const actualDistance = viewportHeight - mainNavLinkBottomEdge;
//         if (actualDistance >= distanceThreshold) {
//             mainNavLinkPanel.classList.add("-top-px");
//         } else {
//             mainNavLinkPanel.classList.add("-bottom-px");
//         }
//     });
// };

onMounted(() => {
    document.addEventListener("keydown", (event) => {
        const formTags = ["INPUT", "TEXTAREA", "SELECT"];
        if (
            event.key === "s" &&
            !formTags.includes(document.activeElement.tagName)
        ) {
            toggleSearchDialog();
        }
    });

    document.getElementById("dialogs").classList.remove("marketing-layout");
    document.getElementById("dialogs").classList.add("sourcing-layout");
    document.documentElement.style.setProperty("--header-height", "93px");

    document.body.classList.add(
        "bg-white",
        "text-gray-800",
        "dark:bg-gray-785",
        "dark:text-white",
        "sourcing-layout",
        "overflow-y-scroll",
    );

    if (settings?.value.is_dark_mode) {
        document.documentElement.classList.add("dark");
    }

    document.body.classList.remove("marketing-layout");

    //handleNavPanelPosition();

    //Initialize side navigation panel state
    initializeSideNavigationPanel()

    loadNotifications()
});

//
const afterEnter = () => {
    nextTick().then(() => {
        document
            .querySelectorAll("[data-inert='true']")
            .forEach((element) => {
                element.setAttribute("inert", "");
            });
        document
            .querySelector("[data-dialog-focus='true'], [autofocus]")
            ?.focus();
    });
}
</script>

<style>
:root {
    --app-main-nav-width: 64px;
    /* Define CSS variable */
}

html {
    --app-main-nav-width: 64px;
    /* Use the CSS variable */
}

.panel-shadow {
    --tw-shadow: 0px 28px 74px rgba(0, 0, 0, 0.5);
    --tw-shadow-colored: 0px 28px 74px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.panel-standard {
    @apply bg-white dark:bg-gray-700 border border-gray-225 dark:border-gray-850 shadow-black/20 shadow-xl
}

.search-pop-enter-active,
.search-pop-leave-active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.search-pop-enter-from,
.search-pop-leave-to {
    transform: scale(0.97);
    opacity: 0;
}
</style>
