<template>
    <div v-if="!settings.is_sourcing_mode">
        <breadcrumbs :crumbs="crumbs" class="print:hidden" />
        <section class="wrap flex flex-col gap-6 at1024:flex-row at1024:gap-12 at1024:py-9 at1350:gap-16">
            <nav
                v-if="links"
                class="w-full shrink-0 overflow-x-auto pb-1 at1024:w-64"
                aria-label="Account navigation"
            >
                <ul class="text-sm at1024:text-base flex flex-row at1024:flex-col mb-4">
                    <template v-for="link in links">
                        <li v-if="!link.roles || hasAnyRole(link.roles)">
                            <Link
                                :href="route(link.route)"
                                @click="activeLink = link.route"
                                class="whitespace-nowrap truncate at1024:rounded-sm flex items-center justify-between w-full px-3.5 at1024:px-3 py-2 at1024:py-2.5 font-medium at1024:hover:bg-gray-125 at1024:active:bg-gray-150 text-gray-400 at1024:text-gray hover:border-gray-400 at1024:border-none at1024:hover:text-black hover:border-b"
                                :class="{
                                    'border-b border-black at1024:bg-gray-150 !text-black at1024:!text-red at1024:active:!bg-gray-150 at1024:hover:!bg-gray-150':
                                        route().current().includes(link.baseRoute),
                                    'bg-gray-125 hover:!text-gray animate-pulse !border-b border-gray at1024:border-b-0': activeLink === link.route,
                                }"
                            >
                                {{ $t(link.text) }}
                            </Link>
                        </li>
                    </template>
                </ul>
            </nav>
            <div class="w-full py-1 grow max-w-full at1024:py-0 at1024:w-[calc(100%_-_304px)] at1350:w-[calc(100%_-_320px)]">
<!--                <div v-if="links.find(link => activeLink?.includes(link.baseRoute))">-->
<!--                    <header class="mb-4">-->
<!--                        <h1 class="font-bold text-2xl at1024:text-3xl">-->
<!--                            {{ $t(links.find(link => activeLink?.includes(link.baseRoute))?.text) }}-->
<!--                        </h1>-->
<!--                    </header>-->
<!--                </div>-->
                <slot v-if="!activeLink"></slot>
                <div v-else class="px-2 pb-96">
                    <LoadingSpinner :large="true" />
                </div>
            </div>
        </section>
    </div>

    <div v-else>
        <slot></slot>
    </div>
</template>

<script setup>
import config from "@/config";
import {computed, inject, onMounted, ref, watch} from "vue";
import { Breadcrumbs } from "@/Components";
import { useUser } from "@/Composables";
import {router, usePage} from "@inertiajs/vue3";
import LoadingSpinner from "@/Components/Modules/LoadingSpinner.vue";

const { settings, user, hasRole, hasAnyRole } = useUser();
const route = inject("route");

const activeLink = ref(null);


router.on('start', (event) => {
    if (event.detail.visit.url !== route().current()) {
        activeLink.value = null;
    }
});

router.on('finish', (event) => {
    activeLink.value = null;
});

const links = computed(() => {
    return config.account.links;
});

const crumbs = computed(() => {
    return usePage().props.crumbs;
});

</script>
