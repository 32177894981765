<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1234"
        height="494"
        viewBox="0 0 1234 494"
    >
        <path
            fill="#000"
            fill-rule="nonzero"
            d="M.004 494.003C607.93 402.211 1019.262 237.543 1234 0v494.003H.004Z"
        />
    </svg>
</template>
