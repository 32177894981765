<template>
    <div
        :disabled="disabled ?? false"
        class="seclock-input"
        :class="{
            'left-label': props.leftLabel,
            'input-animated': props.animated,
            'input-contrast': props.contrast,
            'flex items-center': ['radio', 'checkbox'].includes(props.type),
            'flex flex-col': !['radio', 'checkbox'].includes(props.type),
        }"
    >
        <template v-if="type === 'file'">
            <ul v-if="modelValue.length">
                <li v-for="(file, key) in modelValue" class="flex items-center">
                    <button @click="removeFile(file)" class="opacity-50 hover:opacity-75 active:opacity-100">
                        <BaseSvg name="icon-close" aria-hidden="true" />
                    </button>
                    <span class="px-1 overflow-x-auto">{{ file.name }}</span>
                </li>
            </ul>

            <label
                :class="{
                    'pointer-events-none opacity-50': root?.uploading || (!multiple && modelValue.length >= 1),
                    'bg-gray-tier-2 py-2 rounded border border-keyline-primary hover:border-gray-tier-7': settings.is_sourcing_mode,
                    'uppercase btn-black-outline': !settings.is_sourcing_mode,
                }"
                :for="id"
                class="!flex !justify-center w-full my-2 cursor-pointer"
                v-if="modelValue.length <= 5"
            >
<!--                moved the * because it looked odd in the middle of the icon and the  with-->
<!--                <span class="ml-2 text-sm" v-if="computedRequired"> *</span>-->
                <BaseSvg name="icon-upload-cloud" aria-hidden="true" class="w-6 h-6"/>
                <span class="px-2 overflow-x-auto" v-if="!root?.uploading">Choose a file</span>
                <span class="px-2 overflow-x-auto" v-if="root?.uploading">Uploading... {{ root?.uploadProgress }}%</span>
            </label>
        </template>
        <label
            v-if="!animated && !['radio', 'checkbox', 'file'].includes(type)"
            :for="id"
            :class="{
                'sr-only': hideLabel,
                'opacity-50 cursor-not-allowed': disabled ?? false,
            }"
            v-html="label + (computedRequired ? '<span aria-hidden=true> *</span>' : '')"
        >
        </label>
        <Input
            v-model="computedModel"
            v-bind="$attrs"
            :type="type"
            :autocomplete="autocomplete"
            :hidden="type === 'file'"
            :pattern="pattern"
            :animated="animated"
            :placeholder="(animated ? (label + (computedRequired ? ' *' : '') + (footnoteCharacter ? ' ' + footnoteCharacter : '')) : $attrs.placeholder ? $attrs.placeholder : '')"
            :id="id"
            :indeterminate="indeterminate && ['radio', 'checkbox'].includes(type)"
            ref="root"
            :disabled="disabled ?? false"
            @invalid="$emit('invalid')"
            :aria-describedby="error ? id + '-descr' : (describedby ?? false)"
            :class="{
                'opacity-50 cursor-not-allowed': disabled ?? false,
            }"
        >
            <slot></slot>
        </Input>
        <label
            v-if="(animated || ['radio', 'checkbox'].includes(type)) && !['file'].includes(type)"
            :for="id"
            :class="{
                'sr-only': hideLabel,
                '!cursor-not-allowed': disabled ?? false,
            }"
            v-html="label + (computedRequired ? '<span aria-hidden=true> *</span>' : '')"
        >
        </label>
        <span
            :id="id + '-descr'"
            class="error-message"
            v-html="error"
        ></span>
    </div>
</template>
<script setup>
import {computed, ref, useAttrs} from "vue";
import { v1 as uuidv1 } from "uuid";
import { BaseSvg, Input } from "@/Components";
import {useUser} from "@/Composables";

//const id = props?.id ?? uuidv1();
const emit = defineEmits(["update:modelValue", "invalid"]);
const props = defineProps([
    "modelValue",
    "id",
    "label",
    "animated",
    "contrast",
    "type",
    "error",
    "leftLabel",
    "hideLabel", // hide label for screen readers
    "indeterminate", // for checkbox or radio: https://developer.mozilla.org/en-US/docs/Web/CSS/:indeterminate
    "disabled",
    "multiple",
    "pattern",
    "autocomplete",
    "footnoteCharacter",
    "describedby"
]);

const { settings } = useUser();

const attrs = useAttrs();

//Some components will require an ID we can reference.
// For the most part a uuid will suffice.
const id = computed(() => {
    return props?.id ?? uuidv1();
});

const root = ref();

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});

let computedRequired = computed(() => {
    return Object.keys(attrs).includes('required') && attrs['required'] !== false && attrs['required'] !== undefined;
})

function removeFile(file) {
    if (props.type === "file") {
        let value = props.modelValue;
        value.splice(file, 1);
        emit("update:modelValue", value);
    }
}

defineExpose({
    root,
});
</script>

<style>
.left-label {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    white-space: nowrap;
}
</style>
