import { computed, ref } from "vue";
import { v1 as uuidv1 } from "uuid";
import { eventBus } from "@/Composables";

const alerts = ref({});

export default function useAlerts() {
    const storeAlert = ({
        title = "Error",
        message = "An unknown error occurred.",
        level = "success",
        persist = false,
    } = {}) => {
        // console.log({
        //     "method":"useAlerts::storeAlert",
        //     "title":title,
        //     "message":message,
        //     "level":level,
        //     "persist":persist
        // });

        alerts.value[uuidv1()] = {
            title,
            message,
            level,
            persist,
            visible: true,
        };
    };

    const deleteAlert = (uuid) => {
        try {
            alerts.value[uuid].visible = false;
        } catch (error) {}
    };

    const destroyAlert = (uuid) => {
        try {
            delete alerts.value[uuid];
        } catch (error) {}
    };

    function registerEventListeners() {
        eventBus.on("alert:show", (event) => storeAlert(event));
        eventBus.on("alert:hide", (event) => deleteAlert(event));
    }

    return {
        registerEventListeners,
        storeAlert,
        deleteAlert,
        destroyAlert,
        alerts,
        alertCount: computed(() => {
            return Object.keys(alerts.value).length;
        }),
    };
}
