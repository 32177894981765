import config from "@/config";
import { BottomDrawerTransition, ProductInformationDialog, RightFloatingDrawerTransition } from "@/Components";
import {eventBus, useDialog, useUser} from "@/Composables";

const { toggleDialog, forceClose } = useDialog();

export default function useProductInformationDialog(){

    const toggleProductInformationDialog = (event, data, sourcing) => {
        toggleDialog(ProductInformationDialog, sourcing ? RightFloatingDrawerTransition : BottomDrawerTransition, data, event, config.dialogs.drawers.zindex, false);
    };

    function registerEventListeners() {
        eventBus.on('dialog:toggle-product-preview', (event) => {
            toggleProductInformationDialog(event.event, event.data, useUser().settings.value.is_sourcing_mode)
        });
    }

    return {
        toggleProductInformationDialog,
        forceClose,
        registerEventListeners,
    };
}
