<template>
    <app-modal
        :title="data?.title ?? 'Duplicate Project'"
        :submitText="`Duplicate ${data?.project?.label}`"
        :notification="notification"
        @submit="onSubmit"
        @close="() => forceClose()"
    >
        <template #content>
            <div class="py-4 px-6 type-secondary typography">
                <p>Duplicating this project will make a new project with a full copy of the hardware library, sets, and openings.</p>
            </div>
        </template>
    </app-modal>
</template>

<script setup>
import { inject } from "vue";
import AppModal from "../AppModal.vue";
import { useDialog, useDrawerNotification } from "@/Composables";
import { router } from '@inertiajs/vue3'

const { forceClose } = useDialog();
const { notification } = useDrawerNotification();
const route = inject("route");

const { data } = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

function onSubmit(resolve, reject) {
    router.post(route("web.account.projects.duplicate", data.project), null, {
        onSuccess: resolve,
        onError: (response) => {
            notify("Failed to duplicate project", "error");
            reject(response);
        },
    });
}
</script>
