<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="52"
        fill="none"
        viewBox="0 0 52 52"
    >
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.95"
            d="M26.046 50.21c13.461 0 24.374-10.913 24.374-24.374 0-13.461-10.913-24.374-24.374-24.374-13.461 0-24.374 10.913-24.374 24.374 0 13.462 10.912 24.374 24.374 24.374Z"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.95"
            d="M26.046 42.801c7 0 12.674-5.674 12.674-12.674 0-7-5.674-12.675-12.674-12.675-7 0-12.675 5.675-12.675 12.675 0 7 5.675 12.674 12.675 12.674Z"
        />
        <path fill="none" d="M21.65 22.529a8.779 8.779 0 1 1-3.057 2.964" />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.95"
            d="M21.65 22.529a8.779 8.779 0 1 1-3.057 2.964"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.95"
            d="M27.412 30.905a1.365 1.365 0 1 0-2.73 0v3.51a1.365 1.365 0 0 0 2.73 0v-3.51Z"
        />
    </svg>
</template>
