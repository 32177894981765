export default function useStopImpersonation() {

    function stop_impersonation() {
        if (confirm("Are you sure you want to stop impersonating?\n ") === true) {
           fetch("/impersonation").then(() => {
                location.reload();
            });
        }
    }

    return { stop_impersonation };
}
