<template>
    <component
        :is="rowComponent"
        v-for="(row,key) in rows"
        :key="_.get(row,rowIdentifier,key)"
        :row="row"
        v-bind="props"
        :selected="props.selected[key] ?? false"
        @toggle-row="(event)=>emit('toggle-row',key)"
    />
</template>

<script setup>
import _ from "lodash";

const emit = defineEmits([
    "toggle-row"
]);

const props = defineProps({
    "name": {
        type: String,
        required: true,
    },
    "columns": {
        type: Array,
        required: true,
    },
    "rows": {
        type: Object,
        required: true,
    },
    "rowClasses": {
        type: [String, Function],
        required: false,
        default: '',
    },
    "rowIdentifier": {
        type: [String,Function],
        required: true,
        default: 'id',
    },
    "subRowPath": {
        type: [String, Function],
        required: false,
        default: false,
    },
    "collapsable": {
        type: Boolean,
        required: false,
        default: false,
    },
    "depth": {
        type: Number,
        required: false,
        default: 0,
    },
    "selectable": {
        type: [Boolean, Function],
        required: false,
        default: false,
    },
    "selected": {
        type: Object,
        required: false,
        default: {},
    },
    "headerComponent": {
        type: Object,
        required: true,
    },
    "footerComponent": {
        type: Object,
        required: true,
    },
    "rowComponent": {
        type: Object,
        required: true,
    },
});

</script>
