import config from "@/config";
import useDialog from "./useDialog";
import { ModalTransition, TrayCreateEditDialog } from "@/Components";

const { toggleDialog, forceClose } = useDialog();

export default function useTrayCreateEditDialog() {
    const toggleTrayCreateEditDialog = (event, data) => {
        toggleDialog(
            TrayCreateEditDialog,
            ModalTransition,
            data,
            event,
            config.dialogs.drawers.pinsDialog.zindex,
            true
        );
    };

    return {
        toggleTrayCreateEditDialog,
        forceClose,
    };
}
