import config from "@/config";
import useDialog from "./useDialog";
import {ModalTransition, AddProductDialog} from "@/Components";

export default function useAddProductDialog(){

    const { forceClose, toggleDialog } = useDialog();

    const toggleAddProductDialog = (event, data) => {
        toggleDialog(AddProductDialog, ModalTransition, data, event, config.dialogs.drawers.productSearchDialog.zindex, true);
    };

    return {
        forceClose,
        toggleAddProductDialog,
    };

};
