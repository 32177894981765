<template>
    <div v-if="!['checkbox', 'radio'].includes(type)" class="app-input" :class="disabled ? 'opacity-50' : ''">
        <label
            :for="id"
            v-if="label"
            class="inline-flex items-baseline hover:cursor-default text-sm type-secondary leading-tight py-2"
        >
            <span class="text-sm leading-tight type-secondary">{{ label }}</span>
            <span v-if="!required && !hideOptional" class="text-xs type-tertiary">&nbsp;&nbsp;•&nbsp;&nbsp;Optional</span>
        </label>
        <div class="relative" v-if="type !== 'date'">
            <BaseSvg
                v-if="icon"
                :name="`icon-${icon}`"
                aria-hidden="true"
                class="absolute bottom-3 left-2 w-4 h-4 type-tertiary"
            />
            <Input
                :disabled="disabled"
                :id="id"
                :type="type === 'number' ? 'text' : type"
                v-bind="inputAttributes"
                v-model="computedModel"
                :autocomplete="autocomplete"
                :aria-describedby="describedby"
                class="duration-150 block w-full text-sm rounded dark:bg-gray-795 dark:focus:bg-transparent bg-gray-130 focus:bg-transparent border leading-snug focus-within:border-blue focus-within:ring-1 ring-blue focus-within:outline-0"
                :inputmode="type === 'number' ? 'numeric' : null"
                :class="[
                    {
                        'h-32 pt-2 pb-9 app-scrollbar': type === 'textarea',
                        'pl-6': icon,
                        'border-keyline-primary': !error,
                        'border-scarlet/70 dark:border-scarlet/30': error,
                        'p-2': size === 'large',
                        'px-2 py-1': size === 'small',
                    },
                ]"
                @invalid="updateValidationError"
                :readonly="readonly"
            >
                <slot></slot>
            </Input>
            <div
                v-if="$attrs.maxlength"
                class="flex items-baseline text-xs absolute bottom-2 right-4 type-tertiary"
            >
                {{ textareaValueLength }} / {{ $attrs.maxlength }}
            </div>
        </div>
        <div v-else class="date-select relative z-[5]">
            <Datepicker
                v-model="computedModel"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                :clearable="!required"
                format="MM/dd/yyyy"
                ref="datepickerComponent"
                @closed="close"
                :auto-apply="true"
                :esc-close="true"
                :on-click-outside="close"
                :arrow-navigation="true"
                :close-on-scroll="true"
                :close-on-auto-apply="true"
                :max-date="disableFutureDates ? DateTime.now() : null"
            />
        </div>
        <ValidationError :text="computedErrorMessage" class="mt-1" />
    </div>
</template>

<script setup>
import { computed, ref, useAttrs, watch } from "vue";
import { v1 as uuidv1 } from "uuid";
import _ from "lodash";

import { BaseSvg, Input, ValidationError } from "@/Components";
import { DateTime } from "luxon";
import Datepicker from "@vuepic/vue-datepicker";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
    invalid: {
        type: Boolean,
        required: false,
    },
    label: {
        type: String,
        required: false,
    },
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
    type: {
        type: String,
        required: false,
        default: "text",
    },
    icon: {
        type: String,
        required: false,
    },
    hideOptional: {
        type: Boolean,
        required: false,
        default: false,
    },
    autofocus: {
        type: Boolean,
        required: false,
        default: false,
    },
    error: {
        type: String,
        required: false,
        default: null,
    },
    readonly: {
        type: Boolean,
        required: false,
        default: false,
    },
    id: {
        type: String,
        required: false,
        default: uuidv1(),
    },
    size: {
        type: String,
        required: false,
        default: 'large',
    },
    disableFutureDates: {
        type: Boolean,
        required: false,
    },
    minLength: {
        type: Number,
        required: false,
    },
    autocomplete: {
        type: String,
        required: false,
    },
    describedby: {
        type: String,
        required: false,
    }
});

const inputAttributes = computed(() => {
    const attributes = { ...useAttrs(), ...props };

    return _.pick(attributes, [
        "minLength",
        "maxlength",
        "required",
        "size",
        "rows",
        "cols",
        "autofocus",
        "placeholder",
    ]);
});

const datepickerComponent = ref(null);

let computedModel = computed({
    // getter
    get() {
        if (props.type === 'date' && props.modelValue) {
            return DateTime.fromFormat(props.modelValue, 'yyyy-MM-dd')
        }

        return props.modelValue;
    },
    // setter
    set(newValue) {
        if(props.type === 'date' && newValue) {
            newValue = DateTime.fromJSDate(newValue).toFormat('yyyy-MM-dd');
        }
        emits("update:modelValue", newValue);
    },
});

const textareaValueLength = computed(() => {
    return props.modelValue?.length ?? 0;
});

const textLengthInvalid = computed(() => {
    if(computedModel.value.length < props.minLength) {
        return 'Field must be more than 4 characters';
    }
})

const validationMessage = ref(null);
const computedErrorMessage = computed(() => {
    return props.error || validationMessage.value;
});

function updateValidationError($event) {
    // Grab the default HTML5 validation message from the input element
    validationMessage.value = $event.target.validationMessage;
}

watch(computedModel, () => {
    // Clear validation message if value changes
    validationMessage.value = null;
});
</script>

<style>
.date-select .dp__instance_calendar {
    @apply bg-white dark:bg-gray-700 border border-gray-225 dark:border-gray-850 shadow-black/20 shadow-xl rounded-md p-4;
}

.date-select .dp__input_readonly {
    @apply type-primary bg-gray-tier-2 border-keyline-primary active:border-keyline-primary focus:border-keyline-primary focus:ring-0 focus:outline-0;
}

.date-select .dp__input_focus {
    @apply ring-1 ring-blue border-blue hover:border-blue;
}

.date-select .dp__input_readonly .dp__input_focus {
    @apply ring-1 ring-blue border-blue hover:border-blue;
}

:is(.dark .date-select .dp__input_focus) {
    @apply border-blue;
}

.date-select .dp__input_wrap {
    @apply ring-0 outline-0;
}

.date-select .dp__main {
    @apply focus-visible:ring-0 focus-visible:outline-none
}

.date-select .dp__theme_light {
    @apply focus-visible:ring-0 focus-visible:outline-none
}

.date-select .dp__arrow_top {
    @apply hidden;
}

.date-select .dp__cell_inner {
    @apply type-secondary hover:type-primary hover:bg-gray-tier-6;
}

.date-select .dp__active_date, .date-select .dp__overlay_cell_active {
    @apply bg-black text-white dark:text-black dark:bg-white hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black hover:cursor-default;
}

.date-select .dp__month_year_select, .date-select .dp__month_year_select, .date-select .dp__inner_nav {
    @apply type-secondary hover:type-primary hover:bg-gray-tier-6;

    font-weight: 500;
}

.date-select .dp__overlay {
    @apply rounded-md;
}

.date-select .dp__overlay_cell {
    @apply type-secondary hover:type-primary hover:bg-gray-tier-6;

    font-weight: 500;
}

.date-select .dp__calendar_header_item {
    @apply type-secondary;

    font-weight: 500;
}

.date-select .dp__overlay_cell_disabled, .date-select .dp__cell_disabled {
    @apply opacity-50 hover:bg-gray-tier-5 hover:type-secondary;
}

.date-select .dp__button {
    @apply bg-gray-tier-5 hover:bg-gray-tier-4;
}

.date-select .dp__overlay_container::-webkit-scrollbar {
    @apply hidden;
}
</style>
