<template>
    <header
        class="bg-gray-tier-2 px-6 flex items-center justify-between w-full border-b border-keyline-primary print:hidden z-10 sticky top-[var(--app-header-height)]"
    >
        <h3 class="py-3 font-medium">{{ $t(title) }}</h3>
        <div v-if="search">
            <SearchInput v-model="computedModel" />
            <slot name="buttons" />
        </div>
    </header>
</template>

<script setup>
import { SearchInput } from "@/Components";
import { computed } from "vue";

const props = defineProps(["title", "search", "modelValue"]);
const emit = defineEmits(["update:modelValue"]);

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});
</script>
