<template>
    <AppModal
        :title="$t('Move pin')"
        :submitText="$t('Move')"
        :scroll="false"
        @submit="onSubmit"
        @close="() => forceClose()"
        :valid="selected_tray && selected_tray !== current_tray"
    >
        <template #content>
            <div class="text-base px-4 py-2 bg-gray-tier-3">
                <div v-if="!tray_options.length" class="py-8 flex items-center justify-center">
                    <LoadingCircle/>
                </div>
                <div v-else class="space-y-2">
                    <SeclockMultiselect
                        class="w-full"
                        v-model="selected_tray"
                        :options="tray_options"
                        :option-is-object="true"
                        :large="true"
                        :label="$t('Tray')"
                        :disabled="inert"
                        label-by="name"
                        value-by="name"
                        :can-clear="false"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="grow flex items-center gap-1.5 px-3">
                                <BaseSvg :name="value.icon"/>
                                {{ value.name }}
                            </div>
                        </template>
                        <template v-slot:option="{ option }">
                            <div class="grow flex items-center gap-1.5">
                                <BaseSvg :name="option.icon"/>
                                {{ option.name }}
                            </div>
                        </template>
                    </SeclockMultiselect>
                    <button
                        @click="create_tray"
                        class="inline-flex items-center gap-0.5 text-sm"
                        :disabled="inert"
                    >
                        <BaseSvg
                            name="icon-addition-sign"
                            class="shrink-0 size-5"
                        />
                        {{ $t("Create new tray") }}
                    </button>
                </div>
            </div>
        </template>
    </AppModal>
</template>

<script setup>
import { AppModal, BaseSvg, LoadingCircle, SeclockMultiselect } from "@/Components";
import { eventBus, useDialog, usePinsDrawer, useTrayCreateEditDialog, useTrayMoveDialog, } from "@/Composables";
import { computed, onMounted, onUnmounted, ref } from "vue";

const { movePin, fetchPin, fetchTrays } = usePinsDrawer();
const { forceClose } = useDialog();
const { toggleTrayCreateEditDialog } = useTrayCreateEditDialog();
const { toggleTrayMoveDialog } = useTrayMoveDialog();

//PROPS
const { data } = defineProps(["data"]);

//REFS
const pin = ref(null);
const current_tray = ref(null);
const selected_tray = ref(null);
const tray_options = ref([]);
const submit_resolve = ref(null)
const submit_reject = ref(null)

const current_tray_name = computed(() => current_tray.value?.name)
const selected_tray_name = computed(() => selected_tray.value?.name)

function create_tray(event) {
    //Open the Create/Edit dialog, but toggle back to the Move dialog
    toggleTrayCreateEditDialog(event, {
        callback: toggleTrayMoveDialog,
        callback_params: { ...data },
    });
}

function close(event) {
    forceClose(event);

    if (data?.callback) {
        data?.callback(event, data?.callback_params);
    }
}

function onPinFetched(fetched_pin) {
    pin.value = fetched_pin;
    current_tray.value = fetched_pin.trays[0]

    fetchTrays(data?.tray_created?.name ?? null);
}

function onTraysFetched(trays) {
    tray_options.value = trays;
    selected_tray.value = pin.value.trays[0];
}

function onPinMoved() {
    submit_resolve.value?.call()
}

function onPinMovedError(error) {
    submit_reject.value?.call(error)
}

function register_event_listeners() {
    eventBus.on("pins:pin-fetched", onPinFetched);
    eventBus.on("pins:trays-fetched", onTraysFetched);
    eventBus.on("pins:pin-moved", onPinMoved)
    eventBus.on("pins:pin-move-error", onPinMovedError)
}

function unregister_event_listeners() {
    eventBus.off("pins:pin-fetched", onPinFetched);
    eventBus.off("pins:trays-fetched", onTraysFetched);
    eventBus.off("pins:pin-moved", onPinMoved)
    eventBus.off("pins:pin-move-error", onPinMovedError)
}

onMounted(() => {
    register_event_listeners();
    fetchPin(data?.pin_type, data?.pinnable_id);
});

onUnmounted(() => {
    unregister_event_listeners();
});

function onSubmit(resolve, reject) {
    submit_resolve.value = resolve;
    submit_reject.value = reject;

    movePin(pin.value.id, current_tray_name, selected_tray_name)
}
</script>
