import config from "@/config";
import useDialog from "./useDialog";
import { AvailabilityDialog, ModalTransition } from "@/Components";

export default function useAvailabilityDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleAvailabilityDialog = (event, data) => {
        toggleDialog(
            AvailabilityDialog,
            ModalTransition,
            data,
            event,
            config.dialogs.drawers.zindex,
            true
        );
    };

    return {
        forceClose,
        toggleAvailabilityDialog,
    };
}
