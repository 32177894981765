<template>
    <template v-if="!url">
        <span v-if="text" class="font-medium">{{ text }}
            <template v-if="shipping_method">- {{ shipping_method }}</template>
        </span>
    </template>
    <a :href="url" target="_blank" v-else>
        <span
            v-if="text"
            @mouseover="hintSeen = true"
            @mouseleave="hintSeen = false"
            class="w-fit flex items-center relative font-medium"
            :class="
                !url
                    ? 'type-primary cursor-not-allowed'
                    : 'text-red hover:underline underline-offset-4'
            "
        >
            {{ text }}
            <HintTransition v-if="!url" name="fade-top" :hint-seen="hintSeen">{{
                $t("Tracking link unavailable")
            }}</HintTransition>
        </span>
        <template v-else>
            <span class="sr-only">{{ $t("Track") }}</span>
            <BaseSvg name="icon-send" class="h-6 shrink-0 w-6" />
        </template>
    </a>
</template>
<script setup>
import { BaseSvg, HintTransition } from "@/Components";
import { ref } from "vue";

const props = defineProps({
    url: {
        type: String,
        default: null,
    },
    text: {
        type: String,
        default: null,
    },
    shipping_method: {
        type: String,
        default: null,
    },
});

const hintSeen = ref(false);
</script>
