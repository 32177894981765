<template>
    <div
        class="block at768:flex print:flex justify-between items-center bg-gray-125 uppercase text-gray-800"
    >
        <div class="flex items-center gap-8 p-4 at768:w-2/3 print:w-1/3">
            <div>
                <div class="text-2xs text-light tracking-wider text-gray">
                    {{ $t("Sub Total") }}
                </div>
                <div class="text-xl font-medium">
                    {{ record.subtotal === '—'? record.subtotal :showMeTheMoney(record.subtotal) }}
                </div>
            </div>
            <div v-if="record.tax">
                <div class="text-2xs text-light tracking-wider text-gray">
                    {{ $t("Tax") }}
                </div>
                <div class="text-xl font-medium">
                    {{ showMeTheMoney(record.tax) }}
                </div>
            </div>
            <div v-if="!hideFreight">
                <div class="text-2xs text-light tracking-wider text-gray">
                    {{ $t("Freight") }}
                </div>
                <div class="text-xl font-medium">
                    {{ record.freight === '—'? record.freight :showMeTheMoney(record.freight) }}
                </div>
            </div>
        </div>
        <div class="at768:w-1/3 print:w-1/3 p-4 text-white bg-gray-800 text-right">
            <div class="text-2xs text-light tracking-wider opacity-50">
                {{ $t("Order Total") }}
            </div>
            <div class="text-xl font-bold">
                {{ record.total === '—'? record.total :showMeTheMoney(record.total) }}
                <span class="opacity-50">USD</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useHelpers} from "@/Composables";

const props = defineProps({
    record: {
        type: Object,
        required: true,
    },
    hideFreight: {
        type: Boolean,
        default: false,
    },
});
const { showMeTheMoney } = useHelpers();
</script>
