<template>
    <div data-dialog="true" id="printOrderDialog" class="flex flex-col relative w-[885px] print:w-full h-full bg-gray-tier-3">
        <header class="sticky top-0 bg-gray-tier-2 border-b border-keyline-primary">
            <div class="w-full flex items-center justify-between py-2 px-6 print:px-0">
                <h2>
                    {{ $t("Order #") }}
                    <span class="font-medium">{{ data }}</span>
                </h2>
                <span class="print:hidden flex items-center justify-end gap-2">
                    <AppButton
                        @click="router.post(route('web.account.sales.orders.download', order.id))"
                        text="Download Full Details"
                        icon="export-file"
                        :disabled="inert"
                        :loading="inert"
                    />
                    <DialogCloseButton/>
                </span>
            </div>
        </header>
        <div v-if="order" class="overflow-y-auto">
            <RecordHeader :record="order" class="px-6 print:px-0 py-3" />
            <Divider />
            <OrderRecord v-bind="props" class="p-6 print:px-0 mb-8" />
        </div>
        <div v-else-if="errors" class="grow p-8 print:px-0 flex items-center justify-center">
            <RecordsNotFound
                icon="icon-error-outline"
                header="Something went wrong"
                message="Please try again, and feel free to contact support if the problem persists."
            />
        </div>
        <div v-else class="absolute inset-0 flex items-center justify-center">
            <LoadingSpinner :large="true" />
        </div>
    </div>
</template>

<script setup>
import {
    DialogCloseButton,
    Divider,
    LoadingSpinner,
    OrderRecord,
    RecordHeader,
    AppButton,
    RecordsNotFound,
    AuditModal
} from "@/Components";
import {router} from "@inertiajs/vue3";
import {useModalDialog, useUser} from "@/Composables";

const { isEmployee } = useUser();
const { toggleModal } = useModalDialog();

const props = defineProps([
    "isLoading",
    "order",
    "data",
    "shipmentColumns",
    "lineColumns",
    "invoiceColumns",
    "lineRows",
    "shipmentRows",
    "invoiceRows",
    "errors",
]);
</script>
