<template>
    <transition
        appear
        :name="symmetrical ? 'slide-left-sym' : 'slide-left'"
        v-bind="$attrs"
    >
        <div
            v-if="visible"
            data-dialog="true"
            class="left-0 top-0 bottom-0 overflow-y-hidden"
            :class="[
                'z-' + zindex,
                height,
                positionStyles,
                {
                    'shadow-search': shadow,
                    '!top-[var(--header-height)]': !settings.is_sourcing_mode,
                },
            ]"
        >
            <slot></slot>
        </div>
    </transition>
</template>
<style scoped>
.slide-left-enter-active {
    transition: all 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-left-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-left-enter-from,
.slide-left-leave-to {
    transform: translateX(-100%);
}
</style>
<script setup>
import {useUser} from "@/Composables";

const {settings} = useUser();
</script>
<script>
export default {
    name: "left-drawer-transition-component",
    inheritAttrs: false,
    props: {
        component: {
            type: Object,
            required: false,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        zindex: {
            type: Number,
            required: true,
        },
        height: {
            type: String,
            required: false,
            default: "h-screen",
        },
        positionStyles: {
            type: String,
            required: false,
            default: "fixed",
        },
        shadow: {
            type: Boolean,
            required: false,
            default: true,
        },
        symmetrical: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
