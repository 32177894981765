<template>
    <component
        :is="display"
        :crumbs="crumbs"
        :title="title"
        :subtitle="subtitle"
        :h2style="h2style"
        :use-subheader="useSubheader"
    >
        <template #subheader>
            <slot name="subheader" />
        </template>
    </component>
</template>

<script setup>
import { computed, markRaw } from "vue";
import { useUser } from "@/Composables";
import MarketingBreadcrumbs from "../../Themes/Marketing/Components/Modules/Breadcrumbs.vue";
import SourcingBreadcrumbs from "../../Themes/Sourcing/Components/Modules/Breadcrumbs.vue";

const { settings } = useUser();

const display = computed(() =>
    settings.value.is_sourcing_mode
        ? markRaw(SourcingBreadcrumbs)
        : markRaw(MarketingBreadcrumbs)
);
defineProps(["crumbs", "title", "subtitle", "h2style", "useSubheader"]);
</script>
