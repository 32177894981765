import {nextTick, reactive} from "vue";
import * as googlemaps from "@googlemaps/js-api-loader";
import { eventBus } from "@/Composables";

//Private variables
const state = reactive({
    initialized: false,
});

//Public variables (exported)
const selected_place = reactive({
    street_number: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
});

export default function useGoogleMapsApi() {
    if (!import.meta.env.SSR) {
        if (!state.initialized) {
            const loader = new googlemaps.Loader({
                apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
                version: "weekly",
                libraries: ["places"],
            });

            loader.load().catch((e) => console.dir(e));
        }
        state.initialized = true;
    }

    const delay = 400;

    let maps_initialized = [];
    let autocomplete;
    let seclock_default_location = {
        lat: 42.204383,
        long: -71.150976,
    };
    let options = {
        streetViewControl: false,
        scrollwheel: false,
        libraries: "places",
        zoom: 15,
        mapId: "8c2f30f62f085ed3",
        draggable: true,
        zoomControl: true,
        mapTypeControl: true,
    };
    let address_format_config = {
        street_number: {
            address_field: "street_number",
            place_field: "short_name",
        },
        route: {
            address_field: "address",
            place_field: "long_name",
        },
        locality: {
            address_field: "city",
            place_field: "long_name",
        },
        // sublocality_level_1: {
        //     address_field: '',
        //     place_field: 'long_name'
        // },
        administrative_area_level_1: {
            address_field: "state",
            place_field: "short_name",
        },
        postal_code: {
            address_field: "zip",
            place_field: "short_name",
        },
        country: {
            address_field: "country",
            place_field: "short_name",
        },
    };

    //PRIVATE Functions
    function get_map(element_id, options) {
        if (!maps_initialized.includes(element_id)) {
            maps_initialized[element_id] = new google.maps.Map(
                document.getElementById(element_id),
                options
            );

            //Resize Function
            window.addEventListener("resize", function () {
                let map = maps_initialized[element_id];
                let center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });
        }
        return maps_initialized[element_id];
    }

    function build_selected_place() {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();

        if (place.geometry) {
            Object.values(place.address_components).forEach((component) => {
                let config = address_format_config[component.types[0]] ?? null;
                if (config) {
                    selected_place[config.address_field] = component[config.place_field];
                }
            });

            //concat street number and address field
            nextTick(() => {
                selected_place["address"] = `${selected_place["street_number"]} ${selected_place["address"]}`;
            });
        }
    }

    //PUBLIC Function
    const bind_autocomplete = (element_id) => {
        //We need a slight delay as the google loader is a tad bit slow
        setTimeout(function () {
            let element = document.getElementById(element_id);

            // Create the autocomplete object, restricting the search predictions to
            // addresses in the US and Canada.
            autocomplete = new google.maps.places.Autocomplete(element, {
                componentRestrictions: { country: ["us", "ca"] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener("place_changed", build_selected_place);
        }, delay);
    };

    const code_address = (element_id, address) => {
        //We need a slight delay as the google loader is a tad bit slow
        setTimeout(function () {
            let formatted_address =
                address?.address +
                ", " +
                address?.city +
                " " +
                address?.state +
                " " +
                address?.zip +
                " " +
                address?.country;

            var geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { address: formatted_address },
                function (results, status) {
                    if (status === "OK") {
                        let map_set = new google.maps.Map(
                            document.getElementById(element_id),
                            { ...options, zoom: 12 }
                        );
                        map_set.setCenter(results[0].geometry.location);
                        var marker = new google.maps.Marker({
                            map: map_set,
                            position: results[0].geometry.location,
                        });
                    } else {
                        eventBus.emit("alert:show", {
                            title: "Warning",
                            message: "Please check the address.",
                            level: "warning",
                        });
                        let map_set = new google.maps.Map(
                            document.getElementById(element_id),
                            { ...options, zoom: 1 }
                        );
                        map_set.setCenter(seclock_default_location);
                    }
                }
            );
        }, delay);
    };

    const code_markers = (element_id, markers, opts = this.options) => {
        //We need a slight delay as the google loader is a tad bit slow
        setTimeout(function () {
            let marker_options = { ...options, ...opts };
            let map = get_map(element_id, marker_options);
            let marker_collection = {};

            for (const [key, value] of Object.entries(markers)) {
                marker_collection[key] = {
                    marker: new google.maps.Marker({
                        position: new google.maps.LatLng(value.lat, value.lng),
                        map,
                        //Add title if present
                        ...(value?.title && { title: value?.title }),
                    }),
                    //Add Info Window if info provided
                    ...(value?.info && {
                        info: new google.maps.InfoWindow({
                            content:
                                '<div class="text-black">' +
                                (value?.info ?? "") +
                                "</div>",
                        }),
                    }),
                };

                //If we have info we need the event listener for the info window
                if (value?.info) {
                    marker_collection[key]["marker"].addListener(
                        "click",
                        () => {
                            marker_collection[key]["info"].open({
                                anchor: marker_collection[key]["marker"],
                                map,
                                shouldFocus: false,
                            });
                        }
                    );
                }
            }
        }, delay);
    };

    const reset_selected_place = () => {
        selected_place.street_number = "";
        selected_place.address = "";
        selected_place.address_2 = "";
        selected_place.city = "";
        selected_place.state = "";
        selected_place.zip = "";
        selected_place.country = "";
    };

    return {
        mount() {},
        selected_place,
        reset_selected_place,
        bind_autocomplete,
        code_address,
        code_markers,
    };
}
