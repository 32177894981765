<template>
    <div class="bg-gray-tier-3 relative w-[30vw] h-full overflow-y-auto">
        <loading-progress :is-loading="exporting">
        <transition :name="animation">
            <!-- SELECTED TRAY PANEL -->
            <div v-if="drawer.selected_tray != null">
                <section>
                    <header class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md">
                        <div class="flex items-center justify-start gap-2">

                            <!-- BACK BUTTON -->
                            <button @click="closeTray" class="btn-app-sm-white-on-gray px-0 w-8 h-8 flex items-center justify-center">
                                <BaseSvg name="icon-chevron-left" class="shrink-0" />
                            </button>

                            <!-- TITLE -->
                            <h2 class="flex items-center gap-2 text-base font-medium">
                                <BaseSvg :name="drawer.selected_tray?.icon ?? 'icon-pin-tray'" class="type-secondary shrink-0 w-6"/>
                                {{drawer.selected_tray?.name ?? $t('Tray')}}
                            </h2>
                        </div>
                        <div class="flex items-center gap-2">

                            <!-- TRAY ACTIONS MENU -->
                            <div class="relative">
                                <AppButton
                                    :icon-only="true"
                                    icon="more-horiz"
                                    @click="dropdown==='tray_options' ? dropdown=false : dropdown='tray_options'"
                                />
                                <TrayActionMenu
                                    :tray="drawer.selected_tray"
                                    :open="dropdown==='tray_options'"
                                    @close="closeDropdown"
                                    :hasEditDetails="!drawer.selected_tray?.is_default ?? true"
                                    :hasAddTo="true"
                                    :hasExport="true"
                                    :hasDelete="!drawer.selected_tray?.is_default ?? true"
                                />
                            </div>

                            <!-- CLOSE BUTTON -->
                            <AppButton @click="closeDrawer" icon="close" :icon-only="true" />
                        </div>
                    </header>
                    <Divider />

                    <!-- TRAY DESCRIPTION -->
                    <p class="p-4 type-secondary text-sm bg-gray-tier-4">
                        {{ drawer.selected_tray?.description ?? '' }}
                    </p>
                    <Divider />

                    <!-- TRAY BODY: SEARCH AND FILTER CONTROLS -->
                    <div class="relative flex items-center gap-2 w-full p-4">

                        <!-- SEARCH CONTROL -->
                        <SearchInput
                            :disabled="!searchHasResults && !drawer.state.searching"
                            v-model="searchTerm"
                            :placeholder="$t('Search tray')"
                            :search-is-loading="performing_search"
                            @update:modelValue="set_performing_search"
                        />
                        <!-- FILTER CONTROL -->
                        <facet-checkbox-dropdown
                            :disabled="!searchHasResults"
                            v-show="dropdown==='filter'"
                            :options="filterOptions"
                            class="absolute w-64 right-8 -top-2 h-auto bg-gray-tier-3 shadow-app-standard overflow-visible"
                        />
                    </div>

                    <!-- FILTER TAGS -->
                    <section v-if="hasTags" class="relative flex items-center gap-2 w-full p-4 pt-0">
                        <template v-for="(filter, key) in searchAppliedFilters">
                            <filter-tag v-if="key !== 'trays' && _.get(filter, '0.value', false)" :name="_.get(filter, '0.value')" @remove="removeTag(key,_.get(filter, '0.value'))" />
                        </template>
                    </section>

                    <!--==========================================-->
                    <!-- "Sort By" section is missing from layout -->
                    <!--==========================================-->
                    <!-- SORT BY -->
                    <section class="w-full text-left p-4 pt-0 pb-2">
                        <SeclockMultiselect
                            :disabled="!searchHasResults"
                            v-model="searchSortBy"
                            :options="searchSortOptions"
                            :option-is-object="true"
                            :label="$t('Sort by')"
                            label-position="side"
                            :contrast="false"
                            class="bg-gray-tier-3 cursor-not-allowed"
                            :hideSelected="true"
                        />
                    </section>

                    <!-- SELECTED TRAY BODY: PIN LIST -->
                    <section data-dialog-focus="true" data-dialog="true" class="w-full bg-gray-tier-3 h-full overflow-y-auto">
                        <!-- Loading while fetching -->
                        <div v-if="(performing_search || searchState.isLoading) && drawer.selected_tray?.pin_count !== 0" class="inset-0 flex mt-12 items-center justify-center">
                                <loading-circle class="flex items-center !justify-center w-8 h-8 mb-2" />
                        </div>

                        <!-- No Pins -->
                        <div v-if="!performing_search && filtered_pins?.length === 0 && drawer.state.fetching" class="inset-0 flex mt-10 items-center justify-center">
                            <div class="text-center mb-16 w-64 mx-auto">
                                <BaseSvg name="icon-push-pin-outlined" class="fill-gray w-10 h-10 mb-2" />
                                <div class="type-primary text-lg font-bold">{{ $t('No pinned items') }}</div>
                                <p class="type-tertiary text-xs">There are currently no pins in this tray. </p>
                            </div>
                        </div>

                        <!-- No Search results -->
                        <div v-else-if="!performing_search && !searchState.isLoading && filtered_pins?.length === 0 && drawer.state.searching" class="inset-0 flex mt-10 items-center justify-center">
                            <div class="text-center mb-16 w-64 mx-auto">
                                <BaseSvg name="icon-magnifying-glass" class="fill-gray w-10 h-10 mb-2" />
                                <div>
                                    <span class="type-tertiary text-lg font-medium">0 results for '<span class="type-primary text-lg font-bold">{{ searchTerm }}</span>'</span>
                                </div>
                                <p class="type-tertiary text-xs">Pro tip: Some searches require an exact match.</p>
                                <p class="type-tertiary text-xs">Try typing the entire team, or use a different work or phrase.</p>
                            </div>
                        </div>
                    </section>

                    <!-- PIN LIST -->
                    <ul v-if="!performing_search && !searchState.isLoading && filtered_pins?.length">
                        <li v-for="pin in filtered_pins" class="relative">
                            <tray-pin :pin="pin"/>
                            <Divider/>
                        </li>
                    </ul>

                </section>
            </div>
            <div v-else>
                <!-- MAIN PIN DRAWER BODY -->
                <section>

                    <!-- HEADER -->
                    <header class="flex justify-between items-center gap-8 bg-gray-tier-4 px-4 py-2">
                        <h2 class="font-medium">{{ $t('My pins') }}</h2>
                        <div class="flex items-center gap-2">
                            <div class="relative">
                                <AppButton
                                    :icon-only="true"
                                    icon="more-horiz"
                                    @click="dropdown==='more'?dropdown=false:dropdown = 'more'"
                                />
                                <TrayActionMenu
                                    :tray="drawer.selected_tray"
                                    :open="dropdown==='more'"
                                    @close="closeDropdown"
                                    :hasPinPage="true"
                                    :hasCreate="true"
                                    :hasExportAll="true"
                                />
                            </div>
                            <AppButton @click="closeDrawer" icon="close" :icon-only="true" />
                        </div>
                    </header>

                    <Divider />

                    <!-- SEARCH -->
                    <div class="flex items-center gap-2 w-full p-4">
                        <SearchInput
                            v-model="searchTerm"
                            :placeholder="$t('Search your pins')"
                            :search-is-loading="performing_search"
                            @update:modelValue="set_performing_search"
                        />
                    </div>
                </section>


                <!-- SEARCHING PINS IN MAIN PANEL : Loading while fetching -->
                <div v-if="performing_search || searchState.isLoading || is_fetching_trays" class="inset-0 flex mt-12 items-center justify-center">
                    <LoadingCircle class="flex items-center !justify-center w-8 h-8 mb-2" />
                </div>


                <!-- No SEARCHED PIN list results -->
                <div v-if="!performing_search && !searchState.isLoading && filtered_pins?.length === 0 && drawer.state.searching" class="inset-0 flex mt-10 items-center justify-center">
                    <div class="text-center mb-16 w-64 mx-auto">
                        <BaseSvg name="icon-magnifying-glass" class="fill-gray w-10 h-10 mb-2" />
                        <div>
                            <span class="type-tertiary text-lg font-medium">0 results for '<span class="type-primary text-lg font-bold">{{ searchTerm }}</span>'</span>
                        </div>
                        <p class="type-tertiary text-xs">Pro tip: Some searches require an exact match.</p>
                        <p class="type-tertiary text-xs">Try typing the entire team, or use a different work or phrase.</p>
                    </div>
                </div>


                <!-- SEARCHED PIN LIST -->
                <ul v-if="searchTerm.length && !performing_search && !searchState.isLoading && filtered_pins?.length">
                    <li v-for="pin in filtered_pins" class="relative">
                        <tray-pin :pin="pin"/>
                        <Divider/>
                    </li>
                </ul>


                <!-- TRAYS -->
                <template v-else-if="!performing_search && !searchState.isLoading && !drawer.state.searching && !is_fetching_trays" >
                    <!-- FAVORITES -->
                    <section class="bg-gray-tier-3 mb-2 relative">
                        <Tray
                            :tray="favorite_tray"
                            :isEditable="false"
                            class="mb-4 w-full"
                        />
                    </section>

                    <Divider class="my-4" />

                    <!-- Trays Section -->
                    <template v-if="user_trays.length" v-for="(tray) in user_trays">
                        <section data-dialog-focus="true" class="bg-gray-tier-3 mb-2 relative">
                            <Tray :tray="tray" />
                        </section>
                    </template>
                    <template v-else>
                        <p class="p-4 pb-0 type-primary font-medium text-center bg-gray-tier-3">No Trays</p>
                        <p class="p-4 pt-0 type-secondary text-sm text-center  bg-gray-tier-3">
                            There are currently no trays.
                        </p>
                    </template>

                    <!-- Create New Tray button -->
                    <section
                        class="bg-gray-tier-3 p-4 pt-0"
                        :class="{'text-center': !user_trays.length}"
                    >
                        <button @click="toggleTrayCreateEditDialog($event,{callback:togglePinsDialog})" class="btn-app-sm-white-on-gray">
                            <BaseSvg name="icon-addition-sign" />
                            {{ $t('Create new tray') }}
                        </button>
                    </section>
                </template>
            </div>
        </Transition>
        </loading-progress>
    </div>
</template>

<script setup>
import {
    BaseSvg,
    Divider,
    SearchInput,
    AppButton,
    LoadingCircle,
    TrayActionMenu,
    Tray,
    TrayPin,
    FilterTag,
    FacetCheckboxDropdown,
    SeclockMultiselect,
    LoadingProgress
} from '@/Components';
import {
    useDialog,
    usePinsDrawer,
    useTrayCreateEditDialog,
    usePinsDialog,
    useTrayDeleteDialog,
    usePinSearch
} from '@/Composables';
import {computed, inject, ref, reactive, onMounted, onUnmounted, watch} from 'vue';
import eventBus from "@/Composables/Modules/eventBus";
import config from "@/config";

//COMPOSABLES
const {forceClose} = useDialog();
const {toggleTrayCreateEditDialog} = useTrayCreateEditDialog();
const {toggleTrayDeleteDialog} = useTrayDeleteDialog();
const {togglePinsDialog} = usePinsDialog();
const {drawer, openTray, closeTray, fetchTrays} = usePinsDrawer();

const route = inject('route');

//REFS
const dropdown = ref(null);
const performing_search = ref(false);
const is_fetching_trays = ref(false);
const exporting = ref(false);

//COMPUTED
const animation = computed(() => drawer.value?.selected_tray ? 'slide-left' : 'slide-right');
const hasTags = computed(() => {
    let hasTags = false;
    Object.entries(searchAppliedFilters.value).filter(([key,facets])=>{
        if(key !== 'trays' && (facets[0] ?? false))
            hasTags = true
    })
    return hasTags;
});

//FUNCTIONS
function removeTag(field, value) {
    searchRemoveFilter(field,value);
}

function set_performing_search() {
    performing_search.value = true;
}

function closeDrawer(event) {
    closeTray()
    forceClose(event)
}

function closeDropdown() {
    dropdown.value = false;
}

function onTraysFetched(trays) {
    is_fetching_trays.value = false;
}

function onTrayCreated(tray) {
    is_fetching_trays.value = true;
    fetchTrays()
}

function onTrayUpdated(tray) {
    is_fetching_trays.value = true;
    fetchTrays()
}

function onTrayExporting(exporting_status) {
    exporting.value = exporting_status;
}

const {
    searchTerm,
    searchIsLoading,
    searchState,
    searchHasResults,
    searchSortBy,
    searchRemoveFilter,
    searchAppliedFilters,
    searchSortOptions,
} = usePinSearch(true);

//ONMOUNTED
onMounted(() => {
    eventBus.on('pins:trays-fetched',onTraysFetched);
    eventBus.on('pins:tray-created',onTrayCreated);
    eventBus.on('pins:tray-updated',onTrayUpdated);
    eventBus.on('pins:tray-exporting',onTrayExporting);
    is_fetching_trays.value = true;
    fetchTrays()
});

//ONUNMOUNTED
onUnmounted(() => {
    eventBus.off('pins:trays-fetched',onTraysFetched);
    eventBus.off('pins:tray-created',onTrayCreated);
    eventBus.off('pins:tray-updated',onTrayUpdated);
    eventBus.off('pins:tray-exporting',onTrayExporting);
});

//WATCH
watch(searchIsLoading,(is_loading) => {
    if(is_loading)
        setTimeout(() => performing_search.value = false, 200)
});


const favorite_pins = computed(() => {
    return drawer.value?.pins?.filter((pin) => (pin?.trays[0]?.name === 'Favorites') || (pin?.trays === 'Favorites'))
});
const favorite_pins_product_count = computed(() => favorite_pins.value.filter((pin) => pin.type === 'item').length);
const favorite_tray = computed(() => drawer.value?.trays?.filter((tray) => tray.is_default)[0]);
const user_trays = computed(() => drawer.value?.trays?.filter((tray) => !tray.is_default) ?? []);
const filtered_pins = computed(() => drawer.value.selected_tray?.is_default ? favorite_pins.value : drawer.value.pins);

const filterOptions = [
    {
        label: 'Pin Type',
        facet: 'type'
    },
    {
        label: 'Product Type',
        facet: 'category'
    },
    {
        label: 'Manufacturer',
        facet: 'vendor'
    },
    {
        label: 'Series',
        facet: 'series'
    },
];

</script>


<style scoped>
.pins_dialog_height {
    height: calc(100vh - var(--app-header-height));
    position: relative;
    top: var(--app-header-height);
}
.pins_body_height {
    height: calc(100vh - (var(--app-header-height) * 4)); /* "4" => 1:Top Navigation, 2:Search Bar row, 3:Select Filter row, 4:Pagination row */
    position: relative;
}
.h-panel {
    height: 100%
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all .2s ease-out;
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.slide-right-leave-to,
.slide-left-enter-from {
    transform: translateX(100%);
    overflow: hidden;
}

.slide-right-enter-from,
.slide-left-leave-to {
    transform: translateX(-100%);
    overflow: hidden;
}
</style>
