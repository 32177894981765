<template>
    <component
        :is="display"
        :offset="offset"
        :width="width"
        :class="props.class"
        :top="top"
        :horiz="horiz"
    >
        <slot />
    </component>
</template>

<script setup>
import { computed } from "vue";
import SourcingDropdownWrapper from "../../Themes/Sourcing/Components/Modules/DropdownWrapper.vue";
import MarketingDropdownWrapper from "../../Themes/Marketing/Components/Modules/DropdownWrapper.vue";
import { useUser } from "@/Composables";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode
        ? SourcingDropdownWrapper
        : MarketingDropdownWrapper
);

const props = defineProps({
    offset: {
        type: Object,
        required: false,
        default: {
            top: 1,
            left: 0,
        },
    },
    width: {
        type: String,
        required: false,
        default: "",
    },
    class: {
        type: String,
        required: false,
        default: "",
    },
});

const top = computed(() => {
    if (props.offset.top) {
        return typeof props.offset.top === "number"
            ? `pt-${props.offset.top}`
            : `pt-[${props.offset.top}]`;
    } else {
        return "pt-1";
    }
});

const horiz = computed(() => {
    if (props.offset.right !== undefined) {
        const offset = props.offset.right;
        return typeof offset === "number"
            ? offset < 0
                ? `-right-${Math.abs(offset)}`
                : `right-${offset}`
            : `right-[${offset}]`;
    } else {
        let left = "left-0";
        if (props.offset.left) {
            const offset = props.offset.left;
            left =
                typeof offset === "number"
                    ? offset < 0
                        ? `-left-${Math.abs(offset)}`
                        : `left-${offset}`
                    : `left-[${offset}]`;
        }
        return left;
    }
});
</script>
