<template>
    <button
        class="at1024:hidden"
        type="button"
        data-dialog="true"
        :aria-label="$t('Toggle main navigation')"
        aria-controls="nav-wrap"
        :aria-expanded="menu === 'mobile-nav'"
        @click="toggleMobileNavigationDialog($event)"
    >
        <span class="flex justify-start w-4 h-4 items-center relative">
            <span
                :class="menu === 'mobile-nav'? 'translate-y-0 rotate-45': '-translate-y-1.5'"
                class="block h-px w-4 transform transition bg-gray absolute"
            />
            <span
                :class="menu === 'mobile-nav'? 'opacity-0 translate-x-3': 'opacity-100'"
                class="block h-px w-4 transform transition bg-gray absolute"
            />
            <span
                :class="menu === 'mobile-nav'? 'translate-y-0 -rotate-45': 'translate-y-1.5'"
                class="block h-px w-4 transform transition bg-gray absolute"
            />
        </span>
    </button>
</template>

<script setup>
import {useMobileNavigationDialog, useNavigation} from "@/Composables";

const { toggleMobileNavigationDialog } = useMobileNavigationDialog();
const {menu} = useNavigation()
</script>
