<template>
    <!-- TRAY PIN -->
    <div
        class="p-4 hover:bg-gray-tier-5"
        :class="{
            'bg-gray-tier-5': menu.active,
            'bg-gray-tier-5 disabled !cursor-not-allowed pointer-events-none disabled:hover:no-underline opacity-50': menu.inert
        }"
    >
        <div class="group w-full flex items-center border-keyline-primary" >
            <span class="bg-gray-125 flex h-16 items-center justify-center p-3 rounded-md shrink-0 w-16 dark:bg-gray-700">
                <img
                    v-if="assetUrl(pin?.thumbnail)"
                    :src="assetUrl(pin?.thumbnail)"
                    loading="lazy"
                    :alt="pin?.name"
                />
                <BaseSvg v-else-if="pin.type === 'vendor'" name="icon-manufacturers-outline" class="p-2" />
                <BaseSvg v-else-if="pin.type === 'series'" name="icon-grid-view" class="h-12 p-2" />
                <BaseSvg v-else-if="pin.type === 'resource'" name="icon-catalogs-book-outline" class="p-2" />
                <BaseSvg v-else name="icon-products-filled" class="h-12 p-2" />
            </span>
            <span class="text-left w-full grid grid-cols-1 items-center ml-4">
                <div>
                    <button v-if="pin.type === 'resource' && pin.asset_is_video_resource" @click="toggleVideoDialog($event,youTubeEmbed(pin.asset_external_id, true))" class="type-primary font-semibold text-primary leading-none hover:underline underline-offset-4 w-full">{{ pin.name }}</button>
                    <Link v-else :href="pin?.route" class="type-primary font-semibold text-primary leading-none hover:underline underline-offset-4 w-full">{{ pin.name }}</Link>
                    <span v-if="pin.type === 'item'" class="type-primary bg-gray-tier-6 rounded py-0.5 px-1 text-2xs ml-2">{{ pin.vendor }}</span>
                </div>
                <div>
                    <p class="text-xs whitespace-nowrap w-full truncate">
                        {{ pin.description }}
                    </p>
                </div>
            </span>
            <span class="w-20 flex items-center justify-end">
                <LoadingSpinner
                    v-if="menu.inert"
                    class="mr-4"
                />
                <button
                    @click.stop="toggleMenu"
                    class="relative hidden group-hover:block mr-4"
                    :class="{ '!block': menu.active }"
                >
                    <BaseSvg
                        name="icon-more-horiz"
                        class="p-0.5 rounded-full hover:!bg-gray-tier-4 group-active:bg-gray-tier-6"
                        :class="{
                            '!block bg-gray-tier-6 group-hover:bg-gray-tier-6':
                                menu.active,
                        }"
                    />
                </button>
            </span>
        </div>
    </div>
    <TrayPinActionMenu
        class="mx-8 -mt-6"
        :pin="pin"
        :open="menu.active"
        @close="closeMenu"
        :hasAddTo="true"
        :hasExport="true"
    />
</template>

<script setup>
import { TrayPinActionMenu, BaseSvg, LoadingSpinner } from "@/Components";
import {eventBus, useAssetTransforms, usePinsDrawer, useVideoDialog} from "@/Composables";
import { onMounted, onUnmounted, reactive } from "vue";

onMounted(() => {
    eventBus.on("pins:open-tray-pin-action-menu", onOpenTrayPinActionMenu);
    eventBus.on("tray-pin-action-menu:inert", updateMenuInertState);
});

onUnmounted(() => {
    eventBus.off("pins:open-tray-pin-action-menu", onOpenTrayPinActionMenu);
    eventBus.off("tray-pin-action-menu:inert", updateMenuInertState);
});

//COMPOSABLES
const { openTray, closeTray } = usePinsDrawer();
const { assetUrl } = useAssetTransforms();
const { toggleVideoDialog } = useVideoDialog();
const { youTubeEmbed } = useAssetTransforms();

//REFS
const menu = reactive({
    active: false,
    opened: false, //user interacted with this menu and "opened" it
    inert: false,
});

//PROPS
const { pin } = defineProps({
    pin: {
        type: Object,
        required: true,
        default: {},
    },
});

//FUNCTIONS
function onOpenTrayPinActionMenu() {
    closeMenu();
}

function updateMenuInertState(data) {
    if (data.pin === pin) {
        menu.inert = data.state
    } else {
        menu.inert = false
    }
}

function toggleMenu() {
    menu.active ? closeMenu() : openMenu();
}

function closeMenu() {
    !menu.opened && (menu.active = false);
    menu.opened = false;
}

function openMenu() {
    menu.active = true;
    menu.opened = true;
    eventBus.emit("pins:open-tray-pin-action-menu");
}
</script>
