<template>
    <component
        :is="display"
        v-model="computedModel"
        :search-hidden="searchHidden"
        @csv-export="$emit('csvExport')"
        :is-downloading="isDownloading"
        :is-csv-disabled="isCsvDisabled"
        @change="$emit('change')"
    >
        <slot name="default" />
    </component>
</template>

<script setup>
import { computed, markRaw } from "vue";
import { useUser } from "@/Composables";
import MarketingControls from "../../../Themes/Marketing/Components/Modules/jTable/Controls.vue";
import SourcingControls from "../../../Themes/Sourcing/Components/Modules/jTable/Controls.vue";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode
        ? markRaw(SourcingControls)
        : markRaw(MarketingControls)
);

const emit = defineEmits(["update:modelValue", "csvExport", "change"]);
const props = defineProps(["modelValue", "searchHidden", "isDownloading", 'isCsvDisabled']);

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});
</script>
