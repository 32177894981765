<template>
    <div class="flex items-center justify-center fixed inset-0 bg-black/95 p-8">
        <button
            class="duration-150 group absolute top-4 right-4 w-10 h-10 flex items-center justify-center rounded-full hover:bg-white/10 active:bg-white/20"
            aria-label="Close product information panel"
            data-dialog-focus="true"
            @click="forceClose"
        >
            <BaseSvg
                name="icon-close"
                aria-hidden="true"
                class="duration-150 w-7 h-7 fill-gray-400 group-hover:fill-white"
            />
        </button>
        <iframe
            v-if="data"
            :src="data"
            allow="autoplay; fullscreen; picture-in-picture"
            class="aspect-video max-h-screen w-screen"
        ></iframe>
    </div>
</template>

<script setup>
import { BaseSvg } from "@/Components";
import { useDialog } from "@/Composables";

defineProps(["data"]);
const { forceClose } = useDialog();
</script>
