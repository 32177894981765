<template>
    <MarketingModal v-if="!settings.is_sourcing_mode" :title="$t('Email Quote')">
        <template #subheader>
            <div class="text-center mt-8">
                <span class="font-bold">{{ $t('Please note') }}:</span> <span>{{ $t('this is a SECLOCK.com branded email') }}.</span>
            </div>
        </template>
        <div class="w-full flex flex-col at600:flex-row items-center gap-4">
            <div class="w-full at600:w-auto grow">
                <SeclockInput
                    type="email"
                    :contrast="true"
                    :animated="true"
                    placeholder="Enter an email address"
                    label="Send to Email"
                    v-model="emailForm.email_address"
                />
            </div>
            <button
                @click="submit"
                class="w-full flex items-center justify-center at600:w-fit btn-white-on-black"
                :disabled="inert || !emailForm.email_address"
            >
                <LoadingCircle v-if="inert" />
                <span v-else>{{ $t('Send Quote') }}</span>
            </button>
        </div>
    </MarketingModal>
    <AppModal
        v-else
        title="Email Quote"
        id="printOrderDialog"
        :close-on-success="true"
        submit-text="Send Quote"
        @submit="submit"
        @close="forceClose"
        :valid="!(inert || !isEmailValid)"
    >
        <template #content>
            <div class="flex flex-col items-start space-y-8 p-8">
                <div>
                    <span class="font-bold">{{ $t('Please note') }}:</span> <span>{{ $t('this is a SECLOCK.com branded email') }}.</span>
                </div>
                <div class="w-full">
                    <AppInput
                        type="email"
                        placeholder="Enter an email address"
                        label="Send to Email"
                        v-model="emailForm.email_address"
                        :large="true"
                        :required="true"
                    />
                </div>
            </div>
        </template>
    </AppModal>
</template>

<script setup>
import {computed, inject, onMounted} from 'vue';
import {DialogCloseButton, SeclockInput, AppInput, AppModal, LoadingCircle, MarketingModal} from "@/Components";
import {eventBus, useDialog, useUser} from "@/Composables";
import { useForm } from "@inertiajs/vue3";

const { user, settings } = useUser();
const { forceClose } = useDialog();

const props = defineProps(['data']);
const route = inject('route');

const emailForm = useForm({
    email_address: user.value.email,
});

onMounted(() => {
    eventBus.on("form:reset", () => emailForm.reset());
});

const isEmailValid = computed(() => {
    // Regular expression for basic email validation
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if emailForm exists and has an email_address property
    if (emailForm.email_address) {
        return regex.test(emailForm.email_address);
    }

    // Default to false if emailForm or email_address is not set
    return false;
});

const submit = () => {
    emailForm.post(route('web.account.sales.quotes.email_quote', props.data.id), {
        onSuccess: forceClose,
    });
};
</script>
