import config from "@/config";
import useDialog from "./useDialog";
import {
    InsightsQuoteDialog,
    QuoteDialog,
    RightDrawerTransition,
    RightFloatingDrawerTransition,
} from "@/Components";
import {eventBus, useUser} from "@/Composables";

const { toggleDialog, forceClose } = useDialog();

export default function useQuotePreviewDialog() {
    const toggleQuoteDialog = (event, data) => {
        toggleDialog(
            QuoteDialog,
            useUser().settings.value.is_sourcing_mode ? RightFloatingDrawerTransition : RightDrawerTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    const toggleInsightsQuoteDialog = (event, data) => {
        toggleDialog(
            InsightsQuoteDialog,
            RightFloatingDrawerTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    function registerEventListeners() {
        eventBus.on('dialog:toggle-quote-preview', (event) => {
            toggleQuoteDialog(event.event, event.data);
        });

        eventBus.on('dialog:toggle-insights-quote-preview', (event) => {
            toggleInsightsQuoteDialog(event.event, event.data);
        });
    }

    return {
        registerEventListeners,
        toggleQuoteDialog,
        forceClose,
    };
}
