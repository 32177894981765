import { ref } from "vue";

const clickCounter = ref(0);
const clickTimer = ref(null);

/**
 * Simulate double clicking
 */
export default function useDoubleClick() {
    const doubleClick = (callback, args = {}) => {
        let delay = args.delay ?? 500;
        clickCounter.value++;

        if (clickCounter.value === 1) {
            clickTimer.value = setTimeout(() => {
                clickCounter.value = 0;
            }, delay);
        } else {
            clearTimeout(clickTimer.value);
            callback();
        }
    };

    return { doubleClick };
}
