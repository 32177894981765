<template>
    <component :is="display" :record="record" :hideFreight="hideFreight" />
</template>

<script setup>
import { computed } from "vue";
import { useUser } from "@/Composables";
import MarketingTotalCostBar from "../../../Themes/Marketing/Components/Modules/Record/TotalCostBar.vue";
import SourcingTotalCostBar from "../../../Themes/Sourcing/Components/Modules/Record/TotalCostBar.vue";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode
        ? SourcingTotalCostBar
        : MarketingTotalCostBar
);

const props = defineProps({
    record: {
        type: Object,
        required: true,
    },
    hideFreight: {
        type: Boolean,
        default: false,
    },
});
</script>
