<template>
    <div class="absolute -left-88 top-full w-104 overflow-hidden">
        <transition
            name="slide-menu"
            @before-enter="menuTransitioning = true"
            @before-leave="menuTransitioning = true"
            @after-enter="menuTransitioning = false"
        >
            <div
                v-if="['account', 'notifications'].includes(menu)"
                id="nav-menu-account"
                class="bg-white pt-6 w-104 shadow-menu relative"
            >
                <div
                    class="nav-menu"
                    :class="{ 'opacity-0': menuTransitioning }"
                >
                    <div
                        :inert="menu === 'notifications'"
                        class="border-b border-gray-150 flex items-start justify-between pb-6 pt-0 px-8"
                    >
                        <div class="flex flex-col capitalize text-gray">
                            <Link
                                :href="route('web.account.profile.show')"
                                class="font-medium hover:underline text-black"
                                :aria-label="$t('Your profile')"
                            >
                            <span class="font-medium text-black">
                                {{ user?.name }}
                            </span>
                            </Link>
                            {{ user?.team?.customer?.name }}
                        </div>

                        <Link
                            :href="route('logout')"
                            method="post"
                            class="text-sm text-gray underline underline-offset-4 hover:text-gray-800"
                            role="button"
                            as="button"
                        >
                            {{ $t("Log Out") }}
                        </Link>
                    </div>
                    <div
                        v-if="isEmployee()"
                        :inert="menu === 'notifications'"
                        class="border-b border-gray-150 px-4 py-2"
                    >
                        <button
                            :disabled="inert"
                            class="flex font-semibold items-center justify-between px-4 py-3 text-gray w-full hover:text-gray-800"
                            @click="eventBus.emit('user:toggle-sourcing-mode')"
                        >
                            <span class="flex gap-2 items-center" v-if="isEmployee()">
                                {{ $t("Source Mode") }}
                                <span
                                    class="bg-red inline-block leading-none px-2 py-1 rounded-lg text-white text-[10px] uppercase"
                                    aria-hidden="true"
                                >
                                    {{ $t("New") }}
                                </span>
                            </span>
                            <BaseSvg
                                name="icon-arrow-right-alt"
                                class="w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div :inert="menu === 'notifications'" class="pt-6 px-8">
                        <span class="block mb-2 text-gray-400">
                            {{ $t("Account #") }} {{ user?.team?.customer?.id }}
                        </span>
                        <!--                            Account Links-->
                        <ul class="gap-1 grid grid-cols-2 text-gray">
                            <template v-for="(link, i) in accountMainLinks">
                                <li v-if="!link.hidden" :key="i">
                                    <Link
                                        :href="route(link.route)"
                                        class="font-semibold hover:underline hover:text-gray-800"
                                    >
                                        {{ $t(link.name) }}
                                    </Link>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="pt-6 px-4">
                        <button
                            :inert="menu === 'notifications'"
                            class="bg-gray-125 flex items-center justify-between px-4 py-3 text-gray w-full hover:text-gray-800"
                            aria-controls="account-notifications"
                            :aria-expanded="menu === 'notifications'"
                            @click="toggleMenu('notifications', $event)"
                        >
                            <span class="flex items-center">
                                <!-- Shown when unread notifications exist -->
                                <span
                                    v-if="notificationCount"
                                    class="bg-red inline-block h-2 mr-3 rounded-full w-2"
                                    aria-hidden="true"
                                />
                                {{ $t("Notifications") }}
                                <!-- Shown when unread notifications exist -->
                                <template v-if="notificationCount">
                                    ({{ notificationCount }} {{ $t("unread") }})
                                </template>
                            </span>
                            <BaseSvg
                                name="icon-arrow-right-alt"
                                class="w-5"
                                aria-hidden="true"
                            />
                        </button>
                        <transition name="slide-left">
                            <div
                                v-if="menu === 'notifications'"
                                id="account-notifications"
                                data-dialog="true"
                                class="absolute bg-white bottom-0 left-0 overflow-auto pt-6 right-0 top-0"
                            >
                                <div v-if="!isLoading">
                                    <NotificationsHeader />
                                    <!-- Shown when unread notifications exist -->
                                    <ul
                                        v-if="notificationCount"
                                        class="duration-200 divide-y divide-gray-150"
                                        :class="isLoadingNotifications ? 'opacity-50' : ''"
                                    >
                                        <li v-for="notification in notifications" class="py-4 pl-6 pr-5">
                                            <Notification
                                                :notification="notification"
                                                @mark-as-read="setNotificationAsRead(notification)"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div v-else class="w-full h-full flex items-center justify-center">
                                    <LoadingSpinner :large="true" />
                                </div>
                            </div>
                        </transition>
                    </div>
                    <ul
                        :inert="menu === 'notifications'"
                        class="p-8 pt-6 text-gray grid grid-cols-2 gap-1"
                    >
                        <template v-for="(link, i) in secondaryLinks">
                            <li v-if="!link.hidden" :key="i">
                                <Link
                                    :href="route(link.route)"
                                    class="hover:underline"
                                >
                                    {{ $t(link.name) }}
                                </Link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>
<script setup>
import {inject, onMounted, ref} from "vue";
import {eventBus, useNavigation, useUser} from "@/Composables";
import {LoadingSpinner, Notification, NotificationsHeader} from "@/Components";

const route = inject('route');
const { user, notificationCount, setNotificationAsRead, isLoadingNotifications, hasAnyRole, isEmployee } = useUser();
const { menu, toggleMenu } = useNavigation();

const menuTransitioning = ref(false);

const accountMainLinks = [
    {
        name: "Dashboard",
        route: "web.account.dashboard",
        hidden: false,
    },
    {
        name: "Reports",
        route: "web.account.sales.reports.index",
        hidden: false,
    },
    {
        name: "Orders",
        route: "web.account.sales.orders.index",
        hidden: false,
    },
    {
        name: "Favorites",
        route: "web.account.favorites.index",
        hidden: false,
    },
    {
        name: "Invoices",
        route: "web.account.sales.invoices.index",
        hidden: false,
    },
    {
        name: "Saved Carts",
        route: "web.account.saved-carts.index",
        hidden: false,
    },
    {
        name: "Quotes",
        route: "web.account.sales.quotes.index",
        hidden: false,
    },
    {
        name: "Addresses",
        route: "web.account.addresses.index",
        hidden: false,
    },
];
const secondaryLinks = [
    {
        name: "Returns",
        route: "web.account.returns.index",
        hidden: false,
    },
    {
        name: "My Products",
        route: "web.account.my-products.index",
        hidden: false,
    },
    {
        name: "SKU Sets",
        route: "web.account.sku-sets.index",
        hidden: !hasAnyRole(["SKU Sets","Employee","Developer","MAPI User"]),
    },
    {
        name: "Allegion Order Form",
        route: "web.cart.allegion",
        hidden: !hasAnyRole(["Allegion User","Employee","Developer"]),
    },
    {
        name: "Credit Reference",
        route: "web.account.credit-reference",
        hidden: false,
    },
];

let notifications = ref(null);

const isLoading = ref(true);

onMounted(() => {
    try{
        useUser().loadNotifications().then(response => notifications.value = response);
    } catch (error) {
        console.log(error);
    } finally {
        isLoading.value = false;
    }
});
</script>

<style scoped>
.slide-left-enter-active {
    transition: transform 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-left-leave-active {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-left-enter-from,
.slide-left-leave-to {
    transform: translateX(100%);
}
</style>
