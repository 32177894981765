<template>
    <svg
        width="200px"
        height="120px"
        viewBox="0 0 200 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
        <title>large/visa</title>
        <g
            id="Rounded"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g id="Large" transform="translate(-32.000000, -28.000000)">
                <g id="large/visa" transform="translate(32.000000, 28.000000)">
                    <rect
                        id="Background"
                        fill="#25459A"
                        x="0"
                        y="0"
                        width="200"
                        height="120"
                        rx="8"
                    ></rect>
                    <g
                        id="Logo"
                        transform="translate(32.000000, 40.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                    >
                        <path
                            d="M68.2672516,27.2122001 C68.1919404,21.6403198 73.5588253,18.5307792 77.601826,16.6821648 C81.7558108,14.7851962 83.151042,13.5689046 83.1351871,11.8727915 C83.1034772,9.27654823 79.8215122,8.130928 76.7496247,8.08629345 C71.3906676,8.00818295 68.2751783,9.44392782 65.7978502,10.5300353 L63.8675158,2.05318951 C66.3527715,0.97824065 70.9546576,0.0409150083 75.7269836,0 C86.9284726,0 94.2574019,5.18876693 94.2970395,13.2341454 C94.3406403,23.4442997 79.2467723,24.0096708 79.3498288,28.5735539 C79.3855023,29.9572251 80.7926254,31.4338851 83.8764037,31.8095591 C85.4024382,31.9992559 89.6158792,32.1443183 94.3921687,30.0799701 L96.2670112,38.2815696 C93.6985165,39.1593824 90.3967329,40 86.2863494,40 C75.7428385,40 68.3267073,34.7405617 68.2672516,27.2122001 M114.282145,39.2932862 C112.236863,39.2932862 110.512642,38.1737028 109.743679,36.4552724 L93.7421172,0.602566485 L104.93568,0.602566485 L107.163294,6.37902176 L120.842112,6.37902176 L122.134286,0.602566485 L132,0.602566485 L123.390787,39.2932862 L114.282145,39.2932862 M115.847817,28.8413614 L119.078253,14.3128138 L110.231217,14.3128138 L115.847817,28.8413614 M54.6954535,39.2932862 L45.8721999,0.602566485 L56.538586,0.602566485 L65.3578762,39.2932862 L54.6954535,39.2932862 M38.915861,39.2932862 L27.8134648,12.958899 L23.3225632,35.3505672 C22.7953877,37.8501021 20.7144316,39.2932862 18.4035794,39.2932862 L0.253678458,39.2932862 L0,38.1699831 C3.72590236,37.4111957 7.95916162,36.187465 10.5236923,34.8781847 C12.0933277,34.0784823 12.5412287,33.3792076 13.0565131,31.4785195 L21.5626689,0.602566485 L32.8355053,0.602566485 L50.1173503,39.2932862 L38.915861,39.2932862"
                            id="Shape"
                            transform="translate(66.000000, 20.000000) scale(-1, 1) rotate(-180.000000) translate(-66.000000, -20.000000) "
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
