<template>
    <hr
        v-if="settings.is_dark_mode && settings.is_sourcing_mode && !strict"
        class="app-divider border-t border-t-keyline-primary"
    />
    <hr v-else-if="strict" class="border-y app-divider border-t-gray-850" />
    <hr v-else class="border-keyline-secondary" />
</template>

<script setup>
import { useUser } from "@/Composables";

const props = defineProps(["strict"]);
const { settings } = useUser();
</script>

<style scoped>
.app-divider {
    border-bottom-color: white;
}

.dark .app-divider {
    border-bottom-color: #343434;
}
</style>
