<template>
    <MarketingModal v-if="!settings.is_sourcing_mode" :title="data?.card?.display_sku">
        <ul class="flex items-center flex-col w-full at480:flex-row">
            <li class="w-full">
                <button
                    :disabled="data.pricing?.price === 0 || (!data.availability?.total && data.card?.filter_status?.toUpperCase() === 'D') || inert"
                    @click="eventBus.emit('cart:add-item',{sku:data.card.item_id})"
                    class="disabled:opacity-50 w-full p-4 at768:p-6 text-center block group hover:bg-gray-125 active:bg-gray-150"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-shopping-cart"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 fill-white bg-gray-800 p-4 rounded-full mb-4"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t("Add To Cart") }}
                    </span>
                </button>
            </li>
            <li class="w-full">
                <Pin type="item"
                     :id="data?.card?.item_reference_id"
                     :modal-button="true"
                />
            </li>
            <!--<li class="w-full">
                <button
                    :disabled="(!data.availability?.total && data.card?.filter_status?.toUpperCase() !== 'D') || inert"
                    @click="eventBus.emit('cart:add-item',data)"
                    class="disabled:opacity-50 w-full p-4 at768:p-6 text-center block group hover:bg-gray-125 active:bg-gray-150"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-bookmark-border"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 fill-gray bg-gray-125 p-4 rounded-full mb-4 group-hover:bg-white"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t('Add To Compare') }}
                    </span>
                </button>
            </li>-->
        </ul>
    </MarketingModal>
    <AppModal :title="data?.card?.display_sku" v-else>
        <ul class="flex items-center flex-col at480:flex-row w-full p-4">
            <li class="w-full at768:w-1/3">
                <button
                    :disabled="(data.pricing?.price === 0 || !data.availability?.total && data.card?.filter_status?.toUpperCase() !== 'D') || inert"
                    @click="eventBus.emit('cart:add-item',{sku:data.card.item_id})"
                    class="rounded w-full p-4 at768:p-6 text-center block group hover:bg-gray-tier-3 active:bg-gray-tier-2"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-shopping-cart"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 dark:fill-white bg-gray-tier-2 p-4 rounded-full mb-4"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t('Add To Cart') }}
                    </span>
                </button>
            </li>
            <li class="w-full at768:w-1/3">
                <Pin
                    type="item"
                    :id="data?.card?.item_reference_id"
                    :modal-button="true"
                />
            </li>
            <li class="w-full at768:w-1/3">
                <button
                    :disabled="(!data.availability?.total && data.card?.filter_status?.toUpperCase() !== 'D') || inert"
                    @click="eventBus.emit('cart:add-item',{sku:data.card.item_id})"
                    class="rounded w-full p-4 at768:p-6 text-center block group hover:bg-gray-tier-3 active:bg-gray-tier-2"
                >
                    <span class="flex items-center justify-center w-full">
                        <BaseSvg
                            name="icon-bookmark-border"
                            class="overflow-visible w-14 at768:w-16 h-14 at768:h-16 fill-gray group-hover:fill-white bg-gray-tier-5 group-hover:bg-gray-tier-2 p-4 rounded-full mb-4"
                        />
                    </span>
                    <span class="whitespace-nowrap text-xs at768:text-sm type-primary block">
                        {{ $t("Add To Compare") }}
                    </span>
                </button>
            </li>
        </ul>
    </AppModal>
</template>

<script setup>
import {BaseSvg, DialogCloseButton, Pin, Divider, MarketingModal, AppModal} from "@/Components";
import {eventBus, useUser} from "@/Composables";

const { settings } = useUser();
const props = defineProps(["data"]);
</script>
