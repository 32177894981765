<template>
    <div class="flex items-center justify-center grow-0">
        <div class="flex h-full items-center at1024:hidden">
            <Link :href="route('web.home')">
                <span class="sr-only">{{ $t("SECLOCK") }}</span>
                <BaseSvg name="seclock-logo-horiz-gray" class="w-36" aria-hidden="true"/>
            </Link>
        </div>
        <nav
            class="hidden at1024:block relative at1024:w-2/3 at1350:w-3/5 h-full"
            :aria-label="$t('Primary navigation')"
        >
            <ul class="duration-150 space-y-3 at1024:gap-4 at1200:gap-10 at1024:flex at1024:h-full at1024:justify-center at1024:pt-0.5 at1024:space-y-0">
                <!-- BEGIN: limited to specific audience -->
                <!--                            <li class="at1024:hidden" v-if="user">-->
                <!--                                <Link-->
                <!--                                    :href="route('web.daily-check-in')"-->
                <!--                                    class="font-bold text-gray tracking-wider uppercase hover:text-gray-800"-->
                <!--                                    >{{ $t("Daily Check-In") }}-->
                <!--                                </Link>-->
                <!--                            </li>-->
                <!-- END: limited to specific audience -->
                <CatalogDropdrown />
                <SupportDropdown />
                <li class="at1024:flex at1024:items-center">
                    <Link
                        :href="route('web.about')"
                        class="font-bold text-gray tracking-wider uppercase hover:text-gray-800"
                    >
                        {{ $t("About") }}
                    </Link>
                </li>
                <li class="at1024:flex at1024:items-center">
                    <Link
                        :href="route('web.education.index')"
                        class="font-bold text-gray tracking-wider uppercase hover:text-gray-800"
                    >
                        {{ $t("Blog") }}
                    </Link>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script setup>
import {useNavigation} from "@/Composables";
import {ref} from "vue";
import {SupportDropdown, CatalogDropdrown} from "@/Components";

const {toggleMenu, menu} = useNavigation();
const menuTransitioning = ref(false);

</script>

<style>
.slide-menu-enter-active {
    transition: transform 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-menu-leave-active {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-menu-enter-from,
.slide-menu-leave-to {
    transform: translateY(-100%);
}

.nav-menu {
    transition: opacity 0.3s;
}
</style>
