<template>
    <div class="relative">
        <section v-if="order">
            <div class="block gap-6 mb-8 at768:grid at768:grid-cols-2">
                <div class="border-t border-keyline-primary pt-4 at768:border-t-0 at768:pt-0">
                    <h5 class="font-semibold type-primary block">
                        {{ order.customer?.name }} <span class="font-normal">({{ order.customer?.id }} –
                        {{ order.customer?.group }})</span>
                    </h5>
                    <ul class="text-sm">
                        <li>
                            <span class="type-secondary mr-2">{{
                                $t("Location")
                            }}</span>
                            <span class="type-primary font-medium">
                                {{ order.customer?.address.city }},
                                {{ order.customer?.address.state }}
                                {{ order.customer?.address.zip?.slice(0, 5)}}
                            </span>
                        </li>
                        <li>
                            <span class="type-secondary mr-2">{{$t("Freight") }}</span>
                            <span class="type-primary font-medium">{{order.customer?.buying_group}}</span>
                        </li>
                        <li v-if="order.customer?.terms_code">
                            <span class="type-secondary mr-2">{{$t("Terms") }}</span>
                            <span class="type-primary font-medium">{{order.customer?.terms_code }}</span>
                        </li>
                        <li>
                            <span class="type-secondary mr-2">{{$t("Ordered By")}}</span>
                            <span class="type-primary font-medium">{{order.ordered_by}}</span>
                        </li>
                    </ul>
                </div>
                <div class="border-t border-keyline-primary mt-4 pt-4 at768:mt-0 at768:p-6 at768:bg-gray-tier-5 at768:rounded-md at768:border">
                    <AddressCard
                        v-if="order.address"
                        :header="$t('Shipping')"
                        :address="order.address"
                    />
                    <div v-else class="p-4 bg-gray-tier-5">
                        <h5 class="type-primary font-medium">{{ $t("Ship to") }}:</h5>
                        <p class="type-secondary">{{ $t("VERIFY SHIPPING ADDRESS") }}</p>
                    </div>
                </div>
            </div>

            <RecordSection
                v-if="invoiceRows?.length"
                :title="$t('Invoices')"
                :record="order"
                class="mb-8 mt-8"
            >
                <TableNew
                    :columns="invoiceColumns"
                    :rows="invoiceRows"
                    :row-component="TableRowTwo"
                    :floating-table="true"
                />
            </RecordSection>

            <RecordSection
                v-if="order.notes?.data?.length"
                :title="$t('Header Notes')"
                :record="order"
                class="mb-8"
            >
                <TableNew
                    :header-component="null"
                    :columns="notesColumns"
                    :rows="order.notes?.data"
                    :row-component="TableRowTwo"
                    :floating-table="true"
                />
            </RecordSection>

            <RecordSection
                v-if="order.status !== 'Processing'"
                title="Shipments"
                :record="order"
                class="mb-8"
            >
                <TableNew
                    v-if="shipmentRows?.length"
                    :rows="shipmentRows"
                    :columns="shipmentColumns"
                    :row-component="TableRowTwo"
                    :floating-table="true"
                />
                <div v-else class="grid grid-cols-2 mb-3 gap-6">
                    <div>{{ $t("No shipping information") }}</div>
                    <div>{{ order.ship_via_code }}</div>
                </div>
                <div class="flex items-center justify-end p-3 border-t border-keyline-primary">
                    <a
                        v-if="order.tracking_link"
                        :href="order.tracking_link"
                        target="_blank"
                        class="items-center gap-1 hidden at1024:flex"
                        :class="
                            settings.is_sourcing_mode
                                ? 'btn-app-lg-white-on-gray'
                                : 'btn-black-outline-pill'
                        "
                    >
                        <BaseSvg name="TrackLocation" class="w-6" />
                        {{ $t("Track") }}
                    </a>
                </div>
            </RecordSection>
            <RecordSection
                :title="$t('Items')"
                :record="order"
                class="mb-8"
            >
                <TableNew
                    :columns="lineColumns"
                    :rows="lineRows"
                    :row-component="TableRowTwo"
                    :floating-table="true"
                />
            </RecordSection>
            <div class="pb-6">
                <TotalCostBar :record="order" />
            </div>
        </section>
    </div>
</template>

<script setup>
import {
    AddressCard,
    BaseSvg,
    RecordSection,
    TotalCostBar,
    TableNew,
    TableRowTwo,
} from "@/Components";
import { useUser } from "@/Composables";

const { settings } = useUser();
defineProps([
    "order",
    "invoiceColumns",
    "invoiceRows",
    "shipmentRows",
    "notesColumns",
    "shipmentColumns",
    "lineRows",
    "lineColumns",
    "cards",
]);
</script>

<style>
tbody tr:last-child td {
    border-bottom: none;
}
</style>
