<template>
    <div class="seclock-multiselect contrast large -mt-1">
        <label
            for="team"
            class="inline-flex gap-1 items-center text-sm type-secondary leading-tight pb-2"
        >
            <base-svg name="icon-lock" class="w-4 h-4 opacity-50" />
            <span>{{ label }}</span>
        </label>
        <Multiselect
            id="team"
            placeholder="Search by customer id"
            v-model="computedValue"
            :searchable="true"
            :filter-results="false"
            :loading="searching"
            @search-change="(value) => (query = value)"
            :options="options"
            :option-is-object="true"
            :resolve-on-load="false"
            :allow-absent="true"
            :label="$t('Customer')"
            :can-clear="true"
            track-by="id"
            value-prop="id"
        >
            <template #singlelabel="{ value }">
                <div class="pl-3 grow">
                    {{ value.name }} ({{ value.customer.id }} •
                    {{ value.customer.name }})
                </div>
            </template>
            <template #nooptions="{ search }">
                <div class="px-3 py-1.5 text-sm type-tertiary">
                    <div v-if="!search">Search for a customer</div>
                    <div v-else-if="search.length < minLength">
                        {{ $t(`Enter at least ${minLength} characters`) }}
                    </div>
                    <div v-else-if="searching">{{ $t(`Searching…`) }}</div>
                    <div v-else>{{ $t("No options available") }}</div>
                </div>
            </template>
            <template #option="{ option }">
                <div class="text-sm leading-tight">
                    <h4 class="type-primary font-medium">
                        {{ option.customer.id }} • {{ option.customer.name }}
                    </h4>
                    <p class="type-secondary text-xs">{{ option.name }}</p>
                </div>
            </template>
        </Multiselect>
        <ValidationError :text="error" class="mt-1" />
    </div>
</template>
<script setup>
import Multiselect from "@/Components/Modules/Multiselect/Multiselect.vue";
import { BaseSvg, ValidationError } from "@/Components";
import { computed, ref, watch } from "vue";
import axios from "axios";
import _ from "lodash";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: {
        type: Number,
        default: null,
    },
    label: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        required: false,
        default: null,
    },
    minLength: {
        type: Number,
        default: 3,
    },
});

const computedValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const searching = ref(false);
const query = ref("");
const options = ref([]);

const debouncedQuery = _.debounce(async (value)=>{
    if (value.length < 3) {
        options.value = [];
        searching.value = false;
        return;
    }

    searching.value = true;

    options.value = await axios
        .get(route("api.v1.public.teams.index", { search: value }))
        .then((response) => response.data)
        .finally(() => {
            searching.value = false;
        });
},600);

watch(query, (value) => {
    debouncedQuery(value);
});
</script>
