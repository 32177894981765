import { computed, toRefs } from "vue";

export default function useClasses(props, context, dependencies) {
    const {
        classes: classes_,
        disabled,
        openDirection,
        showOptions,
    } = toRefs(props);

    // ============ DEPENDENCIES ============

    const isOpen = dependencies.isOpen;
    const isPointed = dependencies.isPointed;
    const isSelected = dependencies.isSelected;
    const isDisabled = dependencies.isDisabled;
    const isActive = dependencies.isActive;
    const canPointGroups = dependencies.canPointGroups;
    const resolving = dependencies.resolving;
    const fo = dependencies.fo;

    const classes = computed(() => ({
        multiselectTagPlaceholderWrapper: "multiselect-tag-placeholder-wrapper",
        multiselectTagPlaceholder: "multiselect-tag-placeholder",
        container: "multiselect",
        containerDisabled: "is-disabled",
        containerOpen: "is-open",
        containerOpenTop: "is-open-top",
        containerActive: "is-active",
        wrapper: "multiselect-wrapper",
        singleLabel: "multiselect-single-label",
        singleLabelText: "multiselect-single-label-text",
        multipleLabel: "multiselect-multiple-label",
        search: "multiselect-search",
        tagsLabelText: "multiselect-tags-label-text",
        tagsLabel: "multiselect-tags-label",
        tags: "multiselect-tags",
        tag: "multiselect-tag",
        tagDisabled: "is-disabled",
        tagRemove: "multiselect-tag-remove",
        tagRemoveIcon: "multiselect-tag-remove-icon",
        tagsSearchWrapper: "multiselect-tags-search-wrapper",
        tagsSearch: "multiselect-tags-search",
        tagsSearchCopy: "multiselect-tags-search-copy",
        placeholder: "multiselect-placeholder",
        caret: "multiselect-caret",
        caretOpen: "is-open",
        clear: "multiselect-clear",
        clearIcon: "multiselect-clear-icon",
        spinner: "multiselect-spinner",
        inifinite: "multiselect-inifite",
        inifiniteSpinner: "multiselect-inifite-spinner",
        dropdown: "multiselect-dropdown",
        dropdownTop: "is-top",
        dropdownHidden: "is-hidden",
        options: "multiselect-options",
        optionsTop: "is-top",
        group: "multiselect-group",
        groupLabel: "multiselect-group-label",
        groupLabelPointable: "is-pointable",
        groupLabelPointed: "is-pointed",
        groupLabelSelected: "is-selected",
        groupLabelDisabled: "is-disabled",
        groupLabelSelectedPointed: "is-selected is-pointed",
        groupLabelSelectedDisabled: "is-selected is-disabled",
        groupOptions: "multiselect-group-options",
        option: "multiselect-option",
        optionPointed: "is-pointed",
        optionSelected: "is-selected",
        optionDisabled: "is-disabled",
        optionSelectedPointed: "is-selected is-pointed",
        optionSelectedDisabled: "is-selected is-disabled",
        noOptions: "multiselect-no-options",
        noResults: "multiselect-no-results",
        fakeInput: "multiselect-fake-input",
        assist: "multiselect-assistive-text",
        spacer: "multiselect-spacer",
        ...classes_.value,
    }));

    // ============== COMPUTED ==============

    const showDropdown = computed(() => {
        return !!(
            isOpen.value &&
            showOptions.value &&
            (!resolving.value || (resolving.value && fo.value.length))
        );
    });

    const classList = computed(() => {
        const c = classes.value;

        return {
            multiselectTagPlaceholderWrapper:
                c.multiselectTagPlaceholderWrapper,
            multiselectTagPlaceholder: c.multiselectTagPlaceholder,
            container: [c.container]
                .concat(disabled.value ? c.containerDisabled : [])
                .concat(
                    showDropdown.value && openDirection.value === "top"
                        ? c.containerOpenTop
                        : []
                )
                .concat(
                    showDropdown.value && openDirection.value !== "top"
                        ? c.containerOpen
                        : []
                )
                .concat(isActive.value ? c.containerActive : []),
            wrapper: c.wrapper,
            spacer: c.spacer,
            singleLabel: c.singleLabel,
            singleLabelText: c.singleLabelText,
            multipleLabel: c.multipleLabel,
            search: c.search,
            tagsLabelText: c.tagsLabelText,
            tagsLabel: c.tagsLabel,
            tags: c.tags,
            tag: [c.tag].concat(disabled.value ? c.tagDisabled : []),
            tagDisabled: c.tagDisabled,
            tagRemove: c.tagRemove,
            tagRemoveIcon: c.tagRemoveIcon,
            tagsSearchWrapper: c.tagsSearchWrapper,
            tagsSearch: c.tagsSearch,
            tagsSearchCopy: c.tagsSearchCopy,
            placeholder: c.placeholder,
            caret: [c.caret].concat(isOpen.value ? c.caretOpen : []),
            clear: c.clear,
            clearIcon: c.clearIcon,
            spinner: c.spinner,
            inifinite: c.inifinite,
            inifiniteSpinner: c.inifiniteSpinner,
            dropdown: [c.dropdown]
                .concat(openDirection.value === "top" ? c.dropdownTop : [])
                .concat(
                    !isOpen.value || !showOptions.value || !showDropdown.value
                        ? c.dropdownHidden
                        : []
                ),
            options: [c.options].concat(
                openDirection.value === "top" ? c.optionsTop : []
            ),
            group: c.group,
            groupLabel: (g) => {
                let groupLabel = [c.groupLabel];

                if (isPointed(g)) {
                    groupLabel.push(
                        isSelected(g)
                            ? c.groupLabelSelectedPointed
                            : c.groupLabelPointed
                    );
                } else if (isSelected(g) && canPointGroups.value) {
                    groupLabel.push(
                        isDisabled(g)
                            ? c.groupLabelSelectedDisabled
                            : c.groupLabelSelected
                    );
                } else if (isDisabled(g)) {
                    groupLabel.push(c.groupLabelDisabled);
                }

                if (canPointGroups.value) {
                    groupLabel.push(c.groupLabelPointable);
                }

                return groupLabel;
            },
            groupOptions: c.groupOptions,
            option: (o, g) => {
                let option = [c.option];

                if (isPointed(o)) {
                    option.push(
                        isSelected(o)
                            ? c.optionSelectedPointed
                            : c.optionPointed
                    );
                } else if (isSelected(o)) {
                    option.push(
                        isDisabled(o)
                            ? c.optionSelectedDisabled
                            : c.optionSelected
                    );
                } else if (isDisabled(o) || (g && isDisabled(g))) {
                    option.push(c.optionDisabled);
                }

                return option;
            },
            noOptions: c.noOptions,
            noResults: c.noResults,
            assist: c.assist,
            fakeInput: c.fakeInput,
        };
    });

    return {
        classList,
        showDropdown,
    };
}
