<template>
    <div
        class="absolute bg-gray-tier-3 rounded shadow-md overflow-hidden z-10"
        :class="[props.class, width, horiz, top]"
    >
        <slot />
    </div>
</template>

<script setup>
const props = defineProps({
    offset: {
        type: Object,
        required: false,
        default: {
            top: 1,
            left: 0,
        },
    },
    width: {
        type: String,
        required: false,
        default: "",
    },
    class: {
        type: String,
        required: false,
        default: "",
    },
    top: {
        type: String,
        required: true,
        default: "",
    },
    horiz: {
        type: String,
        required: true,
        default: "",
    },
});
</script>
