<template>
  <tr class="row border-none group relative hover:bg-gray-tier-5" @click="emit('click', $event)">
    <td v-if="selectable">
      <SeclockInput
        v-if="typeof selectable === 'function' ? selectable(row) : true"
        type="checkbox"
        label=""
        v-model="computedModel"
      />
    </td>
    <td
      v-for="(column, key) in columns"
      class="relative group first-of-type:!pl-6 !px-2.5 py-2 at1024:py-[14px]"
      :class="[{'whitespace-nowrap': (!_.has(column, 'noWrap') && !column.noWrap === true)}, { 'last-of-type:!pr-8': isSubTable }, {[column.tdClasses] : column.tdClasses}, (typeof column.cellClass === 'function' ? column.cellClass(row) : column.cellClass) ?? '']">
      <div v-if="isSubTable && key === 0" class="float-left h-4 w-6 ml-4 mr-4 mb-4 border-l border-b border-bl-sm border-gray-tier-1" />
        <span
        v-if="!(typeof column.component === 'function' ? column.component(row) : column.component)"
        v-html="
          typeof column.path === 'function'
            ? column.path(row)?.length
              ? column.path(row)
              : (column.name.toLowerCase() === 'actions' || column.hideDash) ? '' : '—'
            : String(_.get(row, column.path, column.placeholder ?? (column.name.toLowerCase() === 'actions' || column.hideDash) ? '' : '—')).length
              ? _.get(row, column.path, column.placeholder ?? (column.name.toLowerCase() === 'actions' || column.hideDash) ? '' : '—')
              : column.placeholder ?? (column.name.toLowerCase() === 'actions' || column.hideDash) ? '' : '—'
        "
        @mouseover="hintSeen[`${key}-${_.get(row, column.path, '')}`]=true"
        @mouseleave="hintSeen[`${key}-${_.get(row, column.path, '')}`]=false"
        />
      <component
        :is="typeof column.component === 'function' ? column.component(row) : column.component"
        v-else
        :placeholder="column.placeholder"
        v-bind="typeof column.props === 'function' ? column.props(row) : column.props ?? {}"
        @mouseover="hintSeen[`${key}-${_.get(row, column.path, '')}`]=true"
        @mouseleave="hintSeen[`${key}-${_.get(row, column.path, '')}`]=false"
      />

        <span v-if="column?.hint" class="relative">
            <HintTransition v-if="typeof column.hint !== 'undefined' && column.hint(_.get(row, column.path, ''),row)" :hint-seen="hintSeen[`${key}-${_.get(row, column.path, '')}`]"  name="fade-top">
                {{ column.hint(_.get(row, column.path, '') , row) }}
            </HintTransition>
        </span>
    </td>
  </tr>
  <template v-if="subTable && _.get(row, subTable?.path, []).length" class="w-full">
    <tr class="w-full" :class="{ hide: !showSubTable }">
      <td class="!p-0 !m-0" :colspan="columns.length + (selectable ? 1 : 0)">
        <TableNew
          v-bind="typeof subTable.props === 'function' ? subTable.props(row) : subTable.props ?? {}"
          :rows="_.get(row, subTable?.path, [])"
          :columns="typeof subTable.columns === 'function' ? subTable.columns(_.get(row, subTable?.path, [])) : subTable.columns ?? {}"
          :is-sub-table="true" />
      </td>
    </tr>
  </template>
</template>

<script setup>
    import _ from 'lodash';
    import TableNew from '@/Components/Modules/jTable/TableNew.vue';
    import {computed, ref} from 'vue';
    import { SeclockInput, HintTransition } from '@/Components';

    const emit = defineEmits(['toggle-row']);

    const props = defineProps(['name', 'columns', 'row', 'subTable', 'isSubTable', 'selectable', 'selected']);

    const showSubTable = ref(props.subTable?.show ?? false);

    const hintSeen = ref([]);

    let computedModel = computed({
        // getter
        get() {
            return props.selected;
        },
        // setter
        set(newValue) {
            emit("toggle-row", newValue);
        },
    });
</script>
