import {eventBus} from "@/Composables";
import {computed, inject, reactive} from "vue";
import axios from "axios";
import {router} from "@inertiajs/vue3";

export default function useDownload() {
    const route = inject("route");
    let state = reactive({
        isDownloading: false,
    });

    const forceDownload = async (event, url, downloadTitle) => {
        event.preventDefault();
        state.isDownloading = true;

        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = downloadTitle ? downloadTitle :'seclock-file.pdf';

            document.body.appendChild(downloadLink);

            downloadLink.click();

            document.body.removeChild(downloadLink);

            URL.revokeObjectURL(downloadLink.href);
        } catch (error) {
            console.error('Error occurred while downloading the file:', error);
        }
        state.isDownloading = false;
    }






    const exportVisibleResults = (results, availability, pricing) => {
        state.isDownloading = true;
        axios.post(route('web.catalog.products.export_visible_csv'), {
            results: results,
            availability: availability,
            pricing: pricing,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
            });
        state.isDownloading = false;
    }

    const exportOrders = (params) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.orders.export_csv'), {
            ...params,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportUsers = (params) => {
        state.isDownloading = true;
        axios.post(route('web.accounts.users.export'), {
            ...params,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportQuotes = (params) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.quotes.export_csv'), {
            ...params,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportQuoteLines = (quote) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.quotes.export_lines_csv', quote.id))
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportInvoices = (params) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.invoices.export_csv'), {
            ...params,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportSkuSets = (params) => {
        state.isDownloading = true;
        router.visit(route("web.account.sku-sets.export", params), {
            preserveScroll: true,
            preserveState: true,
            onFinish: () => state.isDownloading = false,
        });
    }

    const exportInsights = (insights, type) => {
        state.isDownloading = true;

        let exportRoute = type === 'orders' ? 'web.insights.export_orders_csv' : 'web.insights.export_items_csv';

        axios.post(route(exportRoute), {
            insights: insights,
            type: type,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    };

    const exportManufacturersReport = (rows) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.reports.export_manufacturers_csv'), {
            rows: rows,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportVendorInsights = (rows) => {
        state.isDownloading = true;
        axios.post(route('web.insights.vendors.export'), {
            rows: rows,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportAudit = (id, rows) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.audit.export'), {
            id: id,
            rows: rows,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportReportCsv = (rows) => {
        state.isDownloading = true;
        axios.post(route('web.account.sales.reports.export_csv'), {
            rows: rows,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportFavoritesCsv = (rows) => {
        state.isDownloading = true;
        axios.post(route('web.account.favorites.export'), {
            rows: rows,
        })
            .then(response => {
                window.location.href = response.data.url;
                state.isDownloading = false;
            })
            .catch(error => {
                eventBus.emit("alert:show", {
                    title: "Error",
                    message: "There was an error exporting the CSV",
                    level: "danger"
                });
                console.error('There was an error exporting the CSV:', error);
                state.isDownloading = false;
            });
    }

    const exportFromRoute = async (route) => {
        //parameter should be written as route('example.route.here')
        state.isDownloading = true;
        window.location = await route;
        state.isDownloading = false;
    };

    return {
        forceDownload,
        exportVisibleResults,
        exportOrders,
        exportQuotes,
        exportQuoteLines,
        exportInvoices,
        exportInsights,
        exportSkuSets,
        exportManufacturersReport,
        exportVendorInsights,
        exportAudit,
        exportUsers,
        exportReportCsv,
        exportFromRoute,
        exportFavoritesCsv,
        isDownloading: computed(() => state.isDownloading),
    }
}
