import config from "@/config";
import useDialog from "./useDialog";
import {
    OrderDialog,
    InsightsOrderDialog,
    RightDrawerTransition,
    RightFloatingDrawerTransition,
} from "@/Components";
import {eventBus, useUser} from "@/Composables";

const { toggleDialog, forceClose } = useDialog();

export default function useOrderPreviewDialog() {
    const toggleOrderPreviewDialog = (event, data) => {
        toggleDialog(
            OrderDialog,
            useUser().settings.value.is_sourcing_mode ? RightFloatingDrawerTransition : RightDrawerTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    const toggleInsightsOrderPreviewDialog = (event, data) => {
        toggleDialog(
            InsightsOrderDialog,
            RightFloatingDrawerTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    function registerEventListeners() {
        eventBus.on('dialog:toggle-order-preview', (event) => {
            toggleOrderPreviewDialog(event.event, event.data);
        });

        eventBus.on('dialog:toggle-insights-order-preview', (event) => {
            toggleInsightsOrderPreviewDialog(event.event, event.data);
        });
    }

    return {
        toggleOrderPreviewDialog,
        forceClose,
        registerEventListeners,
    };
}
