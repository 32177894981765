<template>
    <transition appear name="slide-fade-top" v-bind="$attrs">
        <div
            v-if="visible"
            data-dialog="true"
            class="left-0 right-0 top-0"
            :class="['z-' + zindex, positionStyles]"
        >
            <slot></slot>
        </div>
    </transition>
</template>
<style>
.slide-fade-top-enter-active {
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-top-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-top-enter-from,
.slide-fade-top-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>
<script>
export default {
    name: "top-drawer-transition-component",
    inheritAttrs: false,
    props: {
        component: {
            type: Object,
            required: false,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        zindex: {
            type: Number,
            required: true,
        },
        position: {
            type: String,
            required: false,
            default: "fixed",
        },
    },
    computed: {
        positionStyles() {
            if (this.position === "fixed") {
                return "fixed w-screen";
            } else if (this.position === "absolute") {
                return "absolute w-full";
            }
        },
    },
};
</script>
