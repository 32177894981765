<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 656 419"
        height="419"
        width="656"
    >
        <defs>
            <linearGradient
                id="a"
                x1="45.058%"
                x2="45.96%"
                y1="61.697%"
                y2="35.379%"
            >
                <stop offset="0%" stop-color="#2B2B2B" />
                <stop offset="100%" stop-color="#171717" />
            </linearGradient>
        </defs>
        <path
            fill="none"
            fill-rule="evenodd"
            stroke="url(#a)"
            d="M157.96 417.5v.5H1.377v-.5c0-3.216-.312-6.435-.64-9.831l-.046-.478C.347 403.626 0 399.869 0 395.767c0-81.631 26.903-136.455 77.465-176.462 50.521-39.975 124.622-65.123 218.94-87.536h.005c80.542-18.337 130.368-35.98 160.229-56.634 14.919-10.32 24.839-21.382 31.119-33.647 6.281-12.267 8.939-25.77 9.282-41l.011-.488H655V11.367c0 73.48-24.493 126.938-72.633 167.792-48.109 40.827-119.799 69.034-214.131 92.13h-.003c-85.371 20.377-137.95 38.018-169.187 59.184-15.605 10.573-25.865 22.014-32.23 35.099-6.367 13.09-8.856 27.865-8.856 45.136V417.5Z"
            opacity=".7"
            transform="translate(.5 .5)"
        />
    </svg>
</template>
