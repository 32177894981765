<template>
    <div v-if="user"
        class="intercom-btn bottom-[18px] fixed h-11 right-[18px] w-11 at1024:h-[55px] at1024:w-[55px] z-[2147483001]">
        <a
            @click="toggleChat"
            class="
                flex h-full items-center justify-center rounded-full
            "
            :class="{
                'bg-gray-700 dark:bg-gray-225 dark:hover:bg-white hover:bg-black active:scale-90 ease-in': !isOpen,
                'bg-black dark:bg-white active:scale-90 ease-in': isOpen,
            }"
            style="box-shadow: 0 6px 13px 0 rgba(0,0,0,.23);">

            <!-- Show this SVG after Intercom is opened: -->
            <BaseSvg v-if="isOpen" name="IntercomClose" class="fill-white h-7 w-7 at1024:h-8 at1024:w-8 dark:fill-gray-825" />

            <!-- Show this SVG before Intercom is opened: -->
            <BaseSvg v-else name="IntercomOpen" class="fill-white h-7 w-7 dark:fill-gray-825" />
        </a>
    </div>
</template>

<script setup>
import {BaseSvg} from "@/Components";
import {useChat, useUser} from "@/Composables";
const {toggleChat,isOpen} = useChat();
const { user } = useUser();
</script>
