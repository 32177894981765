<template>
    <component
        :is="display"
        :quote="quote"
        :data="data"
        :columns="columns"
        :rows="rows"
    />
</template>

<script setup>
import {computed, inject, markRaw, onMounted, ref} from "vue";
import { useUser } from "@/Composables";
import SourcingQuoteDialog from "../../../Themes/Sourcing/Components/Modules/Dialogs/QuoteDialog.vue";
import MarketingQuoteDialog from "../../../Themes/Marketing/Components/Modules/Dialogs/QuoteDialog.vue";
import axios from "axios";
import {MoneyFormat, PreviewLinkFormat} from "@/Components";
import QuoteLineDetail from "../Formatters/QuoteLineDetail.vue";

const route = inject("route");
const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode ? SourcingQuoteDialog : MarketingQuoteDialog
);

const props = defineProps(["data"]);
const isLoading = ref(true);
const quote = ref();

onMounted(() => {
    axios
        .get(route("api.v1.public.account.quotes.show", props.data), {
            params: {
                with: ["quote"],
            },
        })
        .then((response) => {
            quote.value = response.data["quote"];
            isLoading.value = false;
        });
});

const rows = computed(() => quote.value?.lines);
</script>
