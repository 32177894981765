<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="29"
        fill="none"
        viewBox="0 0 61 29"
    >
        <path
            fill="none"
            d="M10.386 23.898v3.107a.389.389 0 0 1-.388.389H1.453a.389.389 0 0 1-.389-.389V2.147a.388.388 0 0 1 .389-.389h8.545a.388.388 0 0 1 .388.389v3.107"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.942"
            d="M10.386 23.898v3.107a.388.388 0 0 1-.388.389H1.453a.389.389 0 0 1-.389-.389V2.147a.388.388 0 0 1 .389-.389h8.545a.388.388 0 0 1 .388.389v3.107"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.942"
            d="M59.326 17.692v-6.23a3.1 3.1 0 0 0-3.1-3.1H7.272a3.1 3.1 0 0 0-3.1 3.1v6.23a3.1 3.1 0 0 0 3.1 3.099h48.956a3.1 3.1 0 0 0 3.1-3.1Z"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.942"
            d="M36.023 17.294v-5.437a.388.388 0 0 0-.389-.389H7.668a.388.388 0 0 0-.388.389v5.438c0 .214.174.388.388.388h27.966a.388.388 0 0 0 .389-.389Z"
        />
    </svg>
</template>
