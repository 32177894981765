import config from "@/config";
import useDialog from "./useDialog";
import { SourcingSearchDialog, SourcingSearchTransition } from "@/Components";

export default function useSearchDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleSearchDialog = (event) => {
        toggleDialog(
            SourcingSearchDialog,
            SourcingSearchTransition,
            "search",
            event,
            config.dialogs.navigation.searchDialog.zindex,
            true,
        );
    };

    return {
        toggleSearchDialog,
        forceClose,
    };
}
