<template>
    <div :data-name="computed_teleport_target" class="relative">
        <div class="overflow-shadows-horizontal" :class="outerDivClasses">
            <table :id="'table-'+name" class="product-list w-full text-left">
                <thead :class="theadClasses">
                    <slot name="header" v-bind="props">
                        <component
                            :is="props.headerComponent"
                            v-bind="props"
                            @toggle-all="toggleAll"
                            :selected-rows="selectedRows"
                            @update:sort="(value)=>$emit('update:sort',value)"
                        />
                    </slot>
                </thead>
                <tbody :class="tbodyClasses">
                    <slot name="body" v-bind="props">
                        <component
                            :is="props.bodyComponent"
                            v-bind="props"
                            :selected="selected"
                            @toggle-row="toggleRow"
                            @update:selected="(value)=>$emit('update:selected',value)"
                        />
                    </slot>
                </tbody>
                <tfoot v-if="footerComponent" :class="tfootClasses">
                    <slot name="footer" v-bind="props">
                        <component
                            :is="props.footerComponent"
                            v-bind="props"
                            :selected-rows="selectedRows"
                        />
                    </slot>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script setup>
import { v1 as uuidv1 } from "uuid";
import TableHead from "./TableHead.vue";
import TableBody from "./TableBody.vue";
import TableRow from "./TableRow.vue";
import {computed, markRaw, ref, watch} from "vue";

const props = defineProps({
    "rowIdentifier": {
        type: String,
        required: false,
    },
    "columns": {
        type: Array,
        required: true,
    },
    "rows": {
        type: Array,
        required: true,
        default: [],
    },
    "sort": {
        type: Object,
        default: {},
    },
    "sortSingleColumn": {
        type: Boolean,
        required: false,
        default: true,
    },
    "subTable": {
        type: Object,
        default: () => ({}),
    },
    "isSubTable": {
        type: Boolean,
        default: false,
    },
    "name": {
        type: String,
        default: () => null,
    },
    "headerComponent": {
        type: Object,
        default: () => markRaw(TableHead),
    },
    "footerComponent": {
        type: Object,
        required: false,
        default: null,
    },
    "bodyComponent": {
        type: Object,
        default: () => markRaw(TableBody),
    },
    "rowComponent": {
        type: Object,
        default: () => markRaw(TableRow),
    },
    "selectable": {
        type: [Boolean, Function],
        required: false,
        default: false,
    },
    "collapsable": {
        type: Boolean,
        required: false,
        default: false,
    },
    "outerDivClasses": {
        type: String,
        required: false,
        default: "w-full overflow-x-auto",
    },
    "tableElementClasses": {
        type: String,
        required: false,
        default: "product-list w-full text-left",
    },
    "theadClasses": {
        type: String,
        required: false,
        default: "",
    },
    "tbodyClasses": {
        type: String,
        required: false,
        default: "",
    },
    "tfootClasses": {
        type: String,
        required: false,
        default: "",
    },
    "floatingTable": {
        type: Boolean,
        required:false,
        default: false,
    },
    "subRowPath": {
        type: [String, Function],
        required: false,
        default: false,
    },
    "rowClasses": {
        type: [String, Function],
        required: false,
        default: '',
    },
});


const emit = defineEmits(["update:sort"]);

const selected = ref({});

//Some components will require a specific name we can reference.
// For the most part a uuid will suffice.
const name = computed(() => {
    return props?.name ?? uuidv1();
});

const selectedRows = computed(()=>{
    let selection = selected.value;
    return Object.entries(props.rows).filter(([key,value])=>selection[key] ?? false).map(([key,value])=>value);
});

const computed_teleport_target = computed(() => {
    return 'table-teleport-target' + (props.name ? '-' + props.name : '')
})

watch(props.rows,(value)=>{
    selected.value = {};
});

defineExpose({
    selectedRows,
});

function toggleAll(value){
    if(!value){
        selected.value = {};
    }else{
        selected.value = props.rows.map((row)=>true);
    }
}

function toggleRow(key){
    selected.value[key] = !(selected.value[key] ?? false);
}


</script>
