import en from "./Modules/en.js";
import { createI18n } from "vue-i18n";

export default createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        en,
    },
});
