<template>
    <transition appear name="pop" v-bind="$attrs">
        <!-- The below :class="'z-'+zindex" seems to resolve to a value of z-20 (by default, anyway). I need something higher than that, but it's not clear to me (or Ethan) how to pass a custom value into this :class="" attribute, so, we did the nasty thing and simply put a !z-100 in the class list. It works, but it's not great. — M.A. -->
        <div
            v-show="visible"
            class="fixed inset-0 flex items-start pt-8 at1024:pt-16 justify-center overflow-y-scroll overflow-x-hidden !z-100"
            :class="'z-' + zindex"
        >
            <slot></slot>
        </div>
    </transition>
</template>
<style>
.pop-enter-active {
    transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1);
}

.pop-leave-active {
    transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1);
}

.pop-enter-from,
.pop-leave-to {
    transform: scale(0.9);
    opacity: 0;
}
</style>
<script>
export default {
    name: "modal-transition-component-component",
    inheritAttrs: false,
    props: {
        component: {
            type: Object,
            required: false,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        zindex: {
            type: Number,
            required: true,
        },
    },
};
</script>
