<template>
    <label class="inline-flex items-center cursor-pointer relative">
        <Input
            type="checkbox"
            class="sr-only peer"
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
        />
        <span
            role="switch"
            :aria-checked="modelValue"
            class="inline-flex items-center border border-keyline-primary rounded-full flex-none w-10 h-6 p-[2px] overflow-hidden transition-colors peer-focus-visible:ring"
            :class="{
                'bg-gray-225 border-gray-300 dark:bg-gray-825': !modelValue,
                'bg-scarlet border-red-600': modelValue,
            }"
        >
            <span
                aria-hidden="true"
                class="w-4.5 h-4.5 rounded-full bg-white shadow-sm transition-transform"
                :class="{
                    'translate-x-0': !modelValue,
                    'translate-x-4': modelValue,
                }"
            ></span>
        </span>
        <span v-if="label && showLabel" :class="getLabelFormat()"> {{ label }}</span>
    </label>
</template>

<script setup>
import { Input } from "@/Components";

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    showLabel: {
        type: Boolean,
        required: false,
        default: false
    },
    modelValue: {
        type: Boolean,
        required: true,
    },
});

const getLabelFormat = () => 'ml-2' + (props.showLabel ? '' : ' sr-only');
</script>
