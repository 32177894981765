<template>
    <li data-dialog="true" class="at1024:w-[58px]">
        <button
            class="hidden at1024:flex group h-full items-center justify-center transition-colors w-full"
            :class="{
                'bg-gray': menu === 'product-search-drawer',
            }"
            @click="toggleProductSearchDialog($event,'product-search-drawer')"
        >
            <span class="sr-only">{{ $t("Search") }}</span>
            <BaseSvg
                name="icon-search-thick"
                class="transition-colors w-8"
                :class="menu === 'product-search-drawer' ? 'text-white group-hover:text-white' : 'text-gray group-hover:text-gray-800'"
                aria-hidden="true"
            />
        </button>
        <button
            class="at1024:hidden flex group h-full items-center justify-end transition-colors w-full"
            @click="toggleProductSearchDialog($event,'product-search-drawer')"
        >
            <span class="sr-only">{{ $t("Search") }}</span>
            <BaseSvg
                :name="menu === 'product-search-drawer'? 'icon-close': 'icon-search'"
                class="transition-colors w-6 text-gray group-hover:text-gray-800"
                aria-hidden="true"
            />
        </button>
    </li>
</template>

<script setup>
import {useNavigation, useProductSearchDialog} from "@/Composables";

const {menu} = useNavigation();
const {toggleProductSearchDialog} = useProductSearchDialog();
</script>
