<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 -960 960 960"
    >
        <path
            d="M442.5-183.5 243.5-299Q226-309.5 216-326.75T206-364v-229q0-20 10-37.25T243.5-658l199-115.5q17.75-10.5 37.38-10.5 19.62 0 37.62 10.5l199 115.5q17.5 10.5 27.5 27.75T754-593v229q0 20-10 37.25T716.5-299l-199 115.5Q499.75-173 480.12-173q-19.62 0-37.62-10.5ZM90-676v-119q0-30.94 22.03-52.97Q134.06-870 165-870h119v75H165v119H90ZM284-90H165q-30.94 0-52.97-22.03Q90-134.06 90-165v-119h75v119h119v75Zm392 0v-75h119v-118h75v118q0 30.94-22.03 52.97Q825.94-90 795-90H676Zm119-586v-119H676v-75h119q30.94 0 52.97 22.03Q870-825.94 870-795v119h-75Zm-475 60.5L281-593v43l162 92.5v186l37.5 21.5 37.5-21.5v-186L679-550v-43l-39-22.5-160 92-160-92Z"
        />
    </svg>
</template>
