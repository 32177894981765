<template>
    <loading-value v-if="isLoadingMeta" class="min-w-20" classes="!h-6" />
    <div v-else-if="showInventoryNotAvailable">{{ $t("Inventory not available") }}</div>
    <div v-else>
        <div v-if="showContent">
            <div v-if="availabilityTotalGreaterThanZero" class="flex flex-wrap gap-y-2">
                <button
                    v-if="!simple"
                    @click="toggleAvailabilityDialog($event, props)"
                    class="flex gap-1 items-center font-normal shrink-0 text-left underline underline-offset-4"
                    :class="buttonHoverClass"
                >
                    <BaseSvg name="CheckOutlined" aria-hidden="true" class="text-green" />
                    <span>
                        <span class="font-bold" v-if="!hideQuantity">{{ availability?.total }}</span><span v-if="showPlusSign">+</span> <span :class="inStockClass">{{ $t("in stock") }}</span>
                    </span>
                </button>
                <p v-else :class="textColor">
                    <span v-if="!hideQuantity">{{ availability?.total }}</span><span v-if="showPlusSign">+</span> <span :class="inStockClass">{{ $t("in stock") }}</span>
                </p>
                <ul class="flex gap-3 ml-[27px] text-gray" v-if="!simple">
                    <li v-for="warehouse in availableWarehouses" :key="warehouse.code" class="whitespace-nowrap">
                        <span class="font-normal mr-1">{{ warehouse.state }}</span>
                        <span class="font-medium">{{ availability?.warehouses?.[warehouse.code]?.available }}</span>
                    </li>
                </ul>
            </div>
            <div v-else-if="availability" class="flex flex-wrap gap-y-2">
                <!-- ASSEMBLY -->
                <div v-if="isAssembly">
                    <button
                        v-if="!hideButton"
                        @click="toggleAvailabilityDialog($event, props)"
                        class="group"
                        :class="textColor"
                    >
                        <span v-if="showInStock" :class="textColor">
                          <BaseSvg name="CheckOutlined" aria-hidden="true" class="mr-1 text-green" />
                          <span class="underline underline-offset-4 group-hover:text-red">{{ $t('In Stock') }}</span>
                        </span>
                        <span v-else>
                          <span class="underline underline-offset-4 hover:text-red" :class="textColor">{{ $t("Available") }}</span>
                          <span>&nbsp;{{ $t("from components") }}</span>
                        </span>
                    </button>
                    <span v-else :class="textColor" class="type-secondary">
                        <span v-if="showInStock">{{ $t('In Stock') }}</span>
                        <span v-else>{{ $t('Available') }}</span>
                    </span>
                </div>
                <!-- PARTS -->
                <div v-else>
                    <span v-if="hideButton" :class="textClass">{{ $t(availabilityStatus) }}</span>
                    <button
                        v-else
                        @click="toggleAvailabilityDialog($event, props)"
                        :class="buttonTextClass"
                        class="underline underline-offset-4 hover:text-red"
                    >
                        {{ $t(availabilityStatus) }}
                    </button>
                    &nbsp;
                    <span v-if="!simple">
                        <span v-if="isOrderPlus">{{ $t("for backorder") }}</span>
                    </span>
                </div>
                <div v-if="showOnOrder" class="flex items-center gap-1 ml-2.5">
                    <BaseSvg name="icon-cursor-pointer-up" class="ml-2 type-tertiary h-4 rotate-45" />
                    {{ onOrderQuantity }} {{ $t("on order") }}
                </div>
                <div v-else-if="showLeadTime" class="ml-2.5">
                    <div v-if="averageLeadTimeMessage">
                        <div class="flex items-center gap-1">
                            <BaseSvg name="icon-clock-icon" class="type-tertiary" />
                            {{ averageLeadTimeMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>{{ $t("Available from vendor") }}</div>
        </div>
        <div v-else>{{ $t("Not Available") }}</div>
    </div>
</template>

<script setup>
import config from "@/config";
import { useAvailabilityDialog } from "@/Composables";
import { BaseSvg, LoadingValue } from "@/Components";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps([
    "availability",
    "pricing",
    "card",
    "isLoadingMeta",
    "forceDark",
    "textColor",
    "hideQuantity",
    "hideButton",
]);

const simple = computed(() => props.availability.format === "map");
const is_part = computed(() => {
    return _.get(props.card, "filter_sub_category[0]", "")
        .toLowerCase()
        .includes("parts") || _.get(props.card, "filter_sub_category[0]", "").toLowerCase().includes("accessories")
});

const { toggleAvailabilityDialog } = useAvailabilityDialog();

const showInventoryNotAvailable = computed(() => !props.pricing?.price);
const showNotAvailable = computed(() => props.card?.filter_status === "D" && props.availability?.total < 1);
const showContent = computed(() => !showNotAvailable.value);
const availabilityTotalGreaterThanZero = computed(() => props.availability?.total > 0);

const showPlusSign = computed(() => Number(props.card.is_inventory_plus) && !is_part.value && !props.hideQuantity);
const inStockClass = computed(() => (props.hideQuantity ? "capitalize" : ""));

const availableWarehouses = computed(() => {
    return config.warehouses.filter((warehouse) => {
        return props.availability?.warehouses?.[warehouse.code]?.available > 0
    })
});

const isAssembly = computed(() => props.card.filter_status === "A");
const showInStock = computed(() => {
    return Number(props.card.is_inventory_plus) &&
    config.inStockVendors.includes(props.card?.filter_manufacturer?.toLowerCase())
});

const isInStockAndNotPart = computed(() => Number(props.card.is_inventory_plus) && !is_part.value);
const textClass = computed(() => {
    return !Number(props.card.is_inventory_plus) && !is_part.value ? "type-secondary" : props.textColor
});

const buttonTextClass = computed(() => props.textColor);

const availabilityStatus = computed(() => {
    return ((isInStockAndNotPart.value && isAssembly.value )? "In Stock" : "Available");
});

const isOrderPlus = computed(() => Number(props.card.is_order_plus));

const showOnOrder = computed(() => !simple.value && props.availability.total_on_po > 0);

const onOrderQuantity = computed(() => props.availability.total_on_po - props.availability.total_backorder);

const showLeadTime = computed(() => {
    return !simple.value && (
        (props.availability.total_on_po > 0 && !is_part.value && props.card.filter_status === "A") || Number(props.card.is_order_plus)
    )
});

const averageLeadTimeMessage = computed(() => {
    if (props.card.average_lead_time > 0) {
        if (props.card.average_lead_time < 14) {
            return "Avg " + props.card.average_lead_time + " " + (props.card.average_lead_time > 1 ? "days" :"day lead");
        } else {
            return "Avg 2 weeks lead";
        }
    } else if (props.card.dflt_ldtm_days > 0) {
        if (props.card.dflt_ldtm_days < 14) {
            return "Avg " + props.card.dflt_ldtm_days + " " + (props.card.dflt_ldtm_days > 1 ? "days" : "day lead");
        } else {
            return "Avg 2 weeks lead";
        }
    } else {
        return "";
    }
});

const buttonHoverClass = computed(() => ({
    "hover:text-white": props.forceDark,
    "hover:type-primary": !props.forceDark,
}));
</script>
