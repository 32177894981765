<template>
    <component
        :is="display"
        v-model="computedModel"
        :options="sortedOptions"
        :label="label"
        :defaultValue="defaultValue"
        :icon="icon"
        :searchHidden="searchHidden"
        :hoverBlue="hoverBlue"
        :isLoading="isLoading"
        :disabled="disabled"
    >
        <slot></slot>
    </component>
</template>

<script setup>
import { useUser } from "@/Composables";
import { computed } from "vue";
import MarketingSearch from "../../../Themes/Marketing/Components/Modules/FormElements/SearchSelect.vue";
import SourcingSearch from "../../../Themes/Sourcing/Components/Modules/FormElements/SearchSelect.vue";
import _ from "lodash";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode ? SourcingSearch : MarketingSearch
);

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
    options: {
        type: Object,
        required: true,
        default: () => ({
            icon: null,
            label: null,
            value: null,
            isNew: false,
        }),
    },
    label: {
        type: String,
        required: false,
        default: null,
    },
    modelValue: {
        type: String,
        required: false,
        default: null,
    },
    defaultValue: {
        type: String,
        required: false,
        default: null,
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    searchHidden: {
        type: Boolean,
        required: false,
        default: false,
    },
    hoverBlue: {
        type: Boolean,
        required: false,
        default: false,
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emits("update:modelValue", newValue);
    },
});

/*
    TODO: [RJGG] Note: Sorting of options should not have been done in this component. The component should just render the data given.
        The code below actually broke some other parts of the site that was using it. I added a "type of" check that has corrected it,
        but changes like these should be thoroughly tested.
 */
// Computed property to sort options
const sortedOptions = computed(() => {
    const entries = Object.entries(props.options);
    const sortedEntries = _.orderBy(entries, ([, value]) => {
        let entry_value = value;
        if(typeof value === "object") {
            entry_value = value.value;
        }
        const includesStd = entry_value.toLowerCase().includes('std') || entry_value.toLowerCase().includes('standard');
        return includesStd ? 0 : 1;
    });
    return Object.fromEntries(sortedEntries);
});
</script>
