import axios from "axios";
import route from "ziggy-js";
import _ from "lodash";

let settings = {};

const fetchSettings = async (key) => {

    await axios.get(route('api.v1.public.settings'),{params:{with:key}})
        .then((response) => {
            Object.keys(response.data).forEach((key) => {
                settings[key] = response.data[key];
            });
        });
};

export default function useSettingsLoader() {

    return {
        fetchSettings,
        settings(key){
            if (key){
                return _.get(settings,key,null);
            }
            return settings;
        },
    };

}
