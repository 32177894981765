<template>
    <aside
        v-if="settings.is_sourcing_mode"
        data-dialog="true"
        aria-label="Product information panel"
        class="overflow-y-auto bg-gray-tier-3 h-full shadow-xl-north type-primary w-[550px]"
    >
        <header v-if="!isLoadingMeta" class="sticky top-0 bg-gray-tier-4 z-10">
            <div class="flex items-center justify-between py-2 px-6">
                <h2 class="font-medium type-primary">
                    {{ meta.card.item_id }}
                </h2>
                <DialogCloseButton />
            </div>
            <Divider />
        </header>
        <div v-if="isLoadingMeta" class="h-full flex items-center justify-center">
            <LoadingSpinner :large="true"/>
        </div>
        <div v-else class="flex flex-col items-center">
            <Link
                :href="route('web.catalog.products.show', meta.card.slug)"
                class="flex items-center h-72 justify-center p-4"
            >
                <img
                    v-if="productImage(meta.summary)"
                    :src="productImage(meta.summary, 0, true, 'logo-replace')"
                    class="h-full w-full object-contain"
                    :alt="meta.card.display_sku"
                />
                <BaseSvg
                    v-else
                    name="icon-products-cube"
                    class="fill-gray-tier-7 h-full w-full object-contain"
                />
            </Link>
            <div class="items-start block w-full h-auto">
                <div class="shrink-0 px-6 w-full h-fit">
                    <header
                        class="flex items-start justify-between at1024:mb-4"
                    >
                        <div>
                            <Link
                                class="block type-secondary hover:type-primary text-sm hover:underline underline-offset-4"
                                v-if="meta.summary.vendor"
                                :href="
                                    route(
                                        'web.catalog.manufacturers.show',
                                        meta.summary?.vendor?.slug
                                    )
                                "
                            >
                                {{ meta.summary.vendor.display_name }}
                            </Link>
                            <Link
                                :href="
                                    route(
                                        'web.catalog.products.show',
                                        meta.card.slug
                                    )
                                "
                                class="font-bold text-lg at1024:text-xl hover:underline underline-offset-4"
                            >
                                {{ meta.summary.sku }}
                            </Link>
                            <p class="type-secondary text-sm">
                                {{
                                    _.get(
                                        meta.summary,
                                        "descriptions.product-description.0.short",
                                        ""
                                    )
                                }}
                            </p>
                        </div>

                        <div class="flex items-center gap-2">
                            <Pin
                                type="item"
                                :id="meta.summary.item_reference_id"
                            />
                            <AppButton
                                @click="toggleAddProductDialog($event, {
                                    card: meta.summary,
                                    pricing: meta.pricing,
                                    availability: meta.availability,
                                })"
                                :icon-only="true"
                                icon="addition-sign"
                            />
                        </div>
                    </header>
                    <div class="flex items-start gap-6 mb-4">
                        <div
                            v-if="
                                meta.availability.total < 1 &&
                                meta.summary.status === 'D'
                            "
                            class="font-medium text-orange"
                        >
                            {{ $t("Discontinued") }}
                        </div>
                        <div v-else-if="meta.pricing?.price > 0">
                            <strong
                                class="!leading-none text-lg at1200:text-2xl"
                            >
                                {{ showMeTheMoney(meta.pricing?.price) }}
                            </strong>
                        </div>
                        <div v-else>
                            <strong class="!leading-none text-base">{{
                                $t("Call")
                            }}</strong>
                        </div>
                        <div>
                            <!-- --------------- SLD3-1013: Bug #10 --------------- -->
                            <!--                            <div v-if="meta.summary.pricing_warehouse_item.list_price > 0" class="type-secondary">-->
                            <!--                                ${{ showMeTheMoney(meta.summary.pricing_warehouse_item.list_price) }} {{ $t('list') }}-->
                            <!--                                <span v-if="meta.summary.pricing_warehouse_item.fut_list_eff_date && meta.summary.pricing_warehouse_item.last_fut_update && meta.summary.pricing_warehouse_item.fut_list_price">-->
                            <!--                                        ({{ meta.summary.pricing_warehouse_item.fut_list_price == 0 ?-->
                            <!--                                    DateTime.fromISO(meta.summary.pricing_warehouse_item.fut_list_eff_date).toFormat('D') :-->
                            <!--                                    DateTime.fromISO(meta.summary.pricing_warehouse_item.last_fut_update).toFormat('D') }})-->
                            <!--                                </span>-->
                            <!--                            </div>-->
                            <div
                                v-if="
                                    meta.summary.pricing_warehouse_item
                                        .list_price > 0 &&
                                    meta.summary.pricing_warehouse_item
                                        .fut_list_price > 0 &&
                                    meta.summary.pricing_warehouse_item
                                        .fut_list_price !==
                                        meta.summary.pricing_warehouse_item
                                            .list_price
                                "
                                class="type-secondary"
                            >
                                {{ $t("List price") }}
                                {{
                                    meta.summary.pricing_warehouse_item
                                        .fut_list_price >
                                    meta.summary.pricing_warehouse_item
                                        .list_price
                                        ? $t("increase")
                                        : $t("decrease")
                                }}
                                {{ $t("to $") }}
                                {{
                                    meta.summary.pricing_warehouse_item
                                        .fut_list_price
                                }}
                                {{ $t("on") }}
                                {{
                                    DateTime.fromISO(
                                        meta.summary.pricing_warehouse_item
                                            .fut_list_eff_date
                                    ).toFormat("D")
                                }}
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div class="flex items-center w-full gap-4 h-10 mb-4">
                        <AvailabilityList
                            :availability="meta.availability"
                            :pricing="meta.pricing"
                            :card="meta.card"
                            :isLoadingMeta="isLoadingMeta"
                        />
                    </div>

                    <div
                        v-if="meta.pricing?.price && (meta.card?.filter_status?.toUpperCase() !== 'D' || meta.availability?.total > 0)"
                        class="flex items-center w-full gap-4 h-12 mb-4 at1024:mb-8"
                    >
                        <Counter v-model="quantity" />
                        <button
                            @click="
                                eventBus.emit('cart:add-item', {
                                    sku: meta.card.item_id,
                                    quantity,
                                })
                            "
                            :disabled="meta.pricing?.price === 0 || (!meta.availability?.total && meta.card?.filter_status?.toUpperCase() === 'D') || inert"
                            class="btn-app-lg-white-on-red h-10 w-full capitalize flex items-center justify-center gap-1 whitespace-nowrap"
                        >
                            <BaseSvg name="icon-shopping-cart" class="w-5 h-5" />
                            {{ $t("Add to cart") }}
                        </button>
                    </div>
                </div>
                <div v-if="meta.summary.uid" class="mb-4 px-6">
                    <p class="text-sm text-gray-400">{{ $t("UID") }}</p>
                    <p class="mb-4">{{ meta.summary.uid }}</p>
                </div>
            </div>
        </div>
    </aside>
    <aside
        v-else
        data-dialog="true"
        aria-label="Product information panel"
        class="bg-white h-[95svh] overflow-auto overscroll-contain pb-16 pt-4 px-6 relative rounded-t-2xl rounded-top shadow-xl-north w-full z-40 at1024:bg-gray-900/90 at1024:h-auto at1024:min-h-[400px] at1024:px-16 at1024:py-20 at1024:rounded-none at1024:text-white"
    >
        <div class="max-w-7.5 mx-auto">
            <button
                class="z-40 absolute top-2 right-2 w-10 h-10 ml-auto flex items-center justify-center rounded-full hover:bg-white/10 active:bg-white/20"
                aria-label="Close product information panel"
                data-dialog-focus="true"
                @click="forceClose"
            >
                <BaseSvg name="icon-close" aria-hidden="true" />
            </button>
            <div v-if="isLoadingMeta" class="absolute inset-0 flex items-center justify-center">
                <LoadingSpinner :large="true"/>
            </div>
            <div
                v-else
                class="flex flex-col h-full items-center w-full at1024:flex-row at1024:h-auto"
            >
                <Link
                    :href="route('web.catalog.products.show', meta.card.slug)"
                    :class="{
                        'mb-8 at1024:mb-0 at1024:mr-16':
                            productImage(meta.summary),
                    }"
                    tabindex="-1"
                >
                    <img
                        v-if="productImage(meta.summary)"
                        :src="productImage(meta.summary)"
                        class="p-4 bg-white max-h-[234px]"
                        width="234"
                        :alt="meta.card.display_sku"
                    />
                    <BaseSvg
                        v-else
                        name="icon-products-cube"
                        class="fill-gray-tier-7 h-auto w-56"
                    />
                </Link>
                <div class="items-start at1024:flex w-full at1024:w-4/5">
                    <div class="border-gray mb-8 px-6 shrink-0 w-full at1024:border-r at1024:h-full at1024:mb-0 at1024:p-0 at1024:pr-12 at1024:w-1/2">
                        <header class="flex gap-4 items-start justify-between at1024:mb-4">
                            <Link
                                :href="route('web.catalog.products.show', meta.card.slug)"
                                class="w-3/4 overflow-hidden truncate font-bold text-lg at1024:text-xl hover:underline underline-offset-4"
                            >
                                {{ meta.summary.sku }}
                            </Link>
                            <div class="flex items-center gap-2 mt-0.5">
                                <Pin
                                    class="hidden at1024:flex"
                                    :invert="true"
                                    type="item"
                                    :id="meta.summary.item_reference_id"
                                />
                                <Pin
                                    class="block at1024:hidden"
                                    :invert="false"
                                    type="item"
                                    :id="meta.summary.item_reference_id"
                                />
                                <button
                                    @click="toggleProductOptionsDialog($event, {
                                    availability: meta.availability,
                                    card: meta.card,
                                    pricing: meta.pricing,
                                })"
                                    class="btn-icon-black-outline-pill at1024:btn-icon-white-outline-pill !p-0 shrink-0"
                                >
                                    <BaseSvg name="icon-addition-sign" class="p-2" aria-hidden="true"/>
                                    <span class="sr-only">Add this product to…</span>
                                </button>
                            </div>
                        </header>
                        <div class="flex items-start gap-6 mb-4">
                            <div v-if="meta.availability.total < 1 &&meta.summary.status === 'D'" class="font-medium text-orange">
                                {{ $t("Discontinued") }}
                            </div>
                            <div v-else-if="meta.pricing?.price > 0" class="font-bold">
                                {{ showMeTheMoney(meta.pricing?.price) }}
                            </div>
                            <div v-else>{{ $t("Call") }}</div>
                            <div>
                                <div v-if="meta.summary.pricing_warehouse_item.list_price > 0" class="text-gray-400">
                                    {{ showMeTheMoney(meta.summary.pricing_warehouse_item.list_price) }} {{ $t('list') }}
                                </div>

                            </div>
                        </div>
                        <template v-if="meta?.summary?.series?.product_alert">
                            <div class="m-bottom">
                                <span class="font-semibold"><base-svg class="mb-1" name="exclamation-icon"></base-svg> Product restricted:</span>
                                <Link
                                    :href="route('web.catalog.products.show', meta.card.slug)"
                                    class="overflow-hidden truncate hover:underline underline-offset-4"
                                >
                                    View Details
                                </Link>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                v-if="meta.pricing?.price && (meta.card?.filter_status?.toUpperCase() !== 'D' || meta.availability?.total > 0)"
                                class="flex flex-col at768:flex-row at768:items-center w-full gap-4 mb-4 at1024:mb-6"
                            >
                                <div class="w-full at768:w-fit h-12">
                                    <Counter
                                        v-model="quantity"
                                        class="w-full h-full"
                                    />
                                </div>
                                <div>
                                    <button
                                        :disabled="meta.pricing?.price === 0 || (!meta.availability?.total && meta.card?.filter_status?.toUpperCase() === 'D') || inert"
                                        @click="
                                        eventBus.emit('cart:add-item', {
                                            sku: meta.card.item_id,
                                            quantity,
                                        })
                                    "
                                        class="btn-white-on-black normal-case !rounded-full at1024:btn-blue-on-gray at1024:px-6 at1024:tracking-normal at1024:text-base at1350:px-20"
                                    >
                                        {{ $t("Add to Cart") }}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div class="flex items-center w-full gap-4 h-10 mb-4 at1024:mb-0">
                            <span class="block at1024:hidden">
                                <AvailabilityList
                                    :availability="meta.availability"
                                    :pricing="meta.pricing"
                                    :card="meta.card"
                                    :isLoadingMeta="isLoadingMeta"
                                    :force-dark="false"
                                />
                            </span>
                            <span class="hidden at1024:block">
                                <AvailabilityList
                                    :availability="meta.availability"
                                    :pricing="meta.pricing"
                                    :card="meta.card"
                                    :isLoadingMeta="isLoadingMeta"
                                    :force-dark="true"
                                />
                            </span>
                        </div>
                    </div>
                    <div class="flex items-start h-auto px-6 at1024:pl-12 at1024:pr-0">
                        <div>
                            <p
                                class="mb-4"
                                v-if="
                                    _.get(
                                        meta.summary,
                                        'descriptions.product-description.0.short',
                                        ''
                                    )
                                "
                            >
                                {{
                                    _.get(
                                        meta.summary,
                                        "descriptions.product-description.0.short",
                                        ""
                                    )
                                }}
                            </p>
                            <div
                                v-if="meta.summary.vendor.display_name"
                                class="mb-4"
                            >
                                <p class="text-sm text-gray-400">
                                    {{ $t("Manufacturer") }}
                                </p>
                                <Link
                                    class="hover:underline underline-offset-4"
                                    v-if="meta.summary.vendor"
                                    :href="
                                        route(
                                            'web.catalog.manufacturers.show',
                                            meta.summary.vendor.slug
                                        )
                                    "
                                >
                                    {{ meta.summary.vendor.display_name }}
                                </Link>
                            </div>
                            <div v-if="meta.summary.uid">
                                <p class="text-sm text-gray-400">
                                    {{ $t("UID") }}
                                </p>
                                <p>{{ meta.summary.uid }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</template>

<script setup>
import {
    AvailabilityList,
    BaseSvg,
    Counter,
    LoadingSpinner,
    Pin,
    Divider,
    DialogCloseButton,
    AppButton,
} from "@/Components";
import {
    eventBus,
    useDialog,
    useHelpers,
    useMetadata,
    useProductInformationDialog,
    useProductOptionsDialog,
    useUser,
    useAddProductDialog,
} from "@/Composables";

import { inject, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import _ from "lodash";

const { settings } = useUser();
const { toggleProductInformationDialog } = useProductInformationDialog();
const { toggleProductOptionsDialog } = useProductOptionsDialog();
const { toggleAddProductDialog } = useAddProductDialog();
const { forceClose } = useDialog();
const { showMeTheMoney } = useHelpers();
const props = defineProps(["data"]);
const route = inject("route");

let quantity = ref(1);
let options = ref({});

const { meta, loadMeta, isLoadingMeta } = useMetadata(
    { sku: props.data?.card?.slug ?? props.data },
    ["summary", "options", "availability", "pricing", "card"]
);

onMounted(() => {
    loadMeta();
});
</script>
