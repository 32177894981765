import useDialog from "./Dialogs/useDialog";
import eventBus from "./eventBus";
import {computed, nextTick, ref} from "vue";
import { router } from "@inertiajs/vue3";
import _ from "lodash";
import {useChat, useSegment} from "@/Composables";

let inert = ref(false);
let referrer = false;

export default function useGlobalRouting() {
    function registerEventListeners() {
        router.on("error", (errors) => {
            console.info("Validation Errors:", errors);
        });

        router.on("exception", (event) => {
            event.preventDefault();
            eventBus.emit("alert:show", {
                title: "Error",
                message:
                    "A network error occurred while attempting your request. Please try again.",
                level: "danger",
            });
        });

        router.on("start", (event) => {
            // temporary fix: stops inert loading state for user settings like dark mode
            if(event.detail.visit.url.pathname !== '/account/profile/settings'){
                inert.value = true;
            }
        });

        router.on("navigate", (event) => {
            if (_.get(event, "detail.page.props.flash.close_dialogs", true)) {
                useDialog().forceClose(true);
            }
        });

        router.on("success", (event) => {
            let toast = _.get(event, "detail.page.props.flash.toast", false);
            if (toast) {
                eventBus.emit("alert:show", toast);
            }
        });

        router.on("finish", (event) => {
            let open_chat = _.get(event, "detail.page.props.open_chat", false);
            if(open_chat){
                nextTick().then(r  => useChat().openChat());
            }
            inert.value = false;
        });

        referrer = document.referrer;

        router.on("navigate", (event) => {
            eventBus.emit("routing:navigated", event);
            if (_.get(event, "detail.page.props.flash.track", true)) {
                eventBus.emit("page:viewed", { referrer });
            }
            referrer = window.location.href;
        });
    }


    function stringToHash(string) {

        let hash = 0;

        if (string.length == 0) return hash;

        for (let i = 0; i < string.length; i++) {
            let char = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }

        return hash;
    }

    let qstring = null;
    let query = null;

    return {
        registerEventListeners,
        inert: computed(() => {
            return inert.value;
        }),
        query(){
            if(qstring !== window.location.search){
                qstring = window.location.search;
                query = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
            }
            return query;
        }
    };
}
