<template>
    <div v-if="!pin.more">
        <span v-if="!pin.modalButton" class="w-full flex items-center text-center">
            <button
                @click.stop.prevent="$emit('toggle_pin', $event)"
                :disabled="loading"
                :class="{
                    'border border-gray-225 hover:border-gray-785 rounded text-gray-825 bg-white dark:bg-gray-700 dark:!border-gray-875 hover:bg-gray-175 hover:dark:bg-gray-650 active:bg-gray-225 dark:active:bg-gray-725 dark:text-white px-1 py-1 w-full items-center': !pin.custom,
                    '': pin.custom,
                }"
            >
                <loading-circle
                    v-if="loading"
                    class="mx-auto"
                />
                <BaseSvg
                    v-if="!loading"
                    :name="pin.icon"
                    class="h-5 rotate-45"
                    :class="{
                        '!fill-red': pin.state.pinned,
                    }"
                />


                <span v-if="pin.label && !(loading)" class="ml-2">
                        {{ pin.text }}
                    </span>
            </button>
        </span>
        <div
            v-else
            class="bg-transparent w-full text-center block group"
        >
            <button
                @click="$emit('toggle_pin', $event)"
                :disabled="loading"
                class="p-8 flex flex-col items-center justify-center w-full"
            >
                <loading-circle
                    v-if="loading"
                    class="w-16 h-16 p-4 mb-2"
                />
                <BaseSvg
                    v-else
                    :name="pin.icon"
                    class="overflow-visible w-16 h-16 p-4 rounded-full mb-2 group-hover:bg-gray-tier-3"
                    :class="pin.state.pinned ? 'fill-red' : ''"
                />
                <span class="block whitespace-nowrap text-base">
                    {{ pin.text }}
                </span>
            </button>
        </div>
    </div>
    <div v-else class="relative">
        <span class="flex items-center overflow-hidden border border-gray-225 hover:border-gray-785 rounded text-gray-825 bg-white dark:bg-gray-700 dark:!border-gray-875 hover:bg-gray-175 hover:dark:bg-gray-650 active:bg-gray-225 dark:active:bg-gray-725 dark:text-white">
            <button
                @click="$emit('onPin', $event)"
                :disabled="loading"
                class="w-fit"
            >
                <loading-circle v-if="loading" class="h-full p-2 "/>
                <BaseSvg
                    v-else
                    :name="pin.icon"
                    class="h-8 p-1 scale-75 rotate-45 w-8"
                    :class="{
                        'type-primary mr-px': pin.state.pinned,
                    }"
                />
            </button>
            <button
                v-if="pin.state.pinned"
                :disabled="loading"
                data-dialog="true"
                aria-controls="mini-pins"
                aria-expanded="false"
                @click="$emit('showPinMenu', $event)"
                class="border-l border-keyline-primary w-fit hover:bg-gray-175 hover:dark:bg-gray-650 active:bg-gray-225 dark:active:bg-gray-725"
            >
                <BaseSvg :name="menu === 'pin-options'? 'icon-expand-less': 'icon-expand-more'" class="h-8 p-1 w-8"/>
            </button>
        </span>
        <Transition name="search-pop">
            <ActionDropdown
                v-show="menu === 'pin-options'"
                :options="pin.options"
                :offset="{ right: 0 }"
            />
        </Transition>
        <Transition name="search-pop">
            <MiniPinsDialog
                v-if="menu === 'mini-pins'"
                :pin="pin"
                @toggle-pin="$emit('toggle_pin', $event)"
            />
        </Transition>
    </div>
</template>

<script setup>
import { useDialog } from "@/Composables";
import { ActionDropdown, BaseSvg, MiniPinsDialog, LoadingCircle } from "@/Components";
import {computed, inject, ref, watch} from "vue";

const { data: menu } = useDialog();

const props = defineProps({
    pin: {
        required: false,
    },
});

const loading = computed( () => {
    return props.pin.state.toggled || props.pin.state.initializing || props.pin.state.processing;
})

</script>
