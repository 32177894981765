<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="16"
        fill="none"
        viewBox="0 0 22 16"
    >
        <path
            fill="#FE1940"
            d="M10.28.16H5.54l-3.3 4.98C1.16 6.76.26 8.74.26 10.54c0 3.3 2.16 5.1 4.74 5.1 2.64 0 4.68-1.92 4.68-4.56 0-2.4-1.62-3.96-3.66-4.32l4.26-6.6Zm11.7 0h-4.74l-3.3 4.98c-1.08 1.62-1.98 3.6-1.98 5.4 0 3.3 2.16 5.1 4.74 5.1 2.64 0 4.68-1.92 4.68-4.56 0-2.4-1.62-3.96-3.66-4.32l4.26-6.6Z"
        />
    </svg>
</template>
