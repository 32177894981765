<template>
    <span
        class="pointer-events-none absolute z-10 block whitespace-nowrap px-2.5 py-2 bg-gray-900 px-1.5 rounded px-2 text-xs leading-4 text-white"
        :class="[layout.shared, layout[side]]"
    >
        {{ text }}
    </span>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    side: {
        type: String,
        required: false,
        default: "right",
    },
});

const layout = {
    shared: "before:absolute before:border-solid before:border-y-transparent before:border-y-8",
    right: "left-[calc(100%_+_.5rem)] before:border-r-gray-900 before:border-r-6 before:border-l-0 before:-left-1.5",
    left: "right-[calc(100%_+_.5rem)] before:border-l-gray-900 before:border-l-6 before:border-r-0 before:-right-1.5",
    top: "left-0 top-[calc(0px_-_2.25rem)]",
};

// TODO: Figure out if layout needs to be reactive
</script>
