<template>
    <li class="hidden mr-2 at1024:flex items-center translate-y-px">
        <a
            href="/register"
            class="bg-gray-800 duration-100 font-medium inline-flex items-center px-3.5 py-2 rounded-sm text-white tracking-wider transition uppercase hover:bg-black"
        >
            {{ $t("Register") }}
        </a>
    </li>
</template>

<script setup></script>
