import { inject } from "vue";
import { useForm } from "@inertiajs/vue3";
import { eventBus } from "@/Composables";

let initialized = false;
let cart = null;
let route = null;

export default function useSavedCart() {
    function genericToastError(errors) {
        if (errors.length) {
            eventBus.emit("alert:show", {
                title: "Error",
                message:
                    "There was an error processing your request. Please try again.",
                level: "danger",
            });
        }
    }

    function savedCartAddItem({
        cart,
        sku,
        quantity = 1,
        options = {},
        meta = {},
    } = {}) {
        let form = useForm({
            sku,
            quantity,
            options,
            meta,
        });

        form.post(route("web.saved-cart.item.store", cart), {
            preserveScroll: true,
            only: ["cart", "errors", "flash"],
            onError: (errors) => {
                if (errors.sku) {
                    eventBus.emit("alert:show", {
                        title: "Error",
                        message: errors.sku,
                        level: "danger",
                    });
                } else {
                    genericToastError(errors);
                }
            },
        });
    }

    function savedCartRemoveItem(cartitem_id) {
        let form = useForm({});

        form.delete(route("web.saved-cart.item.destroy", cartitem_id), {
            preserveScroll: true,
            only: ["cart", "errors", "flash"],
            onError: (errors) => {
                genericToastError(errors);
            },
        });
    }

    function savedCartRemoveItemBulk(cartitem_ids = []) {
        let form = useForm({
            cartitem_ids,
        });

        form.delete(route("web.cart.item.destroy.bulk"), {
            preserveScroll: true,
            only: ["cart", "errors", "flash"],
            onSuccess: (r) => {
                eventBus.emit("alert:show", {
                    title: "Success",
                    message: "Your item was removed!",
                    level: "success",
                });
            },
            onError: (errors) => {
                genericToastError(errors);
            },
        });
    }

    if (!import.meta.env.SSR) {
        if (!initialized) {
            initialized = true;
            cart = DynamicSettings.cart;
            route = inject("route");
        }
    }

    function registerEventListeners() {
        eventBus.on("saved-cart:add-item", (event) => savedCartAddItem(event));
        eventBus.on("saved-cart:remove-item", (event) =>
            savedCartRemoveItem(event)
        );
        eventBus.on("saved-cart:remove-item-bulk", (event) =>
            savedCartRemoveItemBulk(event)
        );
    }

    return {
        savedCartRemoveItem,
        savedCartAddItem,
        savedCartRemoveItemBulk,
        registerEventListeners,
    };
}
