<template>
    <component
        :is="display"
        :options="options"
        :offset="offset"
    />
</template>

<script setup>
import { computed } from "vue";
import SourcingActionDropdown from "../../Themes/Sourcing/Components/Modules/ActionDropdown.vue";
import MarketingActionDropdown from "../../Themes/Marketing/Components/Modules/ActionDropdown.vue";
import { useUser } from "@/Composables";

const { settings } = useUser();
const display = computed(() =>
    settings.value.is_sourcing_mode
        ? SourcingActionDropdown
        : MarketingActionDropdown
);
// const computed_options = computed(() => props.options)

const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    offset: {
        type: Object,
        required: false,
        default: {
            top: "0.25rem",
            left: 0,
        },
    },
});
</script>
