<template>
    <div class="flex items-center justify-between pb-3 pl-8 pr-5 border-b border-gray-150">
        <!-- Shown when unread notifications exist -->
        <span v-if="notificationCount" class="font-medium">{{$t("Notifications") }}</span>
        <!-- Shown when unread notifications DO NOT exist -->
        <span v-else class="font-medium">{{ $t("You are up to date!")}}</span>
        <div class="flex items-center">
            <!-- Shown when unread notifications exist -->
            <button
                v-if="notificationCount"
                @click="markAllAsRead()"
                class="text-sm text-red underline-offset-4 hover:underline"
            >
                {{ $t("Mark all as read") }}
            </button>
            <button
                id="account-notifications-close"
                class="flex items-center h-10 justify-center ml-2 rounded-full w-10 hover:bg-gray-150"
                :aria-label="$t('Close notifications')"
                @click="toggleMenu('account', $event)"
            >
                <BaseSvg name="icon-close" class="w-6" aria-hidden="true"/>
            </button>
        </div>
    </div>
</template>

<script setup>
import { useNavigation, useUser } from "@/Composables";
const { markAllAsRead } = useUser();

const { notificationCount } = useUser();
const { toggleMenu } = useNavigation();
</script>
