<template>
    <li class="hidden at1024:block mr-5 translate-y-px">
        <Link
            href="/login"
            class="flex font-bold h-full items-center text-gray tracking-wider uppercase hover:text-gray-800"
        >
            {{ $t("Login") }}
        </Link>
    </li>
</template>

<script setup></script>
