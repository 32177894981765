import _ from "lodash";
import {useAppSearchGeneric} from "@/Composables";

export default function usePinSearch(isGlobal = false, defaultSettings = {}) {

    let settings = _.defaultsDeep(defaultSettings, DynamicSettings.search.pinSearch);

    settings.mountSearchDriver = function(driver){
        driver.value.getActions().setResultsPerPage(1000);
    };

    const {
        searchSettings,
        searchDriver,
        searchState,
        searchTerm,
        searchResultsTerm,
        searchHasResults,
        searchWasSearched,
        searchIsLoading,
        searchResults,
        searchTotalResults,
        searchReset,
        searchTrackClick,
        searchRefresh,
        searchResultsPerPage,
        searchSetFilter,
        searchAppliedFilters,
        searchFacets,
        searchSortOptions,
        searchClearFilters,
        searchRemoveFilter,
        mountSearchDriver,
        searchSortBy,
        searchRealTotalResults
    } = useAppSearchGeneric(settings, isGlobal,'usePinSearch');

    return {
        searchRealTotalResults,
        searchSettings,
        searchDriver,
        searchState,
        searchTerm,
        searchResultsTerm,
        searchHasResults,
        searchWasSearched,
        searchIsLoading,
        searchResults,
        searchTotalResults,
        searchReset,
        searchTrackClick,
        searchRefresh,
        searchResultsPerPage,
        searchSetFilter,
        searchAppliedFilters,
        searchFacets,
        searchSortOptions,
        searchClearFilters,
        searchRemoveFilter,
        mountSearchDriver,
        searchSortBy,
    };

}
