<template>
    <div
        v-if="!settings.is_sourcing_mode"
        data-dialog="true"
        class="backdrop text-center fixed inset-0 w-screen h-screen overflow-y-auto"
    >
        <div class="z-100 dark w-full flex items-center justify-end sticky top-4">
            <DialogCloseButton :large="true" :dark="true" class="mr-4" />
        </div>
        <div class="relative px-8 py-12">
            <div class="fixed inset-0" @click="forceClose"></div>
            <h1 class="relative w-fit mx-auto text-2xl at1024:text-3xl my-8 px-2 font-bold text-white">
                {{ data.title }}
            </h1>
            <p class="text-sm type-tertiary mb-4" v-if="disclaimer" v-html="$t(disclaimer)" />
            <div
                draggable="false"
                @click="isZoomed = !isZoomed"
                @mouseleave="isZoomed = false"
                class="cursor-zoom-in relative z-50 bg-white mx-auto max-w-5xl mb-4 w-max"
            >
                <div
                    :class="isZoomed ? 'opacity-0' : 'opacity-100'"
                    class="duration-200 absolute bottom-4 left-0 right-0 flex justify-center items-center"
                >
                    <span class="flex items-center gap-1 justify-center px-4 py-1 bg-black/90 text-white rounded-full">
                        <BaseSvg name="icon-zoom-in" class="w-5 h-5" />
                        <span class="text-sm">{{ $t('Click to zoom') }}</span>
                    </span>
                </div>
                <VueMagnifier
                    v-if="data.images[selectedImage] && isZoomed"
                    :src="imageUrl(data.images[selectedImage], 'product-detail')"
                    :alt="data.images[selectedImage]?.alt_text"
                    :mg-height="175"
                    :mg-width="175"
                    :zoom-factor="1"
                />
                <img
                    v-else-if="data.images[selectedImage]"
                    :src="imageUrl(data.images[selectedImage], 'product-detail')"
                    :alt="data.images[selectedImage]?.alt_text"
                    class="pb-1.5 max-h-[90vh]"
                />
            </div>
        </div>
    </div>
    <div v-else data-dialog="true" class="fixed inset-0 bg-gray-tier-2">
        <header class="flex items-center justify-between px-8 py-4">
            <h2 class="type-primary font-medium text-2xl">{{ data.title }}</h2>
            <DialogCloseButton />
        </header>
        <Divider />
        <section class="flex p-8 gap-8">
            <ul
                v-if="data.images?.length"
                class="flex flex-col w-fit shrink-0 items-center gap-3 mx-4 at1024:m-0 mb-4"
            >
                <li v-for="(image, index) in data.images">
                    <a
                        @click="selectedImage = index"
                        class="block rounded border overflow-hidden w-20 h-20 p-1"
                        :class="
                            index === selectedImage
                                ? 'border-gray-475 dark:border-white bg-gray-tier-5'
                                : 'bg-gray-tier-4 border-gray-tier-6 hover:border-gray-tier-7'
                        "
                    >
                        <img
                            class="grow-0 h-full w-auto m-auto"
                            :src="imageUrl(image, 'thumb')"
                            :alt="image.alt_text"
                        />
                    </a>
                </li>
            </ul>
            <ul class="w-full">
                <li>
                    <div :id="0" class="sentinel flex items-center justify-center rounded-lg bg-gray-tier-5 h-[700px] overflow-hidden border border-keyline-primary w-full">
                        <div
                            @click="isZoomed = !isZoomed"
                            @mouseleave="isZoomed = false"
                            :class="isZoomed ? 'cursor-zoom-out' : 'cursor-zoom-in'"
                            class="relative w-full flex h-[700px] justify-center items-center z-10"
                        >
                            <div
                                :class="isZoomed ? 'opacity-0' : 'opacity-100'"
                                class="duration-200 absolute bottom-4 left-0 right-0 flex justify-center items-center"
                            >
                                <span class="flex items-center gap-1 justify-center px-4 py-1 bg-black/90 text-white rounded-full">
                                    <BaseSvg name="icon-zoom-in" class="w-5 h-5" />
                                    <span class="text-sm">{{ $t('Click to zoom') }}</span>
                                </span>
                            </div>
                            <div class="w-fit flex justify-center items-center h-[700px] overflow-hidden">
                                <VueMagnifier
                                    v-if="data.images[selectedImage] && isZoomed"
                                    :src="imageUrl(data.images[selectedImage],'product-detail')"
                                    :alt="data.images[selectedImage]?.alt_text"
                                    :mg-height="175"
                                    :mg-width="175"
                                    :zoom-factor="1"
                                    :mgCornerBgColor="settings.is_dark_mode ? '#343434': '#f4f4f4'"
                                    class="!h-[700px] mx-auto object-contain !w-fit"
                                />
                                <img
                                    v-else-if="data.images[selectedImage]"
                                    :src="imageUrl(data.images[selectedImage], 'product-detail')"
                                    :alt="data.images[selectedImage]?.alt_text"
                                    class="h-full object-contain"
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { BaseSvg, DialogCloseButton, Divider } from "@/Components";
import { useAssetTransforms, useDialog, useUser } from "@/Composables";
import VueMagnifier from "@websitebeaver/vue-magnifier";
import "@websitebeaver/vue-magnifier/styles.css";

const { settings } = useUser();
const { data } = defineProps(["data"]);
const disclaimer = data.disclaimer ?? "Images may not represent actual product and finish"
const selectedImage = ref(data.selectedImage);
const { assetUrl } = useAssetTransforms();
const { forceClose } = useDialog();

const isZoomed = ref(false);

function imageUrl(value, transform) {
    return value instanceof Object ? assetUrl(value, transform) : value
}
</script>

<style scoped>
.backdrop {
    background-color: rgba(37, 40, 42, 0.8);
}
</style>
