<template>
    <span
        class="pointer-events-none block w-[8px] h-[8px] rounded-full border-2 origin-center scale-0 ease-in-out duration-300"
        :class="{
            'scale-100': showIndicator,
            'absolute z-10 top-[7px] right-[5px]': isAbsolute,
            'border-white': lightMode,
            'border-gray-700': !lightMode,
            'bg-royal': theme === 'default',
            'bg-red': theme === 'red',
        }"
    />
</template>

<script setup>
/**
 * Note:
 * The parent element must have a `relative` class applied
 * to it if the `isAbsolute` prop is set to true
 */

import { computed } from "vue";

const props = defineProps({
    active: {
        type: Boolean,
        default: false,
        required: true,
    },
    isAbsolute: {
        type: Boolean,
        default: true,
    },
    lightMode: {
        type: Boolean,
        default: false,
    },
    theme: {
        type: String,
        default: "default",
        validator: (val) => ["default", "red"].includes(val),
    },
});

const showIndicator = computed({
    get() {
        return props.active;
    },
    set(newValue) {
        return newValue;
    },
});
</script>

<style scoped></style>
