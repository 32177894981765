import { inject, ref } from "vue";
import axios from "axios";

let route = null;

const defaults = {
    show: {
        summary: {},
        cards: [],
        pricing: {},
        availability: {},
        options: [],
        components: [],
        companions: [],
        substitutes: [],
    },
    index: {
        summaries: [],
        cards: [],
        pricing: {},
        availability: {},
        options: [],
    },
};

export default function useMetadata(config, keys = ["pricing"]) {
    route ??= inject("route");

    let is_show = Object.keys(config).includes("sku");
    let reset = defaults[is_show ? "show" : "index"];
    const meta = ref({});
    const isLoadingMeta = ref(true);
    const sku = ref(config.sku ?? null);
    const skus = ref(config.skus ?? []);
    const dataPoints = ref(keys);
    let callback = config.callback;

    function loadMeta(config = {}) {
        reset = defaults[is_show ? "show" : "index"];
        clearMeta();
        sku.value = config.sku ?? sku.value;
        skus.value = config.skus ?? skus.value;
        callback = config.callback ?? callback;
        isLoadingMeta.value = true;

        // Single Item
        if (sku.value && is_show) {
            axios
                .get(route("api.v1.public.products.show", sku.value), {
                    params: { with: dataPoints.value },
                })
                .then((response) => {
                    dataPoints.value.forEach((key) => {
                        meta.value[key] = response.data[key];
                    });
                })
                .catch((response) => {
                    // TODO: Error handling if meta fetch fails
                    console.error(response);
                })
                .finally(() => {
                    isLoadingMeta.value = false;
                    if (callback) {
                        callback(meta.value);
                    }
                });
        }

        // Multiple Items
        if (skus.value.length && !is_show) {
            axios
                .post(route("api.v1.public.products.index"), {
                    skus: skus.value,
                    with: dataPoints.value,
                })
                .then((response) => {
                    dataPoints.value.forEach((key) => {
                        meta.value[key] = response.data[key];
                    });
                })
                .catch((response) => {
                    // TODO: Error handling if meta fetch fails
                    console.error(response);
                })
                .finally(() => {
                    isLoadingMeta.value = false;
                    if (callback) {
                        callback(meta.value);
                    }
                });
        }
    }

    function clearMeta() {
        meta.value = reset;
    }

    clearMeta();

    return {
        // Data
        meta,
        isLoadingMeta,

        // Methods
        loadMeta,
        clearMeta,
    };
}
