export default function (props, context, features, deps = {}) {
    features.forEach((composable) => {
        /* istanbul ignore else */
        if (composable) {
            deps = {
                ...deps,
                ...composable(props, context, deps),
            };
        }
    });

    return deps;
}
