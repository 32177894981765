<template>
    <li data-dialog="true" class="hidden at1024:block relative w-12">
        <button
            class="flex group h-full items-center justify-center relative w-full"
            aria-controls="nav-menu-account"
            :aria-expanded="['account', 'notifications'].includes(menu)"
            @click="toggleMenu('account', $event)"
        >
            <span class="sr-only">{{ $t("Account") }}</span>
            <BaseSvg
                name="icon-account-circle-thick"
                class="shrink-0 text-gray w-8 group-hover:text-gray-800"
                aria-hidden="true"
            />
            <BaseSvg
                name="icon-expand-more-thick"
                class="-ml-[3px] shrink-0 text-gray w-5 group-hover:text-gray-800"
                aria-hidden="true"
                data-name="icon-expand-more-thick"
            />
            <span
                v-if="notificationCount"
                class="absolute bg-red block h-2.5 left-[25px] rounded-full top-[17px] w-2.5"
            >
                <span class="sr-only">{{ $t("copy.unread-notifications") }}</span>
            </span>
        </button>
        <AccountDropdown />
    </li>
</template>
<script setup>
import { useNavigation, useProductSearchDialog, useUser } from "@/Composables";
import { AccountDropdown } from "@/Components";

const { toggleProductSearchDialog } = useProductSearchDialog();
const { notificationCount } = useUser();
const { menu, toggleMenu } = useNavigation();
</script>

<style scoped>
.slide-menu-enter-active {
    transition: transform 175ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-menu-leave-active {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-menu-enter-from,
.slide-menu-leave-to {
    transform: translateY(-100%);
}

.nav-menu {
    transition: opacity 0.3s;
}
</style>
