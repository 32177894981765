import {computed, ref} from "vue";
import _ from "lodash";
import axios from "axios";
import route from "ziggy-js";
import {eventBus} from "@/Composables";

const globalSearchIsLoading = ref(false);
const globalSearchTerm = ref('');
const globalSearchResultsTerm = ref('');
const globalSearchState = ref(null);

export default function useBasicSearchApi(isGlobal = false) {

    const searchIsLoading = !isGlobal ? ref(false) : computed({
        get: () => globalSearchIsLoading.value,
        set: value => globalSearchIsLoading.value = value
    });
    const searchTerm = !isGlobal ? ref('') : computed({
        get: () => globalSearchTerm.value,
        set: (value) => {
            //SEC-2455
            eventBus.emit("catalog:product-search-click", value);
            globalSearchTerm.value = value
        }
    });
    const searchResultsTerm = !isGlobal ? ref('') : computed({
        get: () => globalSearchResultsTerm.value,
        set: (value) => globalSearchResultsTerm.value = value
    });
    const searchState = !isGlobal ? ref(null) : computed({
        get: () => globalSearchState.value,
        set: (value) => globalSearchState.value = value
    });

    let controller = false;

    const search = function(){
        controller = new AbortController();
        searchIsLoading.value = true;
        axios.post(route('api.v1.public.search.basic'),{ query: searchTerm.value },{signal:controller.signal})
            .then(data => {
                searchResultsTerm.value = searchTerm.value;
                searchState.value = data.data;
            })
            .catch(()=>{})
            .finally(()=>{
                searchIsLoading.value = false;
                controller = false;
            });
    };


    const searchDebounced = _.debounce(() => {
        search();
    }, 400);


    return {
        search,
        searchDebounced,
        searchState,
        searchTerm: computed({
            get(){
                return searchTerm.value;
            },
            set(newValue){
                if(controller){
                    controller.abort();
                }
                searchTerm.value = newValue
                searchDebounced();
            }
        }),
        searchTermQuick: computed({
            get(){
                return searchTerm.value;
            },
            set(newValue){
                if(controller){
                    controller.abort();
                }
                searchTerm.value = newValue
                search();
            }
        }),
        searchResultsTerm,
        searchHasResults : computed(() => searchState.value?.meta?.page?.total_results ?? false),
        searchWasSearched: computed(()=> searchState.value !== null),
        searchIsLoading,
        searchResults : computed(() => searchState.value?.data ?? []),
        searchTotalResults : computed(() =>searchState.value?.meta?.page?.total_results ?? 0),
        searchReset(){
            searchTerm.value = '';
        },
        searchTrackClick(documentId){},
        searchRefresh(){
            searchDebounced();
        },
        searchSuggestions: computed(() => {
            if (
                !!searchState.value.meta?.page?.total_results
            ) {
                let keys = [
                    "filter_series",
                    "filter_manufacturer",
                    "filter_sub_category",
                ];

                let suggestions = {};

                function mapCB(value, key) {
                    value = value.pop();
                    let label = Array.isArray(value[key])
                        ? value[key].pop()
                        : value[key];
                    return {
                        label,
                        query: {
                            filters: {
                                0: {
                                    field: "filter_category",
                                    values: { ...value["filter_category"] },
                                    type: "any",
                                },
                                1: {
                                    field: key,
                                    values: { ...[label] },
                                    type: "any",
                                },
                            },
                        },
                    };
                }

                function groupCB(result, key) {
                    return result[key];
                }

                keys.forEach((key) => {
                    suggestions[key] = _.groupBy(
                        searchState.value.data.filter((result) =>
                            groupCB(result, key)
                        ),
                        (result) => groupCB(result, key)
                    );
                    suggestions[key] = _.sortBy(
                        Object.values(suggestions[key]),
                        [(o) => o.length]
                    );
                    suggestions[key] = _.takeRight(suggestions[key], 1);
                    suggestions[key] = suggestions[key].map((value) =>
                        mapCB(value, key)
                    );
                });
                return suggestions;
            }
            return {};
        }),
    };

}
