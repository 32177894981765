<template>
    <div
        data-dialog="true"
        class="border border-keyline-primary rounded-md bg-gray-tier-4 w-96 shadow-app-standard"
    >
        <header
            class="flex items-center justify-between py-2 px-4 bg-gray-tier-4 rounded-t-md"
        >
            <h2 class="flex items-center gap-2 text-base font-medium">
                {{ $t("Add to") }}
            </h2>
            <DialogCloseButton @closed="close" />
        </header>
        <Divider />
        <div class="text-base p-4 pt-2 bg-gray-tier-3">
            <SeclockMultiselect
                :label="pluralize(data?.tray?.product_count, 'Add ## product to:', 'Add ## products to:')"
                v-model="form.destination"
                label-by="value"
                :options="config.pins.addToDestinations"
                :option-is-object="true"
                :large="true"
            />
        </div>
        <Divider />
        <div class="p-4 flex justify-end gap-2">
            <button @click.prevent="close" class="btn-app-sm-white-on-gray">
                {{ $t("Cancel") }}
            </button>
            <button :disabled="form.destination === null || inert" @click.prevent="addToDestination" class="btn-app-sm-white-on-red">
                {{ $t("Add") }} <loading-circle v-if="inert" class="ml-2" />
            </button>
        </div>
    </div>
</template>

<script setup>
import { Divider, DialogCloseButton, SeclockMultiselect, LoadingCircle } from "@/Components";
import {
    usePinsDrawer,
    useDialog,
    eventBus,
    useTrayCreateEditDialog,
    useTrayMoveDialog,
    useHelpers,
} from "@/Composables";
import { ref, computed, onMounted, onUnmounted } from "vue";
import config from "@/config";
import axios from "axios";
import {useForm} from "@inertiajs/vue3";

const { drawer, movePin, fetchPin, fetchTrays } = usePinsDrawer();
const { forceClose } = useDialog();
const { toggleTrayCreateEditDialog } = useTrayCreateEditDialog();
const { toggleTrayMoveDialog } = useTrayMoveDialog();
const { pluralize } = useHelpers()


// FORM HELPER
const form = useForm({
    destination: config.pins.addToDestinations[0],
});

//PROPS
const { data } = defineProps(["data"]);

function addToDestination() {

    let selection = form.destination

    let post_route = null;
    let post_value = null;
    if(data.tray) {
        post_route = selection.tray_route;
        post_value = data.tray.name;
    } else {
        post_route = selection.item_route;
        post_value = data.pin.pinnable_id;
    }

    //USING INERTIA FORM HELPER WITH SUBMIT
    form.submit('post', route(post_route,post_value), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: (response) => {
            data.count ;
            let count = data?.tray?.product_count ? data.tray.product_count : 1;
            let label = count > 1 ? 'products' : 'product';
            eventBus.emit('alert:show',{title:"Add To", message:count+" "+label+" added to "+selection.value})
            eventBus.emit('pins:tray-added-to', { destination: selection.value })
        },
        onError: (error) => {
            console.log("ERROR",error)
        },
        onFinish: () => close()
    });
}

function close(event) {
    forceClose(event);

    if (data?.callback) {
        data?.callback(event, data?.callback_params);
    }
}


onMounted(() => {

});

onUnmounted(() => {

});
</script>
