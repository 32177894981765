<template>
    <app-modal
        title="Export Project to PDF"
        submitText="Export Project"
        :valid="true"
        :notification="notification"
        @submit="onSubmit"
        @close="toggleModal(ProjectExportModal, $event, { ...data })"
    >
        <template #content>
            <div class="bg-gray-tier-4 px-6">
                <ul class="flex gap-x-8 -mb-px" role="tablist">
                    <li v-for="tab in tabs" :key="tab">
                        <button
                            type="button"
                            class="pt-4.5 pb-4 border-b-2"
                            :class="{
                                'border-royal text-default':
                                    tab === state.activeTab,
                                'text-gray-350 border-transparent':
                                    tab !== state.activeTab,
                            }"
                            @click="state.activeTab = tab"
                            v-text="tab"
                            role="tab"
                            :aria-selected="state.activeTab === tab"
                            :aria-controls="`tabpanel-${tab
                                .toLowerCase()
                                .replace(' ', '-')}`"
                        />
                    </li>
                </ul>
            </div>
            <divider />
            <div
                v-show="state.activeTab === 'Project information'"
                role="tabpanel"
                id="tabpanel-project-information"
            >
                <div class="p-6">
                    <dl
                        class="grid grid-cols-2 gap-4 text-sm leading-tight mb-4"
                    >
                        <div>
                            <dt class="type-secondary mb-0.5">Project Name</dt>
                            <dd>{{ data.project.label }}</dd>
                        </div>
                        <div>
                            <dt class="type-secondary mb-0.5">Architect</dt>
                            <dd>{{ data.project.meta.architect ?? "-" }}</dd>
                        </div>
                        <div>
                            <dt class="type-secondary mb-0.5">
                                General Contractor
                            </dt>
                            <dd>
                                {{
                                    data.project.meta.general_contractor ?? "-"
                                }}
                            </dd>
                        </div>
                        <div>
                            <dt class="type-secondary mb-0.5">
                                Job Reference Number
                            </dt>
                            <dd>
                                {{
                                    data.project.meta.job_reference_number ??
                                    "-"
                                }}
                            </dd>
                        </div>
                        <div class="col-span-2">
                            <dt class="type-secondary mb-0.5">Description</dt>
                            <dd>{{ data.project.meta.description ?? "-" }}</dd>
                        </div>
                    </dl>
                    <app-button
                        text="Edit project information"
                        @click="handleEditProject"
                    />
                </div>
                <divider />
                <div class="p-6 space-y-2">
                    <div class="flex gap-x-3">
                        <toggle
                            label="Show list prices in export"
                            v-model="exportForm.include_list_prices"
                        />
                        <p class="text-sm type-secondary">
                            Show list prices in export
                        </p>
                    </div>
                    <div class="flex gap-x-3">
                        <toggle
                            label="Show my costs in export"
                            v-model="exportForm.include_prices"
                        />
                        <p class="text-sm type-secondary">
                            Show my costs in export
                        </p>
                    </div>
                    <div class="flex gap-x-3">
                        <toggle
                            label="Include my costs in export"
                            v-model="exportForm.include_cut_sheets"
                        />
                        <p class="text-sm type-secondary">Include cut sheets</p>
                    </div>
                    <div class="flex gap-x-3" v-if="data.project.openings.length > 0">
                        <toggle
                            label="Include QR codes for openings"
                            v-model="exportForm.include_qr_codes"
                        />
                        <p class="text-sm type-secondary">Include QR codes for openings</p>
                    </div>
                </div>
                <divider />
                <div class="p-6 flex items-center">
                    <pdf-preview class="w-18 mr-5" />
                    <div class="text-xs">
                        <p v-text="filename" />
                        <p class="type-secondary mb-2.5">
                            {{ previewMetadata.join(" • ") }}
                        </p>
                        <a
                            :href="
                                route('web.account.projects.export.pdf', {
                                    project: data.project,
                                    ...exportForm.data(),
                                })
                            "
                            target="_blank"
                            class="inline-flex items-center relative border rounded-md transition h-8 px-4 border-keyline-secondary bg-white text-sm hover:border-gray-700 active:bg-gray-150 dark:bg-gray-700 dark:hover:bg-gray-650 dark:hover:border-gray-875 dark:active:bg-gray-800"
                            v-text="'Preview'"
                        />
                    </div>
                </div>
            </div>
            <div
                v-show="state.activeTab === 'Sets'"
                class="px-6 py-4"
                role="tabpanel"
                id="tabpanel-sets"
            >
                <fieldset>
                    <legend class="type-secondary text-xs">
                        Sets includes in export
                    </legend>
                    <div class="py-3">
                        <div class="seclock-input flex items-center">
                            <input
                                type="checkbox"
                                name="all"
                                id="all"
                                :checked="
                                    exportForm.set_ids.length ===
                                    data.project.sets.length
                                "
                                :indeterminate="
                                    exportForm.set_ids.length > 0 &&
                                    exportForm.set_ids.length <
                                        data.project.sets.length
                                "
                                @change="toggleAllSets"
                            />
                            <label for="all" class="text-xs font-medium">
                                Select All
                            </label>
                        </div>
                    </div>
                    <divider />
                    <template v-for="set in data.project.sets" :key="set.id">
                        <div class="py-3">
                            <div class="flex items-center justify-between">
                                <div class="seclock-input flex items-center">
                                    <input
                                        type="checkbox"
                                        :name="set.id"
                                        :id="set.id"
                                        :checked="
                                            exportForm.set_ids.includes(set.id)
                                        "
                                        @change="selectSet(set.id)"
                                    />
                                    <label :for="set.id" class="text-xs">
                                        <status-indicator
                                            :status="set.status_label"
                                            placement="after"
                                        >
                                            {{ set.label }}
                                        </status-indicator>
                                    </label>
                                </div>
                                <div
                                    class="flex-none flex items-center space-x-1 text-2xs type-secondary"
                                >
                                    <div
                                        v-for="opening in set.openings.slice(
                                            0,
                                            2
                                        )"
                                        :key="opening.id"
                                        class="bg-gray-tier-6 px-1 py-0.5 rounded-sm whitespace-nowrap max-w-[8ch] text-ellipsis overflow-hidden"
                                        :title="opening.label"
                                    >
                                        {{ opening.label }}
                                    </div>
                                    <div
                                        v-if="set.openings.length > 2"
                                        class="border border-gray-tier-7 px-1 py-0.5 rounded-sm relative group"
                                    >
                                        +{{ set.openings.length - 2 }}
                                        <div
                                            class="absolute w-10 bg-gray-tier-1 border border-keyline-primary p-2 rounded-md top-full left-1/2 -translate-x-1/2 mt-1 z-10 hidden group-hover:block"
                                        >
                                            <p
                                                v-for="opening in set.openings.slice(
                                                    2
                                                )"
                                                :key="opening.id"
                                            >
                                                {{ opening.label }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <divider />
                    </template>
                </fieldset>
            </div>
            <div
                v-show="state.activeTab === 'Additional files'"
                class="px-6 py-4"
                role="tabpanel"
                id="tabpanel-additional-files"
            >
                <div class="grid grid-cols-1 gap-1">
                    <attachment
                        v-for="(doc, i) in data.project.docs"
                        :key="i"
                        :file="doc"
                        :selected="exportForm.asset_ids.includes(doc.id)"
                        @select="selectFile(doc.id)"
                    />
                </div>
            </div>
        </template>
    </app-modal>
</template>

<script setup>
import {
    AppModal,
    AppButton,
    Attachment,
    Divider,
    PdfPreview,
    ProjectCreateEditModal,
    ProjectExportModal,
    StatusIndicator,
    Toggle,
} from "@/Components";
import {
    eventBus,
    useDrawerNotification,
    useHelpers,
    useModalDialog,
} from "@/Composables";
import { useForm } from "@inertiajs/vue3";

import { computed, inject, onMounted, reactive } from "vue";

let route = inject("route");

const { toggleModal } = useModalDialog();
const { notification } = useDrawerNotification();
const { slugify, pluralize } = useHelpers();

const { data } = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const state = reactive({
    activeTab: "Project information",
});

onMounted(() => {
    eventBus.on("form:reset", () => exportForm.reset());
});

const exportForm = useForm({
    include_list_prices: false,
    include_prices: false,
    include_cut_sheets: false,
    include_qr_codes: false,
    set_ids: [],
    asset_ids: [],
});

const filename = computed(() => {
    const prefix = slugify(data.project.label, '_');
    const extension =
        exportForm.include_cut_sheets || exportForm.include_qr_codes || exportForm.asset_ids.length > 0
            ? "zip"
            : "pdf";

    return `${prefix}.${extension}`;
});

onMounted(() => {
    selectAllSets();
});

const tabs = ["Project information", "Sets", "Additional files"];

const previewMetadata = computed(() => {
    return [
        pluralize(exportForm.set_ids.length, "## Set", "## Sets"),
        exportForm.include_cut_sheets ? "Cut Sheets" : null,
        exportForm.asset_ids.length > 0
            ? pluralize(
                  exportForm.asset_ids.length,
                  "## Additional File",
                  "## Additional Files"
              )
            : null,
    ].filter(Boolean);
});

function selectSet(id) {
    if (exportForm.set_ids.includes(id)) {
        exportForm.set_ids = exportForm.set_ids.filter((set) => set !== id);
    } else {
        exportForm.set_ids.push(id);
    }
}

function toggleAllSets() {
    if (exportForm.set_ids.length === data.project.sets.length) {
        exportForm.set_ids = [];
    } else {
        selectAllSets();
    }
}

function selectFile(id) {
    if (exportForm.asset_ids.includes(id)) {
        exportForm.asset_ids = exportForm.asset_ids.filter((file) => file !== id);
    } else {
        exportForm.asset_ids.push(id);
    }
}

function selectAllSets() {
    exportForm.set_ids = data.project.sets.map((set) => set.id);
}

function onSubmit(resolve, reject) {
    exportForm
        .post(route("web.account.projects.export.pdf", data.project), {
            onFinish: resolve,
        })
}

function handleEditProject($event) {
    toggleModal(ProjectCreateEditModal, $event, {
        editing: true,
        project: data.project,
    })
}
</script>
