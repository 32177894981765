import config from "@/config";
import useDialog from "./useDialog";
import { ModalTransition } from "@/Components";

export default function useModalDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleModal = (modal, event, data) => {
        toggleDialog(
            modal,
            ModalTransition,
            data,
            event,
            config.dialogs.modals.zindex,
            true
        );
    };

    return {
        forceClose,
        toggleModal,
    };
}
