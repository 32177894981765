<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd">
            <path d="M0 24V0h24v24z" />
            <path
                d="M8.62 15.5h6.76L12 18.88 8.62 15.5M5 14l7 7 7-7H5Zm0-4h14l-7-7-7 7Z"
                fill="#000"
                fill-rule="nonzero"
            />
        </g>
    </svg>
</template>
