<template>
    <section class="flex justify-between items-center gap-2 mb-6">
        <div class="flex items-center justify-start gap-3">
            <AppButton
                @click.stop.prevent="$emit('csvExport')"
                icon="file-download"
                text="CSV"
                :disabled="isDownloading || inert || isCsvDisabled"
                :loading="isDownloading"
            />
            <div
                class="type-tertiary flex gap-2 items-center"
                v-if="computedModel.size"
            >
                <div class="w-fit">
                    <SeclockMultiselect
                        v-model="computedModel.size"
                        :options="[10, 25, 50]"
                        label="Show"
                        label-position="side"
                        :contrast="false"
                    />
                </div>
            </div>
        </div>
        <div class="flex items-center gap-3">
            <slot name="default" />
            <SearchInput
                v-if="!searchHidden"
                class="w-56 h-10"
                v-model="computedModel.query"
                type="text"
                placeholder="Search"
            />
        </div>
    </section>
</template>

<script setup>
import { AppButton, SearchInput, SeclockMultiselect } from "@/Components";
import { computed } from "vue";

const props = defineProps([
    "modelValue",
    "searchHidden",
    "isDownloading",
    "isCsvDisabled",
]);
const emits = defineEmits(["update:modelValue", "csvExport"]);

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emits("update:modelValue", newValue);
    },
});
</script>
