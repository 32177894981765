<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="480.23"
        height="639.61"
        viewBox="0 0 480.23 639.61"
    >
        <g style="isolation: isolate">
            <path
                d="M.17 539.94c74.36 66.58 163.77 99.42 258.49 99.42C388.8 639.36 480 570.12 480 453c0-103.85-68.17-150.9-199.18-182.85-118.63-28.41-147-49.71-147-98.53C133.84 129.85 171 97 234.76 97c56.66 0 112.43 22.2 168.2 63.92l58.43-82.56C398.54 27.77 326.83.25 236.53.25 113.48.25 25 73 25 181.33c0 115.4 74.37 154.45 206.27 186.41 115.09 26.63 139.87 50.59 139.87 95.86 0 47.94-42.49 79-109.77 79"
                style="
                    fill: none;
                    stroke: #63666a;
                    stroke-miterlimit: 10;
                    stroke-width: 0.15px;
                "
            />
        </g>
    </svg>
</template>
