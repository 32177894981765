import config from "@/config";
import useDialog from "./useDialog";
import { ModalTransition, VideoDialog } from "@/Components";

export default function useVideoDialog() {
    const { toggleDialog, forceClose } = useDialog();

    const toggleVideoDialog = (event, data) => {
        toggleDialog(
            VideoDialog,
            ModalTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    return {
        forceClose,
        toggleVideoDialog,
    };
}
