<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1792"
        height="747"
        viewBox="0 0 1792 747"
    >
        <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M0 0c104.503 224.873 406.01 362.992 1117.557 533.353L1792 746.503H0V0Z"
        />
    </svg>
</template>
