<template>
    <div id="printQuoteDialog" class="relative bg-gray-tier-3 h-full w-screen at1024:shadow-xl at1024:w-[885px]">
        <header class="sticky top-0 z-50 bg-gray-tier-4">
            <div class="w-full flex items-center justify-between py-2 px-6">
                <h2>
                    {{ $t("Quote:") }}
                </h2>
                <span class="print:hidden flex items-center justify-end gap-2">
                    <AppButton @click="toggleEmailQuoteDialog($event, {id:data})" icon="icon-email-envelope" text="Email" />
                    <!-- Removed per JDV -->
<!--                    <AppButton-->
<!--                        @click="downloadQuote"-->
<!--                        icon="export-file"-->
<!--                        text="Download Full Details"-->
<!--                        :disabled="inert"-->
<!--                        :loading="isDownloading"-->
<!--                    />-->
                    <DialogCloseButton />
                </span>
            </div>
            <Divider />
        </header>
        <div v-if="quote" class="relative z-40 h-full overflow-y-auto pb-12">
            <div class="flex items-center justify-between px-6 py-3">
                <RecordHeader :record="quote" />
            </div>
            <Divider />
            <div class="p-6 mb-8">
                <QuoteRecord v-bind="props" />
            </div>
        </div>
        <div v-else class="print:hidden absolute inset-0 flex items-center justify-center">
            <LoadingSpinner :large="true"/>
        </div>
    </div>
</template>

<script setup>
import {
    DialogCloseButton,
    Divider,
    LoadingSpinner,
    QuoteRecord,
    RecordHeader,
    AppButton,
    AuditModal
} from "@/Components";
import {useEmailQuoteDialog, useModalDialog, useUser} from '@/Composables';

const { toggleEmailQuoteDialog } = useEmailQuoteDialog();
const { isEmployee } = useUser();
const { toggleModal } = useModalDialog();

const props = defineProps(["data", "quote", "isLoading", "columns", "rows"]);

</script>
