<template>
    <div class="pb-64 px-24 pt-12 absolute mx-auto overflow-y-auto">
        <div
            data-dialog="true"
            class="bg-white relative shadow-app-standard mx-auto w-[95vw]"
            :class="large? 'max-w-full' : 'max-w-3xl'"
        >
            <div class="z-40 absolute top-4 right-4 ml-auto">
                <DialogCloseButton @closed="forceClose" />
            </div>
            <header class="text-center p-8 at1024:p-16 at1024:pb-0 pb-0 relative">
                <h2 class="text-2xl at1024:text-3xl font-medium">{{ title }}</h2>
                <slot name="subheader" />
            </header>
            <div :class="classes ? [classes] :'p-8 at1024:p-16'">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import {DialogCloseButton} from "@/Components";
import {useDialog} from "@/Composables";

const { forceClose } = useDialog();

const props = defineProps(['title', 'classes', 'large']);
</script>
