<template>
    <div class="flex flex-col bg-gray-tier-3 relative w-[30vw] h-full">
        <header class="flex items-center gap-4 bg-gray-tier-4 px-4 py-2 sticky top-0">
            <h2 class="font-medium">{{ $t('Notifications') }}</h2>
            <LoadingSpinner v-if="isLoadingNotifications" />
            <div class="ml-auto">
                <AppButton @click="forceClose" icon="close" :icon-only="true"/>
            </div>
        </header>

        <Divider/>

        <div class="grow overflow-y-auto">
            <div v-if="notificationCount" :class="isLoadingNotifications ? 'opacity-50' : ''">
                <div class="flex items-baseline gap-4 justify-between px-4 py-1 border-b border-keyline-secondary">
                    <h3 class="text-xs uppercase tracking-wide font-bold type-tertiary">{{ notificationCount }} Unread</h3>
                    <button
                        v-if="notificationCount"
                        @click="markAllAsRead()"
                        class="text-sm text-red underline-offset-4 hover:underline"
                    >
                        {{ $t("Mark all as read") }}
                    </button>
                </div>

                <ul
                    class="duration-200 divide-y divide-gray-225 dark:divide-gray-875"
                >
                    <li v-for="notification in notifications" class="px-4 py-2 [&_a]:decoration-dotted">
                        <Notification
                            :notification="notification"
                            @mark-as-read="setNotificationAsRead(notification)"
                        />
                    </li>
                </ul>
            </div>
            <div v-else class="type-tertiary py-6 px-4 text-center text-sm flex flex-col items-center justify-center">
                <BaseSvg name="icon-check-outlined" class="text-apple" />
                <p>{{ $t("You are up to date!")}}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { AppButton, BaseSvg, Divider, LoadingSpinner, Notification } from '@/Components';
import { useDialog, useUser } from '@/Composables';

const { notificationCount, setNotificationAsRead, isLoadingNotifications, markAllAsRead, notifications } = useUser();

const { forceClose } = useDialog();

</script>
