<template>
    <div id="parent" class="relative" :inert="isLoading">
        <div :class="{ 'opacity-25': isLoading }">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import {useUser} from "@/Composables";
const {settings} = useUser();
const parent = ref("");

const props = defineProps({
    isLoading: {
        type: Boolean,
        required: true,
        default: false,
    },
});

watch(
    () => settings.value.is_sourcing_mode,
    (newValue) => {
        if (newValue) {
            document.body.classList.add("loading_is_sourcing");
        } else {
            document.body.classList.remove("loading_is_sourcing");
        }
    },
    { immediate: true }
);
</script>

<style>
@import "nprogress/nprogress.css";

#nprogress .bar {
    background: black !important; /* Default color */
    height: 2px !important;
}

#nprogress .peg {
    box-shadow: none !important;
}

body.loading_is_sourcing #nprogress .bar {
    background: white !important; /* Color when sourcing mode is active */
}
</style>

