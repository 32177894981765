<template>
    <DropdownWrapper :offset="offset" class="min-w-[11rem] w-auto">
        <div v-for="(group, i) in visibleOptions" :key="i" class="pt-2.5">
            <button
                v-for="option in group"
                :key="option.value"
                class="type-secondary hover:type-primary group flex gap-2 items-center text-left w-full px-3 py-2 hover:bg-gray-125 active:bg-gray-150 last-of-type:mb-2.5 pr-8"
                :data-dialog="option.dialog"
                @click="() => {
                    option.action($event);
                    $emit('select');
                }"
            >
                <BaseSvg
                    v-if="option.icon"
                    :name="`icon-${option.icon}`"
                    class="w-5 h-5 fill-inherit"
                    :class="option.icon === 'trash-can' ? '!text-scarlet' : ''"
                    aria-hidden="true"
                />
                <span
                    class="leading-4 whitespace-nowrap"
                    :class="[
                        option?.class,
                        { '!text-scarlet': option.icon === 'trash-can' },
                    ]"
                >
                    {{ option.label }}
                </span>
            </button>
            <Divider v-if="i < options.length - 1" />
        </div>
    </DropdownWrapper>
</template>

<script setup>
import { BaseSvg, Divider, DropdownWrapper } from "@/Components";
import {computed} from "vue";

const props = defineProps(["options", "offset"]);
const emits = defineEmits(['select']);

const visibleOptions = computed(() => {
    return props.options
        .map((group) =>
            group.filter((option) =>
                option.hasOwnProperty("visible") ? option.visible : true
            )
        )
        .filter((group) => group.length);
});
</script>
