<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="54"
        height="39"
        fill="none"
        viewBox="0 0 54 39"
    >
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.718"
            d="M52.623 35.67V24.505a2.319 2.319 0 0 0-2.319-2.319H7.194a2.319 2.319 0 0 0-2.318 2.319v11.163a2.319 2.319 0 0 0 2.319 2.32h43.11a2.319 2.319 0 0 0 2.318-2.32Z"
        />
        <path
            fill="none"
            d="M10.373 16.348h9.618a1.374 1.374 0 0 1 0 2.748H2.473"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.718"
            d="M10.373 16.348h9.618a1.374 1.374 0 0 1 0 2.748H2.473"
        />
        <path
            fill="none"
            d="M35.246 7.359 2.912 19.017a1.374 1.374 0 0 1-1.687-1.894c.158-.328.44-.58.784-.7L34.34 4.766c.269-.092.56-.1.832-.02"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.718"
            d="M35.246 7.359 2.912 19.017a1.374 1.374 0 0 1-1.748-.845 1.374 1.374 0 0 1 .845-1.748L34.34 4.764c.269-.092.56-.1.832-.02"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.718"
            d="M22.395 21.828v-2.37a.36.36 0 0 0-.36-.361h-5.462a.36.36 0 0 0-.36.36v2.37c0 .2.16.361.36.361h5.462c.199 0 .36-.161.36-.36ZM52.623 8.388v-6.41c0-.6-.487-1.088-1.089-1.088H36.537c-.602 0-1.09.487-1.09 1.089v6.41c0 .6.488 1.088 1.09 1.088h14.997c.602 0 1.089-.487 1.089-1.089Z"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.718"
            d="M39.4 6.385a1.202 1.202 0 1 0 0-2.405 1.202 1.202 0 0 0 0 2.405ZM48.674 6.385a1.202 1.202 0 1 0 0-2.405 1.202 1.202 0 0 0 0 2.405ZM2.472 19.096a1.374 1.374 0 1 0 0-2.748 1.374 1.374 0 0 0 0 2.748Z"
        />
    </svg>
</template>
