<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="43"
        fill="none"
        viewBox="0 0 56 43"
    >
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.825"
            d="M52.122 9.733V5.717l-12.818.128-1.04 1.332H27.523c-1.366 0-2.48-1.234-2.48-2.738s1.096-2.738 2.48-2.738h10.803l.978 1.46 14.132-.036a1.588 1.588 0 0 1 1.607 1.563v5.074a1.544 1.544 0 0 1-.993 1.46 1.486 1.486 0 0 1-1.928-1.489Z"
        />
        <path
            fill="none"
            d="M23.646 19.215v-4.381a3.286 3.286 0 0 1 3.286-3.286h14.969a3.286 3.286 0 0 1 3.285 3.286v4.38"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.825"
            d="M23.646 19.215v-4.381a3.286 3.286 0 0 1 3.286-3.286h14.969a3.286 3.286 0 0 1 3.285 3.286v4.38"
        />
        <path
            fill="none"
            d="M27.299 18.483v-3.198a.088.088 0 0 1 .087-.087H41.45a.088.088 0 0 1 .088.087v3.198"
        />
        <path
            stroke="#FAFAFA"
            fill="none"
            stroke-miterlimit="10"
            stroke-width="1.825"
            d="M27.299 18.483v-3.198a.088.088 0 0 1 .087-.087H41.45a.088.088 0 0 1 .088.087v3.198"
        />
        <path
            fill="none"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.825"
            d="M16.851 11.031c0-1.43-1.686-2.676-4.158-3.285V5.752c0-.31-.73-.58-1.825-.73V2.149c0-.23-.818-.42-1.826-.42-1.007 0-1.825.19-1.825.42v2.88c-1.095.143-1.826.413-1.826.73v1.98c-2.475.624-4.162 1.868-4.162 3.285v.15h.147v26.399c0 .985 3.431 1.789 7.666 1.789s7.667-.804 7.667-1.79V11.182h.135c.004-.051.007-.099.007-.15Z"
        />
        <path
            fill="none"
            d="M55.043 26.517V38.71a3.143 3.143 0 0 1-3.143 3.14H16.564a3.14 3.14 0 0 1-3.14-3.14V26.517"
        />
        <path
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.825"
            fill="#25282a"
            d="M55.043 26.517V38.71a3.143 3.143 0 0 1-3.143 3.14H16.564a3.14 3.14 0 0 1-3.14-3.14V26.517"
        />
        <path
            fill="#25282a"
            stroke="#FAFAFA"
            stroke-miterlimit="10"
            stroke-width="1.825"
            d="M51.9 18.482H16.567a3.143 3.143 0 0 0-3.143 3.144v4.665a3.143 3.143 0 0 0 3.143 3.144H51.9a3.143 3.143 0 0 0 3.143-3.143v-4.666a3.143 3.143 0 0 0-3.143-3.144Z"
        />
        <path
            fill="none"
            d="M23.645 32.356v1.96c0 .33-.146.596-.329.596H20.32c-.179 0-.325-.267-.325-.596v-4.15"
        />
        <path
            stroke="#FAFAFA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.825"
            fill="none"
            d="M23.645 32.356v1.96c0 .33-.146.596-.329.596H20.32c-.179 0-.325-.267-.325-.596v-4.15"
        />
        <path
            fill="none"
            d="M48.472 32.356v1.96c0 .33-.146.596-.328.596h-2.998c-.179 0-.325-.267-.325-.596v-4.15"
        />
        <path
            stroke="#FAFAFA"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.825"
            d="M48.472 32.356v1.96c0 .33-.146.596-.328.596h-2.998c-.179 0-.325-.267-.325-.596v-4.15"
        />
    </svg>
</template>
