import { ref } from "vue";

const store = ref({})
export default function useGlobalStore() {
    return {
        set(key, value){
            store.value[key] = value;
        },
        get(key){
            return store.value[key];
        },
    };
}
