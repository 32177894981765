<template>
    <div
        :disabled="disabled"
        @click="disabled ? null : searchInput?.focus()"
        :class="disabled ? '' : 'cursor-text'"
        class="bg-gray-tier-2 duration-150 border border-gray-tier-7 dark:bg-gray-775 focus-within:border-royal ring-1 ring-transparent focus-within:ring-royal flex max-h-8 items-center gap-0.5 px-2.5 rounded-full w-full"
    >
        <BaseSvg
            name="icon-magnifying-glass"
            class="text-gray-tier-7 h-5 shrink-0"
            aria-hidden="true"
        />
        <label :for="id" class="sr-only">{{ placeholder }}</label>
        <input
            :disabled="disabled"
            ref="searchInput"
            autocomplete="off"
            v-model="computedModel"
            type="text"
            class="appearance-none bg-transparent border-transparent focus:border-transparent focus-within:type-primary focus:ring-0 flex-1 grow leading-4 py-1.5 px-0 text-sm h-full"
            :id="id"
            :placeholder="placeholder"
        />

        <LoadingSpinner v-if="searchIsLoading" class="flex-none m-0 p-0 b-0" />
        <span v-else style="width: 28px;">&nbsp;</span>

        <div class="w-4">
            <button
                :disabled="disabled"
                v-if="computedModel?.length"
                class="flex items-center"
                aria-label="Clear search"
                @click="clear"
            >
                <BaseSvg
                    name="icon-close"
                    class="fill-gray-400 hover:fill-gray active:fill-black h-4"
                    aria-hidden="true"
                />
            </button>
        </div>
    </div>
</template>

<script setup>
import { BaseSvg, LoadingSpinner } from "@/Components";
import { computed, ref } from "vue";

const searchInput = ref();
const emit = defineEmits(["update:modelValue", "submit", "change", 'clear']);

const props = defineProps({
    placeholder: {
        type: String,
        required: false,
        default: "Search",
    },
    modelValue: {
        type: String,
        required: false,
        default: "",
    },
    searchIsLoading: {
        type: Boolean,
        required: false,
        default: false,
    },
    id: {
        type: String,
        required: false,
        default: "search",
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

let computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});

function clear() {
    searchInput.value.value = "";
    emit('clear');
    emit("update:modelValue", "");
    emit("submit", "");
}
</script>
