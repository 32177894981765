import {
    useAlerts,
    useCart,
    useDashboardCards,
    useDialog,
    useGlobalRouting,
    useSavedCart,
    useSegment,
    useChat,
    useUser,
    useOrderPreviewDialog,
    useProductInformationDialog, useQuotePreviewDialog, useImageDialog, eventBus,
} from "@/Composables";
import {router} from "@inertiajs/vue3";

let initialized = false;

export default function useGlobalEventListeners() {
    if (!import.meta.env.SSR) {
        if (!initialized) {
            initialized = true;
            useGlobalRouting().registerEventListeners();
            useCart().registerEventListeners();
            useSavedCart().registerEventListeners();
            useSegment().registerEventListeners();
            useChat().registerEventListeners();
            useUser().registerEventListeners();
            useDashboardCards().registerEventListeners();
            useDialog().registerEventListeners();
            useAlerts().registerEventListeners();
            useProductInformationDialog().registerEventListeners();
            useOrderPreviewDialog().registerEventListeners();
            useQuotePreviewDialog().registerEventListeners();
            document.addEventListener('click', (event) => {
                if(event.target.parentNode?.classList?.contains('vex-img')){
                    event.preventDefault();
                    useImageDialog().toggleImageDialog(event, {
                        title: event.target.parentNode.getAttribute('title'),
                        disclaimer: false,
                        selectedImage: 0,
                        images: [
                            event.target.parentNode.getAttribute('href')
                        ]
                    })
                }

            });
        }
    }

    return {
        initialized,
    };
}
