<template>
    <DropdownWrapper :offset="offset" class="mt-px py-1.5 w-min fill-gray-tier-7 group-hover:fill-default">
        <div v-for="(group, i) in visibleOptions" :key="i">
            <component
                v-if="group?.component"
                :is="group?.component"
            />
            <component
                :is="option?.link ? 'a' : 'button'"
                v-for="option in group"
                :key="option.value"
                class="pr-8 group flex gap-2 items-center text-left w-full px-3 py-2 hover:bg-gray-tier-5 active:bg-gray-tier-6 type-secondary hover:type-primary"
                :data-dialog="option.dialog"
                @click="
                    typeof option?.action === 'function'
                        ? option?.action($event)
                        : ''
                "
                :href="option?.link ? option.route : ''"
            >
                <BaseSvg
                    v-if="option.icon"
                    :name="`icon-${option.icon}`"
                    class="w-4 h-4 flex-none"
                    :class="[
                        option?.class,
                        { '!text-scarlet': option.icon === 'trash-can' },
                    ]"
                />
                <span
                    class="text-sm type-primary leading-4 whitespace-nowrap"
                    :class="[
                        option?.class,
                        { '!text-scarlet': option.icon === 'trash-can' },
                    ]"
                >
                    {{ option.label }}
                </span>
            </component>
            <Divider v-if="i < options.length - 1" class="my-1.5" />
        </div>
    </DropdownWrapper>
</template>

<script setup>
import { BaseSvg, Divider, DropdownWrapper } from "@/Components";
import { computed } from "vue";

const props = defineProps(["options", "offset", "component"]);

const visibleOptions = computed(() => {
    return props.options
        .map((group) =>
            group.filter((option) =>
                option.hasOwnProperty("visible") ? option.visible : true
            )
        )
        .filter((group) => group.length);
});
</script>
