import config from "@/config";
import { ModalTransition, ProductOptionsDialog } from "@/Components";
import { eventBus, useDialog } from "@/Composables";

const { toggleDialog, forceClose } = useDialog();

export default function useProductOptionsDialog(){

    const toggleProductOptionsDialog = (event, data) => {
        toggleDialog(ProductOptionsDialog, ModalTransition, data, event, config.dialogs.drawers.zindex, true);
    };

    function registerEventListeners() {
        eventBus.on('dialog:toggle-product-options', (event) => {
            toggleProductOptionsDialog(event.event, event.data, event.sourcing)
        });
    }

    return {
        forceClose,
        toggleProductOptionsDialog,
        registerEventListeners,
    };
}
