import { toRefs } from "vue";

export default function useI18n(props, context, dep) {
    const { locale, fallbackLocale } = toRefs(props);

    // =============== METHODS ==============

    const localize = (target) => {
        if (!target || typeof target !== "object") {
            return target;
        }

        if (target && target[locale.value]) {
            return target[locale.value];
        } else if (
            target &&
            locale.value &&
            target[locale.value.toUpperCase()]
        ) {
            return target[locale.value.toUpperCase()];
        } else if (target && target[fallbackLocale.value]) {
            return target[fallbackLocale.value];
        } else if (
            target &&
            fallbackLocale.value &&
            target[fallbackLocale.value.toUpperCase()]
        ) {
            return target[fallbackLocale.value.toUpperCase()];
        } else if (target && Object.keys(target)[0]) {
            return target[Object.keys(target)[0]];
        } else {
            return "";
        }
    };

    return {
        localize,
    };
}
