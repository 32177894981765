<template>
    <div
        class="status-particle-wrapper"
        :class="{
            'explode fade-out': state.animate,
        }"
    >
        <svg
            ref="particles"
            width="59"
            height="73"
            viewBox="0 0 59 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="19" cy="2" r="2" fill="#2DB85C" />
            <circle cx="6" cy="57" r="3" fill="#2DB85C" />
            <circle cx="39" cy="24" r="2" fill="#2DB85C" />
            <circle cx="34" cy="71" r="2" fill="#2DB85C" />
            <circle cx="28" cy="53" r="1" fill="#2DB85C" />
            <circle cx="58" cy="19" r="1" fill="#2DB85C" />
            <circle cx="21" cy="30" r="1" fill="#2DB85C" />
            <circle cx="1" cy="21" r="1" fill="#2DB85C" />
            <circle cx="53" cy="52" r="1" fill="#2DB85C" />
        </svg>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";

const state = reactive({
    animate: false,
});

const particles = ref(null);

onMounted(() => {
    particles.value.style.transform = `rotate(${Math.random() * 360}deg)`;
    setTimeout(() => {
        state.animate = true;
    }, 0);
});
</script>

<style>
.status-particle-wrapper {
    @apply absolute left-1
    w-20 h-20
    rounded-full
    transition-transform duration-500 origin-left;
    transform: scale(0) translateX(-50%);
    transition-timing-function: cubic-bezier(0.13, 0.7, 0.57, 1);
}

.status-particle-wrapper.explode {
    transform: scale(1) translateX(-50%);
}

.status-particles {
    @apply w-full h-full;
}

.fade-out {
    animation: fade-out 0.5s ease-in;
    opacity: 0;
}

@keyframes fade-out {
    0% {
        opacity: 100%;
    }
    60% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}
</style>
