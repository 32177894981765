<template>
    <transition
        appear
        name="fade"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
    >
        <div
            v-if="visible"
            class="fixed inset-0"
            :class="[
                {
                    // 'top-[var(--header-height)]': !settings.is_sourcing_mode,
                    'z-50': coversNavbar,
                    'z-10' : !coversNavbar,
                },
            ]"
        >
            <div @click="useDialog().forceClose" class="w-full h-full bg-overlay-gradient"></div>
        </div>
    </transition>
</template>

<style>
.fade-enter-active {
    transition: all 0.2s ease-in;
}

.fade-leave-active {
    transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-fast-enter-active {
    transition: all 0.1s ease-in;
}

.fade-fast-leave-active {
    transition: all 0.1s ease-out;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
}

.bg-overlay-gradient {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.55),
        rgba(0, 0, 0, 0.11)
    );
}
</style>

<script setup>
import { useDialog, useOverlay, useUser} from "@/Composables";

const { visible, zindex, coversNavbar } = useOverlay();
const { settings } = useUser();

const beforeLeave = () => document.body.classList.remove("no-scroll");
const afterEnter = () => document.body.classList.add("no-scroll");
</script>
