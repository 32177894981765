<template>
    <div class="relative">
        <button
            @click.prevent="isOpen = !isOpen"
            :disabled="isLoading || computed_disabled"
            class="duration-75 hover:ring-1 hover:border-blue ring-blue bg-gray-tier-2 text-left w-full flex items-center justify-between border px-4 py-3 rounded-md border-keyline-primary overflow-hidden"
            :class="{
                'flash-yellow': shouldFlash,
                '!justify-center': isLoading,
                '!border-blue ring-1': isOpen,
            }"
        >
            <!-- ----- Loading spinner ----- -->
            <span v-if="isLoading">
                <loading-circle />
            </span>

            <!-- ----- Selected value ----- -->
            <span v-else class="flex items-center gap-2">
                <!-- ----- Icon ----- -->
                <BaseSvg
                    v-if="computedIcon"
                    :name="computedIcon"
                    class="w-6"
                    :class="{
                        'relative top-2 type-primary':
                            computedLabel &&
                            (modelValue !== null || defaultValue !== null),
                        'type-secondary': !computedLabel,
                    }"
                />

                <!-- ----- Label ----- -->
                <span
                    v-if="computedLabel"
                    :class="{
                         'text-xs type-tertiary absolute top-1': computedLabel && (modelValue !== null || defaultValue !== null),
                         'text-base': !computedLabel || (modelValue === null && defaultValue === null),
                         'text-gray-300': disabled
                    }"
                >
                    {{ computedLabel }}
                </span>

                <!-- ----- Value ----- -->
                <span
                    v-if="modelValue !== null || defaultValue !== null"
                    :class="{
                        'relative top-2 type-primary':
                            computedLabel &&
                            (modelValue !== null || defaultValue !== null),
                        'text-base': !computedLabel,
                    }"
                >
                    {{
                        modelValue !== null
                            ? options[modelValue]?.value ?? options[modelValue]
                            : defaultValue !== null
                            ? options[defaultValue]
                            : modelValue
                    }}
                </span>

                <!-- ----- "NEW" Badge ----- -->
                <span
                    v-if="options[modelValue]?.isNew"
                    class="font-semibold bg-apple leading-none px-2 py-1 rounded-md text-white text-3xs uppercase"
                    :class="{
                        'relative top-2':
                            computedLabel &&
                            (modelValue !== null || defaultValue !== null),
                    }"
                >
                    {{ $t("New") }}
                </span>
            </span>
            <BaseSvg name="icon-unfold-more" v-show="!isLoading" :class="{'text-gray-300': disabled}"/>
        </button>
        <teleport to="#dialogs">
            <Transition name="fade-fast" class="relative z-50 bg-overlay-gradient inset-0">
                <AppModal v-if="isOpen" data-dialog="true" @click-outside="close" @close="close" :title="$t(label)" :read-only="true">
                    <template #description>
                        <div class="p-6 flex flex-col w-full gap-3">
                            <div class="flex items-center gap-1 flex-col at1024:flex-row justify-center">
                                <p class="type-tertiary">{{ $t("We offer all available options. Need something you don't see?") }}</p>
                                <button @click.stop.prevent="openChat" class="underline underline-offset-4 type-primary">{{ $t('Live chat') }}</button>
                            </div>
                            <SearchInput id="searchSpecs" :large="true" data-dialog-focus="true" v-model="search" />
                        </div>
                    </template>
                    <template #content>
                        <div class="p-6 pt-0 text-center flex flex-col gap-6">
                            <ul v-if="Object.entries(filteredOptions)?.length !== 0" class="overflow-y-auto h-full w-full flex flex-col gap-4">
                                <li v-for="(option, key) in filteredOptions" :key="key">
                                    <button
                                        @click="selected(key)"
                                        class="relative duration-75 at600:active:scale-95 w-full p-4 bg-gray-tier-5 at600:hover:bg-gray-tier-6 at600:active:!bg-gray-tier-6 rounded border border-keyline-primary"
                                    >
                                        {{ option?.value ?? option }}
                                        <span v-if="computedModel === key" class="absolute left-4">
                                            <BaseSvg name="icon-check" class="scale-150 text-green" />
                                        </span>
                                    </button>
                                </li>
                            </ul>
                            <RecordsNotFound v-else />
                        </div>
                    </template>
                </AppModal>
            </Transition>
        </teleport>
    </div>
</template>

<script setup>
import {AppModal, BaseSvg, LoadingCircle, RecordsNotFound, SearchInput} from "@/Components";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import _ from "lodash";
import {useChat} from "@/Composables";

const props = defineProps([
    "options",
    "label",
    "modelValue",
    "defaultValue",
    "icon",
    "searchHidden",
    "hoverBlue",
    "isLoading",
    "disabled",
]);
const emits = defineEmits(["update:modelValue"]);

let isOpen = ref(false);
let search = ref("");
let previous_key = ref(0);

const { showNewMessage } = useChat();
const openChat = () => {
    let message = "I have a question about " + props.label + " options" + (props.card ? " for "+props.card.item_id : "") +" I need: "
    showNewMessage(message)
};

const computedModel = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emits("update:modelValue", newValue);
    },
});

const afterEnter = () =>
    document.querySelector("[data-dialog-focus='true']")?.focus();

let filteredOptions = computed(() => {
    if (search.value) {
        return _.pickBy(props.options, (option) => {
            let value = option?.value ?? option;
            return value.toLowerCase().includes(search.value.toLowerCase());
        });
    }
    return props.options;
});

let computedLabel = computed(() => {
    // console.log({
    //     'method': 'computedLabel',
    //     'label':props.label,
    //     'modelValue':props.modelValue,
    //     'defaultValue':props.defaultValue,
    //     });

    let label = props.label?.length ? props.label : false;
    let hasModelValue = props.modelValue !== null ?? false;
    let hasDefaultValue = props.defaultValue !== null ?? false;
    let computed_label = "";

    if (hasModelValue) {
        computed_label = props.options[props.modelValue]?.label ?? label;
    } else if (hasDefaultValue) {
        computed_label = props.options[props.defaultValue]?.label ?? label;
    } else {
        computed_label = label;
    }

    return computed_label;
});

let computedIcon = computed(() => {
    let icon = props.icon?.length ? props.icon : false;
    let hasModelValue = props.modelValue !== null ?? false;
    let computed_icon = "";

    if (hasModelValue) {
        computed_icon = props.options[props.modelValue]?.icon ?? icon;
    } else {
        computed_icon = icon;
    }

    return computed_icon;
});

const computed_disabled = computed(() => {
    return props.disabled ?? props.options.length;
});

const shouldFlash = ref(false);

let selected = (key) => {
    isOpen.value = false;
    search.value = null;

    emits("update:modelValue", key); //The key of the option that was selected in the SearchSelect is bound to the v-model
    emits("select", key, previous_key.value);
    previous_key.value = key;
    // added this setTimeout so the user can see the check mark move to the selected value. Remove this if you think it doesn't feel right.
    setTimeout(() => {
        isOpen.value = false;
        search.value = null;
        previous_key.value = key;

        shouldFlash.value = true;
        setTimeout(() => {
            shouldFlash.value = false;
        }, 2000);
    }, 100)
};

//If a new option is added and designated as new, make it the selected option
watch(
    () => props.options,
    () => {
        let new_option_key = Object.values(props.options ?? []).findIndex((option) => option?.isNew);
        if (new_option_key > 0) {
            selected(new_option_key);
        }
    }
);

const escapeKeypress = (event) => {
    if (event.key === 'Escape') {
        isOpen.value = false;
    }}

onMounted(() => {
    document.addEventListener('keyup', (event) => escapeKeypress(event));
    emits(
        "update:modelValue",
        props.defaultValue ? props.defaultValue : props.modelValue
    );
});

onUnmounted(() => {
    document.removeEventListener("keyup", (event) => escapeKeypress(event))
});
const close = () => (isOpen.value = false);
</script>
