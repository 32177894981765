import { eventBus } from "@/Composables";
import {ref} from "vue";

//Reference: https://developers.intercom.com/installing-intercom/web/methods/

let isOpen = ref(false);

export default function useChat() {
    const openChat = (event) => {
        try {
            window.Intercom('show', event);
            isOpen.value = true;
        } catch (error) {
            console.error(error);
            eventBus.emit('alert:show', {
                title: 'Error',
                message: 'An unexpected error occurred.',
                level: 'danger',
            });
        }
    };

    const closeChat = (event) => {
        try {
            window.Intercom('hide');
            isOpen.value = false;
        } catch (error) {
            console.error(error);
        }
    };

    const toggleChat = (event) => {
        if (isOpen.value) {
            closeChat(event);
        }else{
            openChat(event);
        }
    }

    const showNewMessage = (pre_populate) => {
        try {
            let options = (typeof pre_populate === 'string' || pre_populate instanceof String) ? pre_populate : null;
            window.Intercom('showNewMessage', options);
            isOpen.value = true;
        } catch (error) {
            eventBus.emit('alert:show', {
                title: 'Error',
                message: 'An unexpected error occurred.',
                level: 'danger',
            });
        }
    };

    const openArticle = (articleID) => {
        try {
            window.Intercom('showArticle', articleID);
            isOpen.value = true;
        } catch (error) {
            eventBus.emit('alert:show', {
                title: 'Error',
                message: 'An unexpected error occurred.',
                level: 'danger',
            });
        }
    };

    function registerEventListeners() {
        eventBus.on("chat:open", (event) => openChat(event));
        eventBus.on("chat:close", (event) => closeChat(event));
        eventBus.on("chat:showNewMessage", (event) => showNewMessage(event));
        eventBus.on("chat:openArticle", (event) => openArticle(event));
    }

    function setIsOpen() {
        isOpen.value = true;
    }

    function setIsClosed() {
        isOpen.value = false;
    }

    return {
        registerEventListeners,
        openChat,
        closeChat,
        showNewMessage,
        openArticle,
        isOpen,
        toggleChat,
        setIsOpen,
        setIsClosed
        /*TODO: OpenIntercomArticle missing
            Need to find all occurrences of Intercom in the code base and ensure that they are all using this composable and not directly
            calling the Intercom API methods.
        * */
    };
}
