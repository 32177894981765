import config from "@/config";
import useDialog from "./useDialog";
import { ProductSearchDialog, RightDrawerTransition } from "@/Components";

export default function useProductSearchDialog() {
    const { forceClose, toggleDialog } = useDialog();

    const toggleProductSearchDialog = (event, data) => {
        toggleDialog(
            ProductSearchDialog,
            RightDrawerTransition,
            data,
            event,
            config.dialogs.drawers.productSearchDialog.zindex,
            true
        );
    };

    return {
        forceClose,
        toggleProductSearchDialog,
    };
}
