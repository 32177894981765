import {inject, nextTick, ref} from "vue";
import { useForm } from "@inertiajs/vue3";
import {eventBus, useDialog, useSegment} from "@/Composables";

let initialized = false;
const count = ref(0);
let route = null;

export default function useCart() {
    function genericToastError(errors) {
        if (errors.length) {
            eventBus.emit("alert:show", {
                title: "Error",
                message:
                    "There was an error processing your request. Please try again.",
                level: "danger",
            });
        }
    }

    function cartAddItem({
        sku,
        quantity = 1,
        options = {},
        meta = {},
        remainOpen = false,
    } = {}) {

        let form = useForm({
            sku,
            quantity,
            options,
            meta,
        });

        //SEC-2857 : If there is a finish alert while adding a product, ask for confirmation before proceeding.
        let proceed_with_addition = true;
        if(meta?.has_finish_alert) {
            if (confirm("This finish is polished bright, not the more common satin finish. Add to cart??") === true) {
                proceed_with_addition = true;
            } else {
                proceed_with_addition = false;
                nextTick().then(() => {
                    eventBus.emit("cart:add-item-cancelled")
                })
            }
        }

        if(proceed_with_addition) {
            form.post(route("web.cart.item.store"), {
                preserveScroll: true,
                only: ['errors','flash','cart'],
                onSuccess: (response) => {

                    //SEC-2342 : In the event an item is not added to the cart the "count" property will not be available
                    //  and we do not need to fire the product-added event.
                    if(response.props.flash.status?.cart_count) {
                        count.value = response.props.flash.status.cart_count;
                    }

                    let segment = response.props.flash.status?.segment;

                    console.log(segment)

                    if(Object.keys(segment??{}).length) {
                        //SEC-2154 : Track Priority Build products added to cart
                        if(segment.inventory < 1 && segment.item_status === 'A') {
                            eventBus.emit("cart:priority-build-added", segment);
                        }

                        eventBus.emit("cart:product-added", segment);
                    }


                    if(!remainOpen) {
                        useDialog().forceClose();
                    }
                },
                onError: (errors) => {
                    if (errors.sku) {
                        eventBus.emit("alert:show", {
                            title: "Error",
                            message: errors.sku,
                            level: "danger",
                        });
                    } else {
                        genericToastError(errors);
                    }
                },
            });
        }

    }

    function cartRemoveItem(cartable_id) {
        let form = useForm({});

        form.delete(route("web.cart.item.destroy", cartable_id), {
            preserveScroll: true,
            only: ["cart", "errors", "flash"],
            onSuccess: () => count.value--,
            onError: (errors) => {
                genericToastError(errors);
            },
        });
    }

    function cartRemoveItemsBulk({ cartable_ids = [], clear = false } = {}) {
        let form = useForm({
            cartable_ids,
            clear,
        });

        form.delete(route("web.cart.item.destroy.bulk"), {
            preserveScroll: true,
            only: ["cart", "errors", "flash"],
            onSuccess: () => count.value = 0,
            onError: (errors) => {
                genericToastError(errors);
            },
        });
    }

    if (!import.meta.env.SSR) {
        if (!initialized) {
            initialized = true;
            count.value = DynamicSettings?.user?.cart_count ?? 0;
            route = inject("route");
        }
    }

    function registerEventListeners() {
        eventBus.on("cart:add-item", (event) => cartAddItem(event));
        eventBus.on("cart:remove-item", (event) => cartRemoveItem(event));
        eventBus.on("cart:remove-item-bulk", (event) =>
            cartRemoveItemsBulk(event)
        );
    }

    return {
        cartAddItem,
        cartRemoveItem,
        cartRemoveItemsBulk,
        registerEventListeners,
        cartItemCount: count,
    };
}
