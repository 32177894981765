<template>
    <app-modal
        :title="$t('Purchase hardware')"
        :submit-text="`Add ${pluralize(
            hardwareCount,
            '## item',
            '## items'
        )} to cart`"
        :notification="notification"
        @submit="onSubmit"
        @close="() => forceClose()"
    >
        <template #content>
            <p class="type-secondary text-sm leading-5 p-6 pt-4">
                Add all
                {{ pluralize(hardwareCount, "## item", "## items") }} ({{quantityCount}} total) from {{ projectName }} to your cart?
            </p>
        </template>
    </app-modal>
</template>

<script setup>
import { AppModal } from "@/Components";
import { useDialog, useDrawerNotification, useHelpers } from "@/Composables";
import { computed, inject } from "vue";
import { router } from "@inertiajs/vue3";

const route = inject("route");
const { forceClose } = useDialog();
const { pluralize } = useHelpers();
const { notify, notification } = useDrawerNotification();

const { data } = defineProps({
    data: {
        type: Object,
        required: true,
    },
});
const projectName = computed(() => data?.project?.label);
const hardwareCount = computed(() => Object.entries(data?.project?.items)?.length);
const quantityCount = computed(() =>
    Object.values(data?.project?.items)?.reduce((total, item) => total + item.pivot.quantity, 0)
);

function onSubmit(resolve, reject) {
    router.post(route("web.account.projects.export.cart", data.project), null, {
        onSuccess: resolve,
        onError: (response) => {
            notify("Could not add project hardware to cart", "error");
            reject(response);
        },
    });
}
</script>
